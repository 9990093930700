import axios from "axios";
import { SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();
var err;
//////////////////////////////////////////////////////////////////////////////////////////////
function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

export const getSVMAction = {
  FETCH_SVM: "FETCH_SVM",
  FETCH_SVM_FULFILLED: "FETCH_SVM_FULFILLED",
  FETCH_SVM_REJECTED: "FETCH_SVM_REJECTED",
  SET_SVM_ERROR: "SET_SVM_ERROR",
  // LOGOUT: "LOGOUT"
};

export function getSVM({ access_token }) {
  //

  return (dispatch) =>
    axios({
      method: "get",
      url:
        `${SystemService_URL}/getAllSystemValueMastersDTO`,
      headers: {
        "Content-Type": "application/json",
        transactionId: "1",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(getSVMRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSVMSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(getSVMError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSVMError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSVMError(err));
              break;
            case 500:
              dispatch(getSVMError("Server Error"));
              break;
            default:
              dispatch(getSVMError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSVMRequest() {
  return {
    type: getSVMAction.FETCH_SVM,
    isPosting: true,
  };
}
export function getSVMError(error) {
  return {
    type: getSVMAction.FETCH_SVM_REJECTED,
    payload: error,
  };
}

// export function logout() {
//   return {
//     type: getAllSVMAction.LOGOUT,
//     payload: "Session Expired. Please Login Again"
//   };
// }

export function getSVMSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getSVMAction.FETCH_SVM_FULFILLED,
      payload: props,
    });
  };
}

export function setSVMError() {
  return (dispatch) => dispatch(setSVMRequest());
}
export function setSVMRequest() {
  return {
    type: getSVMAction.SET_SVM_ERROR,
  };
}
