import {
  SaveHostingProviderActions,
  GetHostingProviderDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveHostingProviderActions
} from "./actions";

const initState = {
  hostingSuccessMessage: null,
  hostingErrorMessage: null,
  HostingProviderStatus: null,
  HostingProviderMaster: [],
  HostingProviderData: null,
  ActiveHostingProviderList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveHostingProviderActions.SAVE_HOSTING_PROVIDER:
      return {
        ...state,
        isLoading: true,
        hostingErrorMessage: null,
        modalActive: false
      };
    case SaveHostingProviderActions.SAVE_HOSTING_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        HostingProviderStatus: payload.status,
        hostingErrorMessage: null,
        hostingSuccessMessage: "Hosting Provider added successfully!"
      };

    case SaveHostingProviderActions.SAVE_HOSTING_PROVIDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        HostingProviderStatus: null,
        hostingErrorMessage: payload
      };
    case SaveHostingProviderActions.SAVE_HOSTING_PROVIDER_STATUS:
      return {
        ...state,
        HostingProviderStatus: 0,
        hostingErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetHostingProviderDetailsActions.FETCH_HOSTING_DETAILS:
      return {
        ...state,
        isLoading: true,
        hostingErrorMessage: null,
        modalActive: false,
        HostingProviderData: null
      };
    case GetHostingProviderDetailsActions.FETCH_HOSTING_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        HostingProviderData: payload.data,
        HostingProviderDataStatus: payload.status,
        hostingErrorMessage: null
      };
    case GetHostingProviderDetailsActions.FETCH_HOSTING_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        hostingErrorMessage: payload,
        HostingProviderData: null
      };
    case GetHostingProviderDetailsActions.FETCH_HOSTING_DETAILS_STATUS:
      return {
        ...state,
        HostingProviderData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveHostingProviderActions.FETCH_ACTIVE_HOSTING_PROVIDER:
      return {
        ...state,
        isLoading: true,
        hostingErrorMessage: null,
        modalActive: false,
        ActiveHostingProviderList: null
      };
    case GetActiveHostingProviderActions.FETCH_ACTIVE_HOSTING_PROVIDER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveHostingProviderList: payload.data,
        hostingErrorMessage: null
      };
    case GetActiveHostingProviderActions.FETCH_ACTIVE_HOSTING_PROVIDER_REJECTED:
      return {
        ...state,
        isLoading: false,
        hostingErrorMessage: payload,
        ActiveHostingProviderList: null
      };
    case GetActiveHostingProviderActions.FETCH_ACTIVE_HOSTING_PROVIDER_STATUS:
      return {
        ...state,
        ActiveHostingProviderList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        hostingSuccessMessage: null,
  hostingErrorMessage: null,
  HostingProviderStatus: null,
  HostingProviderMaster: [],
  HostingProviderData: null,
  ActiveHostingProviderList: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
