import axios from "axios";
import { clearLogoutData } from "../Logout/actions";
import {
  AssetManagementService_URL,
  SystemService_URL,
  RopaManagementService_URL,
} from "../Url";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDataProcCatActions = {
  SAVE_DATA_PROC_CAT: "SAVE_DATA_PROC_CAT",
  SAVE_DATA_PROC_CAT_STATUS: "SAVE_DATA_PROC_CAT_STATUS",
  SAVE_DATA_PROC_CAT_SUCCESS: "SAVE_DATA_PROC_CAT_SUCCESS",
  SAVE_DATA_PROC_CAT_FAILURE: "SAVE_DATA_PROC_CAT_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveDataProcCatActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveDataProcCatRequest() {
  return {
    type: SaveDataProcCatActions.SAVE_DATA_PROC_CAT,
    isPosting: true,
  };
}

export function SaveDataProcCatSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataProcCatActions.SAVE_DATA_PROC_CAT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataProcCatFailure(error) {
  return {
    type: SaveDataProcCatActions.SAVE_DATA_PROC_CAT_FAILURE,
    payload: error,
  };
}

export function SaveDataProcCatError(error) {
  return {
    type: SaveDataProcCatActions.SAVE_DATA_PROC_CAT_FAILURE,
    payload: error,
  };
}

export function saveDataProcCat(props) {
  
  var value = {
    id: props.dataProcCatId || 0,
    name: props.dataProcCatName,
    description: props.description,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_25/saveDataProcessorCategory`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDataProcCatRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataProcCatSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          // dispatch(SaveLegalBasisError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          // case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveLegalBasisError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveLegalBasisError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDataProcCatDetailsActions = {
  FETCH_DATA_PROC_CAT_DETAILS: "FETCH_DATA_PROC_CAT_DETAILS",
  FETCH_DATA_PROC_CAT_DETAILS_STATUS: "FETCH_DATA_PROC_CAT_DETAILS_STATUS",
  FETCH_DATA_PROC_CAT_DETAILS_FULFILLED:
    "FETCH_DATA_PROC_CAT_DETAILS_FULFILLED",
  FETCH_DATA_PROC_CAT_DETAILS_REJECTED: "FETCH_DATA_PROC_CAT_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataProcCatDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_25/getDataProcessorCategoryById?id=` +
        params.dataProcCatId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataProcCatDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataProcCatDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          // dispatch(getLegalBasisDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getLegalBasisDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getLegalBasisDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataProcCatDetailsRequest() {
  return {
    type: GetDataProcCatDetailsActions.FETCH_DATA_PROC_CAT_DETAILS,
    isPosting: true,
  };
}
export function getDataProcCattDetailsError(error) {
  return {
    type: GetDataProcCatDetailsActions.FETCH_DATA_PROC_CAT_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataProcCatDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetDataProcCatDetailsActions.FETCH_DATA_PROC_CAT_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataSubCatDetailsStatus() {
  return (dispatch) => dispatch(getDataSubCatDetailsStatusRequest());
}
export function getDataSubCatDetailsStatusRequest() {
  return {
    type: GetDataProcCatDetailsActions.FETCH_DATA_PROC_CAT_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataSubCatDetailsErrorRequest());
}
export function getDataSubCatDetailsErrorRequest() {
  return {
    type: GetDataProcCatDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDataProcCatActions = {
  FETCH_ACTIVE_DATA_PROC_CAT: "FETCH_ACTIVE_DATA_PROC_CAT",
  FETCH_ACTIVE_DATA_PROC_CAT_STATUS: "FETCH_ACTIVE_DATA_PROC_CAT_STATUS",
  FETCH_ACTIVE_DATA_PROC_CAT_FULFILLED: "FETCH_ACTIVE_DATA_PROC_CAT_FULFILLED",
  FETCH_ACTIVE_DATA_PROC_CAT_REJECTED: "FETCH_ACTIVE_DATA_PROC_CAT_REJECTED",
};
export function getActiveDataProcCatList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${RopaManagementService_URL}/findAllDataProcessorCategory?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    }).then((response) => {
        dispatch(getActiveDataProcCatListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataProcCatListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveDataProcCatListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;          
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataProcCatListRequest() {
  return {
    type: GetActiveDataProcCatActions.FETCH_ACTIVE_DATA_PROC_CAT,
    isPosting: true,
  };
}
export function getActiveDataProcCatListError(error) {
  return {
    type: GetActiveDataProcCatActions.FETCH_ACTIVE_DATA_PROC_CAT_REJECTED,
    payload: error,
  };
}

export function getActiveDataProcCatListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataProcCatActions.FETCH_ACTIVE_DATA_PROC_CAT_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataProcCatListStatus() {
  return (dispatch) => dispatch(getActiveDataProcCatListStatusRequest());
}
export function getActiveDataProcCatListStatusRequest() {
  return {
    type: GetActiveDataProcCatActions.FETCH_ACTIVE_DATA_PROC_CAT_STATUS,
  };
}
