import {
  SaveDataDisposalActions,
  GetDataDisposalDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataDisposalActions
} from "./actions";

const initState = {
  dataDisposalSuccessMessage: null,
  dataDisposalErrorMessage: null,
  dataDisposalStatus: null,
  dataDisposalMaster: [],
  dataDisposalData: null,
  ActiveDataDisposalList: null
};

export default function DataList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataDisposalActions.SAVE_DATA_DISPOSAL:
      return {
        ...state,
        isLoading: true,
        dataDisposalErrorMessage: null,
        modalActive: false
      };
    case SaveDataDisposalActions.SAVE_DATA_DISPOSAL_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataDisposalStatus: payload.status,
        dataDisposalErrorMessage: null,
        dataDisposalSuccessMessage: "Data disposal added successfully!"
      };

    case SaveDataDisposalActions.SAVE_DATA_DISPOSAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataDisposalStatus: null,
        dataDisposalErrorMessage: payload
      };
    case SaveDataDisposalActions.SAVE_DATA_DISPOSAL_STATUS:
      return {
        ...state,
        dataDisposalStatus: 0,
        dataDisposalErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataDisposalDetailsActions.FETCH_DATA_DISPOSAL_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataDisposalErrorMessage: null,
        modalActive: false,
        dataDisposalData: null
      };
    case GetDataDisposalDetailsActions.FETCH_DATA_DISPOSAL_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataDisposalData: payload.data,
        dataDisposalDataStatus: payload.status,
        dataDisposalErrorMessage: null
      };
    case GetDataDisposalDetailsActions.FETCH_DATA_DISPOSAL_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataDisposalErrorMessage: payload,
        dataDisposalData: null
      };
    case GetDataDisposalDetailsActions.FETCH_DATA_DISPOSAL_DETAILS_STATUS:
      return {
        ...state,
        dataDisposalData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataDisposalActions.FETCH_ACTIVE_DATA_DISPOSAL:
      return {
        ...state,
        isLoading: true,
        dataDisposalErrorMessage: null,
        modalActive: false,
        ActiveDataDisposalList: null
      };
    case GetActiveDataDisposalActions.FETCH_ACTIVE_DATA_DISPOSAL_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataDisposalList: payload.data,
        dataDisposalErrorMessage: null
      };
    case GetActiveDataDisposalActions.FETCH_ACTIVE_DATA_DISPOSAL_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataDisposalErrorMessage: payload,
        ActiveDataDisposalList: null
      };
    case GetActiveDataDisposalActions.FETCH_ACTIVE_DATA_DISPOSAL_STATUS:
      return {
        ...state,
        ActiveDataDisposalList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataDisposal: null,
        dataDisposalStatus: null,
        dataDisposalSuccessMessage: null,
        GetdataDisposalStatus: null,
        dataDisposalData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
