import {
  saveRequestSourcesActions,
  deleteRequestSourcesActions,
  GetRequestSourcesDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRequestSourcesActions,
} from "./actions";

const initState = {
  requestSuccessMessage: null,
  requestSourcesErrorMessage: null,
  RequestSourcesStatus: null,
  RequestSourcesMaster: [],
  RequestSourcesData: null,
  ActiveRequestSourcesList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveRequestSourcesActions.SAVE_REQUEST_SOURCES:
      return {
        ...state,
        isLoading: true,
        requestSourcesErrorMessage: null,
        modalActive: false,
      };
    case saveRequestSourcesActions.SAVE_REQUEST_SOURCES_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestSourcesStatus: payload.status,
        requestSourcesErrorMessage: null,
        requestSuccessMessage: "Request Type added successfully!",
      };

    case saveRequestSourcesActions.SAVE_REQUEST_SOURCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        RequestSourcesStatus: null,
        requestSourcesErrorMessage: payload,
      };
    case saveRequestSourcesActions.SAVE_REQUEST_SOURCES_STATUS:
      return {
        ...state,
        RequestSourcesStatus: 0,
        requestSourcesErrorMessage: null,
      };

    // Delete Request Type

    case deleteRequestSourcesActions.DELETE_REQUEST_SOURCES:
      return {
        ...state,
        isLoading: true,
        requestSourcesErrorMessage: null,
        modalActive: false,
      };
    case deleteRequestSourcesActions.DELETE_REQUEST_SOURCES_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestSourcesStatus: payload.status,
        requestSourcesErrorMessage: null,
        requestSuccessMessage: "Request Type Deleted successfully!",
      };

    case deleteRequestSourcesActions.DELETE_REQUEST_SOURCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        RequestSourcesStatus: null,
        requestSourcesErrorMessage: payload,
      };
    case deleteRequestSourcesActions.DELETE_REQUEST_SOURCES_STATUS:
      return {
        ...state,
        RequestSourcesStatus: 0,
        requestSourcesErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetRequestSourcesDetailsActions.FETCH_REQUEST_SOURCES_DETAILS:
      return {
        ...state,
        isLoading: true,
        requestSourcesErrorMessage: null,
        modalActive: false,
        RequestSourcesData: null,
      };
    case GetRequestSourcesDetailsActions.FETCH_REQUEST_SOURCES_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestSourcesData: payload.data,
        RequestSourcesDataStatus: payload.status,
        requestSourcesErrorMessage: null,
      };
    case GetRequestSourcesDetailsActions.FETCH_REQUEST_SOURCES_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        requestSourcesErrorMessage: payload,
        RequestSourcesData: null,
      };
    case GetRequestSourcesDetailsActions.FETCH_REQUEST_SOURCES_DETAILS_STATUS:
      return {
        ...state,
        RequestSourcesData: null,
      };
    ///Active requests
    case GetActiveRequestSourcesActions.FETCH_ACTIVE_REQUEST_SOURCES:
      return {
        ...state,
        isLoading: true,
        requestSourcesErrorMessage: null,
        modalActive: false,
        ActiveRequestSourcesList: null,
      };
    case GetActiveRequestSourcesActions.FETCH_ACTIVE_REQUEST_SOURCES_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveRequestSourcesList: payload.data,
        requestSourcesErrorMessage: null,
      };
    case GetActiveRequestSourcesActions.FETCH_ACTIVE_REQUEST_SOURCES_REJECTED:
      return {
        ...state,
        isLoading: false,
        requestSourcesErrorMessage: payload,
        ActiveRequestSourcesList: null,
      };
    case GetActiveRequestSourcesActions.FETCH_ACTIVE_REQUEST_SOURCES_STATUS:
      return {
        ...state,
        ActiveRequestSourcesList: null,
      };
    // clear requestSources details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRequestSources: null,
        RequestSourcesStatus: null,
        requestSuccessMessage: null,
        GetRequestSourcesStatus: null,
        RequestSourcesData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
