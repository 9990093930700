import {
  saveTemplateActions,
  deleteTemplateActions,
  deleteTemplateGroupActions,
  GetTemplateDetailsActions,
  GetNewTempListActions,
  GetNewTempDataActions,
  saveTemplateGroupActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetTemplateGroupDetailsActions,
  GetActiveTemplateActions,
  GetActiveTemplateGroupActions,
  saveTemplateFieldActions,
  GetTemplateFieldDetailsActions,
  GetActiveTemplateListActions,
} from "./actions";

const initState = {
  templateSuccessMessage: null,
  templateErrorMessage: null,
  TemplateStatus: null,
  TemplateMaster: [],
  TemplateData: null,
  ActiveTemplateList: null,
  newFieldStatus:null,
  newFieldData:null,
  newFielErrorMessage:null,
  TemplateGroupData:[],
  TemplateGroupStatus: null,
  templateGroupSuccessMessage: null,
  templateGroupErrorMessage: null,
  ActiveTemplateGroupList: null,

  TemplateFieldData:[],
  TemplateFieldDataStatus: null,
  templateFieldSuccessMessage: null,
  templateFieldErrorMessage: null,
  ActiveTemplateFieldList: null,

  TemplateRopaData:[],
  TemplateDataStatus: null,
  templateDataSuccessMessage: null,
  templateDataErrorMessage: null,

  ActiveAllTemplateList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveTemplateActions.SAVE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        templateErrorMessage: null,
        modalActive: false
      };
    case saveTemplateActions.SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        TemplateStatus: payload.status,
        templateErrorMessage: null,
        templateSuccessMessage: "Template added successfully!"
      };

    case saveTemplateActions.SAVE_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        TemplateStatus: null,
        templateErrorMessage: payload
      };
    case saveTemplateActions.SAVE_TEMPLATE_STATUS:
      return {
        ...state,
        TemplateStatus: 0,
        templateErrorMessage: null
      };

    // Delete Asset Type

    case deleteTemplateActions.DELETE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        templateErrorMessage: null,
        modalActive: false
      };
    case deleteTemplateActions.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        TemplateStatus: payload.status,
        templateErrorMessage: null,
        templateSuccessMessage: "Template Deleted successfully!"
      };

    case deleteTemplateActions.DELETE_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        TemplateStatus: null,
        templateErrorMessage: payload
      };
    case deleteTemplateActions.DELETE_TEMPLATE_STATUS:
      return {
        ...state,
        TemplateStatus: 0,
        templateErrorMessage: null
      };


// Save Template Group

      case saveTemplateGroupActions.SAVE_TEMPLATE_GROUP:
      return {
        ...state,
        isLoading: true,
        templateGroupErrorMessage: null,
        modalActive: false
      };
    case saveTemplateGroupActions.SAVE_TEMPLATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        TemplateGroupStatus: payload.status,
        TemplateFieldDataStatus:payload.status,
        templateGroupErrorMessage: null,
        templateGroupSuccessMessage: "Template Group added successfully!"
      };

    case saveTemplateGroupActions.SAVE_TEMPLATE_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        TemplateGroupStatus: null,
        templateGroupErrorMessage: payload
      };
    case saveTemplateGroupActions.SAVE_TEMPLATE_GROUP_STATUS:
      return {
        ...state,
        TemplateGroupStatus: 0,
        templateGroupErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS:
      return {
        ...state,
        isLoading: true,
        templateErrorMessage: null,
        modalActive: false,
        TemplateData: null
      };
    case GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        TemplateData: payload.data,
        TemplateStatus: payload.status,
        TemplateGroupStatus: payload.status,
        templateErrorMessage: null
      };
    case GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        templateErrorMessage: payload,
        TemplateData: null
      };
    case GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS_STATUS:
      return {
        ...state,
        TemplateData: null
      };
    ///Active assets
    case GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        templateErrorMessage: null,
        modalActive: false,
        ActiveTemplateList: null
      };
    case GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveTemplateList: payload.data,
        templateErrorMessage: null
      };
    case GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_REJECTED:
      return {
        ...state,
        isLoading: false,
        templateErrorMessage: payload,
        ActiveTemplateList: null
      };
    case GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_STATUS:
      return {
        ...state,
        ActiveTemplateList: null
      };

    // getAllActivetemplateListing
    case GetActiveTemplateListActions.FETCH_ACTIVE_TEMPLATE_LIST:
      return {
        ...state,
        isLoading: true,
        templateErrorMessage: null,
        modalActive: false,
        ActiveAllTemplateList: null
      };
    case GetActiveTemplateListActions.FETCH_ACTIVE_TEMPLATE_LIST_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveAllTemplateList: payload.data,
        templateErrorMessage: null
      };
    case GetActiveTemplateListActions.FETCH_ACTIVE_TEMPLATE_LIST_REJECTED:
      return {
        ...state,
        isLoading: false,
        templateErrorMessage: payload,
        ActiveAllTemplateList: null
      };
    case GetActiveTemplateListActions.FETCH_ACTIVE_TEMPLATE_LIST_STATUS:
      return {
        ...state,
        ActiveAllTemplateList: null
      };

    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        templateSuccessMessage: null,
  templateErrorMessage: null,
  TemplateStatus: null,
  TemplateMaster: [],
  TemplateData: null,
  ActiveTemplateList: null,
  
  TemplateGroupData:[],
  TemplateGroupStatus: null,
  templateGroupSuccessMessage: null,
  templateGroupErrorMessage: null,
  ActiveTemplateGroupList: null,

  TemplateFieldData:[],
  TemplateFieldDataStatus: null,
  templateFieldSuccessMessage: null,
  templateFieldErrorMessage: null,
  ActiveTemplateFieldList: null,
      };

      ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS:
    return {
      ...state,
      isLoading: true,
      templateGroupErrorMessage: null,
      modalActive: false,
      TemplateGroupData: null
    };
  case GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      ActiveTemplateGroupList: payload.data,
      TemplateGroupStatus: payload.status,  
      templateGroupErrorMessage: null
    };
  case GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_REJECTED:
    return {
      ...state,
      isLoading: false,
      templateGroupErrorMessage: payload,
      TemplateGroupData: null
    };
  case GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_STATUS:
    return {
      ...state,
      TemplateGroupData: null
    };
      
  case GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS:
    return {
      ...state,
      isLoading: true,
      templateGroupErrorMessage: null,
      modalActive: false,
      TemplateGroupData: null
    };
  case GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      ActiveTemplateGroupList: payload.data,
      TemplateGroupStatus: payload.status,  
      templateGroupErrorMessage: null
    };
  case GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_REJECTED:
    return {
      ...state,
      isLoading: false,
      templateGroupErrorMessage: payload,
      TemplateGroupData: null
    };
  case GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_STATUS:
    return {
      ...state,
      TemplateGroupData: null
    };
      

    ///Active assets
    case GetActiveTemplateGroupActions.FETCH_ACTIVE_TEMPLATE_GROUP:
      return {
        ...state,
        isLoading: true,
        templateGroupErrorMessage: null,
        modalActive: false,
        ActiveTemplateGroupList: null
      };
    case GetActiveTemplateGroupActions.FETCH_ACTIVE_TEMPLATE_GROUP_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveTemplateGroupList: payload.data,
        templateGroupErrorMessage: null
      };
    case GetActiveTemplateGroupActions.FETCH_ACTIVE_TEMPLATE_GROUP_REJECTED:
      return {
        ...state,
        isLoading: false,
        templateGroupErrorMessage: payload,
        ActiveTemplateGroupList: null
      };
    case GetActiveTemplateGroupActions.FETCH_ACTIVE_TEMPLATE_GROUP_STATUS:
      return {
        ...state,
        ActiveTemplateGroupList: null
      };


      
    ///Active assets
    case deleteTemplateGroupActions.DELETE_TEMPLATE_GROUP_STATUS:
      return {
        ...state,
        isLoading: true,
        TemplateGroupData:[],
        TemplateGroupStatus: payload,
        templateGroupSuccessMessage: null,
        templateGroupErrorMessage: null,
        ActiveTemplateGroupList: null,
      };
      
    case deleteTemplateGroupActions.DELETE_TEMPLATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        TemplateGroupStatus: payload,
        templateGroupSuccessMessage: "Ropa Template Group Deleted Successfully",
        templateGroupErrorMessage: null,
      };
    case deleteTemplateGroupActions.DELETE_TEMPLATE_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        templateGroupErrorMessage: "Internal Server Error",       
      };

    case deleteTemplateGroupActions.DELETE_TEMPLATE_GROUP_ERROR:
      return {
        ...state,
        templateGroupErrorMessage: payload,
      };
    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */



    case saveTemplateFieldActions.SAVE_TEMPLATE_FIELD:
    return {
      ...state,
      isLoading: true,
      templateFieldErrorMessage: null,
      modalActive: false
    };
  case saveTemplateFieldActions.SAVE_TEMPLATE_FIELD_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      TemplateFieldStatus: payload.status,
      TemplateFieldData:payload.data,
      templateFieldErrorMessage: null,
      TemplateFieldStatus: null,
      templateFieldSuccessMessage: "Template Fields added successfully!"
    };

  case saveTemplateFieldActions.SAVE_TEMPLATE_FIELD_FAILURE:
    return {
      ...state,
      isLoading: false,
      TemplateFieldStatus: null,
      templateFieldErrorMessage: payload
    };
  case saveTemplateFieldActions.SAVE_TEMPLATE_FIELD_STATUS:
    return {
      ...state,
      TemplateFieldStatus: 0,
      templateFieldErrorMessage: null
    };
    case GetNewTempListActions.FETCH_NEW_TEMP_LIST:
      return {
        ...state,
        isLoading: true,
        newFielErrorMessage: null,
        newFieldData: null
      };
    case GetNewTempListActions.FETCH_NEW_TEMP_LIST_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        newFieldData: payload.data,
        newFieldStatus: payload.status,
        newFielErrorMessage: null
      };
    case GetNewTempListActions.FETCH_NEW_TEMP_LIST_REJECTED:
      return {
        ...state,
        isLoading: false,
        newFielErrorMessage: payload,
        newFieldData: null
      };
    case GetNewTempListActions.FETCH_NEW_TEMP_LIST_STATUS:
      return {
        ...state,
        newFieldData: null
      };

      case GetNewTempDataActions.FETCH_NEW_TEMP_DATA:
        return {
          ...state,
          isLoading: true,
          templateDataErrorMessage: null,
          TemplateRopaData: null
        };
      case GetNewTempDataActions.FETCH_NEW_TEMP_DATA_FULFILLED:
        return {
          ...state,
          loading: true,
          isLoading: false,
          TemplateRopaData: payload.data,
          TemplateDataStatus: payload.status,
          templateDataErrorMessage: null
        };
      case GetNewTempDataActions.FETCH_NEW_TEMP_DATA_REJECTED:
        return {
          ...state,
          isLoading: false,
          templateDataErrorMessage: payload,
          TemplateRopaData: null
        };
      case GetNewTempDataActions.FETCH_NEW_TEMP_DATA_STATUS:
        return {
          ...state,
          TemplateRopaData: null
        };
    default:
      return state;


      
        // GET  BY ID
    case GetTemplateFieldDetailsActions.FETCH_TEMPLATE_FIELD_DETAILS:
    return {
      ...state,
      isLoading: true,
      templateFieldErrorMessage: null,
      modalActive: false,
      TemplateFieldData: null
    };
  case GetTemplateFieldDetailsActions.FETCH_TEMPLATE_FIELD_DETAILS_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      ActiveTemplateFieldList: payload.data,
      TemplateFieldDataStatus: payload.status,  
      templateGroupErrorMessage: null
    };
  case GetTemplateFieldDetailsActions.FETCH_TEMPLATE_FIELD_DETAILS_REJECTED:
    return {
      ...state,
      isLoading: false,
      templateFieldErrorMessage: payload,
      TemplateFieldData: null
    };
  case GetTemplateFieldDetailsActions.FETCH_TEMPLATE_FIELD_DETAILS_STATUS:
    return {
      ...state,
      TemplateFieldData: null
    };

  }

  
}
