import {
  saveConsentHeadActions,
  getConsentHeadDetailsActions,
  ClearFormDetailsActions,
  GetActiveConsentHeadActions,
  UploadexcelActions,
  deleteConsentHeadActions,

  
} from "./actions";

const initState = {
  consentSuccessMessage: null,
  consentHeadErrorMessage: null,
  consentHeadStatus: null,
  consentHeadMaster: [],
  consentHeadData: null,
  ActiveConsentHeadList: []
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveConsentHeadActions.SAVE_CONSENT_HEAD:
      return {
        ...state,
        isLoading: true,
        consentHeadErrorMessage: null,
        modalActive: false
      };
    case saveConsentHeadActions.SAVE_CONSENT_HEAD_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentHeadStatus: payload.status,
        consentHeadErrorMessage: null,
        consentHeadSuccessMessage: "Consent Head added successfully!"
      };

    case saveConsentHeadActions.SAVE_CONSENT_HEAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        consentHeadStatus: null,
        consentHeadErrorMessage: payload
      };
    case saveConsentHeadActions.SAVE_CONSENT_HEAD_STATUS:
      return {
        ...state,
        consentHeadStatus: 0,
        consentHeadErrorMessage: null
      };
    ///////////////////////////////////////////////////////
    //delete consent Head By id
    

    case deleteConsentHeadActions.DELETE_CONSENT_HEAD:
      return {
        ...state,
        isLoading: true,
        consentHeadErrorMessage: null,
        modalActive: false,
      };
    case deleteConsentHeadActions.DELETE_CONSENT_HEAD_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ConsentHeadStatus: payload.status,
        consentHeadErrorMessage: null,
        consentSuccessMessage: "Consent Head Deleted successfully!",
      };

    case deleteConsentHeadActions.DELETE_CONSENT_HEAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        ConsentHeadStatus: null,
        consentHeadErrorMessage: payload,
      };
    case deleteConsentHeadActions.DELETE_CONSENT_HEAD_STATUS:
      return {
        ...state,
        ConsentHeadStatus: 0,
        consentHeadErrorMessage: null,
      };

    // GET  BY ID
    case getConsentHeadDetailsActions.FETCH_CONSENT_HEAD_DETAILS:
      return {
        ...state,
        isLoading: true,
        consentHeadErrorMessage: null,
        modalActive: false,
        consentHeadData: null
      };
    case getConsentHeadDetailsActions.FETCH_CONSENT_HEAD_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentHeadData: payload.data,
        ConsentHeadDataStatus: payload.status,
        ConsentHeadErrorMessage: null
      };
    case getConsentHeadDetailsActions.FETCH_CONSENT_HEAD_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        consentHeadErrorMessage: payload,
        consentHeadData: null
      };
    case getConsentHeadDetailsActions.FETCH_CONSENT_HEAD_DETAILS_STATUS:
      return {
        ...state,
        ConsentHeadData: null
      };

      // GET  BY ID
    case GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD:
      return {
        ...state,
        isLoading: true,
        consentHeadErrorMessage: null,
        modalActive: false,
        ActiveConsentHeadList: null
      };
    case GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveConsentHeadList: payload.data,
        ConsentHeadDataStatus: payload.status,
        ConsentHeadErrorMessage: null
      };
    case GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_REJECTED:
      return {
        ...state,
        isLoading: false,
        consentHeadErrorMessage: payload,
        ActiveConsentHeadList: null
      };
    case GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_STATUS:
      return {
        ...state,
        ConsentHeadData: null
      };
    // clear ConsentPurpose details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetConsentHead: null,
        ConsentHeadStatus: null,
        HeadSuccessMessage: null,
        GetConsentHeadStatus: null,
        ConsentHeadData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
