import {
  SaveOrganisationActions,
  GetOrganisationDetailsActions,
  GetActiveOrganisationByIdHierarchyActions,
  ClearFormDetailsActions,
  GetActiveOrganisationActions,
  GetActiveOrganisationHierarchyActions,
} from "./actions";

const initState = {
  organisationSuccessMessage: null,
  errorMessage: null,
  organisationStatus: null,
  organisationMaster: [],
  organisationData: null,
  ActiveOrganisationList: [],
  ActiveOrganisationListStatus:null
};

export default function Deaprtment(state = initState, { type, payload }) {
  switch (type) {
    case SaveOrganisationActions.SAVE_organisation:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case SaveOrganisationActions.SAVE_ORGANISATION_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        organisationStatus: payload.status,
        errorMessage: null
        // organisationSuccessMessage: "organisation added successfully!"
      };

    case SaveOrganisationActions.SAVE_ORGANISATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        organisationStatus: null,
        errorMessage: payload
      };
    case SaveOrganisationActions.SAVE_ORGANISATION_STATUS:
      return {
        ...state,
        organisationStatus: 0,
        errorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
        organisationData: null
      };
    case GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        organisationData: payload.data,
        organisationDataStatus: payload.status,
        errorMessage: null
      };
    case GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        organisationData: null
      };
    case GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_STATUS:
      return {
        ...state,
        organisationData: null
      };

      ////////////////////////////////////////////////////////////////////

      case GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS:
        return {
          ...state,
          isLoading: true,
          errorMessage: null,
          modalActive: false,
          organisationData: null
        };
      case GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_FULFILLED:
        return {
          ...state,
          loading: true,
          isLoading: false,
          organisationData: payload.data,
          organisationDataStatus: payload.status,
          errorMessage: null
        };
      case GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_REJECTED:
        return {
          ...state,
          isLoading: false,
          errorMessage: payload,
          organisationData: null
        };
      case GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_STATUS:
        return {
          ...state,
          organisationData: null
        };

        ////////////////////////////////////////////////////////

        case GetActiveOrganisationHierarchyActions.FETCH_ACTIVE_ORGANISATION_HIERARCHY:
        return {
          ...state,
          isLoading: true,
          errorMessage: null,
          modalActive: false,
          ActiveOrganisationList: null
        };
      case GetActiveOrganisationHierarchyActions.FETCH_ACTIVE_ORGANISATION_FULFILLED_HIERARCHY:
        return {
          ...state,
          loading: true,
          isLoading: false,
          ActiveOrganisationList: payload.data,
          ActiveOrganisationListStatus:payload.status,
          errorMessage: null
        };
      case GetActiveOrganisationHierarchyActions.FETCH_ACTIVE_ORGANISATION_REJECTED_HIERARCHY:
        return {
          ...state,
          isLoading: false,
          errorMessage: payload,
          ActiveOrganisationList: null
        };
      case GetActiveOrganisationHierarchyActions.FETCH_ACTIVE_ORGANISATION_STATUS_HIERARCHY:
        return {
          ...state,
          ActiveOrganisationList: null
        };


    default:
      return state;
  }
}
