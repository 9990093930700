import axios from "axios";
import { SuperAdmin_Service_URL,ConsentManagementService_URL, AssetManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save ConsentPurpose

export const saveConsentPurposeActions = {
  SAVE_CONSENT_PURPOSE: "SAVE_CONSENT_PURPOSE",
  SAVE_CONSENT_PURPOSE_STATUS: "SAVE_CONSENT_PURPOSE_STATUS",
  SAVE_CONSENT_PURPOSE_SUCCESS: "SAVE_CONSENT_PURPOSE_SUCCESS",
  SAVE_CONSENT_PURPOSE_FAILURE: "SAVE_CONSENT_PURPOSE_FAILURE",
};

export function saveConsentPurposeRequest() {
  return {
    type: saveConsentPurposeActions.SAVE_CONSENT_PURPOSE,
    isPosting: true,
  };
}

export function saveConsentPurposeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveConsentPurposeActions.SAVE_CONSENT_PURPOSE_SUCCESS,
      payload: props,
    });
  };
}

export function saveConsentPurposeFailure(error) {
  return {
    type: saveConsentPurposeActions.SAVE_CONSENT_PURPOSE_FAILURE,
    payload: error,
  };
}

export function saveConsentPurposeError(error) {
  return {
    type: saveConsentPurposeActions.SAVE_CONSENT_PURPOSE_FAILURE,
    payload: error,
  };
}

export function saveConsentPurpose(props) {
  // var value = {
  //   id: props.id || 0,
  //   ConsentPurposeName: props.ConsentPurposeName,
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${ConsentManagementService_URL}/Apc_Persist/Amc_57/saveConsentPurpose`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveConsentPurposeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveConsentPurposeSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveConsentPurposeError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveConsentPurposeError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveConsentPurposeError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveConsentPurposeError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveConsentPurposeError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveConsentPurposeError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const getConsentPurposeDetailsActions = {
  FETCH_CONSENT_PURPOSE_DETAILS: "FETCH_CONSENT_PURPOSE_DETAILS",
  FETCH_CONSENT_PURPOSE_DETAILS_STATUS: "FETCH_CONSENT_PURPOSE_DETAILS_STATUS",
  FETCH_CONSENT_PURPOSE_DETAILS_FULFILLED: "FETCH_CONSENT_PURPOSE_DETAILS_FULFILLED",
  FETCH_CONSENT_PURPOSE_DETAILS_REJECTED: "FETCH_CONSENT_PURPOSE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getConsentPurposeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${ConsentManagementService_URL}/Apc_View/Amc_57/findConsentPurposeById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getConsentPurposeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getConsentPurposeDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getConsentPurposeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPurposeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPurposeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPurposeDetailsError(err));
              break;
            case 500:
              dispatch(getConsentPurposeDetailsError("Server Error"));
              break;
            default:
              dispatch(getConsentPurposeDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getConsentPurposeDetailsRequest() {
  return {
    type: getConsentPurposeDetailsActions.FETCH_CONSENT_PURPOSE_DETAILS,
    isPosting: true,
  };
}
export function getConsentPurposeDetailsError(error) {
  return {
    type: getConsentPurposeDetailsActions.FETCH_CONSENT_PURPOSE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getConsentPurposeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getConsentPurposeDetailsActions.FETCH_CONSENT_PURPOSE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getConsentPurposeDetailsStatus() {
  return (dispatch) => dispatch(getConsentPurposeDetailsStatusRequest());
}
export function getConsentPurposeDetailsStatusRequest() {
  return {
    type: getConsentPurposeDetailsActions.FETCH_CONSENT_PURPOSE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getConsentPurposeDetailsErrorRequest());
}
export function getConsentPurposeDetailsErrorRequest() {
  return {
    type: getConsentPurposeDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("ConsentPurposes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveConsentPurposeActions = {
  FETCH_ACTIVE_CONSENT_PURPOSE: "FETCH_ACTIVE_CONSENT_PURPOSE",
  FETCH_ACTIVE_CONSENT_PURPOSE_STATUS: "FETCH_ACTIVE_CONSENT_PURPOSE_STATUS",
  FETCH_ACTIVE_CONSENT_PURPOSE_FULFILLED: "FETCH_ACTIVE_CONSENT_PURPOSE_FULFILLED",
  FETCH_ACTIVE_CONSENT_PURPOSE_REJECTED: "FETCH_ACTIVE_CONSENT_PURPOSE_REJECTED",
};

export function getActiveConsentPurposeList(params,access_token) {
  return (dispatch) =>
    axios({
      method: "POST",
      data:params,
      url: `${ConsentManagementService_URL}/Apc_View/Amc_57/listing/ConsentPurpose`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveConsentPurposeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveConsentPurposeListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveConsentPurposeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentPurposeListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentPurposeListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentPurposeListError(err));
              break;
            case 500:
              dispatch(getActiveConsentPurposeListError("Server Error"));
              break;
            default:
              dispatch(getActiveConsentPurposeListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveConsentPurposeListRequest() {
  return {
    type: GetActiveConsentPurposeActions.FETCH_ACTIVE_CONSENT_PURPOSE,
    isPosting: true,
  };
}
export function getActiveConsentPurposeListError(error) {
  return {
    type: GetActiveConsentPurposeActions.FETCH_CONSENT_PURPOSE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getActiveConsentPurposeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveConsentPurposeActions.FETCH_ACTIVE_CONSENT_PURPOSE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveConsentPurposeListStatus() {
  return (dispatch) => dispatch(getActiveConsentPurposeListStatusRequest());
}
export function getActiveConsentPurposeListStatusRequest() {
  return {
    type: GetActiveConsentPurposeActions.FETCH_ACTIVE_CONSENT_PURPOSE_STATUS,
  };
}
