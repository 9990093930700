import {
  SaveLegalBasisActions,
  GetLegalBasisDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveLegalBasisActions
} from "./actions";

const initState = {
  legalBasisSuccessMessage: null,
  legalBasisErrorMessage: null,
  legalBasisStatus: null,
  legalBasisMaster: [],
  legalBasisData: null,
  ActiveLegalBasisList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveLegalBasisActions.SAVE_LEGAL_BASIS:
      return {
        ...state,
        isLoading: true,
        legalBasisErrorMessage: null,
        modalActive: false
      };
    case SaveLegalBasisActions.SAVE_LEGAL_BASIS_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        legalBasisStatus: payload.status,
        legalBasisErrorMessage: null,
        legalBasisSuccessMessage: "Data Subject Category added successfully!"
      };

    case SaveLegalBasisActions.SAVE_LEGAL_BASIS_FAILURE:
      return {
        ...state,
        isLoading: false,
        legalBasisStatus: null,
        legalBasisErrorMessage: payload
      };
    case SaveLegalBasisActions.SAVE_LEGAL_BASIS_STATUS:
      return {
        ...state,
        legalBasisStatus: 0,
        legalBasisErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetLegalBasisDetailsActions.FETCH_LEGAL_BASIS_DETAILS:
      return {
        ...state,
        isLoading: true,
        legalBasisErrorMessage: null,
        modalActive: false,
        legalBasisData: null
      };
    case GetLegalBasisDetailsActions.FETCH_LEGAL_BASIS_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        legalBasisData: payload.data,
        legalBasisDataStatus: payload.status,
        legalBasisErrorMessage: null
      };
    case GetLegalBasisDetailsActions.FETCH_LEGAL_BASIS_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        legalBasisErrorMessage: payload,
        legalBasisData: null
      };
    case GetLegalBasisDetailsActions.FETCH_LEGAL_BASIS_DETAILS_STATUS:
      return {
        ...state,
        legalBasisData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveLegalBasisActions.FETCH_ACTIVE_LEGAL_BASIS:
      return {
        ...state,
        isLoading: true,
        legalBasisErrorMessage: null,
        modalActive: false,
        ActiveLegalBasisList: null
      };
    case GetActiveLegalBasisActions.FETCH_ACTIVE_LEGAL_BASIS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveLegalBasisList: payload.data,
        legalBasisErrorMessage: null
      };
    case GetActiveLegalBasisActions.FETCH_ACTIVE_LEGAL_BASIS_REJECTED:
      return {
        ...state,
        isLoading: false,
        legalBasisErrorMessage: payload,
        ActiveLegalBasisList: null
      };
    case GetActiveLegalBasisActions.FETCH_ACTIVE_LEGAL_BASIS_STATUS:
      return {
        ...state,
        ActiveLegalBasisList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetlegalBasis: null,
        legalBasisStatus: null,
        legalBasisSuccessMessage: null,
        GetlegalBasisStatus: null,
        legalBasisData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
