import {
  saveAssetActions,
  deleteAssetActions,
  GetAssetDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveAssetActions,GetRopaListActions,
  GetAssetRiskAndControlActions,
  GetControlActions
} from "./actions";

const initState = {
  assetSuccessMessage: null,
  assetErrorMessage: null,
  AssetStatus: null,
  AssetMaster: [],
  AssetData: null,
  ActiveAssetList: null,
  AssetBasedRopa:null,
  AssetRisk: null,
  RiskControl: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveAssetActions.SAVE_ASSET:
      return {
        ...state,
        isLoading: true,
        assetErrorMessage: null,
        modalActive: false
      };
    case saveAssetActions.SAVE_ASSET_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AssetStatus: payload.status,
        assetErrorMessage: null,
        assetSuccessMessage: "Asset Type added successfully!"
      };

    case saveAssetActions.SAVE_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        AssetStatus: null,
        assetErrorMessage: payload
      };
    case saveAssetActions.SAVE_ASSET_STATUS:
      return {
        ...state,
        AssetStatus: 0,
        assetErrorMessage: null
      };

    // Delete Asset Type

    case deleteAssetActions.DELETE_ASSET:
      return {
        ...state,
        isLoading: true,
        assetErrorMessage: null,
        modalActive: false
      };
    case deleteAssetActions.DELETE_ASSET_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AssetStatus: payload.status,
        assetErrorMessage: null,
        assetSuccessMessage: "Asset Type Deleted successfully!"
      };

    case deleteAssetActions.DELETE_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        AssetStatus: null,
        assetErrorMessage: payload
      };
    case deleteAssetActions.DELETE_ASSET_STATUS:
      return {
        ...state,
        AssetStatus: 0,
        assetErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetAssetDetailsActions.FETCH_ASSET_DETAILS:
      return {
        ...state,
        isLoading: true,
        assetErrorMessage: null,
        modalActive: false,
        AssetData: null
      };
    case GetAssetDetailsActions.FETCH_ASSET_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AssetData: payload.data,
        AssetDataStatus: payload.status,
        assetErrorMessage: null
      };
    case GetAssetDetailsActions.FETCH_ASSET_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetErrorMessage: payload,
        AssetData: null
      };
    case GetAssetDetailsActions.FETCH_ASSET_DETAILS_STATUS:
      return {
        ...state,
        AssetData: null
      };

      ////Assets Ropa list

      case GetRopaListActions.FETCH_ACTIVE_ROPAS:
      return {
        ...state,
        isLoading: true,
        assetErrorMessage: null,
        modalActive: false,
        AssetBasedRopa: null
      };
    case GetRopaListActions.FETCH_ACTIVE_ROPAS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AssetBasedRopa: payload.data,
        // AssetDataStatus: payload.status,
        assetErrorMessage: null
      };
    case GetRopaListActions.FETCH_ACTIVE_ROPAS_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetErrorMessage: payload,
        AssetBasedRopa: null
      };
    ///Active assets
    case GetActiveAssetActions.FETCH_ACTIVE_ASSETS:
      return {
        ...state,
        isLoading: true,
        assetErrorMessage: null,
        modalActive: false,
        ActiveAssetList: null
      };
    case GetActiveAssetActions.FETCH_ACTIVE_ASSETS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveAssetList: payload.data,
        assetErrorMessage: null
      };
    case GetActiveAssetActions.FETCH_ACTIVE_ASSETS_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetErrorMessage: payload,
        ActiveAssetList: null
      };
    case GetActiveAssetActions.FETCH_ACTIVE_ASSETS_STATUS:
      return {
        ...state,
        ActiveAssetList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetAsset: null,
        AssetStatus: null,
        assetSuccessMessage: null,
        GetAssetStatus: null,
        AssetData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */
     ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetAssetRiskAndControlActions.FETCH_ASSET_RISK_CONTROL:
      return {
        ...state,
        isLoading: true,
        assetRiskErrorMessage: null,
        modalActive: false,
        AssetRisk: null
      };
    case GetAssetRiskAndControlActions.FETCH_ASSET_RISK_CONTROL_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AssetRisk: payload.data,
        AssetRiskStatus: payload.status,
        assetRiskErrorMessage: null
      };
    case GetAssetRiskAndControlActions.FETCH_ASSET_RISK_CONTROL_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetRiskErrorMessage: payload,
        AssetRisk: null
      };
    case GetAssetRiskAndControlActions.FETCH_ASSET_RISK_CONTROL_STATUS:
      return {
        ...state,
        AssetRisk: null
      };

    // GET  BY ID
    case GetControlActions.FETCH_CONTROL:
      return {
        ...state,
        isLoading: true,
        riskControlErrorMessage: null,
        modalActive: false,
        RiskControl: null
      };
    case GetControlActions.FETCH_CONTROL_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskControl: payload.data,
        RiskControlStatus: payload.status,
        riskControlErrorMessage: null
      };
    case GetControlActions.FETCH_CONTROL_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskControlErrorMessage: payload,
        RiskControl: null
      };
    case GetControlActions.FETCH_CONTROL_STATUS:
      return {
        ...state,
        RiskControl: null
      };

    default:
      return state;
  }
}
