import {
  SaveRiskWorkflowActions,
  GetRiskWorkflowDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRiskWorkflowActions,
  GetRiskWorkflowActions
} from "./actions";

const initState = {
  riskWorkflowSuccessMessage: null,
  riskWorkflowErrorMessage: null,
  RiskWorkflowStatus: null,
  RiskWorkflowMaster: [],
  RiskWorkflowData: null,
  ActiveRiskWorkflowList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveRiskWorkflowActions.SAVE_RISKWORKFLOW:
      return {
        ...state,
        isLoading: true,
        riskWorkflowErrorMessage: null,
        modalActive: false,
      };
    case SaveRiskWorkflowActions.SAVE_RISKWORKFLOW_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskWorkflowStatus: payload.status,
        riskWorkflowErrorMessage: null,
        riskWorkflowSuccessMessage: "RiskWorkflow added successfully!",
      };

    case SaveRiskWorkflowActions.SAVE_RISKWORKFLOW_FAILURE:
      return {
        ...state,
        isLoading: false,
        RiskWorkflowStatus: null,
        riskWorkflowErrorMessage: payload,
      };
    case SaveRiskWorkflowActions.SAVE_RISKWORKFLOW_STATUS:
      return {
        ...state,
        RiskWorkflowStatus: 0,
        riskWorkflowErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetRiskWorkflowDetailsActions.FETCH_RISKWORKFLOW:
      return {
        ...state,
        isLoading: true,
        riskWorkflowErrorMessage: null,
        modalActive: false,
        RiskWorkflowData: null,
      };
    case GetRiskWorkflowDetailsActions.FETCH_RISKWORKFLOW_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskWorkflowData: payload.data,
        RiskWorkflowDataStatus: payload.status,
        riskWorkflowErrorMessage: null,
      };
    case GetRiskWorkflowDetailsActions.FETCH_RISKWORKFLOW_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskWorkflowErrorMessage: payload,
        RiskWorkflowData: null,
      };
    case GetRiskWorkflowDetailsActions.FETCH_RISKWORKFLOW_STATUS:
      return {
        ...state,
        RiskWorkflowData: payload.data,
        RiskWorkflowDataStatus: payload.status,
      };
    ///Active DATA DISPOSAL
    case GetActiveRiskWorkflowActions.FETCH_ACTIVE_RISKWORKFLOW:
      return {
        ...state,
        isLoading: true,
        riskWorkflowErrorMessage: null,
        modalActive: false,
        ActiveRiskWorkflowList: null,
      };
    case GetActiveRiskWorkflowActions.FETCH_ACTIVE_RISKWORKFLOW_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveRiskWorkflowList: payload.data,
        riskWorkflowErrorMessage: null,
      };
    case GetActiveRiskWorkflowActions.FETCH_ACTIVE_RISKWORKFLOW_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskWorkflowErrorMessage: payload,
        ActiveRiskWorkflowList: null,
      };
    case GetActiveRiskWorkflowActions.FETCH_ACTIVE_RISKWORKFLOW_STATUS:
      return {
        ...state,
        ActiveRiskWorkflowList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRiskWorkflow: null,
        RiskWorkflowStatus: null,
        riskWorkflowSuccessMessage: null,
        GetRiskWorkflowStatus: null,
        RiskWorkflowData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

//Get Pagination
case GetRiskWorkflowActions.FETCH_RISKWORKFLOW:
  return {
    ...state,
    isLoading: true,
    riskWorkflowErrorMessage: null,
    modalActive: false,
    RiskWorkflowData: null
  };
case GetRiskWorkflowActions.FETCH_RISKWORKFLOW_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveRiskWorkflowList: payload.data,
    RiskWorkflowStatus: payload.status,  
    riskWorkflowErrorMessage: null
  };
case GetRiskWorkflowActions.FETCH_RISKWORKFLOW_REJECTED:
  return {
    ...state,
    isLoading: false,
    riskWorkflowErrorMessage: payload,
    RiskWorkflowData: null
  };
case GetRiskWorkflowActions.FETCH_RISKWORKFLOW_STATUS:
  return {
    ...state,
    RiskWorkflowData: null
  };
    
case GetRiskWorkflowActions.FETCH_RISKWORKFLOW:
  return {
    ...state,
    isLoading: true,
    riskWorkflowErrorMessage: null,
    modalActive: false,
    RiskWorkflowData: null
  };
case GetRiskWorkflowActions.FETCH_RISKWORKFLOW_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveRiskWorkflowList: payload.data,
    RiskWorkflowStatus: payload.status,  
    riskWorkflowErrorMessage: null
  };
case GetRiskWorkflowActions.FETCH_RISKWORKFLOW_REJECTED:
  return {
    ...state,
    isLoading: false,
    riskWorkflowErrorMessage: payload,
    RiskWorkflowData: null
  };
case GetRiskWorkflowActions.FETCH_RISKWORKFLOW_STATUS:
  return {
    ...state,
    RiskWorkflowData: null
  };
  /* */

  default:
    return state;

}
}
