import {
  SaveDataSubProcessorActions,
  GetDataSubProcessorDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataSubProcessorActions,
} from "./actions";

const initState = {
  dataSubProcessorSuccessMessage: null,
  dataSubProcessorErrorMessage: null,
  dataSubProcessorStatus: null,
  dataSubProcessorMaster: [],
  dataSubProcessorData: null,
  ActiveDataSubProcessorList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataSubProcessorActions.SAVE_DATA_PROCESSOR:
      return {
        ...state,
        isLoading: true,
        dataSubProcessorErrorMessage: null,
        modalActive: false,
      };
    case SaveDataSubProcessorActions.SAVE_DATA_PROCESSOR_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubProcessorStatus: payload.status,
        dataSubProcessorErrorMessage: null,
        dataSubProcessorSuccessMessage: "Data Processor added successfully!",
      };
    case GetDataSubProcessorDetailsActions.FETCH_DATA_SUB_PROCESSOR_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubProcessorData: payload.data,
        dataSubProcessorStatus: payload.status,
        dataSubProcessorErrorMessage: null,
        dataSubProcessorSuccessMessage: "Data Processor fetched successfully!",
      };

    case SaveDataSubProcessorActions.SAVE_DATA_PROCESSOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataSubProcessorStatus: null,
        dataSubProcessorErrorMessage: payload,
      };
    case SaveDataSubProcessorActions.SAVE_DATA_PROCESSOR_STATUS:
      return {
        ...state,
        dataSubProcessorStatus: 0,
        dataSubProcessorErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataSubProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataSubProcessorErrorMessage: null,
        modalActive: false,
        dataSubProcessorData: null,
      };
    case GetDataSubProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubProcessorData: payload.data,
        dataSubProcessorDataStatus: payload.status,
        dataSubProcessorErrorMessage: null,
      };
    case GetDataSubProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubProcessorErrorMessage: payload,
        dataSubProcessorData: null,
      };
    case GetDataSubProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_STATUS:
      return {
        ...state,
        dataSubProcessorData: null,
      };
    ///Active DATA DISPOSAL
    case GetActiveDataSubProcessorActions.FETCH_ACTIVE_DATA_SUB_PROCESSOR:
      return {
        ...state,
        isLoading: true,
        dataSubProcessorErrorMessage: null,
        modalActive: false,
        ActiveDataSubProcessorList: null,
      };
    case GetActiveDataSubProcessorActions.FETCH_ACTIVE_DATA_SUB_PROCESSOR_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataSubProcessorList: payload.data,
        dataSubProcessorErrorMessage: null,
      };
    case GetActiveDataSubProcessorActions.FETCH_ACTIVE_DATA_SUB_PROCESSOR_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubProcessorErrorMessage: payload,
        ActiveDataSubProcessorList: null,
      };
    case GetActiveDataSubProcessorActions.FETCH_ACTIVE_DATA_SUB_PROCESSOR_STATUS:
      return {
        ...state,
        ActiveDataSubProcessorList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataSubProcessor: null,
        dataSubProcessorStatus: null,
        dataSubProcessorSuccessMessage: null,
        GetdataSubProcessorStatus: null,
        dataSubProcessorData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
