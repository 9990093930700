import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDataSubVolRangeActions = {
  SAVE_DATA_VOL_RANGE: "SAVE_DATA_VOL_RANGE",
  SAVE_DATA_VOL_RANGE_STATUS: "SAVE_DATA_VOL_RANGE_STATUS",
  SAVE_DATA_VOL_RANGE_SUCCESS: "SAVE_DATA_VOL_RANGE_SUCCESS",
  SAVE_DATA_VOL_RANGE_FAILURE: "SAVE_DATA_VOL_RANGE_FAILURE",
};

export function logout() {
  return {
    type: SaveDataSubVolRangeActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveDataSubVolRangeRequest() {
  return {
    type: SaveDataSubVolRangeActions.SAVE_DATA_VOL_RANGE,
    isPosting: true,
  };
}

export function SaveDataSubVolRangeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataSubVolRangeActions.SAVE_DATA_VOL_RANGE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataSubVolRangeFailure(error) {
  return {
    type: SaveDataSubVolRangeActions.SAVE_DATA_VOL_RANGE_FAILURE,
    payload: error,
  };
}

export function SaveDataSubVolRangeError(error) {
  return {
    type: SaveDataSubVolRangeActions.SAVE_DATA_VOL_RANGE_FAILURE,
    payload: error,
  };
}

export function saveDataSubVolRange(props) {
  var value = {
    volumeRange: props.volumeRange || null,
    // end: props.end
    id: props.id || 0,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_17/saveDataVolume`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDataSubVolRangeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataSubVolRangeSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveDataSubVolRangeError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveRoleError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveRoleError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDataSubVolRangeDetailsActions = {
  FETCH_DATA_VOL_RANGE_DETAILS: "FETCH_DATA_VOL_RANGE_DETAILS",
  FETCH_DATA_VOL_RANGE_DETAILS_STATUS: "FETCH_DATA_VOL_RANGE_DETAILS_STATUS",
  FETCH_DATA_VOL_RANGE_DETAILS_FULFILLED:
    "FETCH_DATA_VOL_RANGE_DETAILS_FULFILLED",
  FETCH_DATA_VOL_RANGE_DETAILS_REJECTED:
    "FETCH_DATA_VOL_RANGE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataSubVolRangeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_17/findDataVolumeById?dataVolumeId=` +
        params.dataVolumeId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataSubVolRangeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataSubVolRangeDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDataSubVolRangeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubVolRangeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubVolRangeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubVolRangeDetailsError(err));
              break;
            case 500:
              dispatch(getDataSubVolRangeDetailsError("Server Error"));
              break;
            default:
              dispatch(getDataSubVolRangeDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataSubVolRangeDetailsRequest() {
  return {
    type: GetDataSubVolRangeDetailsActions.FETCH_DATA_VOL_RANGE_DETAILS,
    isPosting: true,
  };
}
export function getDataSubVolRangeDetailsError(error) {
  return {
    type:
      GetDataSubVolRangeDetailsActions.FETCH_DATA_VOL_RANGE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataSubVolRangeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDataSubVolRangeDetailsActions.FETCH_DATA_VOL_RANGE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataSubVolRangeDetailsStatus() {
  return (dispatch) => dispatch(getDataSubVolRangeDetailsStatusRequest());
}
export function getDataSubVolRangeDetailsStatusRequest() {
  return {
    type: GetDataSubVolRangeDetailsActions.FETCH_DATA_VOL_RANGE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataSubVolRangeDetailsErrorRequest());
}
export function getDataSubVolRangeDetailsErrorRequest() {
  return {
    type: GetDataSubVolRangeDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDepartmentActions = {
  FETCH_ACTIVE_DATASUBVOL: "FETCH_ACTIVE_DATASUBVOL",
  FETCH_ACTIVE_DATASUBVOL_STATUS: "FETCH_ACTIVE_DATASUBVOL_STATUS",
  FETCH_ACTIVE_DATASUBVOL_FULFILLED: "FETCH_ACTIVE_DATASUBVOL_FULFILLED",
  FETCH_ACTIVE_DATASUBVOL_REJECTED: "FETCH_ACTIVE_DATASUBVOL_REJECTED",
};
export function getActiveDataSubVolList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_17/getAllDataSubjectVolumePagination?length=0&sortOrder=desc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataSubVolListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataSubVolListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDataSubVolListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubVolListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubVolListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubVolListError(err));
              break;
            case 500:
              dispatch(getActiveDataSubVolListError("Server Error"));
              break;
            default:
              dispatch(getActiveDataSubVolListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataSubVolListRequest() {
  return {
    type: GetActiveDepartmentActions.FETCH_ACTIVE_DATASUBVOL,
    isPosting: true,
  };
}
export function getActiveDataSubVolListError(error) {
  return {
    type: GetActiveDepartmentActions.FETCH_ACTIVE_DATASUBVOL_REJECTED,
    payload: error,
  };
}

export function getActiveDataSubVolListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDepartmentActions.FETCH_ACTIVE_DATASUBVOL_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataSubVolListStatus() {
  return (dispatch) => dispatch(getActiveDataSubVolListStatusRequest());
}
export function getActiveDataSubVolListStatusRequest() {
  return {
    type: GetActiveDepartmentActions.FETCH_ACTIVE_DATASUBVOL_STATUS,
  };
}
