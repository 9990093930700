import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDataSubElementActions = {
  SAVE_DATA_SUB_ELE: "SAVE_DATA_SUB_ELE",
  SAVE_DATA_SUB_ELE_STATUS: "SAVE_DATA_SUB_ELE_STATUS",
  SAVE_DATA_SUB_ELE_SUCCESS: "SAVE_DATA_SUB_ELE_SUCCESS",
  SAVE_DATA_SUB_ELE_FAILURE: "SAVE_DATA_SUB_ELE_FAILURE",
};

export function SaveDataSubElementRequest() {
  return {
    type: SaveDataSubElementActions.SAVE_DATA_SUB_ELE,
    isPosting: true,
  };
}

export function SaveDataSubElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataSubElementActions.SAVE_DATA_SUB_ELE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataSubElementFailure(error) {
  return {
    type: SaveDataSubElementActions.SAVE_DATA_SUB_ELE_FAILURE,
    payload: error,
  };
}

export function SaveDataSubElementError(error) {
  return {
    type: SaveDataSubElementActions.SAVE_DATA_SUB_ELE_FAILURE,
    payload: error,
  };
}

export function saveDataSubElement(props) {
  var value = {
    dataCategoryId: props.dataSubjectCategoryId,
    dataSubjectId: props.dataSubjectId,
    description: props.dataEleDesc,
    name: props.dataSubjectElementName,
    id: props.dataSubjectElementId || 0,
    // riskImpactId: props.riskImpactId,
    // riskOccuranceId: props.riskOccuranceId
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_16/saveDataElement`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDataSubElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataSubElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveDataSubElementError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveDataSubElementError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveDataSubElementError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDataSubElementDetailsActions = {
  FETCH_DATA_SUB_ELE_DETAILS: "FETCH_DATA_SUB_ELE_DETAILS",
  FETCH_DATA_SUB_ELE_DETAILS_STATUS: "FETCH_DATA_SUB_ELE_DETAILS_STATUS",
  FETCH_DATA_SUB_ELE_DETAILS_FULFILLED: "FETCH_DATA_SUB_ELE_DETAILS_FULFILLED",
  FETCH_DATA_SUB_ELE_DETAILS_REJECTED: "FETCH_DATA_SUB_ELE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataSubElementDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_16/findDataElementById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataSubElementDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataSubElementDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDataSubElementDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubElementDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubElementDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubElementDetailsError(err));
              break;
            case 500:
              dispatch(getDataSubElementDetailsError("Server Error"));
              break;
            default:
              dispatch(getDataSubElementDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataSubElementDetailsRequest() {
  return {
    type: GetDataSubElementDetailsActions.FETCH_DATA_SUB_ELE_DETAILS,
    isPosting: true,
  };
}
export function getDataSubElementDetailsError(error) {
  return {
    type: GetDataSubElementDetailsActions.FETCH_DATA_SUB_ELE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataSubElementDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDataSubElementDetailsActions.FETCH_DATA_SUB_ELE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataSubElementDetailsStatus() {
  return (dispatch) => dispatch(getDataSubElementDetailsStatusRequest());
}
export function getDataSubElementDetailsStatusRequest() {
  return {
    type: GetDataSubElementDetailsActions.FETCH_DATA_SUB_ELE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataSubElementDetailsErrorRequest());
}
export function getDataSubElementDetailsErrorRequest() {
  return {
    type: GetDataSubElementDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDataSubEleActions = {
  FETCH_ACTIVE_DATA_SUBJECT_ELE: "FETCH_ACTIVE_DATA_SUBJECT_ELE",
  FETCH_ACTIVE_DATA_SUBJECT_ELE_STATUS: "FETCH_ACTIVE_DATA_SUBJECT_ELE_STATUS",
  FETCH_ACTIVE_DATA_SUBJECT_ELE_FULFILLED:
    "FETCH_ACTIVE_DATA_SUBJECT_ELE_FULFILLED",
  FETCH_ACTIVE_DATA_SUBJECT_ELE_REJECTED:
    "FETCH_ACTIVE_DATA_SUBJECT_ELE_REJECTED",
};
export function getActiveDataSubEleList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_16/getAllDataElementPagination?length=0&sortOrder=desc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataSubEleListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataSubEleListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDataSubEleListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubEleListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubEleListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubEleListError(err));
              break;
            case 500:
              dispatch(getActiveDataSubEleListError("Server Error"));
              break;
            default:
              dispatch(getActiveDataSubEleListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataSubEleListRequest() {
  return {
    type: GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE,
    isPosting: true,
  };
}
export function getActiveDataSubEleListError(error) {
  return {
    type: GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_REJECTED,
    payload: error,
  };
}

export function getActiveDataSubEleListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataSubEleListStatus() {
  return (dispatch) => dispatch(getActiveDataSubEleListStatusRequest());
}
export function getActiveDataSubEleListStatusRequest() {
  return {
    type: GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_STATUS,
  };
}
