import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveHostingProviderActions = {
  SAVE_HOSTING_PROVIDER: "SAVE_HOSTING_PROVIDER",
  SAVE_HOSTING_PROVIDER_STATUS: "SAVE_HOSTING_PROVIDER_STATUS",
  SAVE_HOSTING_PROVIDER_SUCCESS: "SAVE_HOSTING_PROVIDER_SUCCESS",
  SAVE_HOSTING_PROVIDER_FAILURE: "SAVE_HOSTING_PROVIDER_FAILURE",
};

export function SaveHostingProviderRequest() {
  return {
    type: SaveHostingProviderActions.SAVE_HOSTING_PROVIDER,
    isPosting: true,
  };
}

export function SaveHostingProviderSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveHostingProviderActions.SAVE_HOSTING_PROVIDER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveHostingProviderFailure(error) {
  return {
    type: SaveHostingProviderActions.SAVE_HOSTING_PROVIDER_FAILURE,
    payload: error,
  };
}

export function SaveHostingProviderError(error) {
  return {
    type: SaveHostingProviderActions.SAVE_HOSTING_PROVIDER_FAILURE,
    payload: error,
  };
}

export function saveHostingProvider(props) {
  
  var value = {
    hostProviderId: props.hostingProviderId || null,
    hostProviderName: props.hostingProviderName,
    hostProviderTypeId: props.hostProviderTypeId,
    hostProviderCityId: props.hostProviderCityId,
    hostProviderCountryId: props.hostProviderCountryId,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_11/saveHostingProviderMaster`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveHostingProviderRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveHostingProviderSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveHostingProviderError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveHostingProviderError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveHostingProviderError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveHostingProviderError(err));
              break;
            case 500:
              dispatch(SaveHostingProviderError("Server Error"));
              break;
            default:
              dispatch(SaveHostingProviderError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetHostingProviderDetailsActions = {
  FETCH_HOSTING_DETAILS: "FETCH_HOSTING_DETAILS",
  FETCH_HOSTING_DETAILS_STATUS: "FETCH_HOSTING_DETAILS_STATUS",
  FETCH_HOSTING_DETAILS_FULFILLED: "FETCH_HOSTING_DETAILS_FULFILLED",
  FETCH_HOSTING_DETAILS_REJECTED: "FETCH_HOSTING_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getHostingProviderDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_11/findHostingProviderMasterById?hostProviderId=` +
        params.hostingProviderID,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getHostingProviderDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getHostingProviderDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getHostingProviderDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getHostingProviderDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getHostingProviderDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getHostingProviderDetailsError(err));
              break;
            case 500:
              dispatch(getHostingProviderDetailsError("Server Error"));
              break;
            default:
              dispatch(getHostingProviderDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getHostingProviderDetailsRequest() {
  return {
    type: GetHostingProviderDetailsActions.FETCH_HOSTING_DETAILS,
    isPosting: true,
  };
}
export function getHostingProviderDetailsError(error) {
  return {
    type: GetHostingProviderDetailsActions.FETCH_HOSTING_DETAILS_REJECTED,
    payload: error,
  };
}

export function getHostingProviderDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetHostingProviderDetailsActions.FETCH_HOSTING_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getHostingProviderDetailsStatus() {
  return (dispatch) => dispatch(getHostingProviderDetailsStatusRequest());
}
export function getHostingProviderDetailsStatusRequest() {
  return {
    type: GetHostingProviderDetailsActions.FETCH_HOSTING_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getHostingProviderDetailsErrorRequest());
}
export function getHostingProviderDetailsErrorRequest() {
  return {
    type: GetHostingProviderDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingProviders", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveHostingProviderActions = {
  FETCH_ACTIVE_HOSTING_PROVIDER: "FETCH_ACTIVE_HOSTING_PROVIDER",
  FETCH_ACTIVE_HOSTING_PROVIDER_STATUS: "FETCH_ACTIVE_HOSTING_PROVIDER_STATUS",
  FETCH_ACTIVE_HOSTING_PROVIDER_FULFILLED:
    "FETCH_ACTIVE_HOSTING_PROVIDER_FULFILLED",
  FETCH_ACTIVE_HOSTING_PROVIDER_REJECTED:
    "FETCH_ACTIVE_HOSTING_PROVIDER_REJECTED",
};
export function getActiveHostingProviderList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_11/getAllHostingProviderMasterPagination?length=0&sortOrder=desc&sortField=hostProviderId&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveHostingProviderListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveHostingProviderListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveHostingProviderListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveHostingProviderListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveHostingProviderListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveHostingProviderListError(err));
              break;
            case 500:
              dispatch(getActiveHostingProviderListError("Server Error"));
              break;
            default:
              dispatch(getActiveHostingProviderListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveHostingProviderListRequest() {
  return {
    type: GetActiveHostingProviderActions.FETCH_ACTIVE_HOSTING_PROVIDER,
    isPosting: true,
  };
}
export function getActiveHostingProviderListError(error) {
  return {
    type:
      GetActiveHostingProviderActions.FETCH_ACTIVE_HOSTING_PROVIDER_REJECTED,
    payload: error,
  };
}

export function getActiveHostingProviderListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetActiveHostingProviderActions.FETCH_ACTIVE_HOSTING_PROVIDER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveHostingProviderListStatus() {
  return (dispatch) => dispatch(getActiveHostingProviderListStatusRequest());
}
export function getActiveHostingProviderListStatusRequest() {
  return {
    type: GetActiveHostingProviderActions.FETCH_ACTIVE_HOSTING_PROVIDER_STATUS,
  };
}
