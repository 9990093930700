import {
  SaveVulnerabilityActions,
  GetVulnerabilityDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveVulnerabilityActions,
  GetVulnerabilityActions
} from "./actions";

const initState = {
  vulnerabilitySuccessMessage: null,
  vulnerabilityErrorMessage: null,
  VulnerabilityStatus: null,
  VulnerabilityMaster: [],
  VulnerabilityData: null,
  ActiveVulnerabilityList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveVulnerabilityActions.SAVE_VULNERABILITY:
      return {
        ...state,
        isLoading: true,
        vulnerabilityErrorMessage: null,
        modalActive: false,
      };
    case SaveVulnerabilityActions.SAVE_VULNERABILITY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        VulnerabilityStatus: payload.status,
        vulnerabilityErrorMessage: null,
        vulnerabilitySuccessMessage: "Vulnerability added successfully!",
      };

    case SaveVulnerabilityActions.SAVE_VULNERABILITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        VulnerabilityStatus: null,
        vulnerabilityErrorMessage: payload,
      };
    case SaveVulnerabilityActions.SAVE_VULNERABILITY_STATUS:
      return {
        ...state,
        VulnerabilityStatus: 0,
        vulnerabilityErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetVulnerabilityDetailsActions.FETCH_VULNERABILITY:
      return {
        ...state,
        isLoading: true,
        vulnerabilityErrorMessage: null,
        modalActive: false,
        VulnerabilityData: null,
      };
    case GetVulnerabilityDetailsActions.FETCH_VULNERABILITY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        VulnerabilityData: payload.data,
        VulnerabilityDataStatus: payload.status,
        vulnerabilityErrorMessage: null,
      };
    case GetVulnerabilityDetailsActions.FETCH_VULNERABILITY_BY_RISK_ID_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        VulnerabilityList: payload.data,
        VulnerabilityDataStatus: payload.status,
        vulnerabilityErrorMessage: null,
      };
    case GetVulnerabilityDetailsActions.FETCH_VULNERABILITY_REJECTED:
      return {
        ...state,
        isLoading: false,
        vulnerabilityErrorMessage: payload,
        VulnerabilityData: null,
      };
    case GetVulnerabilityDetailsActions.FETCH_VULNERABILITY_STATUS:
      return {
        ...state,
        VulnerabilityData: payload.data,
        VulnerabilityDataStatus: payload.status,
      };
    ///Active DATA DISPOSAL
    case GetActiveVulnerabilityActions.FETCH_ACTIVE_VULNERABILITY:
      return {
        ...state,
        isLoading: true,
        vulnerabilityErrorMessage: null,
        modalActive: false,
        ActiveVulnerabilityList: null,
      };
    case GetActiveVulnerabilityActions.FETCH_ACTIVE_VULNERABILITY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveVulnerabilityList: payload.data,
        vulnerabilityErrorMessage: null,
      };
    case GetActiveVulnerabilityActions.FETCH_ACTIVE_VULNERABILITY_REJECTED:
      return {
        ...state,
        isLoading: false,
        vulnerabilityErrorMessage: payload,
        ActiveVulnerabilityList: null,
      };
    case GetActiveVulnerabilityActions.FETCH_ACTIVE_VULNERABILITY_STATUS:
      return {
        ...state,
        ActiveVulnerabilityList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetVulnerability: null,
        VulnerabilityStatus: null,
        vulnerabilitySuccessMessage: null,
        GetVulnerabilityStatus: null,
        VulnerabilityData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

//Get Pagination
case GetVulnerabilityActions.FETCH_VULNERABILITY:
  return {
    ...state,
    isLoading: true,
    vulnerabilityErrorMessage: null,
    modalActive: false,
    VulnerabilityData: null
  };
case GetVulnerabilityActions.FETCH_VULNERABILITY_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveVulnerabilityList: payload.data,
    VulnerabilityStatus: payload.status,  
    vulnerabilityErrorMessage: null
  };
case GetVulnerabilityActions.FETCH_VULNERABILITY_REJECTED:
  return {
    ...state,
    isLoading: false,
    vulnerabilityErrorMessage: payload,
    VulnerabilityData: null
  };
case GetVulnerabilityActions.FETCH_VULNERABILITY_STATUS:
  return {
    ...state,
    VulnerabilityData: null
  };
    
case GetVulnerabilityActions.FETCH_VULNERABILITY:
  return {
    ...state,
    isLoading: true,
    vulnerabilityErrorMessage: null,
    modalActive: false,
    VulnerabilityData: null
  };
case GetVulnerabilityActions.FETCH_VULNERABILITY_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveVulnerabilityList: payload.data,
    VulnerabilityStatus: payload.status,  
    vulnerabilityErrorMessage: null
  };
case GetVulnerabilityActions.FETCH_VULNERABILITY_REJECTED:
  return {
    ...state,
    isLoading: false,
    vulnerabilityErrorMessage: payload,
    VulnerabilityData: null
  };
case GetVulnerabilityActions.FETCH_VULNERABILITY_STATUS:
  return {
    ...state,
    VulnerabilityData: null
  };
  /* */

  default:
    return state;


}
}

