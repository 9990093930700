import axios from "axios";
import { IncidentManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveBreachTypeActions = {
  SAVE_BREACH_TYPE: "SAVE_BREACH_TYPE",
  SAVE_BREACH_TYPE_STATUS: "SAVE_BREACH_TYPE_STATUS",
  SAVE_BREACH_TYPE_SUCCESS: "SAVE_BREACH_TYPE_SUCCESS",
  SAVE_BREACH_TYPE_FAILURE: "SAVE_BREACH_TYPE_FAILURE",
};

export function SaveBreachTypeRequest() {
  return {
    type: SaveBreachTypeActions.SAVE_BREACH_TYPE,
    isPosting: true,
  };
}

export function SaveBreachTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveBreachTypeActions.SAVE_BREACH_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveBreachTypeFailure(error) {
  return {
    type: SaveBreachTypeActions.SAVE_BREACH_TYPE_FAILURE,
    payload: error,
  };
}

export function SaveBreachTypeError(error) {
  return {
    type: SaveBreachTypeActions.SAVE_BREACH_TYPE_FAILURE,
    payload: error,
  };
}

export function saveBreachType(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${IncidentManagementService_URL}/Apc_Persist/Amc_37/saveBreachType`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveBreachTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveBreachTypeSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveBreachTypeError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(SaveBreachTypeError(err));
          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveBreachTypeError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveBreachTypeError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveBreachTypeError(err));
          //   break;
          // case 500:
          //   dispatch(SaveBreachTypeError("Server Error"));
          //   break;
          // default:
          //   dispatch(SaveBreachTypeError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetBreachTypeDetailsActions = {
  FETCH_BREACH_DETAILS: "FETCH_BREACH_DETAILS",
  FETCH_BREACH_DETAILS_STATUS: "FETCH_BREACH_DETAILS_STATUS",
  FETCH_BREACH_DETAILS_FULFILLED: "FETCH_BREACH_DETAILS_FULFILLED",
  FETCH_BREACH_DETAILS_REJECTED: "FETCH_BREACH_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getBreachTypeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_37/getBreachTypeById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getBreachTypeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getBreachTypeDetailsSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getBreachTypeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getBreachTypeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getBreachTypeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getBreachTypeDetailsError(err));
              break;
            case 500:
              dispatch(getBreachTypeDetailsError("Server Error"));
              break;
            default:
              dispatch(getBreachTypeDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getBreachTypeDetailsRequest() {
  return {
    type: GetBreachTypeDetailsActions.FETCH_BREACH_DETAILS,
    isPosting: true,
  };
}
export function getBreachTypeDetailsError(error) {
  return {
    type: GetBreachTypeDetailsActions.FETCH_BREACH_DETAILS_REJECTED,
    payload: error,
  };
}

export function getBreachTypeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetBreachTypeDetailsActions.FETCH_BREACH_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getBreachTypeDetailsStatus() {
  return (dispatch) => dispatch(getBreachTypeDetailsStatusRequest());
}
export function getBreachTypeDetailsStatusRequest() {
  return {
    type: GetBreachTypeDetailsActions.FETCH_BREACH_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getBreachTypeDetailsErrorRequest());
}
export function getBreachTypeDetailsErrorRequest() {
  return {
    type: GetBreachTypeDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("breachTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${IncidentManagementService_URL}/Apc_Persist/Amc_37/bulkUploadExcel/breachType`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveBreachTypeActions = {
  FETCH_ACTIVE_BREACH_TYPE: "FETCH_ACTIVE_BREACH_TYPE",
  FETCH_ACTIVE_BREACH_TYPE_STATUS: "FETCH_ACTIVE_BREACH_TYPE_STATUS",
  FETCH_ACTIVE_BREACH_TYPE_FULFILLED: "FETCH_ACTIVE_BREACH_TYPE_FULFILLED",
  FETCH_ACTIVE_BREACH_TYPE_REJECTED: "FETCH_ACTIVE_BREACH_TYPE_REJECTED",
};
export function getActiveBreachTypeList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${IncidentManagementService_URL}/Apc_View/Amc_37/findAllBreachType?length=0&sortOrder=desc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveBreachTypeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveBreachTypeListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveBreachTypeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveBreachTypeListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveBreachTypeListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveBreachTypeListError(err));
              break;
            case 500:
              dispatch(getActiveBreachTypeListError("Server Error"));
              break;
            default:
              dispatch(getActiveBreachTypeListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveBreachTypeListRequest() {
  return {
    type: GetActiveBreachTypeActions.FETCH_ACTIVE_BREACH_TYPE,
    isPosting: true,
  };
}
export function getActiveBreachTypeListError(error) {
  return {
    type: GetActiveBreachTypeActions.FETCH_ACTIVE_BREACH_TYPE_REJECTED,
    payload: error,
  };
}

export function getActiveBreachTypeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveBreachTypeActions.FETCH_ACTIVE_BREACH_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveBreachTypeListStatus() {
  return (dispatch) => dispatch(getActiveBreachTypeListStatusRequest());
}
export function getActiveBreachTypeListStatusRequest() {
  return {
    type: GetActiveBreachTypeActions.FETCH_ACTIVE_BREACH_TYPE_STATUS,
  };
}
