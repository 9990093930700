import {
  saveRequestTypeActions,
  deleteRequestTypeActions,
  GetRequestTypeDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRequestTypeTypeActions,
} from "./actions";

const initState = {
  requestSuccessMessage: null,
  requestTypeErrorMessage: null,
  RequestTypeStatus: null,
  RequestTypeMaster: [],
  RequestTypeData: null,
  ActiveRequestTypeList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveRequestTypeActions.SAVE_REQUEST_TYPE:
      return {
        ...state,
        isLoading: true,
        requestTypeErrorMessage: null,
        modalActive: false,
      };
    case saveRequestTypeActions.SAVE_REQUEST_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestTypeStatus: payload.status,
        requestTypeErrorMessage: null,
        requestSuccessMessage: "Request Type added successfully!",
      };

    case saveRequestTypeActions.SAVE_REQUEST_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        RequestTypeStatus: null,
        requestTypeErrorMessage: payload,
      };
    case saveRequestTypeActions.SAVE_REQUEST_TYPE_STATUS:
      return {
        ...state,
        RequestTypeStatus: 0,
        requestTypeErrorMessage: null,
      };

    // Delete Request Type

    case deleteRequestTypeActions.DELETE_REQUEST_TYPE:
      return {
        ...state,
        isLoading: true,
        requestTypeErrorMessage: null,
        modalActive: false,
      };
    case deleteRequestTypeActions.DELETE_REQUEST_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestTypeStatus: payload.status,
        requestTypeErrorMessage: null,
        requestSuccessMessage: "Request Type Deleted successfully!",
      };

    case deleteRequestTypeActions.DELETE_REQUEST_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        RequestTypeStatus: null,
        requestTypeErrorMessage: payload,
      };
    case deleteRequestTypeActions.DELETE_REQUEST_TYPE_STATUS:
      return {
        ...state,
        RequestTypeStatus: 0,
        requestTypeErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetRequestTypeDetailsActions.FETCH_REQUEST_TYPE_DETAILS:
      return {
        ...state,
        isLoading: true,
        requestTypeErrorMessage: null,
        modalActive: false,
        RequestTypeData: null,
      };
    case GetRequestTypeDetailsActions.FETCH_REQUEST_TYPE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestTypeData: payload.data,
        RequestTypeDataStatus: payload.status,
        requestTypeErrorMessage: null,
      };
    case GetRequestTypeDetailsActions.FETCH_REQUEST_TYPE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        requestTypeErrorMessage: payload,
        RequestTypeData: null,
      };
    case GetRequestTypeDetailsActions.FETCH_REQUEST_TYPE_DETAILS_STATUS:
      return {
        ...state,
        RequestTypeData: null,
      };
    ///Active requests
    case GetActiveRequestTypeTypeActions.FETCH_ACTIVE_REQUEST_TYPE:
      return {
        ...state,
        isLoading: true,
        requestTypeErrorMessage: null,
        modalActive: false,
        ActiveRequestTypeList: null,
      };
    case GetActiveRequestTypeTypeActions.FETCH_ACTIVE_REQUEST_TYPE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveRequestTypeList: payload.data,
        requestTypeErrorMessage: null,
      };
    case GetActiveRequestTypeTypeActions.FETCH_ACTIVE_REQUEST_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        requestTypeErrorMessage: payload,
        ActiveRequestTypeList: null,
      };
    case GetActiveRequestTypeTypeActions.FETCH_ACTIVE_REQUEST_TYPE_STATUS:
      return {
        ...state,
        ActiveRequestTypeList: null,
      };
    // clear requestType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRequestType: null,
        RequestTypeStatus: null,
        requestSuccessMessage: null,
        GetRequestTypeStatus: null,
        RequestTypeData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
