import {
  SaveDataSubVolRangeActions,
  GetDataSubVolRangeDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDepartmentActions
} from "./actions";

const initState = {
  dataSubVolRangeSuccessMessage: null,
  dataSubVolRangeErrorMessage: null,
  dataSubVolRangeStatus: null,
  dataSubVolRangeMaster: [],
  dataSubVolRangeData: null,
  ActiveDataSubVolList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataSubVolRangeActions.SAVE_DATA_VOL_RANGE:
      return {
        ...state,
        isLoading: true,
        dataSubVolRangeErrorMessage: null,
        modalActive: false
      };
    case SaveDataSubVolRangeActions.SAVE_DATA_VOL_RANGE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubVolRangeStatus: payload.status,
        dataSubVolRangeErrorMessage: null,
        dataSubVolRangeSuccessMessage: "Data Subject Vol added successfully!"
      };

    case SaveDataSubVolRangeActions.SAVE_DATA_VOL_RANGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataSubVolRangeStatus: null,
        dataSubVolRangeErrorMessage: payload
      };
    case SaveDataSubVolRangeActions.SAVE_DATA_VOL_RANGE_STATUS:
      return {
        ...state,
        dataSubVolRangeStatus: 0,
        dataSubVolRangeErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataSubVolRangeDetailsActions.FETCH_DATA_VOL_RANGE_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataSubVolRangeErrorMessage: null,
        modalActive: false,
        dataSubVolRangeData: null
      };
    case GetDataSubVolRangeDetailsActions.FETCH_DATA_VOL_RANGE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubVolRangeData: payload.data,
        dataSubVolRangeDataStatus: payload.status,
        dataSubVolRangeErrorMessage: null
      };
    case GetDataSubVolRangeDetailsActions.FETCH_DATA_VOL_RANGE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubVolRangeErrorMessage: payload,
        dataSubVolRangeData: null
      };
    case GetDataSubVolRangeDetailsActions.FETCH_DATA_VOL_RANGE_DETAILS_STATUS:
      return {
        ...state,
        dataSubVolRangeData: null
      };
    ///Active DEPARTMENT
    case GetActiveDepartmentActions.FETCH_ACTIVE_DATASUBVOL:
      return {
        ...state,
        isLoading: true,
        dataSubVolRangeErrorMessage: null,
        modalActive: false,
        ActiveDataSubVolList: null
      };
    case GetActiveDepartmentActions.FETCH_ACTIVE_DATASUBVOL_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataSubVolList: payload.data,
        dataSubVolRangeErrorMessage: null
      };
    case GetActiveDepartmentActions.FETCH_ACTIVE_DATASUBVOL_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubVolRangeErrorMessage: payload,
        ActiveDataSubVolList: null
      };
    case GetActiveDepartmentActions.FETCH_DATA_VOL_RANGE_DETAILS_STATUS:
      return {
        ...state,
        ActiveDataSubVolList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataSubVolRange: null,
        dataSubVolRangeStatus: null,
        dataSubVolRangeSuccessMessage: null,
        GetdataSubVolRangeStatus: null,
        dataSubVolRangeData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
