import axios from "axios";
import HashMap from "hashmap";

var map = new HashMap();
var err;
//////////////////////////////////////////////////////////////////////////////////////////////
function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}
//////////////////////////////////
export const ClearLogoutActions = {
  CLEAR_LOGOUT_DETAILS: "CLEAR_LOGOUT_DETAILS",
};

export function clearLogoutData() {
  return (dispatch) => dispatch(clearLogouttData());
}

export function clearLogouttData() {
  return {
    type: ClearLogoutActions.CLEAR_LOGOUT_DETAILS,
  };
}

/////////////////////////////////////

export const SetLogoutActions = {
  SET_LOGOUT_DETAILS: "SET_LOGOUT_DETAILS",
};

export function setLogoutFlag() {
  return (dispatch) => dispatch(setLogoutFlagg());
}

export function setLogoutFlagg() {
  return {
    type: SetLogoutActions.SET_LOGOUT_DETAILS,
  };
}

/////////////////////

export const ModalLogoutActions = {
  MODAL_LOGOUT_DETAILS: "MODAL_LOGOUT_DETAILS",
};

export function modalOpenFlag() {
  return (dispatch) => dispatch(modalOpenFlagg());
}

export function modalOpenFlagg() {
  return {
    type: ModalLogoutActions.MODAL_LOGOUT_DETAILS,
  };
}

////////////////////////////////

export const ModalCloseLogoutActions = {
  MODAL_CLOSE_LOGOUT_DETAILS: "MODAL_CLOSE_LOGOUT_DETAILS",
};

export function modalCloseFlag() {
  return (dispatch) => dispatch(modalCloseFlagg());
}

export function modalCloseFlagg() {
  return {
    type: ModalCloseLogoutActions.MODAL_CLOSE_LOGOUT_DETAILS,
  };
}
