import {
  SaveDataProcessorActions,
  GetDataProcessorDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataProcessorActions
} from "./actions";

const initState = {
  dataProcessorSuccessMessage: null,
  dataProcessorErrorMessage: null,
  dataProcessorStatus: null,
  dataProcessorMaster: [],
  dataProcessorData: null,
  ActiveDataProcessorList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataProcessorActions.SAVE_DATA_PROCESSOR:
      return {
        ...state,
        isLoading: true,
        dataProcessorErrorMessage: null,
        modalActive: false
      };
    case SaveDataProcessorActions.SAVE_DATA_PROCESSOR_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataProcessorStatus: payload.status,
        dataProcessorErrorMessage: null,
        dataProcessorSuccessMessage: "Data Processor added successfully!"
      };

    case SaveDataProcessorActions.SAVE_DATA_PROCESSOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataProcessorStatus: null,
        dataProcessorErrorMessage: payload
      };
    case SaveDataProcessorActions.SAVE_DATA_PROCESSOR_STATUS:
      return {
        ...state,
        dataProcessorStatus: 0,
        dataProcessorErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataProcessorErrorMessage: null,
        modalActive: false,
        dataProcessorData: null
      };
    case GetDataProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataProcessorData: payload.data,
        dataProcessorDataStatus: payload.status,
        dataProcessorErrorMessage: null
      };
    case GetDataProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataProcessorErrorMessage: payload,
        dataProcessorData: null
      };
    case GetDataProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_STATUS:
      return {
        ...state,
        dataProcessorData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataProcessorActions.FETCH_ACTIVE_DATA_PROCESSOR:
      return {
        ...state,
        isLoading: true,
        dataProcessorErrorMessage: null,
        modalActive: false,
        ActiveDataProcessorList: null
      };
    case GetActiveDataProcessorActions.FETCH_ACTIVE_DATA_PROCESSOR_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataProcessorList: payload.data,
        dataProcessorErrorMessage: null
      };
    case GetActiveDataProcessorActions.FETCH_ACTIVE_DATA_PROCESSOR_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataProcessorErrorMessage: payload,
        ActiveDataProcessorList: null
      };
    case GetActiveDataProcessorActions.FETCH_ACTIVE_DATA_PROCESSOR_STATUS:
      return {
        ...state,
        ActiveDataProcessorList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataProcessor: null,
        dataProcessorStatus: null,
        dataProcessorSuccessMessage: null,
        GetdataProcessorStatus: null,
        dataProcessorData: null,
        ActiveDataProcessorList: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
