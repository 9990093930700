import {
  saveCookieTemplateActions,
  GetCookieTemplateDetailsActions,
  getCookieTemplateListActions,
  SetCookieDomainTemplateActions,
  saveCookieDetailsActions,
  ViewDomainCookiesActions,
  ClearFormDetailsActions,
} from "./actions";

const initState = {
  bannerDetails: null,
  bannerStatus: null,
  TemplateDetails: null,
  error: null,
  scannedCookies: null,
};

export default function CookiesList(state = initState, { type, payload }) {
  switch (type) {
    case saveCookieTemplateActions.SAVE_COOKIE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        error: null,
        modalActive: false,
      };
    case saveCookieTemplateActions.SAVE_COOKIE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        TemplateDetails: payload.data,
        error: null,
        // cooe: "Asset Type added successfully!"
      };

    case saveCookieTemplateActions.SAVE_COOKIE_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        TemplateDetails: null,
        error: payload,
      };
    case ViewDomainCookiesActions.FETCH_DOMAIN_COOKIES:
      return {
        ...state,
        isLoading: true,
        error: null,
        modalActive: false,
      };
    case ViewDomainCookiesActions.FETCH_DOMAIN_COOKIES_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        scannedCookies: payload.data,
        error: null,
        // cooe: "Asset Type added successfully!"
      };

    case ViewDomainCookiesActions.FETCH_DOMAIN_COOKIES_REJECTED:
      return {
        ...state,
        isLoading: false,
        scannedCookies: null,
        error: payload,
      };
    case GetCookieTemplateDetailsActions.FETCH_COOKIE_TEMPLATE_DETAILS:
      return {
        ...state,
        isLoading: true,
        error: null,
        modalActive: false,
      };
    case GetCookieTemplateDetailsActions.FETCH_COOKIE_TEMPLATE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        TemplateDetails: payload.data,
        error: null,
        // cooe: "Asset Type added successfully!"
      };
    case GetCookieTemplateDetailsActions.RESET_COOKIE_TEMPLATE_DETAILS:
      return {
        ...state,
        TemplateDetails: null,
      };

    case GetCookieTemplateDetailsActions.FETCH_COOKIE_TEMPLATE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        TemplateDetails: null,
        error: payload,
      };

    case SetCookieDomainTemplateActions.FETCH_COOKIE_DOMAIN_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        error: null,
        modalActive: false,
      };
    case SetCookieDomainTemplateActions.FETCH_COOKIE_DOMAIN_TEMPLATE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DomainTemplate: payload.data,
        error: null,
        // cooe: "Asset Type added successfully!"
      };

    case SetCookieDomainTemplateActions.FETCH_COOKIE_DOMAIN_TEMPLATE_REJECTED:
      return {
        ...state,
        isLoading: false,
        DomainTemplate: null,
        error: payload,
      };

    case saveCookieDetailsActions.SAVE_COOKIE_DETAILS:
      return {
        ...state,
        isLoading: true,
        error: null,
        modalActive: false,
      };
    case saveCookieDetailsActions.SAVE_COOKIE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        cookiesDetails: payload.data,
        error: null,
        // cooe: "Asset Type added successfully!"
      };

    case saveCookieDetailsActions.SAVE_COOKIE_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        cookiesDetails: null,
        error: payload,
      };

    case getCookieTemplateListActions.FETCH_COOKIE_TEMP_LIST:
      return {
        ...state,
        isLoading: true,
        cookieTempErrorMessage: null,
        modalActive: false,
        ConsentEntryList: null,
      };
    case getCookieTemplateListActions.FETCH_COOKIE_TEMP_LIST_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        CookietempList: payload.data,
        cookieTempDataStatus: payload.status,
        viewInteractionFilter: true,
      };
    case getCookieTemplateListActions.FETCH_COOKIE_TEMP_LIST_REJECTED:
      return {
        ...state,
        isLoading: false,
        cookieTempErrorMessage: payload,
        CookietempList: null,
      };
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        isLoading: false,
        bannerDetails: null,
        bannerStatus: null,
        TemplateDetails: null,
        error: null,
        scannedCookies: null,
        cookieTempErrorMessage: null,
        CookietempList: null,
        cookiesDetails: null,
      };

    default:
      return state;
  }
}
