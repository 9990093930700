import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import userpic from "../../image/user1.png";
import { customLogout } from "../../redux/auth/actions";
import { setDashboardView } from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Server_IP, BUCKET_PATH } from "../../redux/Url";
import { message } from "antd";
import { Divider } from "antd";
import CustomLogout from "../CustomLogout";
var redirectSignin = false;
// const { logout } = authAction;
// const { logout } = authAction;
const { toggleCollapsed, setActiveLink } = appActions;
const { closeAll } = appActions;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  setActiveLink = (linkName) => {
    this.props.setActiveLink(linkName);
  };
  handleCustomLogout = () => {
    this.props
      .customLogout({
        access_token: this.props.access_token,
      })
      .then((res) => {
        if (res && res.status === 200) {
          // redirectSignin = true;
          // this.props.history.push("/");
          // this.setState(
          //   {
          //     redirectSignin: true
          //   },

          message.success(res.data && res.data.status, 3);

          // return <Redirect to={"/"} />;
        } else if (this.props.Auth.error != null) {
          message.error(this.props.Auth.error, 3);
        } else {
          message.error("Session Has Expired!", 3);
        }
      });
  };

  renderDefaultImage = (data) => {
    var initials = data.userFirstName.charAt(0) + data.userLastName.charAt(0);
    return (
      <label
        for="image-profile"
        className="upload-placeholder circle-upload-img"
      >
        {initials}
      </label>
    );
  };

  render() {
    //
    if (this.props.Auth.logoutStatus === 200) {
      return <Redirect to={"/"} />;
    }
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {/* <a className="isoDropdownLink">
          <IntlMessages id="themeSwitcher.settings" />
        </a>
        <a className="isoDropdownLink">
          <IntlMessages id="sidebar.feedback" />
        </a>
        <a className="isoDropdownLink">
          <IntlMessages id="topbar.help" />
        </a> */}
        <div className="compnayuser-name">
          <small>
            {" "}
            <IntlMessages id={"sidebar.Welcome"} />,
          </small>
          <br />
          <p className="Top-username">
            {(this.props.dashboardView.profileData &&
              this.props.dashboardView.profileData.userFirstName) +
              " " +
              (this.props.dashboardView.profileData &&
                this.props.dashboardView.profileData.userLastName)}
          </p>
        </div>
        <Link
          className="isoDropdownLink"
          onClick={() => {
            this.setActiveLink("PROFILE");
            this.props.closeAll();
          }}
          to="/dashboard/Profile"
        >
          <IntlMessages id="sidebar.Profile" />
        </Link>

        {this.props.PermissionData.permissionsMap &&
          
          this.props.PermissionData.permissionsMap.SYSTEM_CONFIGURATION &&
          this.props.PermissionData.permissionsMap.SYSTEM_CONFIGURATION.viewModule && (
            <Link
              className="isoDropdownLink"
              onClick={() => {
                this.setActiveLink("MANAGE COMPANY");
                this.props.setDashboardView(true);
                this.props.closeAll();
              }}
              to="/dashboard/userMaster"
            >
              <IntlMessages id="sidebar.ManageCompany" />
            </Link>
          )}

        <Divider style={{ marginBottom: "0px", marginTop: "4px" }} />

        <a
          className="isoDropdownLink"
          onClick={() => {
            this.handleCustomLogout();
            this.props.closeAll();
          }}
        >
          <IntlMessages id="sidebar.Logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="hover"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="user-top-profile">
          {this.props.Auth &&
          this.props.Auth.profileData &&
          this.props.Auth.profileData.profileImage != null ? (
            <div className="isoImgWrapper">
              <img
                alt="user"
                // src={userpic}
                src={this.props.Auth.profileData.profileImage.path}
              />
              {/* <span className="userActivity online" /> */}
            </div>
          ) : (
            this.renderDefaultImage(this.props.Auth.profileData)
          )}{" "}
        </div>
      </Popover>
    );
  }
}
export default connect(
  (state) => ({
    Auth: state.Auth,
    access_token: state.Auth.idToken,
    LogoutFlag: state.logoutFlag,
    dashboardView: state.Auth,

    PermissionData: state.Auth.PermissionData,
  }),
  { customLogout, setDashboardView, closeAll, setActiveLink }
)(TopbarUser);
