import axios from "axios";
import {
  SuperAdmin_Service_URL,
  AssetManagementService_URL,
  RequestManagement_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Asset

export const saveRequestMasterActions = {
  SAVE_ASSET: "SAVE_ASSET",
  SAVE_ASSET_STATUS: "SAVE_ASSET_STATUS",
  SAVE_ASSET_SUCCESS: "SAVE_ASSET_SUCCESS",
  SAVE_ASSET_FAILURE: "SAVE_ASSET_FAILURE",
};

export function saveRequestMasterRequest() {
  return {
    type: saveRequestMasterActions.SAVE_ASSET,
    isPosting: true,
  };
}

export function saveRequestMasterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveRequestMasterActions.SAVE_ASSET_SUCCESS,
      payload: props,
    });
  };
}

export function saveRequestMasterFailure(error) {
  return {
    type: saveRequestMasterActions.SAVE_ASSET_FAILURE,
    payload: error,
  };
}

export function saveRequestMasterError(error) {
  return {
    type: saveRequestMasterActions.SAVE_ASSET_FAILURE,
    payload: error,
  };
}

export function saveRequestMaster(props, access_token) {
  // var value = {
  //   assetTypeId: props.assetTypeId || null,
  //   assetTypeName: props.assetTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RequestManagement_URL}/saveRequest`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveRequestMasterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveRequestMasterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveRequestMasterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          dispatch(saveRequestMasterError(err));
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(saveRequestMasterError(err));
          //     break;

          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(saveRequestMasterError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(saveRequestMasterError(err));
          //     break;
          //   case 500:
          //     dispatch(saveRequestMasterError("Server Error"));
          //     break;
          //   default:
          //     dispatch(saveRequestMasterError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// // Save RequestMaster

// export const saveRequestMasterActions = {
//   SAVE_REQUEST_MASTER: "SAVE_REQUEST_MASTER",
//   SAVE_REQUEST_MASTER_STATUS: "SAVE_REQUEST_MASTER_STATUS",
//   SAVE_REQUEST_MASTER_SUCCESS: "SAVE_REQUEST_MASTER_SUCCESS",
//   SAVE_REQUEST_MASTER_FAILURE: "SAVE_REQUEST_MASTER_FAILURE",
// };

// export function saveRequestMasterRequest() {
//   return {
//     type: saveRequestMasterActions.SAVE_REQUEST_MASTER,
//     isPosting: true,
//   };
// }

// export function saveRequestMasterSuccess(props) {
//   return (dispatch) => {
//     dispatch({
//       type: saveRequestMasterActions.SAVE_REQUEST_MASTER_SUCCESS,
//       payload: props,
//     });
//   };
// }

// export function saveRequestMasterFailure(error) {
//   return {
//     type: saveRequestMasterActions.SAVE_REQUEST_MASTER_FAILURE,
//     payload: error,
//   };
// }

// export function saveRequestMasterError(error) {
//   return {
//     type: saveRequestMasterActions.SAVE_REQUEST_MASTER_FAILURE,
//     payload: error,
//   };
// }

// export function saveRequestMaster(props) {
//   var value = {
//     id: props.id || 0,
//     name: props.name,
//     summary: props.summary,
//     description: props.description,
//     dataSubjectIds: props.dataSubjectIds,
//     lastName: props.lastName,
//     firstName: props.firstName,
//     email: props.email,
//     contactNumber: props.contactNumber,
//     documentGetDto: props.base64,
//     countryId: props.countryId,
//   };

//   return (dispatch) =>
//     axios({
//       method: "post",
//       data: value,
//       url: `${RequestManagement_URL}/saveRequest`,
//       headers: {
//         authorization: "Bearer " + props.access_token,
//       },
//     })
//       .then((response) => {
//         dispatch(saveRequestMasterRequest());
//         if (response.status >= 200 && response.status < 300) {
//           dispatch(saveRequestMasterSuccess(response));
//           return response;
//         } else if (response.status === 401) {
//         } else {
//           const error = new Error(response.statusText);
//           dispatch(saveRequestMasterError(error));
//           throw error;
//         }
//       })
//       .catch((err) => {
//         //
//         if (err.response) {
//           map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//           map.forEach((ele) => (err = ele));
//           return err;
//           // dispatch(saveRequestMasterError(err));
//           // switch (err.response.status) {
//           //   case 400:

//           //   break;
//           // case 404:
//           //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//           //   map.forEach(ele => (err = ele));

//           //   dispatch(saveRequestMasterError(err));
//           //   break;
//           // case 406:
//           //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//           //   // map.forEach(ele => (err = ele));

//           //   // dispatch(saveRequestMasterError(err));
//           //   return err.response;
//           //   break;
//           // case 500:
//           //   dispatch(saveRequestMasterError("Server Error"));
//           //   break;
//           // default:
//           //   dispatch(saveRequestMasterError("Unknown Error"));
//           //   break;
//           // }
//         } else {
//           // //dispatch(logout());
//           dispatch(clearLogoutData());
//         }
//       });
// }

// Delete RequestMaster

export const deleteRequestMasterActions = {
  DELETE_REQUEST_MASTER: "DELETE_REQUEST_MASTER",
  DELETE_REQUEST_MASTER_STATUS: "DELETE_REQUEST_MASTER_STATUS",
  DELETE_REQUEST_MASTER_SUCCESS: "DELETE_REQUEST_MASTER_SUCCESS",
  DELETE_REQUEST_MASTER_FAILURE: "DELETE_REQUEST_MASTER_FAILURE",
};

export function deleteRequestMasterRequest() {
  return {
    type: deleteRequestMasterActions.DELETE_REQUEST_MASTER,
    isPosting: true,
  };
}

export function deleteRequestMasterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteRequestMasterActions.DELETE_REQUEST_MASTER_SUCCESS,
      payload: props,
    });
  };
}

export function deleteRequestMasterFailure(error) {
  return {
    type: deleteRequestMasterActions.DELETE_REQUEST_MASTER_FAILURE,
    payload: error,
  };
}

export function deleteRequestMasterError(error) {
  return {
    type: deleteRequestMasterActions.DELETE_REQUEST_MASTER_FAILURE,
    payload: error,
  };
}

export function deleteRequestMaster(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${AssetManagementService_URL}/deleteRequestMaster?requestMasterId=` +
        params.requestMasterID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteRequestMasterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteRequestMasterSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteRequestMasterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRequestMasterError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRequestMasterError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRequestMasterError(err));
              break;
            case 500:
              dispatch(deleteRequestMasterError("Server Error"));
              break;
            default:
              dispatch(deleteRequestMasterError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetRequestMasterDetailsActions = {
  FETCH_REQUEST_MASTER_DETAILS: "FETCH_REQUEST_MASTER_DETAILS",
  FETCH_REQUEST_MASTER_DETAILS_STATUS: "FETCH_REQUEST_MASTER_DETAILS_STATUS",
  FETCH_REQUEST_MASTER_DETAILS_FULFILLED:
    "FETCH_REQUEST_MASTER_DETAILS_FULFILLED",
  FETCH_REQUEST_MASTER_DETAILS_REJECTED:
    "FETCH_REQUEST_MASTER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRequestMasterDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RequestManagement_URL}/findRequestById?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRequestMasterDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRequestMasterDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRequestMasterDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestMasterDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestMasterDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestMasterDetailsError(err));
              break;
            case 500:
              dispatch(getRequestMasterDetailsError("Server Error"));
              break;
            default:
              dispatch(getRequestMasterDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRequestMasterDetailsRequest() {
  return {
    type: GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS,
    isPosting: true,
  };
}
export function getRequestMasterDetailsError(error) {
  return {
    type: GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRequestMasterDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRequestMasterDetailsStatus() {
  return (dispatch) => dispatch(getRequestMasterDetailsStatusRequest());
}
export function getRequestMasterDetailsStatusRequest() {
  return {
    type: GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getRequestMasterDetailsErrorRequest());
}
export function getRequestMasterDetailsErrorRequest() {
  return {
    type: GetRequestMasterDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("requestMasters", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveRequestMasterActions = {
  FETCH_ACTIVE_REQUEST_MASTER: "FETCH_ACTIVE_REQUEST_MASTER",
  FETCH_ACTIVE_REQUEST_MASTER_STATUS: "FETCH_ACTIVE_REQUEST_MASTER_STATUS",
  FETCH_ACTIVE_REQUEST_MASTER_FULFILLED:
    "FETCH_ACTIVE_REQUEST_MASTER_FULFILLED",
  FETCH_ACTIVE_REQUEST_MASTER_REJECTED: "FETCH_ACTIVE_REQUEST_MASTER_REJECTED",
};

export function getActiveRequestMasterList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${AssetManagementService_URL}/getAllRequestMasterPagination?start=0&sortOrder=desc&booleanfield=true&sortField=requestMasterId&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveRequestMasterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRequestMasterListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveRequestMasterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRequestMasterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRequestMasterListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRequestMasterListError(err));
              break;
            case 500:
              dispatch(getActiveRequestMasterListError("Server Error"));
              break;
            default:
              dispatch(getActiveRequestMasterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRequestMasterListRequest() {
  return {
    type: GetActiveRequestMasterActions.FETCH_ACTIVE_REQUEST_MASTER,
    isPosting: true,
  };
}
export function getActiveRequestMasterListError(error) {
  return {
    type: GetActiveRequestMasterActions.FETCH_ACTIVE_REQUEST_MASTER_REJECTED,
    payload: error,
  };
}

export function getActiveRequestMasterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveRequestMasterActions.FETCH_ACTIVE_REQUEST_MASTER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRequestMasterListStatus() {
  return (dispatch) => dispatch(getActiveRequestMasterListStatusRequest());
}
export function getActiveRequestMasterListStatusRequest() {
  return {
    type: GetActiveRequestMasterActions.FETCH_ACTIVE_REQUEST_MASTER_STATUS,
  };
}

///////////////////////////////////////////////////////////////////////////////

export const GetRequestActivityDetailsActions = {
  FETCH_REQUEST_ACTIVITY_DETAILS: "FETCH_REQUEST_ACTIVITY_DETAILS",
  FETCH_REQUEST_ACTIVITY_DETAILS_STATUS:
    "FETCH_REQUEST_ACTIVITY_DETAILS_STATUS",
  FETCH_REQUEST_ACTIVITY_DETAILS_FULFILLED:
    "FETCH_REQUEST_ACTIVITY_DETAILS_FULFILLED",
  FETCH_REQUEST_ACTIVITY_DETAILS_REJECTED:
    "FETCH_REQUEST_ACTIVITY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRequestActivityDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RequestManagement_URL}/findRequestActivityById?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRequestActivityDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRequestActivityDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRequestActivityDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestActivityDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestActivityDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestActivityDetailsError(err));
              break;
            case 500:
              dispatch(getRequestActivityDetailsError("Server Error"));
              break;
            default:
              dispatch(getRequestActivityDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRequestActivityDetailsRequest() {
  return {
    type: GetRequestActivityDetailsActions.FETCH_REQUEST_ACTIVITY_DETAILS,
    isPosting: true,
  };
}
export function getRequestActivityDetailsError(error) {
  return {
    type:
      GetRequestActivityDetailsActions.FETCH_REQUEST_ACTIVITY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRequestActivityDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetRequestActivityDetailsActions.FETCH_REQUEST_ACTIVITY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRequestActivityDetailsStatus() {
  return (dispatch) => dispatch(getRequestActivityDetailsStatusRequest());
}
export function getRequestActivityDetailsStatusRequest() {
  return {
    type:
      GetRequestActivityDetailsActions.FETCH_REQUEST_ACTIVITY_DETAILS_STATUS,
  };
}

export function setRequestActivityDetailsError() {
  return (dispatch) => dispatch(getRequestActivityDetailsErrorRequest());
}
export function getRequestActivityDetailsErrorRequest() {
  return {
    type: GetRequestActivityDetailsActions.SET_EDIT_ERROR,
  };
}
//////////////////////////////////////////////////////////////////

export const GetActiveActivityActions = {
  FETCH_ACTIVE_ACTIVITY: "FETCH_ACTIVE_ACTIVITY",
  FETCH_ACTIVE_ACTIVITY_STATUS: "FETCH_ACTIVE_ACTIVITY_STATUS",
  FETCH_ACTIVE_ACTIVITY_FULFILLED: "FETCH_ACTIVE_ACTIVITY_FULFILLED",
  FETCH_ACTIVE_ACTIVITY_REJECTED: "FETCH_ACTIVE_ACTIVITY_REJECTED",
};

export function getActiveActivityList(params) {
  
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RequestManagement_URL}/getAllRequestActivityPagination?start=0&sortOrder=desc&booleanfield=true&status=true&sortField=id&length=0&access_token=` +
        params.access_token,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveActivityListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveActivityListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveActivityListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveActivityListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveActivityListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveActivityListError(err));
              break;
            case 500:
              dispatch(getActiveActivityListError("Server Error"));
              break;
            default:
              dispatch(getActiveActivityListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveActivityListRequest() {
  return {
    type: GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY,
    isPosting: true,
  };
}
export function getActiveActivityListError(error) {
  return {
    type: GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_REJECTED,
    payload: error,
  };
}

export function getActiveActivityListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveActivityListStatus() {
  return (dispatch) => dispatch(getActiveActivityListStatusRequest());
}
export function getActiveActivityListStatusRequest() {
  return {
    type: GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_STATUS,
  };
}

//// get subject data identifier ///

export const getSubDataIdentifierrDetailsActions = {
  FETCH_SUBDATA_IDENTIFIER_DETAILS: "FETCH_SUBDATA_IDENTIFIER_DETAILS",
  FETCH_SUBDATA_IDENTIFIER_DETAILS_STATUS: "FETCH_SUBDATA_IDENTIFIER_DETAILS_STATUS",
  FETCH_SUBDATA_IDENTIFIER_DETAILS_FULFILLED:
    "FETCH_SUBDATA_IDENTIFIER_DETAILS_FULFILLED",
  FETCH_SUBDATA_IDENTIFIER_DETAILS_REJECTED:
    "FETCH_SUBDATA_IDENTIFIER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSubDataIdentifierDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${RequestManagement_URL}/getSubjectData?identifier=` + params.email,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSubDataIdentifierDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSubDataIdentifierDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSubDataIdentifierDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSubDataIdentifierDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSubDataIdentifierDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSubDataIdentifierDetailsError(err));
              break;
            case 500:
              dispatch(getSubDataIdentifierDetailsError("Server Error"));
              break;
            default:
              dispatch(getSubDataIdentifierDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getSubDataIdentifierDetailsRequest() {
  return {
    type: getSubDataIdentifierrDetailsActions.FETCH_SUBDATA_IDENTIFIER_DETAILS,
    isPosting: true,
  };
}
export function getSubDataIdentifierDetailsError(error) {
  return {
    type: getSubDataIdentifierrDetailsActions.FETCH_SUBDATA_IDENTIFIER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSubDataIdentifierDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
      getSubDataIdentifierrDetailsActions.FETCH_SUBDATA_IDENTIFIER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

// export function getRequestMasterDetailsStatus() {
//   return (dispatch) => dispatch(getRequestMasterDetailsStatusRequest());
// }
// export function getRequestMasterDetailsStatusRequest() {
//   return {
//     type: GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS_STATUS,
//   };
// }

// export function setDetailsError() {
//   return (dispatch) => dispatch(getRequestMasterDetailsErrorRequest());
// }
// export function getRequestMasterDetailsErrorRequest() {
//   return {
//     type: GetRequestMasterDetailsActions.SET_EDIT_ERROR,
//   };
// }