import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, LocaleProvider } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import AppRouter from "./AppRouter";
import { siteConfig } from "../../settings";
import { IntlProvider } from "react-intl";

import { AppLocale } from "../../dashApp";
import CacheBuster from "../../components/CacheBuster";
import packageJson from "../../../package.json";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import AppHolder from "./commonStyle";
import "./global.css";
import "../../common/loader.css"
import config, {
  getCurrentLanguage,
} from "../../containers/LanguageSwitcher/config";

global.appVersion = packageJson.version;

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const appHeight = window.innerHeight;

    const { locale, selectedTheme } = this.props;

    const currentAppLocale =
      AppLocale[
        getCurrentLanguage(
          this.props.auth.credentials.data.data.user.language || "chinese"
        ).locale
      ];

    // if (currentAppLocale.locale == "ar") {
    //   document.getElementsByTagName("html")[0].dir = "rtl";
    // }
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={(windowSize) =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                <Topbar url={url} />

                <CacheBuster>
                  {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                      // You can decide how and when you want to force reload
                      refreshCacheAndReload();
                    }

                    return <div></div>;
                  }}
                </CacheBuster>
                <Layout style={{ flexDirection: "row", overflow: "hidden" }}>
                  <Sidebar url={url} />

                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: height,
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: "70px 0 0",
                        flexShrink: "0",
                        // background: "#ffff",
                        position: "relative",
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>
                    <Footer
                      style={{
                        background: "#ffffff",
                        textAlign: "center",
                        borderTop: "1px solid #ededed",
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.Auth,
    height: state.App.height,
    locale: state.LanguageSwitcher.language.locale,
    app: state.App,
  }),
  { logout, toggleAll }
)(App);
