import axios from "axios";
import {
  AssetManagementService_URL,
  SystemService_URL,
  RopaManagementService_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDesignationMasterActions = {
  SAVE_DESIGNATION_MASTER: "SAVE_DESIGNATION_MASTER",
  SAVE_DESIGNATION_MASTER_STATUS: "SAVE_DESIGNATION_MASTER_STATUS",
  SAVE_DESIGNATION_MASTER_SUCCESS: "SAVE_DESIGNATION_MASTER_SUCCESS",
  SAVE_DESIGNATION_MASTER_FAILURE: "SAVE_DESIGNATION_MASTER_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveDesignationMasterActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveDesignationMasterRequest() {
  return {
    type: SaveDesignationMasterActions.SAVE_DESIGNATION_MASTER,
    isPosting: true,
  };
}

export function SaveDesignationMasterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDesignationMasterActions.SAVE_DESIGNATION_MASTER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDesignationMasterFailure(error) {
  return {
    type: SaveDesignationMasterActions.SAVE_DESIGNATION_MASTER_FAILURE,
    payload: error,
  };
}

export function SaveDesignationMasterError(error) {
  return {
    type: SaveDesignationMasterActions.SAVE_DESIGNATION_MASTER_FAILURE,
    payload: error,
  };
}

export function saveDesignationMaster(props) {
  
  // var value = {
  //   id: props.designationId || 0,
  //   name: props.designationName,
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SystemService_URL}/Apc_Persist/Amc_51/saveDesignationMaster`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDesignationMasterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDesignationMasterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          return response;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDesignationMasterError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          //     map.forEach(ele => (err = ele));
          //     dispatch(SaveDesignationMasterError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          //     map.forEach(ele => (err = ele));
          //     dispatch(SaveDesignationMasterError(err));
          //     break;
          //   case 500:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          //     map.forEach(ele => (err = ele));
          //     dispatch(SaveDesignationMasterError(err));
          //     break;
          //   default:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          //     map.forEach(ele => (err = ele));
          //     dispatch(SaveDesignationMasterError(err));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDesignationMasterDetailsActions = {
  FETCH_DESIGNATION_MASTER_DETAILS: "FETCH_DESIGNATION_MASTER_DETAILS",
  FETCH_DESIGNATION_MASTER_DETAILS_STATUS:
    "FETCH_DESIGNATION_MASTER_DETAILS_STATUS",
  FETCH_DESIGNATION_MASTER_DETAILS_FULFILLED:
    "FETCH_DESIGNATION_MASTER_DETAILS_FULFILLED",
  FETCH_DESIGNATION_MASTER_DETAILS_REJECTED:
    "FETCH_DESIGNATION_MASTER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDesignationMasterDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_51/findDesignationMasterById?id=` +
        params.designationId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDesignationMasterDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDesignationMasterDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDesignationMasterDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDesignationMasterDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDesignationMasterDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDesignationMasterDetailsError(err));
              break;
            case 500:
              dispatch(getDesignationMasterDetailsError("Server Error"));
              break;
            default:
              dispatch(getDesignationMasterDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDesignationMasterDetailsRequest() {
  return {
    type: GetDesignationMasterDetailsActions.FETCH_DESIGNATION_MASTER_DETAILS,
    isPosting: true,
  };
}
export function getDesignationMasterDetailsError(error) {
  return {
    type:
      GetDesignationMasterDetailsActions.FETCH_DESIGNATION_MASTER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDesignationMasterDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDesignationMasterDetailsActions.FETCH_DESIGNATION_MASTER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDesignationMasterDetailsStatus() {
  return (dispatch) => dispatch(getDesignationMasterDetailsStatusRequest());
}
export function getDesignationMasterDetailsStatusRequest() {
  return {
    type:
      GetDesignationMasterDetailsActions.FETCH_DESIGNATION_MASTER_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDesignationMasterDetailsErrorRequest());
}
export function getDesignationMasterDetailsErrorRequest() {
  return {
    type: GetDesignationMasterDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDesignationMasterActions = {
  FETCH_ACTIVE_DESIGNATION_MASTER: "FETCH_ACTIVE_DESIGNATION_MASTER",
  FETCH_ACTIVE_DESIGNATION_MASTER_STATUS:
    "FETCH_ACTIVE_DESIGNATION_MASTER_STATUS",
  FETCH_ACTIVE_DESIGNATION_MASTER_FULFILLED:
    "FETCH_ACTIVE_DESIGNATION_MASTER_FULFILLED",
  FETCH_ACTIVE_DESIGNATION_MASTER_REJECTED:
    "FETCH_ACTIVE_DESIGNATION_MASTER_REJECTED",
};
export function getActiveDesignationMasterList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_51/getAllDesignationMasterPagination?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDesignationMasterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDesignationMasterListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDesignationMasterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDesignationMasterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDesignationMasterListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDesignationMasterListError(err));
              break;
            case 500:
              dispatch(getActiveDesignationMasterListError("Server Error"));
              break;
            default:
              dispatch(getActiveDesignationMasterListError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDesignationMasterListRequest() {
  return {
    type: GetActiveDesignationMasterActions.FETCH_ACTIVE_DESIGNATION_MASTER,
    isPosting: true,
  };
}
export function getActiveDesignationMasterListError(error) {
  return {
    type:
      GetActiveDesignationMasterActions.FETCH_ACTIVE_DESIGNATION_MASTER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getActiveDesignationMasterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetActiveDesignationMasterActions.FETCH_ACTIVE_DESIGNATION_MASTER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDesignationMasterListStatus() {
  return (dispatch) => dispatch(getActiveDesignationMasterListStatusRequest());
}
export function getActiveDesignationMasterListStatusRequest() {
  return {
    type:
      GetActiveDesignationMasterActions.FETCH_ACTIVE_DESIGNATION_MASTER_STATUS,
  };
}
