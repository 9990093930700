import axios from "axios";
import { SuperAdmin_Service_URL, AssetManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const saveAssetTypeActions = {
  SAVE_ASSET_TYPE: "SAVE_ASSET_TYPE",
  SAVE_ASSET_TYPE_STATUS: "SAVE_ASSET_TYPE_STATUS",
  SAVE_ASSET_TYPE_SUCCESS: "SAVE_ASSET_TYPE_SUCCESS",
  SAVE_ASSET_TYPE_FAILURE: "SAVE_ASSET_TYPE_FAILURE",
};

export function saveAssetTypeRequest() {
  return {
    type: saveAssetTypeActions.SAVE_ASSET_TYPE,
    isPosting: true,
  };
}

export function saveAssetTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveAssetTypeActions.SAVE_ASSET_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function saveAssetTypeFailure(error) {
  return {
    type: saveAssetTypeActions.SAVE_ASSET_TYPE_FAILURE,
    payload: error,
  };
}

export function saveAssetTypeError(error) {
  return {
    type: saveAssetTypeActions.SAVE_ASSET_TYPE_FAILURE,
    payload: error,
  };
}

export function saveAssetType(props) {
  var value = {
    assetTypeId: props.assetTypeId || 0,
    assetTypeName: props.assetTypeName,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${AssetManagementService_URL}/Apc_Persist/Amc_9/saveAssetType`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveAssetTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveAssetTypeSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveAssetTypeError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveAssetTypeError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveAssetTypeError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveAssetTypeError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveAssetTypeError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveAssetTypeError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete AssetType

export const deleteAssetTypeActions = {
  DELETE_ASSET_TYPE: "DELETE_ASSET_TYPE",
  DELETE_ASSET_TYPE_STATUS: "DELETE_ASSET_TYPE_STATUS",
  DELETE_ASSET_TYPE_SUCCESS: "DELETE_ASSET_TYPE_SUCCESS",
  DELETE_ASSET_TYPE_FAILURE: "DELETE_ASSET_TYPE_FAILURE",
};

export function deleteAssetTypeRequest() {
  return {
    type: deleteAssetTypeActions.DELETE_ASSET_TYPE,
    isPosting: true,
  };
}

export function deleteAssetTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteAssetTypeActions.DELETE_ASSET_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function deleteAssetTypeFailure(error) {
  return {
    type: deleteAssetTypeActions.DELETE_ASSET_TYPE_FAILURE,
    payload: error,
  };
}

export function deleteAssetTypeError(error) {
  return {
    type: deleteAssetTypeActions.DELETE_ASSET_TYPE_FAILURE,
    payload: error,
  };
}

export function deleteAssetType(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${AssetManagementService_URL}/Apc_State/Amc_9/deleteAssetType?assetTypeId=` +
        params.assetTypeID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteAssetTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteAssetTypeSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteAssetTypeError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteAssetTypeError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteAssetTypeError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteAssetTypeError(err));
              break;
            case 500:
              dispatch(deleteAssetTypeError("Server Error"));
              break;
            default:
              dispatch(deleteAssetTypeError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetAssetTypeDetailsActions = {
  FETCH_ASSET_TYPE_DETAILS: "FETCH_ASSET_TYPE_DETAILS",
  FETCH_ASSET_TYPE_DETAILS_STATUS: "FETCH_ASSET_TYPE_DETAILS_STATUS",
  FETCH_ASSET_TYPE_DETAILS_FULFILLED: "FETCH_ASSET_TYPE_DETAILS_FULFILLED",
  FETCH_ASSET_TYPE_DETAILS_REJECTED: "FETCH_ASSET_TYPE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getAssetTypeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${AssetManagementService_URL}/Apc_View/Amc_9/findAssetTypeById?assetTypeId=` +
        params.assetTypeID,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getAssetTypeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAssetTypeDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAssetTypeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAssetTypeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAssetTypeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAssetTypeDetailsError(err));
              break;
            case 500:
              dispatch(getAssetTypeDetailsError("Server Error"));
              break;
            default:
              dispatch(getAssetTypeDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAssetTypeDetailsRequest() {
  return {
    type: GetAssetTypeDetailsActions.FETCH_ASSET_TYPE_DETAILS,
    isPosting: true,
  };
}
export function getAssetTypeDetailsError(error) {
  return {
    type: GetAssetTypeDetailsActions.FETCH_ASSET_TYPE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getAssetTypeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAssetTypeDetailsActions.FETCH_ASSET_TYPE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getAssetTypeDetailsStatus() {
  return (dispatch) => dispatch(getAssetTypeDetailsStatusRequest());
}
export function getAssetTypeDetailsStatusRequest() {
  return {
    type: GetAssetTypeDetailsActions.FETCH_ASSET_TYPE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getAssetTypeDetailsErrorRequest());
}
export function getAssetTypeDetailsErrorRequest() {
  return {
    type: GetAssetTypeDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("assetTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveAssetTypeTypeActions = {
  FETCH_ACTIVE_ASSETS_TYPE: "FETCH_ACTIVE_ASSETS_TYPE",
  FETCH_ACTIVE_ASSETS_TYPE_STATUS: "FETCH_ACTIVE_ASSETS_TYPE_STATUS",
  FETCH_ACTIVE_ASSETS_TYPE_FULFILLED: "FETCH_ACTIVE_ASSETS_TYPE_FULFILLED",
  FETCH_ACTIVE_ASSETS_TYPE_REJECTED: "FETCH_ACTIVE_ASSETS_TYPE_REJECTED",
};

export function getActiveAssetTypeList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${AssetManagementService_URL}/Apc_View/Amc_9/getAllAssetTypePagination?start=0&sortOrder=desc&booleanfield=true&sortField=assetTypeId&length=0`,
      // url: `${AssetManagementService_URL}/Apc_View/Amc_9/getAllAssetTypePagination?start=0&sortOrder=desc&booleanfield=true&sortField=assetTypeId&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveAssetTypeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveAssetTypeListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveAssetTypeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveAssetTypeListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveAssetTypeListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveAssetTypeListError(err));
              break;
            case 500:
              dispatch(getActiveAssetTypeListError("Server Error"));
              break;
            default:
              dispatch(getActiveAssetTypeListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveAssetTypeListRequest() {
  return {
    type: GetActiveAssetTypeTypeActions.FETCH_ACTIVE_ASSETS_TYPE,
    isPosting: true,
  };
}
export function getActiveAssetTypeListError(error) {
  return {
    type: GetActiveAssetTypeTypeActions.FETCH_ACTIVE_ASSETS_TYPE_REJECTED,
    payload: error,
  };
}

export function getActiveAssetTypeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveAssetTypeTypeActions.FETCH_ACTIVE_ASSETS_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveAssetTypeListStatus() {
  return (dispatch) => dispatch(getActiveAssetTypeListStatusRequest());
}
export function getActiveAssetTypeListStatusRequest() {
  return {
    type: GetActiveAssetTypeTypeActions.FETCH_ACTIVE_ASSETS_TYPE_STATUS,
  };
}
