import {
  ListActions,
  updateActiveInactiveActions,
  clearlocationdetailsAction,
  editLocationActions,
  ClearHistoryDetailsActions,
  FetchHistoryActions,
  ProjectListActions,
  CustomerProjectListActions,
  deactivatedUsersActions,
  deactivateUsersActions,
  customerProjectsActions,
  updateActiveInactiveLocationActions,
  forDpoReviewActions,
  forDpoReviewSecondActions,
} from "./actions";
import { getCurrentPageName } from "../../containers/listing/PageModule";
import { listActions } from "./actions";

let PageNames = getCurrentPageName();

let initState = {
  // ARTISTLISTING:[],
  ItemUpdateStatus: null,
  HistoryData: null,
  TotalCount: 0,
  DeactivateUserStatus: null,
  successMessage: null,
  isLoading: false,
  tabName: null,
  tabNameSecond: null,
};
export default function CommonListing(state = initState, { type, payload }) {
  let PageName = getCurrentPageName();

  switch (type) {
    case forDpoReviewActions.GET_DPO_REVIEW:
      return {
        ...state,
        tabName: payload,
      };
    case forDpoReviewSecondActions.GET_ROPA_TEMPLATE_TAB:
      return {
        ...state,
        tabNameSecond: payload,
      };
    case ListActions.FETCH_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
      };
    case ListActions.FETCH_LIST_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        [PageName]: payload.data,
        TotalCount: payload.data.TotalCount || payload.data.totalCount,
        errorMessage: null,
      };
    case ListActions.FETCH_LIST_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        [PageName]: [],
      };

    case updateActiveInactiveActions.UPDATE_STATUS:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
      };
    case updateActiveInactiveActions.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ItemUpdateStatus: payload.status,
        errorMessage: null,
        successMessage: "Status changed successfully!",
      };

    case updateActiveInactiveActions.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    case FetchHistoryActions.FETCH_HISTORY:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
      };
    case FetchHistoryActions.FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        HistoryData: payload,
        successMessage: null,
      };

    case FetchHistoryActions.SAVE_BREACH_TYPE_FAILURE:
      return {
        ...state,
        loading: true,
        isLoading: false,
        HistoryData: null,
      };
    case FetchHistoryActions.SAVE_BREACH_TYPE_STATUS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        HistoryData: null,
      };

    case updateActiveInactiveActions.SET_UPDATE_ERROR:
      return {
        ...state,
        errorMessage: null,
        // successMessage:null
      };

    case ClearHistoryDetailsActions.CLEAR_HISTORY_DETAILS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        HistoryData: null,
      };
    default:
      return state;
  }
}
