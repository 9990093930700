import axios from "axios";
import { SuperAdmin_Service_URL, CookieService_Url } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save ScanDomain

export const saveScanDomainActions = {
  SCAN_DOMAIN: "SCAN_DOMAIN",
  SCAN_DOMAIN_STATUS: "SCAN_DOMAIN_STATUS",
  SCAN_DOMAIN_SUCCESS: "SCAN_DOMAIN_SUCCESS",
  SCAN_DOMAIN_FAILURE: "SCAN_DOMAIN_FAILURE",
};

export function saveScanDomainRequest() {
  return {
    type: saveScanDomainActions.SCAN_DOMAIN,
    isPosting: true,
  };
}

export function saveScanDomainSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveScanDomainActions.SCAN_DOMAIN_SUCCESS,
      payload: props,
    });
  };
}

export function saveScanDomainFailure(error) {
  return {
    type: saveScanDomainActions.SCAN_DOMAIN_FAILURE,
    payload: error,
  };
}

export function saveScanDomainError(error) {
  return {
    type: saveScanDomainActions.SCAN_DOMAIN_FAILURE,
    payload: error,
  };
}

export function saveScanDomain(props) {
  var data = {
    name: props.name,
    orgId: props.orgId,
    months: props.months,
    id: props.id,
  };

  return (dispatch) =>
    axios({
      method: "post",
      // data: value,
      data: data,
      url: `${CookieService_Url}/Apc_Persist/Amc_93/scanDomain`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveScanDomainSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(saveScanDomainError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveScanDomainError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveScanDomainError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveScanDomainError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveScanDomainError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveScanDomainError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete ScanDomain

export const deleteScanDomainActions = {
  DELETE_ASSET_TYPE: "DELETE_ASSET_TYPE",
  DELETE_ASSET_TYPE_STATUS: "DELETE_ASSET_TYPE_STATUS",
  DELETE_ASSET_TYPE_SUCCESS: "DELETE_ASSET_TYPE_SUCCESS",
  DELETE_ASSET_TYPE_FAILURE: "DELETE_ASSET_TYPE_FAILURE",
};

export function deleteScanDomainRequest() {
  return {
    type: deleteScanDomainActions.DELETE_ASSET_TYPE,
    isPosting: true,
  };
}

export function deleteScanDomainSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteScanDomainActions.DELETE_ASSET_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function deleteScanDomainFailure(error) {
  return {
    type: deleteScanDomainActions.DELETE_ASSET_TYPE_FAILURE,
    payload: error,
  };
}

export function deleteScanDomainError(error) {
  return {
    type: deleteScanDomainActions.DELETE_ASSET_TYPE_FAILURE,
    payload: error,
  };
}

export function deleteScanDomain(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${CookieService_Url}/Apc_State/Amc_9/deleteScanDomain?assetTypeId=` +
        params.assetTypeID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteScanDomainRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteScanDomainSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteScanDomainError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteScanDomainError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteScanDomainError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteScanDomainError(err));
              break;
            case 500:
              dispatch(deleteScanDomainError("Server Error"));
              break;
            default:
              dispatch(deleteScanDomainError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetScanDomainDetailsActions = {
  FETCH_SCAN_DOMAIN_DETAILS: "FETCH_SCAN_DOMAIN_DETAILS",
  FETCH_SCAN_DOMAIN_DETAILS_STATUS: "FETCH_SCAN_DOMAIN_DETAILS_STATUS",
  FETCH_SCAN_DOMAIN_DETAILS_FULFILLED: "FETCH_SCAN_DOMAIN_DETAILS_FULFILLED",
  FETCH_SCAN_DOMAIN_DETAILS_REJECTED: "FETCH_SCAN_DOMAIN_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getScanDomainDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${CookieService_Url}/Apc_View/Amc_93/getCookieDomainDetails?id=` +
        params.domainId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getScanDomainDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getScanDomainDetailsSuccess(response, params.domainId));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getScanDomainDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(getScanDomainDetailsError(err));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getScanDomainDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getScanDomainDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getScanDomainDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getScanDomainDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getScanDomainDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getScanDomainDetailsRequest() {
  return {
    type: GetScanDomainDetailsActions.FETCH_SCAN_DOMAIN_DETAILS,
    isPosting: true,
  };
}
export function getScanDomainDetailsError(error) {
  return {
    type: GetScanDomainDetailsActions.FETCH_SCAN_DOMAIN_DETAILS_REJECTED,
    payload: error,
  };
}

export function getScanDomainDetailsSuccess(props, domainId) {
  var data = { ...props, domainId: domainId };
  return (dispatch) => {
    dispatch({
      type: GetScanDomainDetailsActions.FETCH_SCAN_DOMAIN_DETAILS_FULFILLED,
      payload: data,
    });
  };
}

export function getScanDomainDetailsStatus() {
  return (dispatch) => dispatch(getScanDomainDetailsStatusRequest());
}
export function getScanDomainDetailsStatusRequest() {
  return {
    type: GetScanDomainDetailsActions.FETCH_SCAN_DOMAIN_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getScanDomainDetailsErrorRequest());
}
export function getScanDomainDetailsErrorRequest() {
  return {
    type: GetScanDomainDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("assetTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${CookieService_Url}/Apc_Persist/Amc_62/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveScanDomainTypeActions = {
  FETCH_ACTIVE_ASSETS_TYPE: "FETCH_ACTIVE_ASSETS_TYPE",
  FETCH_ACTIVE_ASSETS_TYPE_STATUS: "FETCH_ACTIVE_ASSETS_TYPE_STATUS",
  FETCH_ACTIVE_ASSETS_TYPE_FULFILLED: "FETCH_ACTIVE_ASSETS_TYPE_FULFILLED",
  FETCH_ACTIVE_ASSETS_TYPE_REJECTED: "FETCH_ACTIVE_ASSETS_TYPE_REJECTED",
};

export function getActiveScanDomainList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CookieService_Url}/Apc_View/Amc_9/getAllScanDomainPagination?start=0&sortOrder=desc&booleanfield=true&sortField=assetTypeId&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveScanDomainListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveScanDomainListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveScanDomainListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveScanDomainListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveScanDomainListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveScanDomainListError(err));
              break;
            case 500:
              dispatch(getActiveScanDomainListError("Server Error"));
              break;
            default:
              dispatch(getActiveScanDomainListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveScanDomainListRequest() {
  return {
    type: GetActiveScanDomainTypeActions.FETCH_ACTIVE_ASSETS_TYPE,
    isPosting: true,
  };
}
export function getActiveScanDomainListError(error) {
  return {
    type: GetActiveScanDomainTypeActions.FETCH_ACTIVE_ASSETS_TYPE_REJECTED,
    payload: error,
  };
}

export function getActiveScanDomainListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveScanDomainTypeActions.FETCH_ACTIVE_ASSETS_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveScanDomainListStatus() {
  return (dispatch) => dispatch(getActiveScanDomainListStatusRequest());
}
export function getActiveScanDomainListStatusRequest() {
  return {
    type: GetActiveScanDomainTypeActions.FETCH_ACTIVE_ASSETS_TYPE_STATUS,
  };
}

export function validateAddCookieDomain(access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${CookieService_Url}/Apc_View/Amc_93/companyScanCookieDomainLimit`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);

          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 500:
          //     err = { message: "Server Error" };
          //     break;
          //   default:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          // }
          return err.response;
        } else {
          dispatch(clearLogoutData());
        }
      });
}
