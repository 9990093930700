import {
  SaveDataSubCatActions,
  GetDataSubCatDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataSubjectCatActions
} from "./actions";

const initState = {
  dataSubCatSuccessMessage: null,
  dataSubCatErrorMessage: null,
  dataSubCatStatus: null,
  dataSubCatMaster: [],
  dataSubCatData: null,
  ActiveDataSubjectCatList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataSubCatActions.SAVE_DATA_SUB_CAT:
      return {
        ...state,
        isLoading: true,
        dataSubCatErrorMessage: null,
        modalActive: false
      };
    case SaveDataSubCatActions.SAVE_DATA_SUB_CAT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubCatStatus: payload.status,
        dataSubCatErrorMessage: null,
        dataSubCatSuccessMessage: "Data Subject Category added successfully!"
      };

    case SaveDataSubCatActions.SAVE_DATA_SUB_CAT_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataSubCatStatus: null,
        dataSubCatErrorMessage: payload
      };
    case SaveDataSubCatActions.SAVE_DATA_SUB_CAT_STATUS:
      return {
        ...state,
        dataSubCatStatus: 0,
        dataSubCatErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataSubCatDetailsActions.FETCH_DATA_SUB_CAT_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataSubCatErrorMessage: null,
        modalActive: false,
        dataSubCatData: null
      };
    case GetDataSubCatDetailsActions.FETCH_DATA_SUB_CAT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubCatData: payload.data,
        dataSubCatDataStatus: payload.status,
        dataSubCatErrorMessage: null
      };
    case GetDataSubCatDetailsActions.FETCH_DATA_SUB_CAT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubCatErrorMessage: payload,
        dataSubCatData: null
      };
    case GetDataSubCatDetailsActions.FETCH_DATA_SUB_CAT_DETAILS_STATUS:
      return {
        ...state,
        dataSubCatData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataSubjectCatActions.FETCH_ACTIVE_DATA_SUBJECT:
      return {
        ...state,
        isLoading: true,
        dataSubCatErrorMessage: null,
        modalActive: false,
        ActiveDataSubjectCatList: null
      };
    case GetActiveDataSubjectCatActions.FETCH_ACTIVE_DATA_SUBJECT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataSubjectCatList: payload.data,
        dataSubCatErrorMessage: null
      };
    case GetActiveDataSubjectCatActions.FETCH_ACTIVE_DATA_SUBJECT_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubCatErrorMessage: payload,
        ActiveDataSubjectCatList: null
      };
    case GetActiveDataSubjectCatActions.FETCH_ACTIVE_DATA_SUBJECT_STATUS:
      return {
        ...state,
        ActiveDataSubjectCatList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataSubCat: null,
        dataSubCatStatus: null,
        dataSubCatSuccessMessage: null,
        GetdataSubCatStatus: null,
        dataSubCatData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
