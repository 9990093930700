import axios from "axios";
import {
  IncidentManagementService_URL,
  RopaManagementService_URL,
  AssetManagementService_URL,
  RiskManagementService_URL,
} from "../Url";
import { clearLogoutData } from "../Logout/actions";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Asset

export const saveAssetActions = {
  SAVE_ASSET: "SAVE_ASSET",
  SAVE_ASSET_STATUS: "SAVE_ASSET_STATUS",
  SAVE_ASSET_SUCCESS: "SAVE_ASSET_SUCCESS",
  SAVE_ASSET_FAILURE: "SAVE_ASSET_FAILURE",
};

export function saveAssetRequest() {
  return {
    type: saveAssetActions.SAVE_ASSET,
    isPosting: true,
  };
}

export function saveAssetSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveAssetActions.SAVE_ASSET_SUCCESS,
      payload: props,
    });
  };
}

export function saveAssetFailure(error) {
  return {
    type: saveAssetActions.SAVE_ASSET_FAILURE,
    payload: error,
  };
}

export function saveAssetError(error) {
  return {
    type: saveAssetActions.SAVE_ASSET_FAILURE,
    payload: error,
  };
}

export function saveAsset(props, access_token) {
  // var value = {
  //   assetTypeId: props.assetTypeId || null,
  //   assetTypeName: props.assetTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${AssetManagementService_URL}/Apc_Persist/Amc_8/saveAssetMaster`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveAssetRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveAssetSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveAssetError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          dispatch(saveAssetError(err));
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(saveAssetError(err));
          //     break;

          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(saveAssetError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(saveAssetError(err));
          //     break;
          //   case 500:
          //     dispatch(saveAssetError("Server Error"));
          //     break;
          //   default:
          //     dispatch(saveAssetError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete Asset

export const deleteAssetActions = {
  DELETE_ASSET: "DELETE_ASSET",
  DELETE_ASSET_STATUS: "DELETE_ASSET_STATUS",
  DELETE_ASSET_SUCCESS: "DELETE_ASSET_SUCCESS",
  DELETE_ASSET_FAILURE: "DELETE_ASSET_FAILURE",
};

export function deleteAssetRequest() {
  return {
    type: deleteAssetActions.DELETE_ASSET,
    isPosting: true,
  };
}

export function deleteAssetSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteAssetActions.DELETE_ASSET_SUCCESS,
      payload: props,
    });
  };
}

export function deleteAssetFailure(error) {
  return {
    type: deleteAssetActions.DELETE_ASSET_FAILURE,
    payload: error,
  };
}

export function deleteAssetError(error) {
  return {
    type: deleteAssetActions.DELETE_ASSET_FAILURE,
    payload: error,
  };
}

export function deleteAsset(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${AssetManagementService_URL}/deleteAsset?assetTypeId=` +
        params.assetTypeID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteAssetRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteAssetSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteAssetError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteAssetError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteAssetError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteAssetError(err));
              break;
            case 500:
              dispatch(deleteAssetError("Server Error"));
              break;
            default:
              dispatch(deleteAssetError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetAssetDetailsActions = {
  FETCH_ASSET_DETAILS: "FETCH_ASSET_DETAILS",
  FETCH_ASSET_DETAILS_STATUS: "FETCH_ASSET_DETAILS_STATUS",
  FETCH_ASSET_DETAILS_FULFILLED: "FETCH_ASSET_DETAILS_FULFILLED",
  FETCH_ASSET_DETAILS_REJECTED: "FETCH_ASSET_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getAssetDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${AssetManagementService_URL}/Apc_View/Amc_8/getByIdAssetMaster
?assetId=` + params.assetTypeID,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAssetDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAssetDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAssetDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAssetDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAssetDetailsError(err));
              break;
            case 500:
              dispatch(getAssetDetailsError("Server Error"));
              break;
            default:
              dispatch(getAssetDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAssetDetailsRequest() {
  return {
    type: GetAssetDetailsActions.FETCH_ASSET_DETAILS,
    isPosting: true,
  };
}
export function getAssetDetailsError(error) {
  return {
    type: GetAssetDetailsActions.FETCH_ASSET_DETAILS_REJECTED,
    payload: error,
  };
}

export function getAssetDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAssetDetailsActions.FETCH_ASSET_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getAssetDetailsStatus() {
  return (dispatch) => dispatch(getAssetDetailsStatusRequest());
}
export function getAssetDetailsStatusRequest() {
  return {
    type: GetAssetDetailsActions.FETCH_ASSET_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getAssetDetailsErrorRequest());
}
export function getAssetDetailsErrorRequest() {
  return {
    type: GetAssetDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("assetTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveAssetActions = {
  FETCH_ACTIVE_ASSETS: "FETCH_ACTIVE_ASSETS",
  FETCH_ACTIVE_ASSETS_STATUS: "FETCH_ACTIVE_ASSETS_STATUS",
  FETCH_ACTIVE_ASSETS_FULFILLED: "FETCH_ACTIVE_ASSETS_FULFILLED",
  FETCH_ACTIVE_ASSETS_REJECTED: "FETCH_ACTIVE_ASSETS_REJECTED",
};

export function getActiveAssetList(params) {
  var dataToPost = {
    defaultSort: {
      sortField: "assetName",
      sortOrder: "desc",
    },
    sort: [],
    search: [],
    length: 1000,
    start: 0,
    status: true,
    type: 2,
  };
  return (dispatch) =>
    axios({
      method: "POST",
      data: dataToPost,
      url: `${AssetManagementService_URL}/Apc_View/Amc_8/getAllAssetMasterPagination`,
      // url: `${AssetManagementService_URL}/Apc_View/Amc_8/getAllAssetNamesByCompanyId`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveAssetListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveAssetListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveAssetListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveAssetListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveAssetListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveAssetListError(err));
              break;
            case 500:
              dispatch(getActiveAssetListError("Server Error"));
              break;
            default:
              dispatch(getActiveAssetListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveAssetListRequest() {
  return {
    type: GetActiveAssetActions.FETCH_ACTIVE_ASSETS,
    isPosting: true,
  };
}
export function getActiveAssetListError(error) {
  return {
    type: GetActiveAssetActions.FETCH_ACTIVE_ASSETS_REJECTED,
    payload: error,
  };
}

export function getActiveAssetListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveAssetActions.FETCH_ACTIVE_ASSETS_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveAssetListStatus() {
  return (dispatch) => dispatch(getActiveAssetListStatusRequest());
}
export function getActiveAssetListStatusRequest() {
  return {
    type: GetActiveAssetActions.FETCH_ACTIVE_ASSETS_STATUS,
  };
}

export const GetAssetRelatedMastersActions = {
  FETCH_ASSET_REL_MASTERS: "FETCH_ASSET_REL_MASTERS",
  FETCH_ASSET_REL_MASTERS_STATUS: "FETCH_ASSET_REL_MASTERS_STATUS",
  FETCH_ASSET_REL_MASTERS_FULFILLED: "FETCH_ASSET_REL_MASTERS_FULFILLED",
  FETCH_ASSET_REL_MASTERS_REJECTED: "FETCH_ASSET_REL_MASTERS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getAssetRelatedMasters(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_36/getRelatedMastersByAssetId/` +
        params.assetId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getAssetRelatedMastersRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAssetRelatedMastersSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAssetRelatedMastersError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getAssetRelatedMastersRequest() {
  return {
    type: GetAssetRelatedMastersActions.FETCH_ASSET_REL_MASTERS,
    isPosting: true,
  };
}
export function getAssetRelatedMastersError(error) {
  return {
    type: GetAssetRelatedMastersActions.FETCH_ASSET_REL_MASTERS_REJECTED,
    payload: error,
  };
}

export function getAssetRelatedMastersSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAssetRelatedMastersActions.FETCH_ASSET_REL_MASTERS_FULFILLED,
      payload: props,
    });
  };
}

export function getAssetRelatedMastersStatus() {
  return (dispatch) => dispatch(getAssetRelatedMastersStatusRequest());
}
export function getAssetRelatedMastersStatusRequest() {
  return {
    type: GetAssetRelatedMastersActions.FETCH_ASSET_REL_MASTERS_STATUS,
  };
}

///ACTIVE ROPAS :LIST

export const GetRopaListActions = {
  FETCH_ACTIVE_ROPAS: "FETCH_ACTIVE_ROPAS",
  FETCH_ACTIVE_ROPAS_STATUS: "FETCH_ACTIVE_ROPAS_STATUS",
  FETCH_ACTIVE_ROPAS_FULFILLED: "FETCH_ACTIVE_ROPAS_FULFILLED",
  FETCH_ACTIVE_ROPAS_REJECTED: "FETCH_ACTIVE_ROPAS_REJECTED",
};

export function getRopaList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      data: JSON.stringify({
        defaultSort: { sortField: "name", sortOrder: "desc" },
        length: 10,
        search: [{ search: params.searchVal, searchCol: "assetsAffected" }],
        sort: [],
        start: "0",
        status: true,
      }),
      url: `${RopaManagementService_URL}/getAllRopaWorkflowPagination`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getActiveRopaListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRopaListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveRopaListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getActiveRopaListError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getActiveRopaListError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getActiveRopaListError(err));
          //     break;
          //   case 500:
          //     dispatch(getActiveRopaListError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getActiveRopaListError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRopaListRequest() {
  return {
    type: GetRopaListActions.FETCH_ACTIVE_ROPAS,
    isPosting: true,
  };
}
export function getActiveRopaListError(error) {
  return {
    type: GetRopaListActions.FETCH_ACTIVE_ROPAS_REJECTED,
    payload: error,
  };
}

export function getActiveRopaListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRopaListActions.FETCH_ACTIVE_ROPAS_FULFILLED,
      payload: props,
    });
  };
}

export const GetAssetRiskAndControlActions = {
  FETCH_ASSET_RISK_CONTROL: "FETCH_ASSET_RISK_CONTROL",
  FETCH_ASSET_RISK_CONTROL_STATUS: "FETCH_ASSET_RISK_CONTROL_STATUS",
  FETCH_ASSET_RISK_CONTROL_FULFILLED: "FETCH_ASSET_RISK_CONTROL_FULFILLED",
  FETCH_ASSET_RISK_CONTROL_REJECTED: "FETCH_ASSET_RISK_CONTROL_REJECTED",
};

export function getAssetRiskAndControl(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${RiskManagementService_URL}/getByAssetIdRiskMaster?assetId=` +
        params.assetId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getAssetRiskAndControlRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAssetRiskAndControlSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAssetRiskAndControlError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getAssetRiskAndControlRequest() {
  return {
    type: GetAssetRiskAndControlActions.FETCH_ASSET_RISK_CONTROL,
    isPosting: true,
  };
}
export function getAssetRiskAndControlError(error) {
  return {
    type: GetAssetRiskAndControlActions.FETCH_ASSET_RISK_CONTROL_REJECTED,
    payload: error,
  };
}

export function getAssetRiskAndControlSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAssetRiskAndControlActions.FETCH_ASSET_RISK_CONTROL_FULFILLED,
      payload: props,
    });
  };
}

export function getAssetRiskAndControlStatus() {
  return (dispatch) => dispatch(getAssetRiskAndControlStatusRequest());
}
export function getAssetRiskAndControlStatusRequest() {
  return {
    type: GetAssetRiskAndControlActions.FETCH_ASSET_RISK_CONTROL_STATUS,
  };
}

// Control

export const GetControlActions = {
  FETCH_CONTROL: "FETCH_CONTROL",
  FETCH_CONTROL_STATUS: "FETCH_CONTROL_STATUS",
  FETCH_CONTROL_FULFILLED: "FETCH_CONTROL_FULFILLED",
  FETCH_CONTROL_REJECTED: "FETCH_CONTROL_REJECTED",
};

export function getControl(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${RiskManagementService_URL}/getByRiskIdControlRegister?riskId=` +
        params.riskId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getControlRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getControlSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getControlError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getControlRequest() {
  return {
    type: GetControlActions.FETCH_CONTROL,
    isPosting: true,
  };
}
export function getControlError(error) {
  return {
    type: GetControlActions.FETCH_CONTROL_REJECTED,
    payload: error,
  };
}

export function getControlSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetControlActions.FETCH_CONTROL_FULFILLED,
      payload: props,
    });
  };
}

export function getControlStatus() {
  return (dispatch) => dispatch(getControlStatusRequest());
}
export function getControlStatusRequest() {
  return {
    type: GetControlActions.FETCH_CONTROL_STATUS,
  };
}

export function validateAddAsset(access_token) {
  // var value = {
  //   assetTypeId: props.assetTypeId || null,
  //   assetTypeName: props.assetTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url: `${AssetManagementService_URL}/Apc_View/Amc_8/companyAssetLimit`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);

          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 500:
          //     err = { message: "Server Error" };
          //     break;
          //   default:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          // }
          return err.response;
        } else {
          dispatch(clearLogoutData());
        }
      });
}
