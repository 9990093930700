import {
  saveActivityActions,
  deleteActivityActions,
  GetActivityActions,
  GetAllActivityActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveActivityActions
} from "./actions";

const initState = {
  activitySuccessMessage: null,
  activityErrorMessage: null,
  ActivityStatus: null,
  ActivityData: null,
  ActiveActivityList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveActivityActions.SAVE_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        activityErrorMessage: null,
        modalActive: false
      };
    case saveActivityActions.SAVE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActivityStatus: payload.status,
        activityErrorMessage: null,
        activitySuccessMessage: "Activity added successfully!"
      };

    case saveActivityActions.SAVE_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        ActivityStatus: null,
        activityErrorMessage: payload
      };
    case saveActivityActions.SAVE_ACTIVITY_STATUS:
      return {
        ...state,
        ActivityStatus: 0,
        activityErrorMessage: null
      };

    // Delete Asset Type

    case deleteActivityActions.DELETE_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        activityErrorMessage: null,
        modalActive: false
      };
    case deleteActivityActions.DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActivityStatus: payload.status,
        activityErrorMessage: null,
        activitySuccessMessage: "Activity Deleted successfully!"
      };

    case deleteActivityActions.DELETE_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        ActivityStatus: null,
        activityErrorMessage: payload
      };
    case deleteActivityActions.DELETE_ACTIVITY_STATUS:
      return {
        ...state,
        ActivityStatus: 0,
        activityErrorMessage: null
      };

        ///////////////////////////////////////////////////////
    // GET ALL BY PAGINATION
    case GetAllActivityActions.FETCH_ALL_ACTIVITY_DETAILS:
    return {
      ...state,
      isLoading: true,
      assetErrorMessage: null,
      modalActive: false,
      ActiveActivityList: null
    };
  case GetAllActivityActions.FETCH_ALL_ACTIVITY_DETAILS_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      ActiveActivityList : payload.data,
      ActivityDataStatus: payload.status,
      activityErrorMessage: null
    };
  case GetAllActivityActions.FETCH_ALL_ACTIVITY_DETAILS_REJECTED:
    return {
      ...state,
      isLoading: false,
      activityErrorMessage: payload,
      ActiveActivityList: null
    };
  case GetAllActivityActions.FETCH_ALL_ACTIVITY_DETAILS_STATUS:
    return {
      ...state,
      ActiveActivityList: null
    };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetActivityActions.FETCH_ACTIVITY_DETAILS:
      return {
        ...state,
        isLoading: true,
        activityErrorMessage: null,
        modalActive: false,
        ActivityData: null
      };
    case GetActivityActions.FETCH_ACTIVITY_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActivityData: payload.data,
        ActivityDataStatus: payload.status,
        activityErrorMessage: null
      };
    case GetActivityActions.FETCH_ACTIVITY_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        activityErrorMessage: payload,
        ActivityData: null
      };
    case GetActivityActions.FETCH_ACTIVITY_DETAILS_STATUS:
      return {
        ...state,
        ActivityData: null
      };
    ///Active assets
    case GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        activityErrorMessage: null,
        modalActive: false,
        ActiveActivityList: null
      };
    case GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveActivityList: payload.data,
        activityErrorMessage: null
      };
    case GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_REJECTED:
      return {
        ...state,
        isLoading: false,
        activityErrorMessage: payload,
        ActiveActivityList: null
      };
    case GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_STATUS:
      return {
        ...state,
        ActiveActivityList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetActivity: null,
        ActivityStatus: null,
        activitySuccessMessage: null,
        GetActivityStatus: null,
        ActiveActivityList: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
