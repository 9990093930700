import {
  SaveControlRegisterActions,
  GetControlRegisterDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveControlRegisterActions,
  GetControlRegisterActions
} from "./actions";

const initState = {
  controlRegisterSuccessMessage: null,
  controlRegisterErrorMessage: null,
  ControlRegisterStatus: null,
  ControlRegisterMaster: [],
  ControlRegisterData: null,
  ActiveControlRegisterList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveControlRegisterActions.SAVE_CONTROLREGISTER:
      return {
        ...state,
        isLoading: true,
        controlRegisterErrorMessage: null,
        modalActive: false,
      };
    case SaveControlRegisterActions.SAVE_CONTROLREGISTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ControlRegisterStatus: payload.status,
        controlRegisterErrorMessage: null,
        controlRegisterSuccessMessage: "Control Register added successfully!",
      };

    case SaveControlRegisterActions.SAVE_CONTROLREGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        ControlRegisterStatus: null,
        controlRegisterErrorMessage: payload,
      };
    case SaveControlRegisterActions.SAVE_CONTROLREGISTER_STATUS:
      return {
        ...state,
        ControlRegisterStatus: 0,
        controlRegisterErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER:
      return {
        ...state,
        isLoading: true,
        controlRegisterErrorMessage: null,
        modalActive: false,
        ControlRegisterData: null,
      };
    case GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ControlRegisterData: payload.data,
        ControlRegisterDataStatus: payload.status,
        controlRegisterErrorMessage: null,
      };
    case GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER_REJECTED:
      return {
        ...state,
        isLoading: false,
        controlRegisterErrorMessage: payload,
        ControlRegisterData: null,
      };
    case GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER_STATUS:
      return {
        ...state,
        ControlRegisterData: payload.data,
        ControlRegisterDataStatus: payload.status,
      };
    ///Active DATA DISPOSAL
    case GetActiveControlRegisterActions.FETCH_ACTIVE_CONTROLREGISTER:
      return {
        ...state,
        isLoading: true,
        controlRegisterErrorMessage: null,
        modalActive: false,
        ActiveControlRegisterList: null,
      };
    case GetActiveControlRegisterActions.FETCH_ACTIVE_CONTROLREGISTER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveControlRegisterList: payload.data,
        controlRegisterErrorMessage: null,
      };
    case GetActiveControlRegisterActions.FETCH_CONTROLREGISTER_LIST_BY_RISKID_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveControlRegisterListByRiskId: payload.data,
        controlRegisterErrorMessage: null,
      };
    case GetActiveControlRegisterActions.FETCH_ACTIVE_CONTROLREGISTER_REJECTED:
      return {
        ...state,
        isLoading: false,
        controlRegisterErrorMessage: payload,
        ActiveControlRegisterList: null,
      };
    case GetActiveControlRegisterActions.FETCH_ACTIVE_CONTROLREGISTER_STATUS:
      return {
        ...state,
        ActiveControlRegisterList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetControlRegister: null,
        ControlRegisterStatus: null,
        controlRegisterSuccessMessage: null,
        GetControlRegisterStatus: null,
        ControlRegisterData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

//Get Pagination
case GetControlRegisterActions.FETCH_CONTROLREGISTER:
  return {
    ...state,
    isLoading: true,
    controlRegisterErrorMessage: null,
    modalActive: false,
    ControlRegisterData: null
  };
case GetControlRegisterActions.FETCH_CONTROLREGISTER_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveControlRegisterList: payload.data,
    ControlRegisterStatus: payload.status,  
    controlRegisterErrorMessage: null
  };
case GetControlRegisterActions.FETCH_CONTROLREGISTER_REJECTED:
  return {
    ...state,
    isLoading: false,
    controlRegisterErrorMessage: payload,
    ControlRegisterData: null
  };
case GetControlRegisterActions.FETCH_CONTROLREGISTER_STATUS:
  return {
    ...state,
    ControlRegisterData: null
  };
    
case GetControlRegisterActions.FETCH_CONTROLREGISTER:
  return {
    ...state,
    isLoading: true,
    controlRegisterErrorMessage: null,
    modalActive: false,
    ControlRegisterData: null
  };
case GetControlRegisterActions.FETCH_CONTROLREGISTER_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveControlRegisterList: payload.data,
    ControlRegisterStatus: payload.status,  
    controlRegisterErrorMessage: null
  };
case GetControlRegisterActions.FETCH_CONTROLREGISTER_REJECTED:
  return {
    ...state,
    isLoading: false,
    controlRegisterErrorMessage: payload,
    ControlRegisterData: null
  };
case GetControlRegisterActions.FETCH_CONTROLREGISTER_STATUS:
  return {
    ...state,
    ControlRegisterData: null
  };
  /* */

  default:
    return state;

}
}
