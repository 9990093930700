import axios from "axios";
import { CompanyService_URL, UserManagementService_URL } from "../Url";
import { clearLogoutData } from "../Logout/actions";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveCompanyActions = {
  SAVE_COMPANY: "SAVE_COMPANY",
  SAVE_COMPANY_STATUS: "SAVE_COMPANY_STATUS",
  SAVE_COMPANY_SUCCESS: "SAVE_COMPANY_SUCCESS",
  SAVE_COMPANY_FAILURE: "SAVE_COMPANY_FAILURE",
  LOGOUT: "LOGOUT",
};

export function logout() {
  return {
    type: SaveCompanyActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}
export function SaveCompanyRequest() {
  return {
    type: SaveCompanyActions.SAVE_COMPANY,
    isPosting: true,
  };
}

export function SaveCompanySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveCompanyActions.SAVE_COMPANY_SUCCESS,
      payload: props,
    });
  };
}

export function SaveCompanyFailure(error) {
  return {
    type: SaveCompanyActions.SAVE_COMPANY_FAILURE,
    payload: error,
  };
}

export function SaveCompanyError(error) {
  return {
    type: SaveCompanyActions.SAVE_COMPANY_FAILURE,
    payload: error,
  };
}

export function saveCompany(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CompanyService_URL}/Apc_Persist/Amc_43/saveCompanyDetails`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveCompanyRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveCompanySuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveCompanyError(error));
          throw error;
          // return response;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveCompanyError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveCompanyError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveCompanyError(err));
              break;
            case 500:
              dispatch(SaveCompanyError("Server Error"));
              break;
            default:
              dispatch(SaveCompanyError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetCompanyDetailsActions = {
  FETCH_COMPANY_DETAILS: "FETCH_COMPANY_DETAILS",
  FETCH_COMPANY_DETAILS_STATUS: "FETCH_COMPANY_DETAILS_STATUS",
  FETCH_COMPANY_DETAILS_FULFILLED: "FETCH_COMPANY_DETAILS_FULFILLED",
  FETCH_COMPANY_DETAILS_REJECTED: "FETCH_COMPANY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getCompanyDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${CompanyService_URL}/Apc_View/Amc_43/findCompanyDetailsById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getCompanyDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getCompanyDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getCompanyDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCompanyDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCompanyDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCompanyDetailsError(err));
              break;
            case 500:
              dispatch(getCompanyDetailsError("Server Error"));
              break;
            default:
              dispatch(getCompanyDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getCompanyDetailsRequest() {
  return {
    type: GetCompanyDetailsActions.FETCH_COMPANY_DETAILS,
    isPosting: true,
  };
}
export function getCompanyDetailsError(error) {
  return {
    type: GetCompanyDetailsActions.FETCH_COMPANY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getCompanyDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetCompanyDetailsActions.FETCH_COMPANY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getCompanyDetailsStatus() {
  return (dispatch) => dispatch(getCompanyDetailsStatusRequest());
}
export function getCompanyDetailsStatusRequest() {
  return {
    type: GetCompanyDetailsActions.FETCH_COMPANY_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getCompanyDetailsErrorRequest());
}
export function getCompanyDetailsErrorRequest() {
  return {
    type: GetCompanyDetailsActions.SET_EDIT_ERROR,
  };
}

export const GetActiveCompanyActions = {
  FETCH_ACTIVE_COMPANY: "FETCH_ACTIVE_COMPANY",
  FETCH_ACTIVE_COMPANY_STATUS: "FETCH_ACTIVE_COMPANY_STATUS",
  FETCH_ACTIVE_COMPANY_FULFILLED: "FETCH_ACTIVE_COMPANY_FULFILLED",
  FETCH_ACTIVE_COMPANY_REJECTED: "FETCH_ACTIVE_COMPANY_REJECTED",
};
export function getActiveCompanyList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CompanyService_URL}/getAllCompanyMasterPagination?start=0&length=10&sortOrder=desc&sortField=subscriptionPlanId&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveCompanyListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveCompanyListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveCompanyListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveCompanyListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveCompanyListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveCompanyListError(err));
              break;
            case 500:
              dispatch(getActiveCompanyListError("Server Error"));
              break;
            default:
              dispatch(getActiveCompanyListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveCompanyListRequest() {
  return {
    type: GetActiveCompanyActions.FETCH_ACTIVE_COMPANY,
    isPosting: true,
  };
}
export function getActiveCompanyListError(error) {
  return {
    type: GetActiveCompanyActions.FETCH_ACTIVE_COMPANY_REJECTED,
    payload: error,
  };
}

export function getActiveCompanyListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveCompanyActions.FETCH_ACTIVE_COMPANY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveCompanyListStatus() {
  return (dispatch) => dispatch(getActiveCompanyListStatusRequest());
}
export function getActiveCompanyListStatusRequest() {
  return {
    type: GetActiveCompanyActions.FETCH_ACTIVE_COMPANY_STATUS,
  };
}

export const GetCompanyUsersDetailsActions = {
  FETCH_COMPANY_USER_DETAILS: "FETCH_COMPANY_USER_DETAILS",
  FETCH_COMPANY_USER_DETAILS_STATUS: "FETCH_COMPANY_DETAILS_STATUS",
  FETCH_COMPANY_USER_DETAILS_FULFILLED: "FETCH_COMPANY_USER_DETAILS_FULFILLED",
  FETCH_COMPANY_USER_DETAILS_REJECTED: "FETCH_COMPANY_USER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getCompanyUsersDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${UserManagementService_URL}/Apc_View/Amc_31/getAllCompanyUserByPagination?start=0&sortOrder=desc&booleanfield=true&sortField=userId&length=0&companyId=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getCompanyUsersDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getCompanyUsersDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getCompanyUsersDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCompanyUsersDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCompanyUsersDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCompanyUsersDetailsError(err));
              break;
            case 500:
              dispatch(getCompanyUsersDetailsError("Server Error"));
              break;
            default:
              dispatch(getCompanyUsersDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getCompanyUsersDetailsRequest() {
  return {
    type: GetCompanyUsersDetailsActions.FETCH_COMPANY_USER_DETAILS,
    isPosting: true,
  };
}
export function getCompanyUsersDetailsError(error) {
  return {
    type: GetCompanyUsersDetailsActions.FETCH_COMPANY_USER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getCompanyUsersDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetCompanyUsersDetailsActions.FETCH_COMPANY_USER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getCompanyUsersDetailsStatus() {
  return (dispatch) => dispatch(getCompanyUsersDetailsStatusRequest());
}
export function getCompanyUsersDetailsStatusRequest() {
  return {
    type: GetCompanyUsersDetailsActions.FETCH_COMPANY_USER_DETAILS_STATUS,
  };
}
