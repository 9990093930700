import axios from "axios";
import { EdgeService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();
var err;
//////////////////////////////////////////////////////////////////////////////////////////////
function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

export const getModulesAction = {
  FETCH_MODULES: "FETCH_MODULES",
  FETCH_MODULES_FULFILLED: "FETCH_MODULES_FULFILLED",
  FETCH_MODULES_REJECTED: "FETCH_MODULES_REJECTED",
  SET_MODULES_ERROR: "SET_MODULES_ERROR",
  // LOGOUT: "LOGOUT"
};

export function getAllModules(props) {
  //

  return (dispatch) =>
    axios({
      method: "post",
      url: `${EdgeService_URL}/findAllModules`,
      headers: {
        //  transactionId: "1",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(getModulesRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getModulesSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(getModulesError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getModulesError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getModulesError(err));
              break;
            case 500:
              dispatch(getModulesError("Server Error"));
              break;
            default:
              dispatch(getModulesError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(clearLogoutData());
        }
      });
}

export function getModulesRequest() {
  return {
    type: getModulesAction.FETCH_MODULES,
    isPosting: true,
  };
}
export function getModulesError(error) {
  return {
    type: getModulesAction.FETCH_MODULES_REJECTED,
    payload: error,
  };
}

// export function logout() {
//   return {
//     type: getAllModulesAction.LOGOUT,
//     payload: "Session Expired. Please Login Again"
//   };
// }

export function getModulesSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getModulesAction.FETCH_MODULES_FULFILLED,
      payload: props,
    });
  };
}

export function setModulesError() {
  return (dispatch) => dispatch(setModulesRequest());
}
export function setModulesRequest() {
  return {
    type: getModulesAction.SET_MODULES_ERROR,
  };
}

//////////////////////////////////Save Role/////////////////////////////////////////

export const saveRoleAction = {
  SAVE_ROLE: "SAVE_ROLE",
  SAVE_ROLE_FULFILLED: "SAVE_ROLE_FULFILLED",
  SAVE_ROLE_REJECTED: "SAVE_ROLE_REJECTED",
  SAVE_ROLE_ERROR: "SAVE_ROLE_ERROR",
  // LOGOUT: "LOGOUT"
};

export function saveRoleDetails(props, access_token) {
  //

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${EdgeService_URL}/saveCompanyRole`,
      headers: {
        //  transactionId: "1",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveRoleRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveRoleSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveRoleError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveRoleError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveRoleError(err));
              break;
            case 500:
              dispatch(saveRoleError("Server Error"));
              break;
            default:
              dispatch(saveRoleError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(clearLogoutData());
        }
      });
}

export function saveRoleRequest() {
  return {
    type: saveRoleAction.SAVE_ROLE,
    isPosting: true,
  };
}
export function saveRoleError(error) {
  return {
    type: saveRoleAction.SAVE_ROLE_REJECTED,
    payload: error,
  };
}

export function saveRoleSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveRoleAction.SAVE_ROLE_FULFILLED,
      payload: props,
    });
  };
}

// export function setModulesError() {
//   return (dispatch) => dispatch(saveRoleRequest());
// }
// export function saveRoleRequest() {
//   return {
//     type: saveModulesAction.SAVE_ROLE_ERROR,
//   };
// }

/////////////////////////////Find role by Id///////////////////////////////////////

export const getRoleAction = {
  FETCH_ROLE: "FETCH_ROLE",
  FETCH_ROLE_FULFILLED: "FETCH_ROLE_FULFILLED",
  FETCH_ROLE_REJECTED: "FETCH_ROLE_REJECTED",
  FETCH_ROLE_ERROR: "FETCH_ROLE_ERROR",
  // LOGOUT: "LOGOUT"
};

export function findRoleDetails(props) {
  //

  return (dispatch) =>
    axios({
      method: "post",
      url: `${EdgeService_URL}/findRoleById?id=` + props.roleId,
      headers: {
        //  transactionId: "1",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(findRoleRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(findRoleSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(findRoleError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(findRoleError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(findRoleError(err));
              break;
            case 500:
              dispatch(findRoleError("Server Error"));
              break;
            default:
              dispatch(findRoleError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(clearLogoutData());
        }
      });
}

export function findRoleRequest() {
  return {
    type: getRoleAction.FETCH_ROLE,
    isPosting: true,
  };
}
export function findRoleError(error) {
  return {
    type: getRoleAction.FETCH_ROLE_ERROR,
    payload: error,
  };
}

export function findRoleSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getRoleAction.FETCH_ROLE_FULFILLED,
      payload: props,
    });
  };
}

/////////////////set Null data/////////////////

export const clearDataActions = {
  CLEAR_ROLE_DETAILS: "CLEAR_ROLE_DETAILS",
};

export function clearData() {
  return (dispatch) => dispatch(clearRoleData());
}

export function clearRoleData() {
  return {
    type: clearDataActions.CLEAR_ROLE_DETAILS,
  };
}
