import axios from "axios";
import {
  AssetManagementService_URL,
  RopaManagementService_URL,
  SystemService_URL,
} from "../Url";
import { clearLogoutData } from "../Logout/actions";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save DataRecipient Type

export const SaveDataRecipientActions = {
  SAVE_DATA_RECIPIENT: "SAVE_DATA_RECIPIENT",
  SAVE_DATA_RECIPIENT_STATUS: "SAVE_DATA_RECIPIENT_STATUS",
  SAVE_DATA_RECIPIENT_SUCCESS: "SAVE_DATA_RECIPIENT_SUCCESS",
  SAVE_DATA_RECIPIENT_FAILURE: "SAVE_DATA_RECIPIENT_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveDataRecipientActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveDataRecipientRequest() {
  return {
    type: SaveDataRecipientActions.SAVE_DATA_RECIPIENT,
    isPosting: true,
  };
}

export function SaveDataRecipientSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataRecipientActions.SAVE_DATA_RECIPIENT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataRecipientFailure(error) {
  return {
    type: SaveDataRecipientActions.SAVE_DATA_RECIPIENT_FAILURE,
    payload: error,
  };
}

export function SaveDataRecipientError(error) {
  return {
    type: SaveDataRecipientActions.SAVE_DATA_RECIPIENT_FAILURE,
    payload: error,
  };
}

export function saveDataRecipient(props) {
  
  var value = {
    id: props.dataRecipientId || 0,
    name: props.dataRecipientName,
    address: props.address,
    email: props.emailAddress,
    ropaContactIds: props.dataContactId,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_27/saveDataRecipient`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDataRecipientRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataRecipientSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          // dispatch(SaveDataRecipientError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          // case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          //     dispatch(SaveDataRecipientError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataRecipientError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataRecipientError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveDataRecipientError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveDataRecipientError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get Data Recipient details by id

///////GET Data Recipient BY ID

export const GetDataRecipientDetailsActions = {
  FETCH_DATA_RECIPIENT_DETAILS: "FETCH_DATA_RECIPIENT_DETAILS",
  FETCH_DATA_RECIPIENT_DETAILS_STATUS: "FETCH_DATA_RECIPIENT_DETAILS_STATUS",
  FETCH_DATA_RECIPIENT_DETAILS_FULFILLED:
    "FETCH_DATA_RECIPIENT_DETAILS_FULFILLED",
  FETCH_DATA_RECIPIENT_DETAILS_REJECTED:
    "FETCH_DATA_RECIPIENT_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataRecipientDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",

      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_27/getDataRecipientById?id=` +
        params.dataRecipientId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataRecipientDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataRecipientDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          // dispatch(getLegalBasisDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getLegalBasisDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getLegalBasisDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataRecipientDetailsRequest() {
  return {
    type: GetDataRecipientDetailsActions.FETCH_DATA_RECIPIENT_DETAILS,
    isPosting: true,
  };
}
export function getDataRecipientDetailsError(error) {
  return {
    type: GetDataRecipientDetailsActions.FETCH_DATA_RECIPIENT_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataRecipientDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDataRecipientDetailsActions.FETCH_DATA_RECIPIENT_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataRecipientDetailsStatus() {
  return (dispatch) => dispatch(getDataRecipientDetailsStatusRequest());
}
export function getDataRecipientDetailsStatusRequest() {
  return {
    type: GetDataRecipientDetailsActions.FETCH_DATA_RECIPIENT_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataRecipientDetailsErrorRequest());
}
export function getDataRecipientDetailsErrorRequest() {
  return {
    type: GetDataRecipientDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RopaManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDataRecipientActions = {
  FETCH_ACTIVE_DATA_RECIPIENT: "FETCH_ACTIVE_DATA_RECIPIENT",
  FETCH_ACTIVE_DATA_RECIPIENT_STATUS: "FETCH_ACTIVE_DATA_RECIPIENT_STATUS",
  FETCH_ACTIVE_DATA_RECIPIENT_FULFILLED:
    "FETCH_ACTIVE_DATA_RECIPIENT_FULFILLED",
  FETCH_ACTIVE_DATA_RECIPIENT_REJECTED: "FETCH_ACTIVE_DATA_RECIPIENT_REJECTED",
};
export function getActiveDataRecipeintList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RopaManagementService_URL}/Apc_View/Amc_27/findAllDataRecipient?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataRecipientListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataRecipientListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDataRecipientListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataRecipientListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataRecipientListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataRecipientListError(err));
              break;
            case 500:
              dispatch(getActiveDataRecipientListError("Server Error"));
              break;
            default:
              dispatch(getActiveDataRecipientListError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataRecipientListRequest() {
  return {
    type: GetActiveDataRecipientActions.FETCH_ACTIVE_DATA_RECIPIENT,
    isPosting: true,
  };
}
export function getActiveDataRecipientListError(error) {
  return {
    type: GetActiveDataRecipientActions.FETCH_ACTIVE_DATA_RECIPIENT_REJECTED,
    payload: error,
  };
}

export function getActiveDataRecipientListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataRecipientActions.FETCH_ACTIVE_DATA_RECIPIENT_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataRecipientListStatus() {
  return (dispatch) => dispatch(getActiveDataRecipientListStatusRequest());
}
export function getActiveDataRecipientListStatusRequest() {
  return {
    type: GetActiveDataRecipientActions.FETCH_ACTIVE_DATA_RECIPIENT_STATUS,
  };
}
