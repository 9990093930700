import {
  saveAssetTypeActions,
  deleteAssetTypeActions,
  GetAssetTypeDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveAssetTypeTypeActions
} from "./actions";

const initState = {
  assetSuccessMessage: null,
  assetTypeErrorMessage: null,
  AssetTypeStatus: null,
  AssetTypeMaster: [],
  AssetTypeData: null,
  ActiveAssetTypeList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveAssetTypeActions.SAVE_ASSET_TYPE:
      return {
        ...state,
        isLoading: true,
        assetTypeErrorMessage: null,
        modalActive: false
      };
    case saveAssetTypeActions.SAVE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AssetTypeStatus: payload.status,
        assetTypeErrorMessage: null,
        assetSuccessMessage: "Asset Type added successfully!"
      };

    case saveAssetTypeActions.SAVE_ASSET_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        AssetTypeStatus: null,
        assetTypeErrorMessage: payload
      };
    case saveAssetTypeActions.SAVE_ASSET_TYPE_STATUS:
      return {
        ...state,
        AssetTypeStatus: 0,
        assetTypeErrorMessage: null
      };

    // Delete Asset Type

    case deleteAssetTypeActions.DELETE_ASSET_TYPE:
      return {
        ...state,
        isLoading: true,
        assetTypeErrorMessage: null,
        modalActive: false
      };
    case deleteAssetTypeActions.DELETE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AssetTypeStatus: payload.status,
        assetTypeErrorMessage: null,
        assetSuccessMessage: "Asset Type Deleted successfully!"
      };

    case deleteAssetTypeActions.DELETE_ASSET_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        AssetTypeStatus: null,
        assetTypeErrorMessage: payload
      };
    case deleteAssetTypeActions.DELETE_ASSET_TYPE_STATUS:
      return {
        ...state,
        AssetTypeStatus: 0,
        assetTypeErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetAssetTypeDetailsActions.FETCH_ASSET_TYPE_DETAILS:
      return {
        ...state,
        isLoading: true,
        assetTypeErrorMessage: null,
        modalActive: false,
        AssetTypeData: null
      };
    case GetAssetTypeDetailsActions.FETCH_ASSET_TYPE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AssetTypeData: payload.data,
        AssetTypeDataStatus: payload.status,
        assetTypeErrorMessage: null
      };
    case GetAssetTypeDetailsActions.FETCH_ASSET_TYPE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        AssetTypeData: null
      };
    case GetAssetTypeDetailsActions.FETCH_ASSET_TYPE_DETAILS_STATUS:
      return {
        ...state,
        AssetTypeData: null
      };
    ///Active assets
    case GetActiveAssetTypeTypeActions.FETCH_ACTIVE_ASSETS_TYPE:
      return {
        ...state,
        isLoading: true,
        assetTypeErrorMessage: null,
        modalActive: false,
        ActiveAssetTypeList: null
      };
    case GetActiveAssetTypeTypeActions.FETCH_ACTIVE_ASSETS_TYPE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveAssetTypeList: payload.data,
        assetTypeErrorMessage: null
      };
    case GetActiveAssetTypeTypeActions.FETCH_ACTIVE_ASSETS_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveAssetTypeList: null
      };
    case GetActiveAssetTypeTypeActions.FETCH_ACTIVE_ASSETS_TYPE_STATUS:
      return {
        ...state,
        ActiveAssetTypeList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetAssetType: null,
        AssetTypeStatus: null,
        assetSuccessMessage: null,
        GetAssetTypeStatus: null,
        AssetTypeData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
