import {
  saveIndustryTypeActions,
  deleteIndustryTypeActions,
  GetIndustryTypeDetailsActions,
  ClearFormDetailsActions,
  GetVendorTypeDetailsActions,
  SaveVendorTypeActions,
  SaveVendorCategoryActions,
  GetVendorCategoryDetailsActions,
  SaveVendorRegisterActions,
  GetVendorRegisterDetailsActions,
  GetActiveVendorTypeActions,
  GetActiveIndustryTypeActions,
  GetActiveVendorCategoryActions,
  DeleteVendorAddressActions,
  DeleteVendorContactActions,
  GetVendorTnCDocumentsActions,
  SaveVendorTnCDocumentsActions,
  DeleteVendorTnCDocumentsActions,
  GetActiveVendorActions,
  SaveVendorRiskRatingActions,
  GetVendorRiskRatingDetailsActions,
  GetActiveVendorRiskRatingActions,
  SaveVendorTierActions,
  GetVendorTierDetailsActions,
  GetActiveVendorTierActions,
  SaveVendorTnCDocumentsReviewActions,
  GetViewDocumentsActions,
  VenderIdListActions,
  SaveVendorTierSequenceActions,
  SaveVendorRiskRatingSequenceActions,
  SaveDocumentTypeActions,
  GetDocumentTypeDetailsActions,
  GetActiveDocumentTypeActions,
  GetActiveVendorRiskRatingSequenceActions,
  GetActiveVendorTierSequenceActions,
  GetVendorDashboardActions,
  SaveTierScaleActions,
  GetTierScaleActions,
  GetVendorSummaryActions,
  GetGoogleDriveOpenDocActions,
  saveShareDocumentActions,
  GetAllTierAssessmentActions,
} from "./actions";

const initState = {
  industrySuccessMessage: null,
  industryTypeErrorMessage: null,
  IndustryTypeStatus: null,
  IndustryTypeMaster: [],
  IndustryTypeData: null,
  ActiveIndustryTypeList: null,
  vendorTypeSuccessMessage: null,
  vendorTypeErrorMessage: null,
  vendorTypeStatus: null,
  vendorTypeMaster: [],
  vendorTypeData: null,
  ActiveVendorTypeList: null,
  vendorCategorySuccessMessage: null,
  vendorCategoryErrorMessage: null,
  vendorCategoryStatus: null,
  vendorCategoryMaster: [],
  vendorCategoryData: null,
  ActiveVendorCategoryList: null,
  vendorRegisterSuccessMessage: null,
  vendorRegisterErrorMessage: null,
  vendorRegisterStatus: null,
  vendorRegisterMaster: [],
  vendorRegisterData: null,
  ActiveVendorRegisterList: null,
  ActiveVendorList: [],
  vendorErrorMessage: null,
  venderIdDetailsData: [],
  venderIdDetailsDataErrorMessage: null,
  venderIdDetailsDataStatus: null,
  googleDriveDocData: [],
  googleDriveDocDataStatus: null,
  googleDriveDocDataMessage: null,
  googleDriveDocDataErrorMessage: null,
  shareDocTypeStatus: null,
  shareDocTypeErrorMessage: null,
  shareDocSuccessMessage: null,
};

export default function VendorManagement(state = initState, { type, payload }) {
  switch (type) {
    //Save Industry
    case saveIndustryTypeActions.SAVE_INDUSTRY_TYPE:
      return {
        ...state,
        isLoading: true,
        industryTypeErrorMessage: null,
        modalActive: false,
      };
    case saveIndustryTypeActions.SAVE_INDUSTRY_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        IndustryTypeStatus: payload.status,
        industryTypeErrorMessage: null,
        industrySuccessMessage: "Industry Type added successfully!",
      };

    case saveIndustryTypeActions.SAVE_INDUSTRY_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        IndustryTypeStatus: null,
        industryTypeErrorMessage: payload,
      };
    case saveIndustryTypeActions.SAVE_INDUSTRY_TYPE_STATUS:
      return {
        ...state,
        IndustryTypeStatus: 0,
        industryTypeErrorMessage: null,
      };

    // GET Industry By ID
    case GetIndustryTypeDetailsActions.FETCH_INDUSTRY_TYPE_DETAILS:
      return {
        ...state,
        isLoading: true,
        industryTypeErrorMessage: null,
        modalActive: false,
        IndustryTypeData: null,
      };
    case GetIndustryTypeDetailsActions.FETCH_INDUSTRY_TYPE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        IndustryTypeData: payload.data,
        IndustryTypeDataStatus: payload.status,
        industryTypeErrorMessage: null,
      };
    case GetIndustryTypeDetailsActions.FETCH_INDUSTRY_TYPE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        industryTypeErrorMessage: payload,
        IndustryTypeData: null,
      };
    case GetIndustryTypeDetailsActions.FETCH_INDUSTRY_TYPE_DETAILS_STATUS:
      return {
        ...state,
        IndustryTypeData: null,
      };

    ///Active Industry Type List
    case GetActiveIndustryTypeActions.FETCH_ACTIVE_INDUSTRY_TYPE:
      return {
        ...state,
        isLoading: true,
        assetTypeErrorMessage: null,
        modalActive: false,
        ActiveIndustryTypeList: null,
      };
    case GetActiveIndustryTypeActions.FETCH_ACTIVE_INDUSTRY_TYPE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveIndustryTypeList: payload.data,
        assetTypeErrorMessage: null,
      };
    case GetActiveIndustryTypeActions.FETCH_ACTIVE_INDUSTRY_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveIndustryTypeList: null,
      };
    case GetActiveIndustryTypeActions.FETCH_ACTIVE_INDUSTRY_TYPE_STATUS:
      return {
        ...state,
        ActiveIndustryTypeList: null,
      };

    //SAVE Vendor Type
    case SaveVendorTypeActions.SAVE_VENDOR_TYPE:
      return {
        ...state,
        isLoading: true,
        vendorTypeErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorTypeActions.SAVE_VENDOR_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTypeStatus: payload.status,
        vendorTypeErrorMessage: null,
        vendorTypeSuccessMessage: "Vendor Type Added Successfully!",
      };

    case SaveVendorTypeActions.SAVE_VENDOR_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorTypeStatus: null,
        vendorTypeErrorMessage: payload,
      };
    case SaveVendorTypeActions.SAVE_VENDOR_TYPE_STATUS:
      return {
        ...state,
        vendorTypeStatus: 0,
        vendorTypeErrorMessage: null,
      };

    // GET Vendor Type  BY ID
    case GetVendorTypeDetailsActions.FETCH_VENDOR_TYPE_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorTypeErrorMessage: null,
        modalActive: false,
        vendorTypeData: null,
      };
    case GetVendorTypeDetailsActions.FETCH_VENDOR_TYPE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTypeData: payload.data,
        vendorTypeDataStatus: payload.status,
        vendorTypeErrorMessage: null,
      };
    case GetVendorTypeDetailsActions.FETCH_VENDOR_TYPE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorTypeErrorMessage: payload,
        vendorTypeData: null,
      };
    case GetVendorTypeDetailsActions.FETCH_VENDOR_TYPE_DETAILS_STATUS:
      return {
        ...state,
        vendorTypeData: null,
      };

    ///Active Vendor Type List
    case GetActiveVendorTypeActions.FETCH_ACTIVE_VENDOR_TYPE:
      return {
        ...state,
        isLoading: true,
        assetTypeErrorMessage: null,
        modalActive: false,
        ActiveVendorTypeList: null,
      };
    case GetActiveVendorTypeActions.FETCH_ACTIVE_VENDOR_TYPE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveVendorTypeList: payload.data,
        assetTypeErrorMessage: null,
      };
    case GetActiveVendorTypeActions.FETCH_ACTIVE_VENDOR_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveVendorTypeList: null,
      };
    case GetActiveVendorTypeActions.FETCH_ACTIVE_VENDOR_TYPE_STATUS:
      return {
        ...state,
        ActiveVendorTypeList: null,
      };

    //SAVE Vendor Category
    case SaveVendorCategoryActions.SAVE_VENDOR_CATEGORY:
      return {
        ...state,
        isLoading: true,
        vendorCategoryErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorCategoryActions.SAVE_VENDOR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorCategoryStatus: payload.status,
        vendorCategoryErrorMessage: null,
        vendorCategorySuccessMessage: "Vendor Category Added Successfully!",
      };

    case SaveVendorCategoryActions.SAVE_VENDOR_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorCategoryStatus: null,
        vendorCategoryErrorMessage: payload,
      };
    case SaveVendorCategoryActions.SAVE_VENDOR_CATEGORY_STATUS:
      return {
        ...state,
        vendorCategoryStatus: 0,
        vendorCategoryErrorMessage: null,
      };

    // GET Vendor Category  BY ID
    case GetVendorCategoryDetailsActions.FETCH_VENDOR_CATEGORY_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorCategoryErrorMessage: null,
        modalActive: false,
        vendorCategoryData: null,
      };
    case GetVendorCategoryDetailsActions.FETCH_VENDOR_CATEGORY_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorCategoryData: payload.data,
        vendorCategoryDataStatus: payload.status,
        vendorCategoryErrorMessage: null,
      };
    case GetVendorCategoryDetailsActions.FETCH_VENDOR_CATEGORY_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorCategoryErrorMessage: payload,
        vendorCategoryData: null,
      };
    case GetVendorCategoryDetailsActions.FETCH_VENDOR_CATEGORY_DETAILS_STATUS:
      return {
        ...state,
        vendorCategoryData: null,
      };

    ///Active Vendor Category List
    case GetActiveVendorCategoryActions.FETCH_ACTIVE_VENDOR_CATEGORY:
      return {
        ...state,
        isLoading: true,
        assetTypeErrorMessage: null,
        modalActive: false,
        ActiveVendorCategoryList: null,
      };
    case GetActiveVendorCategoryActions.FETCH_ACTIVE_VENDOR_CATEGORY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveVendorCategoryList: payload.data,
        assetTypeErrorMessage: null,
      };
    case GetActiveVendorCategoryActions.FETCH_ACTIVE_VENDOR_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveVendorCategoryList: null,
      };
    case GetActiveVendorCategoryActions.FETCH_ACTIVE_VENDOR_CATEGORY_STATUS:
      return {
        ...state,
        ActiveVendorCategoryList: null,
      };

    //SAVE Vendor Register
    case SaveVendorRegisterActions.SAVE_VENDOR_REGISTER:
      return {
        ...state,
        isLoading: true,
        vendorRegisterErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorRegisterActions.SAVE_VENDOR_REGISTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorRegisterStatus: payload.status,
        vendorRegisterErrorMessage: null,
        vendorRegisterSuccessMessage: "Vendor Register Added Successfully!",
      };

    case SaveVendorRegisterActions.SAVE_VENDOR_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorRegisterStatus: null,
        vendorRegisterErrorMessage: payload,
      };
    case SaveVendorRegisterActions.SAVE_VENDOR_REGISTER_STATUS:
      return {
        ...state,
        vendorRegisterStatus: 0,
        vendorRegisterErrorMessage: null,
      };

    // GET Vendor Register  BY ID
    case GetVendorRegisterDetailsActions.FETCH_VENDOR_REGISTER_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorRegisterErrorMessage: null,
        modalActive: false,
        vendorRegisterData: null,
      };
    case GetVendorRegisterDetailsActions.FETCH_VENDOR_REGISTER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorRegisterData: payload.data,
        vendorRegisterDataStatus: payload.status,
        vendorRegisterErrorMessage: null,
      };
    case GetVendorRegisterDetailsActions.FETCH_VENDOR_REGISTER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorRegisterErrorMessage: payload,
        vendorRegisterData: null,
      };
    case GetVendorRegisterDetailsActions.FETCH_VENDOR_REGISTER_DETAILS_STATUS:
      return {
        ...state,
        vendorRegisterData: null,
      };

    // DELETE Vendor Address  BY ID
    case DeleteVendorAddressActions.DELETE_VENDOR_ADDRESS_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorAddressErrorMessage: null,
      };
    case DeleteVendorAddressActions.DELETE_VENDOR_ADDRESS_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        vendorAddressErrorMessage: null,
      };
    case DeleteVendorAddressActions.DELETE_VENDOR_ADDRESS_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorAddressErrorMessage: payload,
      };

    // DELETE Vendor CONTACT  BY ID
    case DeleteVendorContactActions.DELETE_VENDOR_CONTACT_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorContactErrorMessage: null,
      };
    case DeleteVendorContactActions.DELETE_VENDOR_CONTACT_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        vendorContactErrorMessage: null,
      };
    case DeleteVendorContactActions.DELETE_VENDOR_CONTACT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorContactErrorMessage: payload,
      };

    ///Active Vendors
    case GetActiveVendorActions.FETCH_ACTIVE_VENDORS:
      return {
        ...state,
        isLoading: true,
        vendorErrorMessage: null,
        modalActive: false,
        ActiveVendorList: null,
      };
    case GetActiveVendorActions.FETCH_ACTIVE_VENDOR_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveVendorList: payload.data,
        vendorErrorMessage: null,
      };
    case GetActiveVendorActions.FETCH_ACTIVE_VENDOR_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorErrorMessage: payload,
        ActiveVendorList: null,
      };
    case GetActiveVendorActions.FETCH_ACTIVE_VENDOR_STATUS:
      return {
        ...state,
        ActiveVendorList: null,
      };

    // GET Vendor TnC Documents BY Vendot ID
    case GetVendorTnCDocumentsActions.FETCH_VENDOR_TNC_DOCUMENTS:
      return {
        ...state,
        isLoading: true,
        vendorTnCDocumentsErrorMessage: null,
        vendorTnCDocumentsData: null,
      };
    case GetVendorTnCDocumentsActions.FETCH_VENDOR_TNC_DOCUMENTS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTnCDocumentsData: payload.data,
        vendorTnCDocumentsDataStatus: payload.status,
        vendorTnCDocumentsErrorMessage: null,
      };
    case GetVendorTnCDocumentsActions.FETCH_VENDOR_TNC_DOCUMENTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorTnCDocumentsErrorMessage: payload,
        vendorTnCDocumentsData: null,
      };
    case GetVendorTnCDocumentsActions.FETCH_VENDOR_TNC_DOCUMENTS_STATUS:
      return {
        ...state,
        vendorTnCDocumentsData: null,
      };

    //SAVE Vendor Terms and Conditions Documents
    case SaveVendorTnCDocumentsActions.SAVE_VENDOR_TNC_DOCUMENTS:
      return {
        ...state,
        isLoading: true,
        vendorTnCDocumentsErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorTnCDocumentsActions.SAVE_VENDOR_TNC_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTnCDocumentsStatus: payload.status,
        vendorTnCDocumentsErrorMessage: null,
        vendorTnCDocumentsSuccessMessage:
          "Vendor Documents Added Successfully!",
      };

    case SaveVendorTnCDocumentsActions.SAVE_VENDOR_TNC_DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorTnCDocumentsStatus: null,
        vendorTnCDocumentsErrorMessage: payload,
      };
    case SaveVendorTnCDocumentsActions.SAVE_VENDOR_TNC_DOCUMENTS_STATUS:
      return {
        ...state,
        vendorTnCDocumentsStatus: 0,
        vendorTnCDocumentsErrorMessage: null,
      };

    //SAVE Vendor Terms and Conditions Documents Review
    case SaveVendorTnCDocumentsReviewActions.SAVE_VENDOR_TNC_DOCUMENTS_REVIEW:
      return {
        ...state,
        isLoading: true,
        vendorTnCDocumentsReviewErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorTnCDocumentsReviewActions.SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTnCDocumentsReviewStatus: payload.status,
        vendorTnCDocumentsReviewErrorMessage: null,
        vendorTnCDocumentsReviewSuccessMessage:
          "Vendor Documents Added Successfully!",
      };

    case SaveVendorTnCDocumentsReviewActions.SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorTnCDocumentsReviewStatus: null,
        vendorTnCDocumentsReviewErrorMessage: payload,
      };
    case SaveVendorTnCDocumentsReviewActions.SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_STATUS:
      return {
        ...state,
        vendorTnCDocumentsReviewStatus: 0,
        vendorTnCDocumentsReviewErrorMessage: null,
      };

    //view document details by id
    case GetViewDocumentsActions.FETCH_VIEW_DOCUMENTS:
      return {
        ...state,
        isLoading: true,
        venderViewErrorMsg: null,
        venderViewDocumentData: null,
      };
    case GetViewDocumentsActions.FETCH_VIEW_DOCUMENTS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        venderViewDocumentData: payload.data,
        venderViewDocumentDataStatus: payload.status,
        venderViewErrorMsg: null,
      };
    case GetViewDocumentsActions.FETCH_VIEW_DOCUMENTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        venderViewErrorMsg: payload,
        venderViewDocumentData: null,
      };
    case GetViewDocumentsActions.FETCH_VIEW_DOCUMENTS_STATUS:
      return {
        ...state,
        venderViewDocumentData: null,
      };

    // DELETE Vendor Terms And Conditions BY Vendor ID
    case DeleteVendorTnCDocumentsActions.DELETE_VENDOR_TNC_DOCUMENTS_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorTnCDocumentsErrorMessage: null,
      };
    case DeleteVendorTnCDocumentsActions.DELETE_VENDOR_TNC_DOCUMENTS_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        vendorTnCDocumentsErrorMessage: null,
      };
    case DeleteVendorTnCDocumentsActions.DELETE_VENDOR_TNC_DOCUMENTS_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorTnCDocumentsErrorMessage: payload,
      };

    /////-------------VENDOR RISK RATING --------------------//
    //SAVE Vendor Risk Rating
    case SaveVendorRiskRatingActions.SAVE_VENDOR_RISK_RATING:
      return {
        ...state,
        isLoading: true,
        vendorRiskRatingErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorRiskRatingActions.SAVE_VENDOR_RISK_RATING_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorRiskRatingStatus: payload.status,
        vendorRiskRatingErrorMessage: null,
        vendorRiskRatingSuccessMessage:
          "Vendor Risk Rating Added Successfully!",
      };

    case SaveVendorRiskRatingActions.SAVE_VENDOR_RISK_RATING_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorRiskRatingStatus: null,
        vendorRiskRatingErrorMessage: payload,
      };
    case SaveVendorRiskRatingActions.SAVE_VENDOR_RISK_RATING_STATUS:
      return {
        ...state,
        vendorRiskRatingStatus: 0,
        vendorRiskRatingErrorMessage: null,
      };

    //SAVE Vendor Risk Rating Sequence
    case SaveVendorRiskRatingSequenceActions.SAVE_VENDOR_RISK_RATING_SEQUENCE:
      return {
        ...state,
        isLoading: true,
        vendorRiskRatingSequenceErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorRiskRatingSequenceActions.SAVE_VENDOR_RISK_RATING_SEQUENCE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorRiskRatingSequenceStatus: payload.status,
        vendorRiskRatingSequenceErrorMessage: null,
        vendorRiskRatingSequenceSuccessMessage:
          "Vendor Risk Rating Sequence Updated Successfully!",
      };

    case SaveVendorRiskRatingSequenceActions.SAVE_VENDOR_RISK_RATING_SEQUENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorRiskRatingSequenceStatus: null,
        vendorRiskRatingSequenceErrorMessage: payload,
      };
    case SaveVendorRiskRatingSequenceActions.SAVE_VENDOR_RISK_RATING_SEQUENCE_STATUS:
      return {
        ...state,
        vendorRiskRatingSequenceStatus: 0,
        vendorRiskRatingSequenceErrorMessage: null,
      };

    // GET Vendor Risk Rating  BY ID
    case GetVendorRiskRatingDetailsActions.FETCH_VENDOR_RISK_RATING_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorRiskRatingErrorMessage: null,
        modalActive: false,
        vendorRiskRatingData: null,
      };
    case GetVendorRiskRatingDetailsActions.FETCH_VENDOR_RISK_RATING_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorRiskRatingData: payload.data,
        vendorRiskRatingDataStatus: payload.status,
        vendorRiskRatingErrorMessage: null,
      };
    case GetVendorRiskRatingDetailsActions.FETCH_VENDOR_RISK_RATING_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorRiskRatingErrorMessage: payload,
        vendorRiskRatingData: null,
      };
    case GetVendorRiskRatingDetailsActions.FETCH_VENDOR_RISK_RATING_DETAILS_STATUS:
      return {
        ...state,
        vendorRiskRatingData: null,
      };

    ///Active Vendor Risk Rating List
    case GetActiveVendorRiskRatingActions.FETCH_ACTIVE_VENDOR_RISK_RATING:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
        ActiveVendorRiskRatingList: null,
      };
    case GetActiveVendorRiskRatingActions.FETCH_ACTIVE_VENDOR_RISK_RATING_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveVendorRiskRatingList: payload.data,
      };
    case GetActiveVendorRiskRatingActions.FETCH_ACTIVE_VENDOR_RISK_RATING_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveVendorRiskRatingList: null,
      };
    case GetActiveVendorRiskRatingActions.FETCH_ACTIVE_VENDOR_RISK_RATING_STATUS:
      return {
        ...state,
        ActiveVendorRiskRatingList: null,
      };

    ///Active Vendor Risk Rating Sequence List
    case GetActiveVendorRiskRatingSequenceActions.FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
        ActiveVendorRiskRatingSequenceList: null,
      };
    case GetActiveVendorRiskRatingSequenceActions.FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveVendorRiskRatingSequenceList: payload.data,
      };
    case GetActiveVendorRiskRatingSequenceActions.FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveVendorRiskRatingSequenceList: null,
      };
    case GetActiveVendorRiskRatingSequenceActions.FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_STATUS:
      return {
        ...state,
        ActiveVendorRiskRatingSequenceList: null,
      };

    /////-------------VENDOR TIER --------------------//
    //SAVE Vendor Tier
    case SaveVendorTierActions.SAVE_VENDOR_TIER:
      return {
        ...state,
        isLoading: true,
        vendorTierErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorTierActions.SAVE_VENDOR_TIER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTierStatus: payload.status,
        vendorTierErrorMessage: null,
        vendorTierSuccessMessage: "Vendor Tier Added Successfully!",
      };

    case SaveVendorTierActions.SAVE_VENDOR_TIER_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorTierStatus: null,
        vendorTierErrorMessage: payload,
      };
    case SaveVendorTierActions.SAVE_VENDOR_TIER_STATUS:
      return {
        ...state,
        vendorTierStatus: 0,
        vendorTierErrorMessage: null,
      };

    // GET Vendor Tier  BY ID
    case GetVendorTierDetailsActions.FETCH_VENDOR_TIER_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorTierErrorMessage: null,
        modalActive: false,
        vendorTierData: null,
      };
    case GetVendorTierDetailsActions.FETCH_VENDOR_TIER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTierData: payload.data,
        vendorTierDataStatus: payload.status,
        vendorTierErrorMessage: null,
      };
    case GetVendorTierDetailsActions.FETCH_VENDOR_TIER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorTierErrorMessage: payload,
        vendorTierData: null,
      };
    case GetVendorTierDetailsActions.FETCH_VENDOR_TIER_DETAILS_STATUS:
      return {
        ...state,
        vendorTierData: null,
      };

    ///Active Vendor Tier List
    case GetActiveVendorTierActions.FETCH_ACTIVE_VENDOR_TIER:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
        ActiveVendorTierList: null,
      };
    case GetActiveVendorTierActions.FETCH_ACTIVE_VENDOR_TIER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveVendorTierList: payload.data,
      };
    case GetActiveVendorTierActions.FETCH_ACTIVE_VENDOR_TIER_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveVendorTierList: null,
      };
    case GetActiveVendorTierActions.FETCH_ACTIVE_VENDOR_TIER_STATUS:
      return {
        ...state,
        ActiveVendorTierList: null,
      };

    //SAVE Vendor Tier Sequence
    case SaveVendorTierSequenceActions.SAVE_VENDOR_TIER_SEQUENCE:
      return {
        ...state,
        isLoading: true,
        vendorTierSequenceErrorMessage: null,
        modalActive: false,
      };
    case SaveVendorTierSequenceActions.SAVE_VENDOR_TIER_SEQUENCE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTierSequenceStatus: payload.status,
        vendorTierSequenceErrorMessage: null,
        vendorTierSequenceSuccessMessage:
          "Vendor Tier Sequence Saved Successfully!",
      };

    case SaveVendorTierSequenceActions.SAVE_VENDOR_TIER_SEQUENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorTierSequenceStatus: null,
        vendorTierSequenceErrorMessage: payload,
      };
    case SaveVendorTierSequenceActions.SAVE_VENDOR_TIER_SEQUENCE_STATUS:
      return {
        ...state,
        vendorTierSequenceStatus: 0,
        vendorTierSequenceErrorMessage: null,
      };

    ///Active Vendor Tier Sequence List
    case GetActiveVendorTierSequenceActions.FETCH_ACTIVE_VENDOR_TIER_SEQUENCE:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
        ActiveVendorTierSequenceList: null,
      };
    case GetActiveVendorTierSequenceActions.FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveVendorTierSequenceList: payload.data,
      };
    case GetActiveVendorTierSequenceActions.FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveVendorTierSequenceList: null,
      };
    case GetActiveVendorTierSequenceActions.FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_STATUS:
      return {
        ...state,
        ActiveVendorTierSequenceList: null,
      };

    //view vender list id details
    case VenderIdListActions.FETCH_VENDER_LIST_DETAILS:
      return {
        ...state,
        isLoading: true,
        venderIdDetailsDataErrorMessage: null,
        venderIdDetailsData: null,
      };
    case VenderIdListActions.FETCH_VENDER_LIST_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        venderIdDetailsData: payload.data,
        venderIdDetailsDataStatus: payload.status,
        venderIdDetailsDataErrorMessage: null,
      };
    case VenderIdListActions.FETCH_VENDER_LIST_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        venderIdDetailsDataErrorMessage: payload,
        venderIdDetailsData: null,
      };
    case VenderIdListActions.FETCH_VENDER_LIST_DETAILS_STATUS:
      return {
        ...state,
        venderIdDetailsData: null,
      };

    //SAVE Vendor Document Type
    case SaveDocumentTypeActions.SAVE_DOCUMENT_TYPE:
      return {
        ...state,
        isLoading: true,
        documentTypeErrorMessage: null,
        modalActive: false,
      };
    case SaveDocumentTypeActions.SAVE_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        documentTypeStatus: payload.status,
        documentTypeErrorMessage: null,
        documentTypeSuccessMessage: "Document Type Added Successfully!",
      };

    case SaveDocumentTypeActions.SAVE_DOCUMENT_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        documentTypeStatus: null,
        documentTypeErrorMessage: payload,
      };
    case SaveDocumentTypeActions.SAVE_DOCUMENT_TYPE_STATUS:
      return {
        ...state,
        documentTypeStatus: 0,
        documentTypeErrorMessage: null,
      };

    // GET Document Type  BY ID
    case GetDocumentTypeDetailsActions.FETCH_DOCUMENT_TYPE_DETAILS:
      return {
        ...state,
        isLoading: true,
        documentTypeErrorMessage: null,
        modalActive: false,
        documentTypeData: null,
      };
    case GetDocumentTypeDetailsActions.FETCH_DOCUMENT_TYPE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        documentTypeData: payload.data,
        documentTypeDataStatus: payload.status,
        documentTypeErrorMessage: null,
      };
    case GetDocumentTypeDetailsActions.FETCH_DOCUMENT_TYPE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        documentTypeErrorMessage: payload,
        documentTypeData: null,
      };
    case GetDocumentTypeDetailsActions.FETCH_DOCUMENT_TYPE_DETAILS_STATUS:
      return {
        ...state,
        documentTypeData: null,
      };

    ///Active Document Type List
    case GetActiveDocumentTypeActions.FETCH_ACTIVE_DOCUMENT_TYPE:
      return {
        ...state,
        isLoading: true,
        activeDocumentTypeErrorMessage: null,
        modalActive: false,
        ActiveDocumentTypeList: null,
      };
    case GetActiveDocumentTypeActions.FETCH_ACTIVE_DOCUMENT_TYPE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDocumentTypeList: payload.data,
        activeDocumentTypeErrorMessage: null,
      };
    case GetActiveDocumentTypeActions.FETCH_ACTIVE_DOCUMENT_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        activeDocumentTypeErrorMessage: payload,
        ActiveDocumentTypeList: null,
      };
    case GetActiveDocumentTypeActions.FETCH_ACTIVE_DOCUMENT_TYPE_STATUS:
      return {
        ...state,
        ActiveDocumentTypeList: null,
      };

    ///GET Vendor Dashboard Data
    case GetVendorDashboardActions.FETCH_VENDOR_DASHBOARD:
      return {
        ...state,
        isLoading: true,
        vendorDashboardErrorMessage: null,
        modalActive: false,
        vendorDashboardData: null,
      };
    case GetVendorDashboardActions.FETCH_VENDOR_DASHBOARD_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorDashboardData: payload.data,
        vendorDashboardDataStatus: payload.status,
        vendorDashboardErrorMessage: null,
      };
    case GetVendorDashboardActions.FETCH_VENDOR_DASHBOARD_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorDashboardErrorMessage: payload,
        vendorDashboardData: null,
      };
    case GetVendorDashboardActions.FETCH_VENDOR_DASHBOARD_STATUS:
      return {
        ...state,
        vendorDashboardData: null,
      };

    //SAVE TIER SCALES
    case SaveTierScaleActions.SAVE_TIER_SCALE:
      return {
        ...state,
        isLoading: true,
        tierScaleErrorMessage: null,
        modalActive: false,
      };
    case SaveTierScaleActions.SAVE_TIER_SCALE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        tierScaleStatus: payload.status,
        tierScaleErrorMessage: null,
        tierScaleSuccessMessage: "Tier Scales Added Successfully!",
      };

    case SaveTierScaleActions.SAVE_TIER_SCALE_FAILURE:
      return {
        ...state,
        isLoading: false,
        tierScaleStatus: null,
        tierScaleErrorMessage: payload,
      };
    case SaveTierScaleActions.SAVE_TIER_SCALE_STATUS:
      return {
        ...state,
        tierScaleStatus: 0,
        tierScaleErrorMessage: null,
      };

    // GET TIER SCALES
    case GetTierScaleActions.FETCH_TIER_SCALE_DETAILS:
      return {
        ...state,
        isLoading: true,
        tierScaleErrorMessage: null,
        modalActive: false,
        tierScaleData: null,
      };
    case GetTierScaleActions.FETCH_TIER_SCALE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        tierScaleData: payload.data,
        tierScaleDataStatus: payload.status,
        tierScaleErrorMessage: null,
      };
    case GetTierScaleActions.FETCH_TIER_SCALE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        tierScaleErrorMessage: payload,
        tierScaleData: null,
      };
    case GetTierScaleActions.FETCH_TIER_SCALE_DETAILS_STATUS:
      return {
        ...state,
        tierScaleData: null,
      };
    //get google drive doc data
    case GetGoogleDriveOpenDocActions.FETCH_OPEN_DOC_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        googleDriveDocData: payload.data,
        googleDriveDocDataStatus: payload.status,
        googleDriveDocDataMessage: null,
      };
    case GetGoogleDriveOpenDocActions.FETCH_OPEN_DOC_REJECTED:
      return {
        ...state,
        isLoading: false,
        googleDriveDocDataErrorMessage: payload,
        googleDriveDocData: null,
      };
    case GetGoogleDriveOpenDocActions.FETCH_OPEN_DOC_STATUS:
      return {
        ...state,
        googleDriveDocData: null,
      };

    //save share document
    case saveShareDocumentActions.SAVE_SHARE_DOCUMENT_TYPE:
      return {
        ...state,
        isLoading: true,
        shareDocSuccessMessage: null,
      };
    case saveShareDocumentActions.SAVE_SHARE_DOCUMEN_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        shareDocTypeStatus: payload.status,
        shareDocTypeErrorMessage: null,
        shareDocSuccessMessage: "Details saved successfully!",
      };

    case saveShareDocumentActions.SAVE_SHARE_DOCUMEN_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        shareDocTypeStatus: null,
        shareDocSuccessMessage: payload,
      };
    case saveShareDocumentActions.SAVE_SHARE_DOCUMEN_TYPE_STATUS:
      return {
        ...state,
        shareDocTypeStatus: null,
        shareDocSuccessMessage: null,
      };
    // GET VENDOR SUMMARY BY ID
    case GetVendorSummaryActions.FETCH_VENDOR_SUMMARY_DETAILS:
      return {
        ...state,
        isLoading: true,
        vendorSummaryErrorMessage: null,
        modalActive: false,
        vendorSummaryData: null,
      };
    case GetVendorSummaryActions.FETCH_VENDOR_SUMMARY_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorSummaryData: payload.data,
        vendorSummaryDataStatus: payload.status,
        vendorSummaryErrorMessage: null,
      };
    case GetVendorSummaryActions.FETCH_VENDOR_SUMMARY_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorSummaryErrorMessage: payload,
        vendorSummaryData: null,
      };
    case GetVendorSummaryActions.FETCH_VENDOR_SUMMARY_DETAILS_STATUS:
      return {
        ...state,
        vendorSummaryData: null,
      };

    // GET VENDOR TIER ASSESSMENT
    case GetAllTierAssessmentActions.FETCH_ALL_TIER_ASSESSMENT:
      return {
        ...state,
        isLoading: true,
        vendorTierAssessmentErrorMessage: null,
        modalActive: false,
        vendorTierAssessmentData: null,
      };
    case GetAllTierAssessmentActions.FETCH_ALL_TIER_ASSESSMENT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        vendorTierAssessmentData: payload.data,
        vendorTierAssessmentDataStatus: payload.status,
        vendorTierAssessmentErrorMessage: null,
      };
    case GetAllTierAssessmentActions.FETCH_ALL_TIER_ASSESSMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        vendorTierAssessmentErrorMessage: payload,
        vendorTierAssessmentData: null,
      };
    case GetAllTierAssessmentActions.FETCH_ALL_TIER_ASSESSMENT_STATUS:
      return {
        ...state,
        vendorTierAssessmentData: null,
      };

    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetIndustryType: null,
        IndustryTypeStatus: null,
        industrySuccessMessage: null,
        GetIndustryTypeStatus: null,
        IndustryTypeData: null,
        GetVendorType: null,
        vendorTypeStatus: null,
        vendorTypeSuccessMessage: null,
        GetVendorTypeStatus: null,
        vendorTypeData: null,
        GetVendorCategory: null,
        vendorCategoryStatus: null,
        vendorCategorySuccessMessage: null,
        GetVendorCategoryStatus: null,
        vendorCategoryData: null,
        GetVendorRegister: null,
        vendorRegisterStatus: null,
        vendorRegisterSuccessMessage: null,
        GetVendorRegisterStatus: null,
        vendorRegisterData: null,
        vendorAddressErrorMessage: null,
        vendorContactErrorMessage: null,
        GetVendorTnCDocuments: null,
        vendorTnCDocumentsStatus: null,
        vendorTnCDocumentsSuccessMessage: null,
        GetVendorTnCDocumentsStatus: null,
        vendorTnCDocumentsData: null,
        vendorTnCDocumentsErrorMessage: null,
        GetVendorRiskRating: null,
        vendorRiskratingStatus: null,
        vendorRiskratingSuccessMessage: null,
        GetVendorRiskRatingStatus: null,
        vendorRiskratingData: null,
        GetVendorTier: null,
        vendorTierStatus: null,
        vendorTierSuccessMessage: null,
        GetVendorTierStatus: null,
        vendorTierData: null,
        vendorTnCDocumentsReviewStatus: null,
        vendorTnCDocumentsReviewErrorMessage: null,
        vendorTnCDocumentsReviewSuccessMessage: null,
        venderViewDocumentData: null,
        venderViewDocumentDataStatus: null,
        venderViewErrorMsg: null,
        venderIdDetailsData: null,
        venderIdDetailsDataErrorMessage: null,
        venderIdDetailsDataStatus: null,
        GetDocumentType: null,
        documentTypeStatus: null,
        documentTypeSuccessMessage: null,
        GetDocumentTypeStatus: null,
        documentTypeData: null,
        activeDocumentTypeErrorMessage: null,
        documentTypeErrorMessage: null,
        vendorDashboardErrorMessage: null,
        vendorDashboardDataStatus: null,
        vendorDashboardData: null,
        tierScaleErrorMessage: null,
        tierScaleSuccessMessage: null,
        tierScaleStatus: null,
        tierScaleData: null,
        tierScaleDataStatus: null,
        vendorSummaryErrorMessage: null,
        vendorSummarySuccessMessage: null,
        vendorSummaryStatus: null,
        vendorSummaryData: null,
        vendorSummaryDataStatus: null,
        googleDriveDocData: null,
        googleDriveDocDataStatus: null,
        googleDriveDocDataMessage: null,
        googleDriveDocDataErrorMessage: null,
        vendorTierAssessmentErrorMessage: null,
        vendorTierAssessmentData: null,
        vendorTierAssessmentDataStatus: null,
      };

    default:
      return state;
  }
}
