import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
// import options from "./options";
// import ropaOptionsNew from "./ropaOptionsNew";
// import options2 from "./options2";
// import useroptions from "./useroptions";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import Logo from "../../components/utility/logo";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import "./customStyle.css";
// import ropaOptions from "./ropaOptions";
// import surveyOptions from "./surveyOptions";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } =
  appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children } = singleOption;

    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              {/* <i className={leftIcon} /> */}
              <div>
                <img
                  style={{ width: 20, height: 20 }}
                  src={require("../../image/dpo-images/Icons/" + leftIcon)}
                  className="sidebar-menu-icons"
                  alt={label}
                />
              </div>
              <span className="nav-text">
                <p className="menu-txt">
                  <IntlMessages id={label} />
                </p>
              </span>
            </span>
          }
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            {/* <i className={leftIcon} /> */}
            <div>
              <img
                style={{ width: 20, height: 20 }}
                src={require("../../image/dpo-images/Icons/" + leftIcon)}
                className="sidebar-menu-icons"
              />
            </div>
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };
  renderView({ style }) {
    const overflow =
      this.props.app.linkActive != "ROPA" ? "hidden" : "initial !important";
    const customStyle = {
      overflow: overflow,
    };
    return <div style={{ ...style, ...customStyle }} />;
  }
  render() {
    const { app, toggleOpenDrawer, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const options2 = [];
    const surveyOptions = new Array();
    const requestOptions = new Array();
    const requestOptionsNew = new Array();
    const requestOptionDevlop = new Array();

    const useroptions = new Array();
    const ropaOptions = new Array();
    const options = new Array();
    const ropaOptionsNew = new Array();
    const ropaOptionsNewProcess = new Array();

    const breachOptions = new Array();
    const breachOptionsNew = new Array();
    const riskOptions = new Array();
    const riskOptionsNew = new Array();
    const cookiesOptions = new Array();
    const cookieOptions = new Array();
    const cookieConfig = new Array();
    const consentOptions1 = new Array();
    const consentOptions2 = new Array();
    const consentOptions3 = new Array();
    const consentConfig = new Array();
    const vendorOption1 = [];
    const vendorOption2 = [];
    const vendorOption3 = [];
    const vendorOption4 = [];
    const vendorAssessmentOption = [];
    //Ropa Master Options

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.ROPA_MASTER &&
    //   this.props.PermissionData.permissionsMap.ROPA_MASTER.viewModule
    // ) {
    //   var obj = {
    //     key: "ropaMasterListing",
    //     label: "sidebar.ropaMaster",
    //     leftIcon: "Process-Records.png",
    //   };
    //   ropaOptionsNew.push(obj);
    // }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ROPA_MASTER &&
      this.props.PermissionData.permissionsMap.ROPA_MASTER.viewModule
    ) {
      var obj = {
        key: "ropaMasterListing",
        label: "sidebar.ropaMaster",
        leftIcon: "Process-Records.png",
      };
      ropaOptionsNewProcess.push(obj);
    } else {
      var AcessControl = {
        key: "accessControl",
        label: "sidebar.ropaMaster",
        leftIcon: "Process-Records.png",
      };
      ropaOptionsNewProcess.push(AcessControl);
    }
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.DOMAINS &&
      this.props.PermissionData.permissionsMap.DOMAINS.viewModule
    ) {
      var cookie1 = {
        key: "DomainListing",
        label: "sidebar.domain",
        leftIcon: "Web_Domains.svg",
      };

      cookieOptions.push(cookie1);
      //cookiesOptions.push(cookie2)
    } else {
      var AcessControl = {
        key: "accessControl",
        label: "sidebar.domain",
        leftIcon: "Web_Domains.svg",
      };
      cookieOptions.push(AcessControl);
    }
    var cookie3 = {
      key: "CookieClassificationListing",
      label: "sidebar.cookieClassification",
      leftIcon: "Cookie Classification.svg",
    };

    var cookie4 = {
      key: "LocationRulesListing",
      label: "sidebar.locationRule",
      leftIcon: "Location Rules.svg",
    };
    var cookie5 = {
      key: "cookieCategoriesListing",
      label: "sidebar.cookieCategories",
      leftIcon: "Cookie Categories.svg",
    };

    cookieConfig.push(cookie3);
    cookieConfig.push(cookie4);
    cookieConfig.push(cookie5);

    var cookie2 = {
      key: "cookieTemplateListing",
      label: "sidebar.cookieTemplate",
      leftIcon: "Cookie Template.svg",
    };
    cookieOptions.push(cookie2);
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.CONSENT_CAPTURE_POINTS &&
      this.props.PermissionData.permissionsMap.CONSENT_CAPTURE_POINTS.viewModule
    ) {
      var consent1 = {
        key: "consentPointsListing",
        label: "sidebar.consCaptPoints",
        leftIcon: "Consent Capture Points.svg",
      };

      consentOptions1.push(consent1);
    } else {
      var AcessControl = {
        key: "accessControl",
        label: "sidebar.consCaptPoints",
        leftIcon: "Consent Capture Points.svg",
      };
      consentOptions1.push(AcessControl);
    }

    // var consent2 = {
    //   key: "dataSubjects",
    //   label: "sidebar.consentDataSubjects",
    //   leftIcon: "Data Subjects.svg",
    // };
    // consentOptions1.push(consent2)

    var consent4 = {
      key: "consentHeadListing",
      label: "sidebar.consentHeads",
      leftIcon: "Consent Heads.svg",
    };
    consentOptions3.push(consent4);

    var consent5 = {
      key: "consentPurposeListing",
      label: "sidebar.consentPurposes",
      leftIcon: "Consent Purposes.svg",
    };
    consentOptions3.push(consent5);
    var consent3 = {
      key: "collectionInteractionListing",
      label: "sidebar.interactions",
      leftIcon: "Interactions.svg",
    };

    consentOptions2.push(consent3);
    // consentOptions3.push(consent3)
    var consent6 = {
      key: "CollectionsMasterListing",
      label: "sidebar.collections",
      leftIcon: "Collections.svg",
    };
    consentOptions2.push(consent6);
    var consent7 = {
      key: "dataSubjectsConsent",
      label: "sidebar.dataSubject",
      leftIcon: "Process-Records.png",
    };
    consentOptions2.push(consent7);
    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.TEMPLATE_MASTER &&
    //   this.props.PermissionData.permissionsMap.TEMPLATE_MASTER.viewModule
    // ) {
    //   var obj = {
    //     key: "templateListing",
    //     label: "sidebar.templateMaster",
    //     leftIcon: "Template-Master.svg",
    //   };
    //   ropaOptionsNew.push(obj);
    // }

    ///Asset Master Options

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSET_MASTER &&
      this.props.PermissionData.permissionsMap.ASSET_MASTER.viewModule
    ) {
      // var obj = {
      //   key: "blankPage1",
      //   label: "sidebar.Dashboard",
      //   leftIcon: "speedometer.png",
      // };
      var obj1 = {
        key: "assetMaster",
        label: "sidebar.AssetRegister",
        leftIcon: "Assets.png",
      };

      // options.push(obj);
      options.push(obj1);
    } else {
      var AcessControl = {
        key: "accessControl",
        label: "sidebar.AssetRegister",
        leftIcon: "Assets.png",
      };
      options.push(AcessControl);
    }
    ///Asset Master Options
    //REQUEST MANAGEMENT START///////////////////////
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT.addition
    ) {
      var obj = {
        key: "requestMaster",
        label: "sidebar.RequestManagement",
        leftIcon: "Subject-Requests.png",
        // children: [
        //   // {
        //   //   key: "requestMaster",
        //   //   label: "sidebar.addnewrequest",
        //   //   leftIcon: "clipboard.png",
        //   // },
        //   {
        //     key: "requestMaster",
        //     label: "sidebar.RequestRegister",
        //     leftIcon: "Data Processor.svg",
        //   },
        // ],
      };
      requestOptions.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT.viewModule
    ) {
      var obj = {
        key: "requestMaster",
        label: "sidebar.RequestManagement",
        leftIcon: "Subject-Requests.png",
        // children: [
        //   {
        //     key: "requestMaster",
        //     label: "sidebar.RequestRegister",
        //     leftIcon: "Data Processor.svg",
        //   },
        // ],
      };
      requestOptions.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT.addition
    ) {
      var obj = {
        key: "requestMaster",
        label: "sidebar.RequestManagement",
        leftIcon: "Subject-Requests.png",
        // children: [
        //   {
        //     key: "requestMaster",
        //     label: "sidebar.addnewrequest",
        //     leftIcon: "clipboard.png",
        //   },
        // ],
      };
      requestOptions.push(obj);
    } else {
      var AcessControl = {
        key: "accessControl",
        label: "sidebar.RequestManagement",
        leftIcon: "Subject-Requests.png",
      };
      requestOptions.push(AcessControl);
    }

    //REQUEST MANAGEMENT END///////////////////////

    //REQUEST TYPE START///////////////////////

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_TYPE &&
      this.props.PermissionData.permissionsMap.REQUEST_TYPE.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_SOURCES &&
      this.props.PermissionData.permissionsMap.REQUEST_SOURCES.viewModule
    ) {
      var obj = {
        key: "requestTypeMaster",
        label: "sidebar.RequestTypeMaster",
        leftIcon: "Request-Types.png",
      };
      var obj1 = {
        key: "requestSourcesMaster",
        label: "sidebar.RequestSourcesMaster",
        leftIcon: "Request-Sources.png",
      };

      requestOptionsNew.push(obj);
      requestOptionsNew.push(obj1);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_TYPE &&
      this.props.PermissionData.permissionsMap.REQUEST_TYPE.viewModule
    ) {
      var obj = {
        key: "requestTypeMaster",
        label: "sidebar.RequestTypeMaster",
        leftIcon: "Request-Types.png",
      };

      requestOptionsNew.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_SOURCES &&
      this.props.PermissionData.permissionsMap.REQUEST_SOURCES.viewModule
    ) {
      var obj1 = {
        key: "requestSourcesMaster",
        label: "sidebar.RequestSourcesMaster",
        leftIcon: "Request-Sources.png",
      };

      requestOptionsNew.push(obj1);
    }

    //REQUEST TYPE END///////////////////////
    //Request Devlop
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.REQUEST_TYPE &&
      this.props.PermissionData.permissionsMap.REQUEST_SOURCES.viewModule
    ) {
      var obj = {
        key: "requestMasterDev",
        label: "sidebar.requestApi",
        leftIcon: "Create-Request-API.png",
      };
      requestOptionDevlop.push(obj);
    }
    //Ropa Options
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MASTER &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MASTER.viewModule
    ) {
      var obj = {
        key: "templateListing",
        label: "sidebar.templateMaster",
        leftIcon: "Process-Templates.png",
      };
      ropaOptions.push(obj);
    }

    //Data & sub Processors Master

    if (
      (this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.PROCESS_REGISTER &&
      this.props.PermissionData.permissionsMap.PROCESS_REGISTER.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSESSMENT &&
      this.props.PermissionData.permissionsMap.ASSESSMENT.viewModule) ||
      (this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.DATA_PROCESSOR &&
        this.props.PermissionData.permissionsMap.DATA_PROCESSOR.viewModule)
    ) {
      var obj = {
        key: "DataProcessor",
        label: "sidebar.ManageData",
        leftIcon: "Processors-Sub-Processors.png",
        children: [
          {
            key: "RopaDataProcCatListing",
            label: "sidebar.DataProcCat",
            leftIcon: "Data Processor Category.svg",
          },
          {
            key: "dataProcessorListing",
            label: "sidebar.dataProcessor",
            leftIcon: "Data Processor.svg",
          },
          {
            key: "dataSubProcessorListing",
            label: "sidebar.dataSubProcessors",
            leftIcon: "Data Sub Processor.svg",
          },
          {
            key: "dataRecipientListing",
            label: "sidebar.dataRecipient",
            leftIcon: "Data Recipient.svg",
          },
          {
            key: "dataContactListing",
            label: "sidebar.dataContactMaster",
            leftIcon: "Data Contact Master.svg",
          },
          {
            key: "dataTransferListing",
            label: "sidebar.dataTransferMethod",
            leftIcon: "Data Contact Master.svg",
          },
        ],
      };
      ropaOptions.push(obj);
    } else {
      if (
        (this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES
          .viewModule)||
          (this.props.PermissionData.permissionsMap &&
            this.props.PermissionData.permissionsMap.DATA_PROCESSOR &&
            this.props.PermissionData.permissionsMap.DATA_PROCESSOR.viewModule)
      ) {
        var obj = {
          key: "DataProcessor",
          label: "sidebar.ManageData",
          leftIcon: "Processors-Sub-Processors.png",
          children: [
            {
              key: "RopaDataProcCatListing",
              label: "sidebar.DataProcCat",
              leftIcon: "Data Processor Category.svg",
            },
            {
              key: "dataProcessorListing",
              label: "sidebar.dataProcessor",
              leftIcon: "Data Processor.svg",
            },
            {
              key: "dataSubProcessorListing",
              label: "sidebar.dataSubProcessors",
              leftIcon: "Data Sub Processor.svg",
            },
            {
              key: "dataRecipientListing",
              label: "sidebar.dataRecipient",
              leftIcon: "Data Recipient.svg",
            },
            {
              key: "dataContactListing",
              label: "sidebar.dataContactMaster",
              leftIcon: "Data Contact Master.svg",
            },
          ],
        };
        ropaOptions.push(obj);
      }
    }

    //Legal Master

    if (
      (this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES
        .viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER.viewModule) ||
      (this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.DATA_PROCESSOR &&
        this.props.PermissionData.permissionsMap.DATA_PROCESSOR.viewModule)
    ) {
      var obj = {
        key: "LegalMaster",
        label: "sidebar.ManageLegal",
        leftIcon: "Basis-Purpose.png",
        children: [
          {
            key: "legalBasis",
            label: "sidebar.lawfulBasis",
            leftIcon: "Lawful Basis.svg",
          },
          {
            key: "purposeListing",
            label: "sidebar.purposeProcessing",
            leftIcon: "Purpose of Processing.svg",
          },
        ],
      };
      ropaOptions.push(obj);
    } else {
      if (
        (this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES
          .viewModule) ||
        (this.props.PermissionData.permissionsMap &&
            this.props.PermissionData.permissionsMap.DATA_PROCESSOR &&
            this.props.PermissionData.permissionsMap.DATA_PROCESSOR.viewModule)
      ) {
        var obj = {
          key: "LegalMaster",
          label: "sidebar.ManageLegal",
          leftIcon: "Basis-Purpose.png",
          children: [
            {
              key: "legalBasis",
              label: "sidebar.lawfulBasis",
              leftIcon: "Lawful Basis.svg",
            },
            {
              key: "purposeListing",
              label: "sidebar.purposeProcessing",
              leftIcon: "Purpose of Processing.svg",
            },
          ],
        };
        ropaOptions.push(obj);
      }
    }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.REGULATION_MASTER &&
    //   this.props.PermissionData.permissionsMap.REGULATION_MASTER.viewModule
    // ) {
    //   var obj = {
    //     key: "regulationMasterListing",
    //     label: "sidebar.regulationMaster",
    //     leftIcon: "Regulation Master.svg",
    //   };
    //   ropaOptions.push(obj);
    // }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.DATA_CONTACT_MASTER &&
    //   this.props.PermissionData.permissionsMap.DATA_CONTACT_MASTER.viewModule
    // ) {
    //   var obj = {
    //     key: "dataContactListing",
    //     label: "sidebar.dataContactMaster",
    //     leftIcon: "Data Contact Master.svg",
    //   };
    //   ropaOptions.push(obj);
    // }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.LAWFUL_BASIS &&
    //   this.props.PermissionData.permissionsMap.LAWFUL_BASIS.viewModule
    // ) {
    //   var obj = {
    //     key: "legalBasis",
    //     label: "sidebar.lawfulBasis",
    //     leftIcon: "Lawful Basis.svg",
    //   };
    //   ropaOptions.push(obj);
    // }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.DATA_PROCESSOR &&
    //   this.props.PermissionData.permissionsMap.DATA_PROCESSOR.viewModule
    // ) {
    //   var obj = {
    //     key: "dataProcessorListing",
    //     label: "sidebar.dataProcessor",
    //     leftIcon: "Data Processor.svg",
    //   };
    //   ropaOptions.push(obj);
    // }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.DATA_PROCESSOR_CATEGORY &&
    //   this.props.PermissionData.permissionsMap.DATA_PROCESSOR_CATEGORY.viewModule
    // ) {
    //   var obj = {
    //     key: "RopaDataProcCatListing",
    //     label: "sidebar.DataProcCat",
    //     leftIcon: "Data Processor Category.svg",
    //   };
    //   ropaOptions.push(obj);
    // }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.DATA_SUB_PROCESSOR &&
    //   this.props.PermissionData.permissionsMap.DATA_SUB_PROCESSOR.viewModule
    // ) {
    //   var obj = {
    //     key: "dataSubProcessorListing",
    //     label: "sidebar.dataSubProcessor",
    //     leftIcon: "Data Sub Processor.svg",
    //   };
    //   ropaOptions.push(obj);
    // }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.DATA_RECIPIENT &&
    //   this.props.PermissionData.permissionsMap.DATA_RECIPIENT.viewModule
    // ) {
    //   var obj = {
    //     key: "dataRecipientListing",
    //     label: "sidebar.dataRecipient",
    //     leftIcon: "Data Recipient.svg",
    //   };
    //   ropaOptions.push(obj);
    // }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.PURPOSE_OF_PROCESSING &&
    //   this.props.PermissionData.permissionsMap.PURPOSE_OF_PROCESSING.viewModule
    // ) {
    //   var obj = {
    //     key: "purposeListing",
    //     label: "sidebar.purposeProcessing",
    //     leftIcon: "Purpose of Processing.svg",
    //   };
    //   ropaOptions.push(obj);
    // }

    //User Option

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.USER_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.USER_MANAGEMENT.viewModule
    ) {
      var obj = {
        key: "userMaster",
        label: "sidebar.userListing",
        leftIcon: "user-filled.png",
      };
      useroptions.push(obj);
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.OWNERSHIP_ROLES &&
      this.props.PermissionData.permissionsMap.OWNERSHIP_ROLES.viewModule
    ) {
      var obj = {
        key: "roleMaster",
        label: "sidebar.contactRole",
        leftIcon: "Ownership-Roles.png",
      };
      useroptions.push(obj);
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ROLE_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.ROLE_MANAGEMENT.viewModule
    ) {
      var obj = {
        key: "roleManagementListing",
        label: "sidebar.ContactRole",
        leftIcon: "Users-Roles-Permissions.png",
      };
      useroptions.push(obj);
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.USER_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.USER_MANAGEMENT.viewModule
    ) {
      var obj = {
        key: "designationListing",
        label: "sidebar.designationMgmt",
        leftIcon: "Company-Designations.png",
      };
      useroptions.push(obj);
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ORG_STRUCTURE &&
      this.props.PermissionData.permissionsMap.ORG_STRUCTURE.viewModule
    ) {
      var obj = {
        key: "OrganisationMaster",
        label: "sidebar.organisationMaster",
        leftIcon: "Org-Structure.png",
      };
      useroptions.push(obj);
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.RISK_MATRIX_SETUP &&
      this.props.PermissionData.permissionsMap.RISK_MATRIX_SETUP.viewModule
    ) {
      var obj = {
        key: "riskMaster",
        label: "sidebar.RiskLevelMaster",
        leftIcon: "Risk-Setup.png",
      };
      useroptions.push(obj);
    }
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.RISK_MATRIX_SETUP &&
      this.props.PermissionData.permissionsMap.RISK_MATRIX_SETUP.viewModule
    ) {
      var obj = {
        key: "templateFieldMaster",
        label: "sidebar.tempField",
        leftIcon: "Risk-Setup.png",
      };
      useroptions.push(obj);
    }

    //Regulation Master

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES
        .viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER.viewModule
    ) {
      var obj = {
        key: "hostingProviderMasterParent",
        label: "sidebar.RegulationMenu",
        leftIcon: "Regulatory-Landscape.png",
        children: [
          {
            key: "regulationMasterListing",
            label: "sidebar.regulationMaster",
            leftIcon: "Regulation Master.svg",
          },
        ],
      };
      useroptions.push(obj);
    } else {
      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES
          .viewModule
      ) {
        var obj = {
          key: "hostingProviderMasterParent",
          label: "sidebar.RegulationMenu",
          leftIcon: "Regulatory-Landscape.png",
          children: [
            {
              key: "regulationMasterListing",
              label: "sidebar.regulationMaster",
              leftIcon: "Regulation Master.svg",
            },
          ],
        };
        useroptions.push(obj);
      }
    }
    ///////////////BREACH TEMPLATE END///////////////////////////

    ///////////////BREACH INCIDENT START///////////////////////////

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT
        .viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT
        .addition
    ) {
      var obj = {
        key: "breachIncidentManagement",
        label: "sidebar.breachIncidentManagement",
        leftIcon: "Regulation Master.svg",
        children: [
          // {
          //   key: "addNewBreachIncident",
          //   label: "sidebar.addNewBreachIncident",
          //   leftIcon: "Data Processor Category.svg",
          // },
          {
            key: "breachIncidentRegister",
            label: "sidebar.breachIncidentRegister",
            leftIcon: "Breach Incident Management.svg",
          },
        ],
      };
      breachOptions.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT
        .viewModule
    ) {
      var obj = {
        key: "breachIncidentManagement",
        label: "sidebar.breachIncidentManagement",
        leftIcon: "Breach Incident Management.svg",
        children: [
          {
            key: "breachIncidentRegister",
            label: "sidebar.breachIncidentRegister",
            leftIcon: "Breach Incident Management.svg",
          },
        ],
      };

      breachOptions.push(obj);
    } else {
      var AcessControl = {
        key: "accessControl",
        label: "sidebar.breachIncidentRegister",
        leftIcon: "Breach Incident Management.svg",
      };
      breachOptions.push(AcessControl);
    }
    // else if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT &&
    //   this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT.addition
    // ) {
    //   var obj = {
    //     key: "breachIncidentManagement",
    //     label: "sidebar.breachIncidentManagement",
    //     leftIcon: "Regulation Master.svg",
    //     children: [
    //       {
    //         key: "addNewBreachIncident",
    //         label: "sidebar.addNewBreachIncident",
    //         leftIcon: "Data Processor Category.svg",
    //       },
    //     ],
    //   };
    //   breachOptions.push(obj);
    // }

    ///////////////BREACH INCIDENT END///////////////////////////

    ///////////////BREACH TYPE START///////////////////////////
    /////////BREACH TEMPLATE START////////////////////

    ///RISK MANAGEMENT START
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.RISK_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.RISK_MANAGEMENT.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.RISK_MANAGEMENT &&
      this.props.PermissionData.permissionsMap.RISK_MANAGEMENT.addition
    ) {
      var obj = {
        key: "RiskRegisterListing",
        label: "sidebar.riskManagement",
        leftIcon: "Regulation Master.svg",
        children: [
          {
            key: "RiskRegisterListing",
            label: "sidebar.riskRegister",
            leftIcon: "Data Processor Category.svg",
          },
          {
            key: "ControlRegisterListing",
            label: "sidebar.controlRegister",
            leftIcon: "Data Processor.svg",
          },
          {
            key: "riskAssessment",
            label: "sidebar.assessments",
            leftIcon: "Data Sub Processor.svg",
          },
          {
            key: "engagementsListing",
            label: "sidebar.engagements",
            leftIcon: "Data Recipient.svg",
          },
          {
            key: "contractsListing",
            label: "sidebar.contracts",
            leftIcon: "Data Contact Master.svg",
          },
        ],
      };
      riskOptions.push(obj);
      var obj1 = {
        key: "registers",
        label: "sidebar.registers",
        leftIcon: "Regulation Master.svg",
        children: [
          {
            key: "assetRegisterListing",
            label: "sidebar.assetRegister",
            leftIcon: "Data Processor Category.svg",
          },
          {
            key: "VendorRegisterListing",
            label: "sidebar.allVendors",
            leftIcon: "Data Processor.svg",
          },
        ],
      };

      riskOptions.push(obj1);
    } else {
      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.RISK_MANAGEMENT &&
        this.props.PermissionData.permissionsMap.RISK_MANAGEMENT.viewModule
      ) {
        var obj = {
          key: "riskRegisterListing",
          label: "sidebar.riskManagement",
          leftIcon: "Regulation Master.svg",
          children: [
            {
              key: "riskRegisterListing",
              label: "sidebar.riskRegister",
              leftIcon: "Data Processor Category.svg",
            },
            {
              key: "ControlRegisterListing",
              label: "sidebar.controlRegister",
              leftIcon: "Data Processor.svg",
            },
            {
              key: "assessmentsListing",
              label: "sidebar.assessments",
              leftIcon: "Data Sub Processor.svg",
            },
            {
              key: "engagementsListing",
              label: "sidebar.engagements",
              leftIcon: "Data Recipient.svg",
            },
            {
              key: "contractsListing",
              label: "sidebar.contracts",
              leftIcon: "Data Contact Master.svg",
            },
          ],
        };

        riskOptions.push(obj);

        var obj1 = {
          key: "registers",
          label: "sidebar.registers",
          leftIcon: "Regulation Master.svg",
          children: [
            {
              key: "assetRegisterListing",
              label: "sidebar.assetRegister",
              leftIcon: "Data Processor Category.svg",
            },
            {
              key: "VendorRegisterListing",
              label: "sidebar.allVendors",
              leftIcon: "Data Processor.svg",
            },
          ],
        };

        //riskOptions.push(obj);
        riskOptions.push(obj1);
      } else {
        var AcessControl = {
          key: "accessControl",
          label: "sidebar.breachIncidentRegister",
          leftIcon: "Breach Incident Management.svg",
        };
        riskOptions.push(AcessControl);
      }
    }

    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.PROCESS_REGISTER &&
    //   this.props.PermissionData.permissionsMap.PROCESS_REGISTER.viewModule &&
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.ASSESSMENT &&
    //   this.props.PermissionData.permissionsMap.ASSESSMENT.viewModule
    // ) {
    //   var obj = {
    //     key: "riskRegisterManagement",
    //     label: "sidebar.riskManagement",
    //     leftIcon: "Regulation Master.svg",
    //     children: [
    //       {
    //         key: "riskRegister",
    //         label: "sidebar.riskRegister",
    //         leftIcon: "Data Processor Category.svg",
    //       },
    //       {
    //         key: "ControlRegisterListing",
    //         label: "sidebar.controlRegister",
    //         leftIcon: "Data Processor.svg",
    //       },
    //       {
    //         key: "assessmentsListing",
    //         label: "sidebar.assessments",
    //         leftIcon: "Data Sub Processor.svg",
    //       },
    //       {
    //         key: "engagementsListing",
    //         label: "sidebar.engagements",
    //         leftIcon: "Data Recipient.svg",
    //       },
    //       {
    //         key: "contractsListing",
    //         label: "sidebar.contracts",
    //         leftIcon: "Data Contact Master.svg",
    //       },
    //     ],
    //   };
    //   riskOptions.push(obj);
    // } else {
    //   if (
    //     this.props.PermissionData.permissionsMap &&
    //
    //     this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES &&
    //     this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES.viewModule
    //   ) {
    //     var obj = {
    //       key: "riskRegisterManagement",
    //       label: "sidebar.riskManagement",
    //       leftIcon: "Regulation Master.svg",
    //       children: [
    //         {
    //           key: "riskRegister",
    //           label: "sidebar.riskRegister",
    //           leftIcon: "Data Processor Category.svg",
    //         },
    //         {
    //           key: "ControlRegisterListing",
    //           label: "sidebar.controlRegister",
    //           leftIcon: "Data Processor.svg",
    //         },
    //         {
    //           key: "assessmentsListing",
    //           label: "sidebar.assessments",
    //           leftIcon: "Data Sub Processor.svg",
    //         },
    //         {
    //           key: "engagementsListing",
    //           label: "sidebar.engagements",
    //           leftIcon: "Data Recipient.svg",
    //         },
    //         {
    //           key: "contractsListing",
    //           label: "sidebar.contracts",
    //           leftIcon: "Data Contact Master.svg",
    //         },
    //       ],
    //     };
    //     riskOptions.push(obj);
    //   }
    // }

    //REGISTERS
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.PROCESS_REGISTER &&
      this.props.PermissionData.permissionsMap.PROCESS_REGISTER.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_RISK &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_RISK.viewModule
    ) {
      // var obj = {
      //   key: "registers",
      //   label: "sidebar.registers",
      //   leftIcon: "Regulation Master.svg",
      //   children: [
      //     {
      //       key: "assetRegisterListing",
      //       label: "sidebar.assetRegister",
      //       leftIcon: "Data Processor Category.svg",
      //     },
      //     {
      //       key: "vendorRegisterListing",
      //       label: "sidebar.vendorRegister",
      //       leftIcon: "Data Processor.svg",
      //     },
      //   ],
      // };
      var obj1 = {
        key: "libraries",
        label: "sidebar.libraries",
        leftIcon: "Regulation Master.svg",
        children: [
          {
            key: "riskLibraryListing",
            label: "sidebar.ris",
            leftIcon: "Data Processor Category.svg",
          },
          {
            key: "ControlLibraryListing",
            label: "sidebar.control",
            leftIcon: "Data Processor.svg",
          },
          {
            key: "ThreatListing",
            label: "sidebar.thr",
            leftIcon: "Breach Type.svg",
          },
          {
            key: "vulnerabilityListing",
            label: "sidebar.vulnerability",
            leftIcon: "Data Processor.svg",
          },
        ],
      };
      var obj2 = {
        key: "Configuration",
        label: "sidebar.Conf",
        leftIcon: "Regulation Master.svg",
        children: [
          {
            key: "RiskCategoriesListing",
            label: "sidebar.riskCategories",
            leftIcon: "Data Processor Category.svg",
          },
          {
            key: "ControlCategoriesListing",
            label: "sidebar.controlCategories",
            leftIcon: "Data Processor.svg",
          },
          {
            key: "ControlFrameworkListing",
            label: "sidebar.controlFramework",
            leftIcon: "Breach Type.svg",
          },
          {
            key: "riskTempAssessment",
            label: "sidebar.templates",
            leftIcon: "Data Processor.svg",
          },
          {
            key: "RiskWorkflowListing",
            label: "sidebar.workflow",
            leftIcon: "Data Processor.svg",
          },
        ],
      };
      // riskOptions.push(obj);
      // riskOptionsNew.push(obj);
      riskOptionsNew.push(obj1);
      riskOptionsNew.push(obj2);
    } else {
      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.RISK_REGISTER &&
        this.props.PermissionData.permissionsMap.RISK_REGISTER.viewModule
      ) {
        // var obj = {
        //   key: "registers",
        //   label: "sidebar.registers",
        //   leftIcon: "Regulation Master.svg",
        //   children: [
        //     {
        //       key: "assetRegisterListing",
        //       label: "sidebar.assetRegister",
        //       leftIcon: "Data Processor Category.svg",
        //     },
        //     {
        //       key: "vendorRegisterListing",
        //       label: "sidebar.vendorRegister",
        //       leftIcon: "Data Processor.svg",
        //     },
        //   ],
        // };
        var obj1 = {
          key: "libraries",
          label: "sidebar.libraries",
          leftIcon: "Regulation Master.svg",
          children: [
            {
              key: "riskLibraryListing",
              label: "sidebar.ris",
              leftIcon: "Data Processor Category.svg",
            },
            {
              key: "ControlLibraryListing",
              label: "sidebar.control",
              leftIcon: "Data Processor.svg",
            },
            {
              key: "ThreatListing",
              label: "sidebar.thr",
              leftIcon: "Breach Type.svg",
            },
            {
              key: "vulnerabilityListing",
              label: "sidebar.vulnerability",
              leftIcon: "Data Processor.svg",
            },
          ],
        };
        var obj2 = {
          key: "Configuration",
          label: "sidebar.Conf",
          leftIcon: "Regulation Master.svg",
          children: [
            {
              key: "RiskCategoriesListing",
              label: "sidebar.riskCategories",
              leftIcon: "Data Processor Category.svg",
            },
            {
              key: "ControlCategoriesListing",
              label: "sidebar.controlCategories",
              leftIcon: "Data Processor.svg",
            },
            {
              key: "ControlFrameworkListing",
              label: "sidebar.controlFramework",
              leftIcon: "Breach Type.svg",
            },
            {
              key: "TemplatesListing",
              label: "sidebar.templates",
              leftIcon: "Data Processor.svg",
            },
            {
              key: "RiskWorkflowListing",
              label: "sidebar.workflow",
              leftIcon: "Data Processor.svg",
            },
          ],
        };
        // riskOptions.push(obj);
        // riskOptionsNew.push(obj);
        riskOptionsNew.push(obj1);
        riskOptionsNew.push(obj2);
      }
    }

    //  if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT &&
    //   this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT.viewModule &&
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT &&
    //   this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT.addition
    // ) {
    //   var obj = {
    //     key: "riskRegisterManagement",
    //     label: "sidebar.riskManagement",
    //     leftIcon: "Regulation Master.svg",
    //     children: [
    //       // {
    //       //   key: "addNewBreachIncident",
    //       //   label: "sidebar.addNewBreachIncident",
    //       //   leftIcon: "Data Processor Category.svg",
    //       // },
    //       {
    //         key: "riskRegisterManagement",
    //         label: "sidebar.riskRegisterManagement",
    //         leftIcon: "Breach Incident Management.svg",
    //       },
    //     ],
    //   };
    //   riskOptions.push(obj);
    // } else if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT &&
    //   this.props.PermissionData.permissionsMap.BREACH_INCIDENT_MANAGEMENT.viewModule
    // ) {
    //   var obj = {
    //     key: "riskRegisterManagement",
    //     label: "sidebar.riskManagement",
    //     leftIcon: "Breach Incident Management.svg",
    //     children: [
    //       {
    //         key: "riskRegisterManagement",
    //         label: "sidebar.riskRegisterManagement",
    //         leftIcon: "Breach Incident Management.svg",
    //       },
    //     ],
    //   };

    //   riskOptions.push(obj);
    // }else{
    //   var AcessControl = {
    //     key: "accessControl",
    //     label: "sidebar.riskRegister",
    //     leftIcon: "Breach Incident Management.svg",
    //   };
    //   riskOptions.push(AcessControl);
    // }
    ////RISK MANAGEMENT ENd
    ///RISK START
    // if (
    //   this.props.PermissionData.permissionsMap &&
    //
    //   this.props.PermissionData.permissionsMap.BREACH_TYPE &&
    //   this.props.PermissionData.permissionsMap.BREACH_TYPE.viewModule
    // ) {
    //   var obj = {
    //     key: "ThreatListing",
    //     label: "sidebar.thr",
    //     leftIcon: "Breach Type.svg",
    //     //  children: [],
    //   };

    //   riskOptionsNew.push(obj);
    // }
    ///////////////RISK TYPE END///////////////////////////

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER_BREACH &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER_BREACH
        .viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER_BREACH &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER_BREACH.addition
    ) {
      var obj = {
        key: "templateManager",
        label: "sidebar.templateManager",
        leftIcon: "Regulation Master.svg",
        children: [
          // {
          //   key: "BreachTemplateManager",
          //   label: "sidebar.addBreachTemplates",
          //   leftIcon: "Data Processor Category.svg",
          // },
          {
            key: "breachTemplateManagerListing",
            label: "sidebar.templateListing",
            leftIcon: "Breach Templates.svg",
          },
        ],
      };
      breachOptionsNew.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER_BREACH &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER_BREACH
        .viewModule
    ) {
      var obj = {
        key: "templateManager",
        label: "sidebar.templateManager",
        leftIcon: "Regulation Master.svg",
        children: [
          {
            key: "breachTemplateManagerListing",
            label: "sidebar.templateListing",
            leftIcon: "Data Processor.svg",
          },
        ],
      };
      breachOptionsNew.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER_BREACH &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER_BREACH.addition
    ) {
      var obj = {
        key: "templateManager",
        label: "sidebar.templateManager",
        leftIcon: "Regulation Master.svg",
        children: [
          {
            key: "BreachTemplateManager",
            label: "sidebar.addBreachTemplates",
            leftIcon: "Data Processor Category.svg",
          },
        ],
      };
      breachOptionsNew.push(obj);
    }
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.BREACH_TYPE &&
      this.props.PermissionData.permissionsMap.BREACH_TYPE.viewModule
    ) {
      var obj = {
        key: "BreachTypeListing",
        label: "sidebar.breachType",
        leftIcon: "Breach Type.svg",
        //  children: [],
      };
      var obj1 = {
        key: "BreachSetup",
        label: "sidebar.setup",
        leftIcon: "Setup.svg",
        //  children: [],
      };

      var obj2 = {
        key: "BreachSourceListing",
        label: "sidebar.breachSource",
        leftIcon: "Breach Type.svg",
        //  children: [],
      };

      breachOptionsNew.push(obj);
      breachOptionsNew.push(obj1);
      breachOptionsNew.push(obj2);
    }
    ///////////////BREACH TYPE END///////////////////////////

    /////////ASSESSMENT TEMPLATE START//////////////////////

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER.addition
    ) {
      var obj = {
        key: "templateManagerListingParent",
        label: "sidebar.templateManager",
        leftIcon: "Assessment-Template.svg",
        children: [
          {
            key: "templateManagerDraftListing",
            label: "sidebar.draftTemplates",
            leftIcon: "Assessment-Template.svg",
          },
          {
            key: "SurveyTemplatesAwaiting",
            label: "sidebar.addNewTemplate",
            leftIcon: "Assessment-Template.svg",
          },
          {
            key: "templateManagerListing",
            label: "sidebar.publishedTemplates",
            leftIcon: "Assessment-Template.svg",
          },
        ],
      };
      surveyOptions.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER.viewModule
    ) {
      var obj = {
        key: "templateManagerListingParent",
        label: "sidebar.templateManager",
        leftIcon: "Assessment-Template.svg",
        children: [
          {
            key: "templateManagerListing",
            label: "sidebar.publishedTemplates",
            leftIcon: "Assessment-Template.svg",
          },
          {
            key: "templateManagerDraftListing",
            label: "sidebar.draftTemplates",
            leftIcon: "Assessment-Template.svg",
          },
        ],
      };
      surveyOptions.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER &&
      this.props.PermissionData.permissionsMap.TEMPLATE_MANAGER.addition
    ) {
      var obj = {
        key: "templateManagerListingParent",
        label: "sidebar.templateManager",
        leftIcon: "Assessment-Template.svg",
        children: [
          {
            key: "SurveyTemplateManager",
            label: "sidebar.addNewTemplate",
            leftIcon: "Assessment-Template.svg",
          },
        ],
      };
      surveyOptions.push(obj);
    }

    /////////ASSESSMENT BUILDER START//////////////////////

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_BUILDER &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_BUILDER.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_BUILDER &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_BUILDER.addition
    ) {
      var obj = {
        key: "SurveyListingParent",
        label: "sidebar.surveyMaster",
        leftIcon: "Assessment-Template.svg",
        children: [
          {
            key: "SurveyDraftListing",
            label: "sidebar.draftAssessments",
            leftIcon: "Assessment-Template.svg",
          },
          {
            key: "SurveyAssessmentAwatingListing",
            label: "sidebar.assessmentAwaiting",
            leftIcon: "Assessment-Template.svg",
          },
          {
            key: "SurveyListing",
            label: "sidebar.publishedAssess",
            leftIcon: "Assessment-Template.svg",
          },
          {
            key: "SurveyListingCompleted",
            label: "sidebar.completedAssess",
            leftIcon: "Assessment-Template.svg",
          },
        ],
      };
      surveyOptions.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_BUILDER &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_BUILDER.viewModule
    ) {
      var obj = {
        key: "SurveyListingParent",
        label: "sidebar.surveyMaster",
        leftIcon: "Assessment-Template.svg",
        children: [
          {
            key: "SurveyDraftListing",
            label: "sidebar.draftAssessments",
            leftIcon: "Assessment-Template.svg",
          },
        ],
      };
      surveyOptions.push(obj);
    } else if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_BUILDER &&
      this.props.PermissionData.permissionsMap.ASSESSMENT_BUILDER.addition
    ) {
      var obj = {
        key: "SurveyListingParent",
        label: "sidebar.surveyMaster",
        leftIcon: "Assessment-Template.svg",
        children: [
          {
            key: "presurvey",
            label: "sidebar.preaddNewSurvey",
            leftIcon: "Assessment-Template.svg",
          },
        ],
      };
      surveyOptions.push(obj);
    } else {
      var AcessControl = {
        key: "accessControl",
        label: "sidebar.publishedAssess",
        leftIcon: "Assessment-Template.svg",
      };
      surveyOptions.push(AcessControl);
    }

    /////////ASSESSMENTS START//////////////////////

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSESSMENTS &&
      this.props.PermissionData.permissionsMap.ASSESSMENTS.viewModule
    ) {
      var obj = {
        key: "AssessmentListingParent",
        label: "sidebar.Review",
        leftIcon: "Assessment.svg",
        children: [
          {
            key: "ResponsesListing",
            label: "sidebar.myResponses",
            leftIcon: "Assessment-Template.svg",
          },
          // {
          //   key: "AssessmentReviewListing",
          //   label: "sidebar.myReviewAssessments",
          //   leftIcon: "Assessment-Template.svg",
          // },
          // {
          //   key: "AssessmentPastReviewListing",
          //   label: "sidebar.pastReviewsAssessments",
          //   leftIcon: "Assessment-Template.svg",
          // },
        ],
      };
      surveyOptions.push(obj);
    }

    //ASSET

    //Asset Type

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.ASSET_TYPES &&
      this.props.PermissionData.permissionsMap.ASSET_TYPES.viewModule
    ) {
      var obj = {
        key: "assetTypeMaster",
        label: "sidebar.AssetType",
        leftIcon: "Asset-Types.png",
      };
      options2.push(obj);
    }

    //Hosting Providers

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES
        .viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER &&
      this.props.PermissionData.permissionsMap.HOSTING_PROVIDER.viewModule
    ) {
      var obj = {
        key: "hostingProviderMasterParent",
        label: "sidebar.HostingProvider",
        leftIcon: "Hosting-Providers.png",
        children: [
          {
            key: "hostingTypeMaster",
            label: "sidebar.HostingProviderType",
            // label: "sidebar.HostingType",
            leftIcon: "stack.png",
          },
          {
            key: "hostingProviderMaster",
            label: "sidebar.HostingProviderMaster",
            leftIcon: "stack.png",
          },
        ],
      };
      options2.push(obj);
    } else {
      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER_TYPES
          .viewModule
      ) {
        var obj = {
          key: "hostingProviderMasterParent",
          label: "sidebar.HostingProvider",
          leftIcon: "hosting (1).png",
          children: [
            {
              key: "hostingTypeMaster",
              label: "sidebar.HostingProviderType",
              // label: "sidebar.HostingType",
              leftIcon: "stack.png",
            },
          ],
        };
        options2.push(obj);
      }

      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER &&
        this.props.PermissionData.permissionsMap.HOSTING_PROVIDER.viewModule
      ) {
        var obj = {
          key: "hostingProviderMasterParent",
          label: "sidebar.HostingProvider",
          leftIcon: "hosting (1).png",
          children: [
            {
              key: "hostingProviderMaster",
              label: "sidebar.HostingProviderMaster",
              leftIcon: "stack.png",
            },
          ],
        };
        options2.push(obj);
      }
    }

    //Data Mapping
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.DATA_SUBJECTS_GROUPS &&
      this.props.PermissionData.permissionsMap.DATA_SUBJECTS_GROUPS
        .viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.DATA_TYPES &&
      this.props.PermissionData.permissionsMap.DATA_TYPES.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.DATA_ELEMENTS &&
      this.props.PermissionData.permissionsMap.DATA_ELEMENTS.viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.DATA_SUBJECTS_VOLUME_RANGE &&
      this.props.PermissionData.permissionsMap.DATA_SUBJECTS_VOLUME_RANGE
        .viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.DATA_DISPOSAL_METHODS &&
      this.props.PermissionData.permissionsMap.DATA_DISPOSAL_METHODS.viewModule
    ) {
      var obj = {
        key: "dataSubjectParent",
        label: "sidebar.DataSubjects",
        leftIcon: "Data-Mapping.png",
        children: [
          {
            key: "dataSubjectMaster",
            label: "sidebar.DataSubject",
            leftIcon: "stack.png",
          },
          {
            key: "dataSubjectCategories",
            label: "sidebar.DataSubjectsCategories",
            leftIcon: "stack.png",
          },
          {
            key: "dataSubjectElements",
            label: "sidebar.DataSubjectElement",
            leftIcon: "stack.png",
          },
          {
            key: "dataSubjectVolRange",
            label: "sidebar.DataSubjectsVolRange",
            leftIcon: "stack.png",
          },
          {
            key: "dataDisposalMaster",
            label: "sidebar.DataDisposal",
            leftIcon: "tiny-paper-bin.png",
          },
        ],
      };
      options2.push(obj);
    } else {
      if (
        (this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_SUBJECTS_GROUPS &&
          this.props.PermissionData.permissionsMap.DATA_SUBJECTS_GROUPS
            .viewModule) ||
        (this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_TYPES &&
          this.props.PermissionData.permissionsMap.DATA_TYPES.viewModule) ||
        (this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_ELEMENTS &&
          this.props.PermissionData.permissionsMap.DATA_ELEMENTS.viewModule) ||
        (this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_SUBJECTS_VOLUME_RANGE &&
          this.props.PermissionData.permissionsMap.DATA_SUBJECTS_VOLUME_RANGE
            .viewModule) ||
        (this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_DISPOSAL_METHODS &&
          this.props.PermissionData.permissionsMap.DATA_DISPOSAL_METHODS
            .viewModule)
      ) {
        var obj = {
          key: "dataSubjectParent",
          label: "sidebar.DataSubjects",
          leftIcon: "stack.png",
          children: [],
        };

        if (
          this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_SUBJECTS_GROUPS &&
          this.props.PermissionData.permissionsMap.DATA_SUBJECTS_GROUPS
            .viewModule
        ) {
          obj.children.push({
            key: "dataSubjectMaster",
            label: "sidebar.DataSubject",
            leftIcon: "stack.png",
          });
        }
        if (
          this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_TYPES &&
          this.props.PermissionData.permissionsMap.DATA_TYPES.viewModule
        ) {
          obj.children.push({
            key: "dataSubjectCategories",
            label: "sidebar.DataSubjectsCategories",
            leftIcon: "stack.png",
          });
        }
        if (
          this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_ELEMENTS &&
          this.props.PermissionData.permissionsMap.DATA_ELEMENTS.viewModule
        ) {
          obj.children.push({
            key: "dataSubjectVolRange",
            label: "sidebar.DataSubjectsVolRange",
            leftIcon: "stack.png",
          });
        }
        if (
          this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_SUBJECTS_VOLUME_RANGE &&
          this.props.PermissionData.permissionsMap.DATA_SUBJECTS_VOLUME_RANGE
            .viewModule
        ) {
          obj.children.push({
            key: "dataSubjectElements",
            label: "sidebar.DataSubjectElement",
            leftIcon: "stack.png",
          });
        }
        if (
          this.props.PermissionData.permissionsMap &&
          this.props.PermissionData.permissionsMap.DATA_DISPOSAL_METHODS &&
          this.props.PermissionData.permissionsMap.DATA_DISPOSAL_METHODS
            .viewModule
        ) {
          obj.children.push({
            key: "dataDisposalMaster",
            label: "sidebar.DataDisposal",
            leftIcon: "tiny-paper-bin.png",
          });
        }
        options2.push(obj);
      }
    }

    //Security Measures

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.SECURITY_MEASURE_TYPES &&
      this.props.PermissionData.permissionsMap.SECURITY_MEASURE_TYPES
        .viewModule &&
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.SECURITY_MEASURE &&
      this.props.PermissionData.permissionsMap.SECURITY_MEASURE.viewModule
    ) {
      var obj = {
        key: "securityMeasureMasterParent",
        label: "sidebar.SecurityMeasure",
        leftIcon: "Security-Measures.png",
        children: [
          {
            key: "securityTypeMaster",
            label: "sidebar.securityTypeMaster",
            leftIcon: "stack.png",
          },
          {
            key: "securityMeasureMaster",
            label: "sidebar.securityMeasureMaster",
            leftIcon: "stack.png",
          },
        ],
      };
      options2.push(obj);
    } else {
      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.SECURITY_MEASURE_TYPES &&
        this.props.PermissionData.permissionsMap.SECURITY_MEASURE_TYPES
          .viewModule
      ) {
        var obj = {
          key: "securityMeasureMasterParent",
          label: "sidebar.SecurityMeasure",
          leftIcon: "locked-padlock.png",
          children: [
            {
              key: "securityTypeMaster",
              label: "sidebar.securityTypeMaster",
              leftIcon: "stack.png",
            },
          ],
        };
        options2.push(obj);
      }

      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.SECURITY_MEASURE &&
        this.props.PermissionData.permissionsMap.SECURITY_MEASURE.viewModule
      ) {
        var obj = {
          key: "securityMeasureMasterParent",
          label: "sidebar.SecurityMeasure",
          leftIcon: "locked-padlock.png",
          children: [
            {
              key: "securityMeasureMaster",
              label: "sidebar.securityMeasureMaster",
              leftIcon: "stack.png",
            },
          ],
        };
        options2.push(obj);
      }
    }

    //VENDOR MANAGEMENT
    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.VENDOR_MASTER &&
      this.props.PermissionData.permissionsMap.VENDOR_MASTER.viewModule
    ) {
      vendorOption4.push({
        key: "VendorDashboard",
        label: "sidebar.vendorDashboard",
        leftIcon: "Asset-Types.png",
      });
    } else {
      vendorOption4.push({
        key: "accessControl",
        label: "sidebar.vendorDashboard",
        leftIcon: "Asset-Types.png",
      });
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.VENDOR_MASTER &&
      this.props.PermissionData.permissionsMap.VENDOR_MASTER.viewModule
    ) {
      vendorOption1.push({
        key: "VendorRegisterListing",
        label: "sidebar.allVendors",
        leftIcon: "Asset-Types.png",
      });
    } else {
      vendorOption1.push({
        key: "accessControl",
        label: "sidebar.allVendors",
        leftIcon: "Asset-Types.png",
      });
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.INDUSTRY &&
      this.props.PermissionData.permissionsMap.INDUSTRY.viewModule
    ) {
      vendorOption3.push({
        key: "VendorIndustryListing",
        label: "sidebar.industries",
        leftIcon: "Asset-Types.png",
      });
    } else {
      vendorOption3.push({
        key: "accessControl",
        label: "sidebar.industries",
        leftIcon: "Asset-Types.png",
      });
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.VENDOR_TYPE &&
      this.props.PermissionData.permissionsMap.VENDOR_TYPE.viewModule
    ) {
      vendorOption3.push({
        key: "VendorTypeListing",
        label: "sidebar.vendorTypes",
        leftIcon: "Asset-Types.png",
      });
    } else {
      vendorOption3.push({
        key: "accessControl",
        label: "sidebar.vendorTypes",
        leftIcon: "Asset-Types.png",
      });
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.VENDOR_CATEGORY &&
      this.props.PermissionData.permissionsMap.VENDOR_CATEGORY.viewModule
    ) {
      vendorOption3.push({
        key: "VendorServiceListing",
        label: "sidebar.VendorServices",
        leftIcon: "Asset-Types.png",
      });
    } else {
      vendorOption3.push({
        key: "accessControl",
        label: "sidebar.VendorServices",
        leftIcon: "Asset-Types.png",
      });
    }

    if (
      this.props.PermissionData.permissionsMap &&
      this.props.PermissionData.permissionsMap.VENDOR_DOCUMENT_TYPE &&
      this.props.PermissionData.permissionsMap.VENDOR_DOCUMENT_TYPE.viewModule
    ) {
      vendorOption3.push({
        key: "DocumentTypeListing",
        label: "sidebar.DocumentTypes",
        leftIcon: "Asset-Types.png",
      });
    } else {
      vendorOption3.push({
        key: "accessControl",
        label: "sidebar.DocumentTypes",
        leftIcon: "Asset-Types.png",
      });
    }

    if (
      (this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER.viewModule) ||
      (this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK.viewModule)
    ) {
      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER.viewModule &&
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK.viewModule
      ) {
        vendorOption3.push({
          label: "sidebar.vendorRiskAndTiers",
          leftIcon: "Asset-Types.png",
          children: [
            {
              key: "VendorTierListing",
              label: "sidebar.vendorTier",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "TierScaleListing",
              label: "sidebar.tierScale",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "VendorRiskRatingListing",
              label: "sidebar.riskRating",
              leftIcon: "Asset-Types.png",
            },
          ],
        });
      } else if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER.viewModule
      ) {
        vendorOption3.push({
          label: "sidebar.vendorRiskAndTiers",
          leftIcon: "Asset-Types.png",
          children: [
            {
              key: "VendorTierListing",
              label: "sidebar.vendorTier",
              leftIcon: "Asset-Types.png",
            },
          ],
        });
      } else if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK.viewModule
      ) {
        vendorOption3.push({
          label: "sidebar.vendorRiskAndTiers",
          leftIcon: "Asset-Types.png",
          children: [
            {
              key: "VendorRiskRatingListing",
              label: "sidebar.riskRating",
              leftIcon: "Asset-Types.png",
            },
          ],
        });
      }
      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER.viewModule &&
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK.viewModule
      ) {
        vendorOption2.push({
          label: "sidebar.allTieringAssessment",
          leftIcon: "Asset-Types.png",
          key: "vendorTieringAssessmentOneKey",
          children: [
            {
              key: "VendorDraftListing",
              label: "sidebar.draft-deactivated",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "VendorAllTierListing",
              label: "sidebar.submitted",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "TieringAssessmentAwatingListing",
              label: "tab.underReview",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "TieringAssessmentListingCompleted",
              label: "tab.closed",
              leftIcon: "Asset-Types.png",
            },
          ],
        });
      }
      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER.viewModule &&
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK.viewModule
      ) {
        vendorOption2.push({
          label: "sidebar.tieringTemplate",
          leftIcon: "Asset-Types.png",
          key: "vendorTieringAssessmentTwoKey",
          children: [
            {
              key: "TieringTemplateManagerDraftListing",
              label: "sidebar.draft-deactivated",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "TieringTemplatesAwaiting",
              label: "sidebar.tieringAwaitingApproval",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "TieringTemplateManagerListing",
              label: "sidebar.published",
              leftIcon: "Asset-Types.png",
            },
          ],
        });
      }

      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER.viewModule
      ) {
        var obj = {
          // key: "AssessmentListingParent",
          label: "sidebar.Review",
          leftIcon: "Asset-Types.png",
          key: "vendorTieringAssessmentThreeKey",
          children: [
            {
              key: "TierResponsesListing",
              label: "sidebar.myResponses",
              leftIcon: "Asset-Types.png",
            },
          ],
        };
        vendorOption2.push(obj);
      }

      if (
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_TIER &&
        this.props.PermissionData.permissionsMap &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK &&
        this.props.PermissionData.permissionsMap.VENDOR_RISK.viewModule
      ) {
        vendorAssessmentOption.push({
          label: "sidebar.assessments",
          leftIcon: "Asset-Types.png",
          key: "vendorAssessmentOptionKey",
          children: [
            {
              key: "VendorAssessmentDraftListing",
              label: "sidebar.draft-deactivated",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "VendorAllPublishedListing",
              label: "sidebar.submitted",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "VendorAssessmentAwatingListing",
              label: "tab.underReview",
              leftIcon: "Asset-Types.png",
            },
            {
              key: "VendorAssessmentListingCompleted",
              label: "tab.closed",
              leftIcon: "Asset-Types.png",
            },
          ],
        });
      }
    } else {
      vendorOption3.push({
        key: "accessControl",
      });
    }

    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const customizedTheme = themes[themeConfig.theme];
    const style = { backgroundColor: customizedTheme.backgroundColor };

    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };

    return (
      <Scrollbars className="overflowmain">
        <SidebarWrapper>
          {/* <div className="custom-side" style={styleForSide}> */}
          <Sider
            trigger={null}
            collapsible={true}
            collapsed={collapsed}
            width={200}
            // className="isomorphicSidebar overflowmain "
            className={
              this.props.CompanyMaster.companyData != null &&
              this.props.CompanyMaster.companyData.companyDetails &&
              this.props.CompanyMaster.companyData.companyDetails
                .subscriptionPlansDto.type === 0
                ? "isomorphicSidebar stylingWhitelabel"
                : this.props.CompanyMaster.companyData != null &&
                  this.props.CompanyMaster.companyData.companyDetails &&
                  this.props.CompanyMaster.companyData.companyDetails
                    .subscriptionPlansDto.type === 1
                ? "isomorphicSidebar stylingCo"
                : "isomorphicSidebar stylingCoNew"
            }
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
            style={style}
          >
            <Logo collapsed={collapsed} />
            <Scrollbars>
              {this.props.app.linkActive === "ASSET" ? (
                <div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        <IntlMessages id={"sidebar.AssetMenu"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      overflowedIndicator={<div>Overflow</div>}
                    >
                      {options.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                  <div>
                    <div className="menu-headings config-menu-wrapper">
                      <label>
                        <IntlMessages id={"sidebar.Configuration"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                    >
                      {options2.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                </div>
              ) : this.props.app.linkActive === "ADMINISTRATION" ? (
                <div>
                  <div className="menu-headings">
                    <label>
                      {" "}
                      <IntlMessages id={"sidebar.UserMenu"} />
                    </label>
                  </div>
                  <Menu
                    onClick={this.handleClick}
                    theme="dark"
                    className="isoDashboardMenu"
                    mode={mode}
                    openKeys={collapsed ? [] : app.openKeys}
                    selectedKeys={app.current}
                    onOpenChange={this.onOpenChange}
                  >
                    {useroptions.map((singleOption) =>
                      this.getMenuItem({
                        submenuStyle,
                        submenuColor,
                        singleOption,
                      })
                    )}
                  </Menu>
                </div>
              ) : this.props.app.linkActive === "MANAGE COMPANY" ? (
                <div>
                  <div className="menu-headings">
                    <label>
                      {" "}
                      <IntlMessages id={"sidebar.UserMenu"} />
                    </label>
                  </div>
                  <Menu
                    onClick={this.handleClick}
                    theme="dark"
                    className="isoDashboardMenu"
                    mode={mode}
                    openKeys={collapsed ? [] : app.openKeys}
                    selectedKeys={app.current}
                    onOpenChange={this.onOpenChange}
                  >
                    {useroptions.map((singleOption) =>
                      this.getMenuItem({
                        submenuStyle,
                        submenuColor,
                        singleOption,
                      })
                    )}
                  </Menu>
                </div>
              ) : this.props.app.linkActive === "INCIDENT" ? (
                <div>
                  <div className="menu-headings">
                    <label>
                      {" "}
                      <IntlMessages id={"sidebar.breachIncidentMenu"} />
                    </label>
                  </div>
                  <Menu
                    onClick={this.handleClick}
                    theme="dark"
                    className="isoDashboardMenu"
                    mode={mode}
                    openKeys={collapsed ? [] : app.openKeys}
                    selectedKeys={app.current}
                    onOpenChange={this.onOpenChange}
                  >
                    {breachOptions.map((singleOption) =>
                      this.getMenuItem({
                        submenuStyle,
                        submenuColor,
                        singleOption,
                      })
                    )}
                  </Menu>
                  <div>
                    <div className="menu-headings config-menu-wrapper">
                      <label>
                        <IntlMessages id={"sidebar.Configuration"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                    >
                      {breachOptionsNew.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                </div>
              ) : this.props.app.linkActive === "RISKMANAGEMENT" ? (
                <div>
                  <div className="menu-headings">
                    <label>
                      {" "}
                      <IntlMessages id={"sidebar.riskIncidentMenu"} />
                    </label>
                  </div>
                  <Menu
                    onClick={this.handleClick}
                    theme="dark"
                    className="isoDashboardMenu"
                    mode={mode}
                    activeKey={app.current[0]}
                    openKeys={collapsed ? [] : app.openKeys}
                    selectedKeys={app.current}
                    onOpenChange={this.onOpenChange}
                  >
                    {riskOptions.map((singleOption) =>
                      this.getMenuItem({
                        submenuStyle,
                        submenuColor,
                        singleOption,
                      })
                    )}
                  </Menu>
                  <div>
                    <div className="menu-headings config-menu-wrapper">
                      <label>
                        <IntlMessages id={"sidebar.Configuration"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                    >
                      {riskOptionsNew.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                </div>
              ) : this.props.app.linkActive === "ROPA" ? (
                <div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        {" "}
                        <IntlMessages id={"sidebar.ropaMenu"} />
                      </label>
                    </div>

                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {/* {ropaOptionsNew.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )} */}
                      {ropaOptionsNewProcess.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                  <div>
                    <div className="menu-headings config-menu-wrapper">
                      <label>
                        <IntlMessages id={"sidebar.Configuration"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                    >
                      {ropaOptions.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                </div>
              ) : this.props.app.linkActive === "ASSESSMENT" ? (
                <div>
                  <div className="menu-headings">
                    <label>
                      {" "}
                      <IntlMessages id={"sidebar.assessmentMenu"} />
                    </label>
                  </div>
                  <Menu
                    onClick={this.handleClick}
                    theme="dark"
                    className="isoDashboardMenu"
                    mode={mode}
                    openKeys={collapsed ? [] : app.openKeys}
                    selectedKeys={app.current}
                    onOpenChange={this.onOpenChange}
                    style={{
                      overflow: "initial",
                    }}
                  >
                    {surveyOptions.map((singleOption) =>
                      this.getMenuItem({
                        submenuStyle,
                        submenuColor,
                        singleOption,
                      })
                    )}
                  </Menu>
                </div>
              ) : this.props.app.linkActive === "ASSESSMENT" ? (
                <div>
                  <div className="menu-headings">
                    <label>
                      {" "}
                      <IntlMessages id={"sidebar.assessmentMenu"} />
                    </label>
                  </div>
                  <Menu
                    onClick={this.handleClick}
                    theme="dark"
                    className="isoDashboardMenu"
                    mode={mode}
                    openKeys={collapsed ? [] : app.openKeys}
                    selectedKeys={app.current}
                    onOpenChange={this.onOpenChange}
                    style={{
                      overflow: "initial",
                    }}
                  >
                    {surveyOptions.map((singleOption) =>
                      this.getMenuItem({
                        submenuStyle,
                        submenuColor,
                        singleOption,
                      })
                    )}
                  </Menu>
                </div>
              ) : this.props.app.linkActive === "DATARIGHTS" ? (
                <div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        {" "}
                        <IntlMessages id={"sidebar.RequestMenu"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {requestOptions.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        {" "}
                        <IntlMessages id={"sidebar.Developer"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {requestOptionDevlop.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        {" "}
                        <IntlMessages id={"sidebar.RequestConfiguration"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {requestOptionsNew.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                </div>
              ) : this.props.app.linkActive === "PROFILE" ? (
                ""
              ) : this.props.app.linkActive === "COOKIE" ? (
                <div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        <IntlMessages id={"sidebar.cookieManager"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {cookieOptions.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>

                  <div>
                    <div className="menu-headings">
                      <label>
                        <IntlMessages id={"sidebar.configurations"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {cookieConfig.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                  {/* <div>
                  <div className="menu-headings">
                    <label>
                     Configurations
                    </label>
                  </div>
                  <Menu
                    onClick={this.handleClick}
                    theme="dark"
                    className="isoDashboardMenu"
                    mode={mode}
                    openKeys={collapsed ? [] : app.openKeys}
                    selectedKeys={app.current}
                    onOpenChange={this.onOpenChange}
                    style={{
                     overflow: "initial",
                    }}
                  >
                    {requestOptionsNew.map((singleOption) =>
                      this.getMenuItem({
                        submenuStyle,
                        submenuColor,
                        singleOption,
                      })
                    )}
                  </Menu>
                </div> */}
                </div>
              ) : this.props.app.linkActive === "CONSENT" ? (
                <div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        {" "}
                        <IntlMessages id={"sidebar.ConsentManagement"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {consentOptions1.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        {" "}
                        <IntlMessages id={"sidebar.consentReporting"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {consentOptions2.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                  <div>
                    <div className="menu-headings">
                      <label>
                        {" "}
                        <IntlMessages id={"sidebar.RequestConfiguration"} />
                      </label>
                    </div>
                    <Menu
                      onClick={this.handleClick}
                      theme="dark"
                      className="isoDashboardMenu"
                      mode={mode}
                      openKeys={collapsed ? [] : app.openKeys}
                      selectedKeys={app.current}
                      onOpenChange={this.onOpenChange}
                      style={{
                        overflow: "initial",
                      }}
                    >
                      {consentOptions3.map((singleOption) =>
                        this.getMenuItem({
                          submenuStyle,
                          submenuColor,
                          singleOption,
                        })
                      )}
                    </Menu>
                  </div>
                </div>
              ) : this.props.app.linkActive === "VENDORMANAGEMENT" ? (
                <div>
                  {vendorOption4.length > 0 && (
                    <div>
                      <div className="menu-headings">
                        <label>
                          {" "}
                          <IntlMessages id={"sidebar.vendorManagement"} />
                        </label>
                      </div>
                      <Menu
                        onClick={this.handleClick}
                        theme="dark"
                        className="isoDashboardMenu"
                        mode={mode}
                        openKeys={collapsed ? [] : app.openKeys}
                        selectedKeys={app.current}
                        onOpenChange={this.onOpenChange}
                        style={{
                          overflow: "initial",
                        }}
                      >
                        {vendorOption4.map((singleOption) =>
                          this.getMenuItem({
                            submenuStyle,
                            submenuColor,
                            singleOption,
                          })
                        )}
                      </Menu>
                    </div>
                  )}
                  {vendorOption1.length > 0 && (
                    <div>
                      <div className="menu-headings">
                        <label>
                          {" "}
                          <IntlMessages id={"sidebar.vendorRiskTracking"} />
                        </label>
                      </div>
                      <Menu
                        onClick={this.handleClick}
                        theme="dark"
                        className="isoDashboardMenu"
                        mode={mode}
                        openKeys={collapsed ? [] : app.openKeys}
                        selectedKeys={app.current}
                        onOpenChange={this.onOpenChange}
                        style={{
                          overflow: "initial",
                        }}
                      >
                        {vendorOption1.map((singleOption) =>
                          this.getMenuItem({
                            submenuStyle,
                            submenuColor,
                            singleOption,
                          })
                        )}
                      </Menu>
                    </div>
                  )}
                  {vendorOption2.length > 0 && (
                    <div>
                      <div className="menu-headings">
                        <label>
                          {" "}
                          <IntlMessages id={"sidebar.tieringAssessment"} />
                        </label>
                      </div>
                      <Menu
                        onClick={this.handleClick}
                        theme="dark"
                        className="isoDashboardMenu"
                        mode={mode}
                        openKeys={collapsed ? [] : app.openKeys}
                        selectedKeys={app.current}
                        onOpenChange={this.onOpenChange}
                        style={{
                          overflow: "initial",
                        }}
                      >
                        {vendorOption2.map((singleOption) =>
                          this.getMenuItem({
                            submenuStyle,
                            submenuColor,
                            singleOption,
                          })
                        )}
                      </Menu>
                    </div>
                  )}
                  {vendorAssessmentOption.length > 0 && (
                    <div>
                      <div className="menu-headings">
                        <label>
                          {" "}
                          <IntlMessages id={"sidebar.all"} />{" "}
                          <IntlMessages id={"sidebar.assessments"} />
                        </label>
                      </div>
                      <Menu
                        onClick={this.handleClick}
                        theme="dark"
                        className="isoDashboardMenu"
                        mode={mode}
                        openKeys={collapsed ? [] : app.openKeys}
                        selectedKeys={app.current}
                        onOpenChange={this.onOpenChange}
                        style={{
                          overflow: "initial",
                        }}
                      >
                        {vendorAssessmentOption.map((singleOption) =>
                          this.getMenuItem({
                            submenuStyle,
                            submenuColor,
                            singleOption,
                          })
                        )}
                      </Menu>
                    </div>
                  )}
                  {vendorOption3.length > 0 && (
                    <div>
                      <div className="menu-headings">
                        <label>
                          {" "}
                          <IntlMessages id={"sidebar.Configuration"} />
                        </label>
                      </div>
                      <Menu
                        onClick={this.handleClick}
                        theme="dark"
                        className="isoDashboardMenu"
                        mode={mode}
                        openKeys={collapsed ? [] : app.openKeys}
                        selectedKeys={app.current}
                        onOpenChange={this.onOpenChange}
                        style={{
                          overflow: "initial",
                        }}
                      >
                        {vendorOption3.map((singleOption) =>
                          this.getMenuItem({
                            submenuStyle,
                            submenuColor,
                            singleOption,
                          })
                        )}
                      </Menu>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </Scrollbars>

            {/* <Scrollbars style={{ height: height - 70 }}>
            <label>Configurations</label>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options2.map(singleOption =>
                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}
            </Menu>
          </Scrollbars> */}
            {/* <div className="sidebar-footer">Version 1.1 Powered By Astrika.</div> */}
          </Sider>
          {/* </div> */}
        </SidebarWrapper>
        <div className="powerdeByPreviziDiv">
          Powered By
          <img
            src={require("../../image/dpo-images/Privezi-Logo.png")}
            style={{
              maxHeight: "100%",
              width: "50%",
            }}
          />
        </div>
      </Scrollbars>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App,
    height: state.App.height,
    CompanyMaster: state.CompanyMaster,
    PermissionData: state.Auth.PermissionData,
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
