import {
  getModulesAction,
  getRoleAction,
  saveRoleAction,
  clearDataActions,
} from "./actions";

const initState = {
  Modules: [],
  RoleData: null,
  errorMessage: null,
  SuccessData: null,
  RoleStatus: null,
  RoleErrorMessage: null,
  RoleSuccessMessage: null,
};

export default function Modules(state = initState, { type, payload }) {
  switch (type) {
    case getModulesAction.FETCH_MODULES:
      return {
        ...state,
        errorMessage: null,
      };
    case getModulesAction.FETCH_MODULES_FULFILLED:
      return {
        ...state,
        Modules: payload.data,
        errorMessage: null,
      };
    case getModulesAction.FETCH_MODULES_REJECTED:
      return {
        ...state,
        errorMessage: payload,
        Modules: [],
      };
    case getModulesAction.SET_MODULES_ERROR:
      return {
        errorMessage: payload,
        Modules: [],
      };

    ///////find role by Id//////////////////

    case getRoleAction.FETCH_ROLE:
      return {
        ...state,
        errorMessage: null,
      };
    case getRoleAction.FETCH_ROLE_FULFILLED:
      return {
        ...state,
        RoleData: payload.data,
        errorMessage: null,
      };
    case getRoleAction.FETCH_ROLE_REJECTED:
      return {
        ...state,
        errorMessage: payload,
        RoleData: null,
      };
    case getRoleAction.FETCH_ROLE_ERROR:
      return {
        errorMessage: payload,
        RoleData: null,
      };

    //////save Role/////////////

    case saveRoleAction.SAVE_ROLE:
      return {
        ...state,
        isLoading: true,
        RoleErrorMessage: null,
        modalActive: false,
      };
    case saveRoleAction.SAVE_ROLE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SuccessData: payload.data,
        RoleStatus: payload.status,
        RoleErrorMessage: null,
        RoleSuccessMessage: "Role added successfully!",
      };
    case saveRoleAction.SAVE_ROLE_REJECTED:
      return {
        ...state,
        isLoading: false,
        RoleStatus: null,
        RoleErrorMessage: payload,
      };
    case saveRoleAction.SAVE_ROLE_ERROR:
      return {
        errorMessage: payload,
      };

    //////////

    case clearDataActions.CLEAR_ROLE_DETAILS:
      return {
        ...state,
        isLoading: false,
        RoleData: null,
        errorMessage: null,
        SuccessData: null,
        RoleStatus: null,
        RoleErrorMessage: null,
        RoleSuccessMessage: null,
      };

    default:
      return state;
  }
}
