import {
  cityMasterListActions,
  countryMasterListActions,
  CityByCityIdActions,
  CountryByIDActions,
  continentMasterListActions,
  SubContinentMasterListActions,
  stateMasterListActions,
  PinCodeByCityIDActions
} from "./actions";

const initState = {
  CityMasterList: [],
  CountryMasterList: [],
  SubContinentMasterList: [],
  ContinentMasterStatus: null,
  SubContinentMasterStatus: null,
  TotalCount: 0,
  successMessage: null,
  CityById: null,
  CountryById: [],
  StateMasterList: [],
  PinCodeByCityId: []
};

export default function CityList(state = initState, { type, payload }) {
  switch (type) {
    case cityMasterListActions.FETCH_CITY_MASTER_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case cityMasterListActions.FETCH_CITY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        CityMasterList: payload.data,
        TotalCount: payload.count,
        errorMessage: null
      };
    case cityMasterListActions.FETCH_CITY_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        CityMasterList: []
      };
    case cityMasterListActions.SET_CITY_ERROR:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        CityMasterList: []
      };

    ///////////////CityById///////////////////
    case CityByCityIdActions.FETCH_CITY_NAME:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case CityByCityIdActions.FETCH_CITY_NAME_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        CityById: payload.data,
        TotalCount: payload.count,
        errorMessage: null
      };
    case CityByCityIdActions.FETCH_CITY_NAME_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        CityById: null
      };
    case CityByCityIdActions.SET_CITY_NAME_ERROR:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        CityById: null
      };

    // STATE

    case stateMasterListActions.FETCH_STATE_MASTER_LIST:
      return {
        ...state,
        loading: true,
        isLoading: false,
        StateMasterList: [],
        errorMessage: null
      };
    case stateMasterListActions.FETCH_STATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        StateMasterList: payload.data
      };
    case stateMasterListActions.FETCH_STATE_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        StateMasterList: []
      };
    case stateMasterListActions.SET_STATE_ERROR:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        StateMasterList: []
      };

    ////////////////////////////////

    case countryMasterListActions.FETCH_COUNTRY_MASTER_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case countryMasterListActions.FETCH_COUNTRY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        CountryMasterList: payload.data,
        TotalCount: payload.count,
        errorMessage: null
      };
    case countryMasterListActions.FETCH_COUNTRY_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        CountryMasterList: []
      };
    case countryMasterListActions.SET_COUNTRY_ERROR:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        CountryMasterList: []
      };

    ///////////////COUNTRY BY ID////////////

    case CountryByIDActions.FETCH_COUNTRY_BY_ID:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case CountryByIDActions.FETCH_COUNTRY_BY_ID_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        CountryById: payload.data,
        TotalCount: payload.count,
        errorMessage: null
      };
    case CountryByIDActions.FETCH_COUNTRY_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        CountryById: null
      };
    case CountryByIDActions.SET_COUNTRY_BY_ID_ERROR:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        CountryById: null
      };

    /////////////////////////

    ///////////////PINCODE BY ID////////////

    case PinCodeByCityIDActions.FETCH_PINCODE_BY_CITYID:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case PinCodeByCityIDActions.FETCH_PINCODE_BY_CITYID_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        PinCodeByCityId: payload.data,
        TotalCount: payload.count,
        errorMessage: null
      };
    case PinCodeByCityIDActions.FETCH_PINCODE_BY_CITYID_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        PinCodeByCityId: []
      };
    case PinCodeByCityIDActions.SET_PINCODE_BY_CITYID_ERROR:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        PinCodeByCityId: []
      };

    /////////////////////////
    //////////get all continent

    case continentMasterListActions.FETCH_CONTINENT_MASTER_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case continentMasterListActions.FETCH_CONTINENT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ContinentMasterList: payload.data,
        ContinentMasterStatus: payload.status,
        TotalCount: payload.count,
        errorMessage: null
      };
    case continentMasterListActions.FETCH_CONTINENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        ContinentMasterStatus: null,
        ContinentMasterList: []
      };
    case continentMasterListActions.SET_CONTINENT_ERROR:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        ContinentMasterStatus: null,
        ContinentMasterList: []
      };

    //   subcontinent list
    /////////////////////////
    //////////get all subcontinent

    case SubContinentMasterListActions.FETCH_SUBCONTINENT_MASTER_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case SubContinentMasterListActions.FETCH_SUBCONTINENT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SubContinentMasterList: payload.data,
        SubContinentMasterStatus: payload.status,
        TotalCount: payload.count,
        errorMessage: null
      };
    case SubContinentMasterListActions.FETCH_SUBCONTINENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        SubContinentMasterStatus: null,
        SubContinentMasterList: []
      };
    case SubContinentMasterListActions.SET_CONTINENT_ERROR:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        SubContinentMasterStatus: null,
        SubContinentMasterList: []
      };

    default:
      return state;
  }
}
