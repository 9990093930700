import {
  SaveDataSubElementActions,
  GetDataSubElementDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataSubEleActions
} from "./actions";

const initState = {
  dataSubEleSuccessMessage: null,
  dataSubEleErrorMessage: null,
  dataSubEleStatus: null,
  dataSubEleMaster: [],
  dataSubEleData: null,
  ActiveDataSubEleList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataSubElementActions.SAVE_DATA_SUB_ELE:
      return {
        ...state,
        isLoading: true,
        dataSubEleErrorMessage: null,
        modalActive: false
      };
    case SaveDataSubElementActions.SAVE_DATA_SUB_ELE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubEleStatus: payload.status,
        dataSubEleErrorMessage: null,
        dataSubEleSuccessMessage: "Data Subject Category added successfully!"
      };

    case SaveDataSubElementActions.SAVE_DATA_SUB_ELE_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataSubEleStatus: null,
        dataSubEleErrorMessage: payload
      };
    case SaveDataSubElementActions.SAVE_DATA_SUB_ELE_STATUS:
      return {
        ...state,
        dataSubEleStatus: 0,
        dataSubEleErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataSubElementDetailsActions.FETCH_DATA_SUB_ELE_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataSubEleErrorMessage: null,
        modalActive: false,
        dataSubEleData: null
      };
    case GetDataSubElementDetailsActions.FETCH_DATA_SUB_ELE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubEleData: payload.data,
        dataSubEleDataStatus: payload.status,
        dataSubEleErrorMessage: null
      };
    case GetDataSubElementDetailsActions.FETCH_DATA_SUB_ELE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubEleErrorMessage: payload,
        dataSubEleData: null
      };
    case GetDataSubElementDetailsActions.FETCH_DATA_SUB_ELE_DETAILS_STATUS:
      return {
        ...state,
        dataSubEleData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE:
      return {
        ...state,
        isLoading: true,
        dataSubEleErrorMessage: null,
        modalActive: false,
        ActiveDataSubEleList: null
      };
    case GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataSubEleList: payload.data,
        dataSubEleErrorMessage: null
      };
    case GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubEleErrorMessage: payload,
        ActiveDataSubEleList: null
      };
    case GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_STATUS:
      return {
        ...state,
        ActiveDataSubEleList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataSubEle: null,
        dataSubEleStatus: null,
        dataSubEleSuccessMessage: null,
        GetdataSubEleStatus: null,
        dataSubEleData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
