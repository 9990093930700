import {
  SaveControlCategoryActions,
  GetControlCategoryDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveControlCategoryActions,
} from "./actions";

const initState = {
  controlCatSuccessMessage: null,
  controlErrorMessage: null,
  ControlStatus: null,
  ControlCategoryMaster: [],
  ControlCategoryData: null,
  ActiveControlCatList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveControlCategoryActions.SAVE_CONTROL_CATEGORY:
      return {
        ...state,
        isLoading: true,
        controlErrorMessage: null,
        modalActive: false,
      };
    case SaveControlCategoryActions.SAVE_CONTROL_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ControlStatus: payload.status,
        controlErrorMessage: null,
        controlCatSuccessMessage: "Control Category added successfully!",
      };

    case SaveControlCategoryActions.SAVE_CONTROL_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        ControlStatus: null,
        controlErrorMessage: payload,
      };
    case SaveControlCategoryActions.SAVE_CONTROL_CATEGORY_STATUS:
      return {
        ...state,
        ControlStatus: 0,
        controlErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetControlCategoryDetailsActions.FETCH_CONTROL_CATEGORY:
      return {
        ...state,
        isLoading: true,
        controlErrorMessage: null,
        modalActive: false,
        ControlCategoryData: null,
      };
    case GetControlCategoryDetailsActions.FETCH_CONTROL_CATEGORY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ControlCategoryData: payload.data,
        ControlCategoryDataStatus: payload.status,
        controlErrorMessage: null,
      };
    case GetControlCategoryDetailsActions.FETCH_CONTROL_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        controlErrorMessage: payload,
        ControlCategoryData: null,
      };
    case GetControlCategoryDetailsActions.FETCH_CONTROL_CATEGORY_STATUS:
      return {
        ...state,
        ControlCategoryData: payload.data,
        ControlCategoryDataStatus: payload.status,
      };
    ///Active DATA DISPOSAL
    case GetActiveControlCategoryActions.FETCH_ACTIVE_CONTROL_CATEGORY:
      return {
        ...state,
        isLoading: true,
        controlErrorMessage: null,
        modalActive: false,
        ActiveControlCatList: null,
      };
    case GetActiveControlCategoryActions.FETCH_ACTIVE_CONTROL_CATEGORY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveControlCatList: payload.data,
        controlErrorMessage: null,
      };
    case GetActiveControlCategoryActions.FETCH_ACTIVE_CONTROL_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        controlErrorMessage: payload,
        ActiveControlCatList: null,
      };
    case GetActiveControlCategoryActions.FETCH_ACTIVE_CONTROL_CATEGORY_STATUS:
      return {
        ...state,
        ActiveControlCatList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetControlCategory: null,
        ControlStatus: null,
        controlCatSuccessMessage: null,
        GetControlStatus: null,
        ControlCategoryData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
   case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
       ...state,
        ItineraryImage: payload.data,
       ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
       //error = payload || { message: payload.message };//2nd one is network or server down errors
     return {
       ...state,
       errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
