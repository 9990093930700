import axios from "axios";
import {
  SuperAdmin_Service_URL,
  AssetManagementService_URL,
  RopaManagementService_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Asset

export const saveTemplateActions = {
  SAVE_TEMPLATE: "SAVE_TEMPLATE",
  SAVE_TEMPLATE_STATUS: "SAVE_TEMPLATE_STATUS",
  SAVE_TEMPLATE_SUCCESS: "SAVE_TEMPLATE_SUCCESS",
  SAVE_TEMPLATE_FAILURE: "SAVE_TEMPLATE_FAILURE",
};

export function saveTemplateRequest() {
  return {
    type: saveTemplateActions.SAVE_TEMPLATE,
    isPosting: true,
  };
}

export function saveTemplateSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveTemplateActions.SAVE_TEMPLATE_SUCCESS,
      payload: props,
    });
  };
}

export function saveTemplateFailure(error) {
  return {
    type: saveTemplateActions.SAVE_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function saveTemplateError(error) {
  return {
    type: saveTemplateActions.SAVE_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function saveTemplate(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_20/saveRopaTemplate`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        
        dispatch(saveTemplateRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveTemplateSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveTemplateError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          //     dispatch(saveTemplateError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(saveTemplateError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(saveTemplateError(err));
          //     break;
          //   case 500:
          //     dispatch(saveTemplateError("Server Error"));
          //     break;
          //   default:
          //     dispatch(saveTemplateError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete Asset

export const deleteTemplateActions = {
  DELETE_TEMPLATE: "DELETE_TEMPLATE",
  DELETE_TEMPLATE_STATUS: "DELETE_TEMPLATE_STATUS",
  DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
  DELETE_TEMPLATE_FAILURE: "DELETE_TEMPLATE_FAILURE",
};

export function deleteTemplateRequest() {
  return {
    type: deleteTemplateActions.DELETE_TEMPLATE,
    isPosting: true,
  };
}

export function deleteTemplateSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteTemplateActions.DELETE_TEMPLATE_SUCCESS,
      payload: props,
    });
  };
}

export function deleteTemplateFailure(error) {
  return {
    type: deleteTemplateActions.DELETE_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function deleteTemplateError(error) {
  return {
    type: deleteTemplateActions.DELETE_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function deleteTemplate(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${AssetManagementService_URL}/Apc_State/Amc_20/deleteRopaTemplate?assetTypeId=` +
        params.assetTypeID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteTemplateRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteTemplateSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteTemplateError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteTemplateError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteTemplateError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteTemplateError(err));
              break;
            case 500:
              dispatch(deleteTemplateError("Server Error"));
              break;
            default:
              dispatch(deleteTemplateError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// Save Template Group

export const saveTemplateGroupActions = {
  SAVE_TEMPLATE_GROUP: "SAVE_TEMPLATE_GROUP",
  SAVE_TEMPLATE_GROUP_STATUS: "SAVE_TEMPLATE_GROUP_STATUS",
  SAVE_TEMPLATE_GROUP_SUCCESS: "SAVE_TEMPLATE_GROUP_SUCCESS",
  SAVE_TEMPLATE_GROUP_FAILURE: "SAVE_TEMPLATE_GROUP_FAILURE",
};

export function saveTemplateGroupRequest() {
  return {
    type: saveTemplateGroupActions.SAVE_TEMPLATE_GROUP,
    isPosting: true,
  };
}

export function saveTemplateGroupSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveTemplateGroupActions.SAVE_TEMPLATE_GROUP_SUCCESS,
      payload: props,
    });
  };
}

export function saveTemplateGroupFailure(error) {
  return {
    type: saveTemplateGroupActions.SAVE_TEMPLATE_GROUP_FAILURE,
    payload: error,
  };
}

export function saveTemplateGroupError(error) {
  return {
    type: saveTemplateGroupActions.SAVE_TEMPLATE_GROUP_FAILURE,
    payload: error,
  };
}

export function saveTemplateGroup(props, access_token) {
  
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_20/saveRopaTemplateGroup`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        
        dispatch(saveTemplateGroupRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveTemplateGroupSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveTemplateGroupError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(saveTemplateGroupError(err));

          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(saveTemplateGroupError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(saveTemplateGroupError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(saveTemplateGroupError(err));
          //     break;
          //   case 500:
          //     dispatch(saveTemplateGroupError("Server Error"));
          //     break;
          //   default:
          //     dispatch(saveTemplateGroupError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}
// get  details by id

///////GET LOCATION BY ID

export const GetTemplateDetailsActions = {
  FETCH_TEMPLATE_DETAILS: "FETCH_TEMPLATE_DETAILS",
  FETCH_TEMPLATE_DETAILS_STATUS: "FETCH_TEMPLATE_DETAILS_STATUS",
  FETCH_TEMPLATE_DETAILS_FULFILLED: "FETCH_TEMPLATE_DETAILS_FULFILLED",
  FETCH_TEMPLATE_DETAILS_REJECTED: "FETCH_TEMPLATE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};
export const GetNewTempListActions = {
  FETCH_NEW_TEMP_LIST: "FETCH_NEW_TEMP_LIST",
  FETCH_NEW_TEMP_LIST_STATUS: "FETCH_NEW_TEMP_LIST_STATUS",
  FETCH_NEW_TEMP_LIST_FULFILLED: "FETCH_NEW_TEMP_LIST_FULFILLED",
  FETCH_NEW_TEMP_LIST_REJECTED: "FETCH_NEW_TEMP_LIST_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export const GetNewTempDataActions = {
  FETCH_NEW_TEMP_DATA: "FETCH_NEW_TEMP_DATA",
  FETCH_NEW_TEMP_DATA_STATUS: "FETCH_NEW_TEMP_DATA_STATUS",
  FETCH_NEW_TEMP_DATA_FULFILLED: "FETCH_NEW_TEMP_DATA_FULFILLED",
  FETCH_NEW_TEMP_DATA_REJECTED: "FETCH_NEW_TEMP_DATA_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};
export function getTemplateDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_20/getRopaTemplate
?templateId=` + params.templateId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getTemplateDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getTemplateDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          //     dispatch(getTemplateDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getTemplateDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getTemplateDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getTemplateDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getTemplateDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getTemplateDetailsRequest() {
  return {
    type: GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS,
    isPosting: true,
  };
}
export function getTemplateDetailsError(error) {
  return {
    type: GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getTemplateDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getTemplateDetailsStatus() {
  return (dispatch) => dispatch(getTemplateDetailsStatusRequest());
}
export function getTemplateDetailsStatusRequest() {
  return {
    type: GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getTemplateDetailsErrorRequest());
}
export function getTemplateDetailsErrorRequest() {
  return {
    type: GetTemplateDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("assetTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveTemplateActions = {
  FETCH_ACTIVE_TEMPLATE: "FETCH_ACTIVE_TEMPLATE",
  FETCH_ACTIVE_TEMPLATE_STATUS: "FETCH_ACTIVE_TEMPLATE_STATUS",
  FETCH_ACTIVE_TEMPLATE_FULFILLED: "FETCH_ACTIVE_TEMPLATE_FULFILLED",
  FETCH_ACTIVE_TEMPLATE_REJECTED: "FETCH_ACTIVE_TEMPLATE_REJECTED",
};

export function getActiveTemplateList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_20/getRopaTemplatesByRegulationId?regulationId=` +
        params.regulationId,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        
        dispatch(getActiveTemplateListRequest());

        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveTemplateListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveTemplateListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          //     dispatch(getActiveTemplateListError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getActiveTemplateListError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getActiveTemplateListError(err));
          //     break;
          //   case 500:
          //     dispatch(getActiveTemplateListError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getActiveTemplateListError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveTemplateListRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE,
    isPosting: true,
  };
}
export function getActiveTemplateListError(error) {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_REJECTED,
    payload: error,
  };
}

export function getActiveTemplateListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveTemplateListStatus() {
  return (dispatch) => dispatch(getActiveTemplateListStatusRequest());
}
export function getActiveTemplateListStatusRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_STATUS,
  };
}

// Get all Template Group Pagination

export const GetTemplateGroupDetailsActions = {
  FETCH_TEMPLATE_GROUP_DETAILS: "FETCH_TEMPLATE_GROUP_DETAILS",
  FETCH_TEMPLATE_GROUP_DETAILS_STATUS: "FETCH_TEMPLATE_GROUP_DETAILS_STATUS",
  FETCH_TEMPLATE_GROUP_DETAILS_FULFILLED:
    "FETCH_TEMPLATE_GROUP_DETAILS_FULFILLED",
  FETCH_TEMPLATE_GROUP_DETAILS_REJECTED:
    "FETCH_TEMPLATE_GROUP_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getTemplateGroupDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_20/findAllTemplateGroupPagination?length=10&sortOrder=asc&sortField=sequence&booleanfield=true&templateId=` +
        params.templateId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getTemplateGroupDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getTemplateGroupDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTemplateGroupDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTemplateGroupDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTemplateGroupDetailsError(err));
              break;
            case 500:
              dispatch(getTemplateGroupDetailsError("Server Error"));
              break;
            default:
              dispatch(getTemplateGroupDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getTemplateGroupDetailsRequest() {
  return {
    type: GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS,
    isPosting: true,
  };
}
export function getTemplateGroupDetailsError(error) {
  return {
    type: GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_REJECTED,
    payload: error,
  };
}

export function getTemplateGroupDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getTemplateGroupDetailsStatus() {
  return (dispatch) => dispatch(getTemplateGroupDetailsStatusRequest());
}
export function getTemplateGroupDetailsStatusRequest() {
  return {
    type: GetTemplateGroupDetailsActions.FETCH_TEMPLATE_GROUP_DETAILS_STATUS,
  };
}

///ACTIVE ASSETS :LIST

export const GetActiveTemplateGroupActions = {
  FETCH_ACTIVE_TEMPLATE_GROUP: "FETCH_ACTIVE_TEMPLATE_GROUP",
  FETCH_ACTIVE_TEMPLATE_GROUP_STATUS: "FETCH_ACTIVE_TEMPLATE_GROUP_STATUS",
  FETCH_ACTIVE_TEMPLATE_GROUP_FULFILLED:
    "FETCH_ACTIVE_TEMPLATE_GROUP_FULFILLED",
  FETCH_ACTIVE_TEMPLATE_GROUP_REJECTED: "FETCH_ACTIVE_TEMPLATE_GROUP_REJECTED",
};

export function getActiveTemplateGroupList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_20/getRopaTemplateGroup?groupId=` +
        params.groupId,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        
        dispatch(getActiveTemplateGroupListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveTemplateGroupListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveTemplateGroupListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveTemplateGroupListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveTemplateGroupListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveTemplateGroupListError(err));
              break;
            case 500:
              dispatch(getActiveTemplateGroupListError("Server Error"));
              break;
            default:
              dispatch(getActiveTemplateGroupListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveTemplateGroupListRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE,
    isPosting: true,
  };
}
export function getActiveTemplateGroupListError(error) {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_REJECTED,
    payload: error,
  };
}

export function getActiveTemplateGroupListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveTemplateGroupListStatus() {
  return (dispatch) => dispatch(getActiveTemplateGroupListStatusRequest());
}
export function getActiveTemplateGroupListStatusRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_STATUS,
  };
}

// Save Asset

export const saveTemplateFieldActions = {
  SAVE_TEMPLATE_FIELD: "SAVE_TEMPLATE_FIELD",
  SAVE_TEMPLATE_FIELD_STATUS: "SAVE_TEMPLATE_FIELD_STATUS",
  SAVE_TEMPLATE_FIELD_FULLFILLED: "SAVE_TEMPLATE_FIELD_FULLFILLED",
  SAVE_TEMPLATE_FIELD_FAILURE: "SAVE_TEMPLATE_FIELD_FAILURE",
};

export function saveTemplateFieldRequest() {
  return {
    type: saveTemplateFieldActions.SAVE_TEMPLATE_FIELD,
    isPosting: true,
  };
}

export function saveTemplateFieldSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveTemplateFieldActions.SAVE_TEMPLATE_FIELD_FULLFILLED,
      payload: props,
    });
  };
}

export function saveTemplateFieldFailure(error) {
  return {
    type: saveTemplateFieldActions.SAVE_TEMPLATE_FIELD_FAILURE,
    payload: error,
  };
}

export function saveTemplateFieldError(error) {
  return {
    type: saveTemplateFieldActions.SAVE_TEMPLATE_FIELD_FAILURE,
    payload: error,
  };
}

export function saveTemplateField(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_20/saveRopaTemplateFields`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        
        dispatch(saveTemplateFieldRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveTemplateFieldSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveTemplateFieldError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveTemplateFieldError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveTemplateFieldError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveTemplateFieldError(err));
              break;
            case 500:
              dispatch(saveTemplateFieldError("Server Error"));
              break;
            default:
              dispatch(saveTemplateFieldError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Get all Template Group Pagination

export const GetTemplateFieldDetailsActions = {
  FETCH_TEMPLATE_FIELD_DETAILS: "FETCH_TEMPLATE_FIELD_DETAILS",
  FETCH_TEMPLATE_FIELD_DETAILS_STATUS: "FETCH_TEMPLATE_FIELD_DETAILS_STATUS",
  FETCH_TEMPLATE_FIELD_DETAILS_FULFILLED:
    "FETCH_TEMPLATE_FIELD_DETAILS_FULFILLED",
  FETCH_TEMPLATE_FIELD_DETAILS_REJECTED:
    "FETCH_TEMPLATE_FIELD_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getTemplateFieldDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_20/findAllTemplateFieldPagination?length=0&sortOrder=desc&sortField=id&booleanfield=true&templateId=` +
        params.templateId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getTemplateFieldDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getTemplateFieldDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTemplateFieldDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTemplateFieldDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTemplateFieldDetailsError(err));
              break;
            case 500:
              dispatch(getTemplateFieldDetailsError("Server Error"));
              break;
            default:
              dispatch(getTemplateFieldDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getTemplateFieldDetailsRequest() {
  return {
    type: GetTemplateFieldDetailsActions.FETCH_TEMPLATE_FIELD_DETAILS,
    isPosting: true,
  };
}
export function getTemplateFieldDetailsError(error) {
  return {
    type: GetTemplateFieldDetailsActions.FETCH_TEMPLATE_FIELD_DETAILS_REJECTED,
    payload: error,
  };
}

export function getTemplateFieldDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetTemplateFieldDetailsActions.FETCH_TEMPLATE_FIELD_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getTemplateFieldDetailsStatus() {
  return (dispatch) => dispatch(getTemplateFieldDetailsStatusRequest());
}
export function getTemplateFieldDetailsStatusRequest() {
  return {
    type: GetTemplateFieldDetailsActions.FETCH_TEMPLATE_FIELD_DETAILS_STATUS,
  };
}

export const deleteTemplateGroupActions = {
  DELETE_TEMPLATE_GROUP_STATUS: "DELETE_TEMPLATE_GROUP_STATUS",
  DELETE_TEMPLATE_GROUP_SUCCESS: "DELETE_TEMPLATE_GROUP_SUCCESS",
  DELETE_TEMPLATE_GROUP_FAILURE: "DELETE_TEMPLATE_GROUP_FAILURE",
  DELETE_TEMPLATE_GROUP_ERROR: "DELETE_TEMPLATE_GROUP_ERROR",
  // LOGOUT:'LOGOUT'
};

export function deleteTemplateGroupRequest() {
  return {
    type: deleteTemplateGroupActions.DELETE_TEMPLATE_GROUP_STATUS,
    isPosting: true,
  };
}

export function deleteTemplateGroupSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteTemplateGroupActions.DELETE_TEMPLATE_GROUP_SUCCESS,
      payload: props,
    });
  };
}

export function deleteTemplateGroupFailure(error) {
  return {
    type: deleteTemplateGroupActions.DELETE_TEMPLATE_GROUP_FAILURE,
    payload: error,
  };
}

export function deleteTemplateGroup(props) {
  let handler =
    `${RopaManagementService_URL}/Apc_State/Amc_20/deleteRopaTemplateGroup?groupId=` +
    props.groupId +
    `&templateId=` +
    props.templateId;

  return (dispatch) =>
    axios({
      method: "post",
      // data: value,
      url: handler,
      headers: {
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteTemplateGroupRequest());
        if (response.status === 200) {
          dispatch(deleteTemplateGroupSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteTemplateGroupFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteTemplateGroupFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteTemplateGroupFailure(err));
              break;
            case 500:
              dispatch(deleteTemplateGroupFailure("Server Error"));
              break;
            default:
              dispatch(deleteTemplateGroupFailure("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}
//----------------------------------------------------------------------------
// export function getTemplateDetailsRequest() {
//   return {
//     type: GetTemplateDetailsActions.FETCH_TEMPLATE_DETAILS,
//     isPosting: true,
//   };
// }
export function getNewTempListError(error) {
  return {
    type: GetNewTempListActions.FETCH_NEW_TEMP_LIST_REJECTED,
    payload: error,
  };
}

export function getNewTempListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetNewTempListActions.FETCH_NEW_TEMP_LIST_FULFILLED,
      payload: props,
    });
  };
}
export function getNewTempList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_19/getNewTemplateFieldByUserId?userId=` + params.userId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(getNewTempListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getNewTempListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//---

export function getNewTempDataError(error) {
  return {
    type: GetNewTempDataActions.FETCH_NEW_TEMP_DATA_REJECTED,
    payload: error,
  };
}

export function getNewTempDataSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetNewTempDataActions.FETCH_NEW_TEMP_DATA_FULFILLED,
      payload: props,
    });
  };
}
export function getNewTempData(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
      `${RopaManagementService_URL}/Apc_View/Amc_20/getRopaTemplate/templateId/ropaId?templateId=`+ params.templateId +`&ropaId=`+params.ropaId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(getNewTempDataSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getNewTempDataError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveTemplateListActions = {
  FETCH_ACTIVE_TEMPLATE_LIST: "FETCH_ACTIVE_TEMPLATE_LIST",
  FETCH_ACTIVE_TEMPLATE_LIST_STATUS: "FETCH_ACTIVE_TEMPLATE_LIST_STATUS",
  FETCH_ACTIVE_TEMPLATE_LIST_FULFILLED: "FETCH_ACTIVE_TEMPLATE_LIST_FULFILLED",
  FETCH_ACTIVE_TEMPLATE_LIST_REJECTED: "FETCH_ACTIVE_TEMPLATE_LIST_REJECTED",
};

export function getActiveTemplateListList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${RopaManagementService_URL}/Apc_View/Amc_20/findAllTemplatePagination?start=0&length=2000&sortOrder=asc&sortField=name&status=true&search=&searchCol=&access_token=${params.access_token}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getAllActiveTemplateListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllActiveTemplateListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAllActiveTemplateListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllActiveTemplateListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllActiveTemplateListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllActiveTemplateListError(err));
              break;
            case 500:
              dispatch(getAllActiveTemplateListError("Server Error"));
              break;
            default:
              dispatch(getAllActiveTemplateListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllActiveTemplateListRequest() {
  return {
    type: GetActiveTemplateListActions.FETCH_ACTIVE_TEMPLATE_LIST,
    isPosting: true,
  };
}
export function getAllActiveTemplateListError(error) {
  return {
    type: GetActiveTemplateListActions.FETCH_ACTIVE_TEMPLATE_LIST_REJECTED,
    payload: error,
  };
}

export function getAllActiveTemplateListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveTemplateListActions.FETCH_ACTIVE_TEMPLATE_LIST_FULFILLED,
      payload: props,
    });
  };
}

export function getAllActiveTemplateListStatus() {
  return (dispatch) => dispatch(getAllActiveTemplateListStatusRequest());
}
export function getAllActiveTemplateListStatusRequest() {
  return {
    type: GetActiveTemplateListActions.FETCH_ACTIVE_TEMPLATE_LIST_STATUS,
  };
}