import {
  SaveDataTransferActions,
  GetDataTransferDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataTransferActions
} from "./actions";

const initState = {
  dataTransferSuccessMessage: null,
  dataTransferErrorMessage: null,
  dataTransferStatus: null,
  dataTransferMaster: [],
  dataTransferData: null,
  ActiveDataTransferList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataTransferActions.SAVE_DATA_TRANSFER:
      return {
        ...state,
        isLoading: true,
        dataTransferErrorMessage: null,
        modalActive: false
      };
    case SaveDataTransferActions.SAVE_DATA_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataTransferStatus: payload.status,
        dataTransferErrorMessage: null,
        dataTransferSuccessMessage: "Data Subject Category added successfully!"
      };

    case SaveDataTransferActions.SAVE_DATA_TRANSFER_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataTransferStatus: null,
        dataTransferErrorMessage: payload
      };
    case SaveDataTransferActions.SAVE_DATA_TRANSFER_STATUS:
      return {
        ...state,
        dataTransferStatus: 0,
        dataTransferErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataTransferDetailsActions.FETCH_DATA_TRANSFER_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataTransferErrorMessage: null,
        modalActive: false,
        dataTransferData: null
      };
    case GetDataTransferDetailsActions.FETCH_DATA_TRANSFER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataTransferData: payload.data,
        dataTransferDataStatus: payload.status,
        dataTransferErrorMessage: null
      };
    case GetDataTransferDetailsActions.FETCH_DATA_TRANSFER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataTransferErrorMessage: payload,
        dataTransferData: null
      };
    case GetDataTransferDetailsActions.FETCH_DATA_TRANSFER_DETAILS_STATUS:
      return {
        ...state,
        dataTransferData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataTransferActions.FETCH_ACTIVE_DATA_TRANSFER:
      return {
        ...state,
        isLoading: true,
        dataTransferErrorMessage: null,
        modalActive: false,
        ActiveDataTransferList: null
      };
    case GetActiveDataTransferActions.FETCH_ACTIVE_DATA_TRANSFER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataTransferList: payload.data,
        dataTransferErrorMessage: null
      };
    case GetActiveDataTransferActions.FETCH_ACTIVE_DATA_TRANSFER_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataTransferErrorMessage: payload,
        ActiveDataTransferList: null
      };
    case GetActiveDataTransferActions.FETCH_ACTIVE_DATA_TRANSFER_STATUS:
      return {
        ...state,
        ActiveDataTransferList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataTransfer: null,
        dataTransferStatus: null,
        dataTransferSuccessMessage: null,
        GetdataTransferStatus: null,
        dataTransferData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
