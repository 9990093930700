export default {
  apiUrl: "http://yoursite.com/api/"
};

const siteConfig = {
  siteName: "Previnsight",
  siteIcon: "ion-flash",
  footerText: "Version 1.0.6 Powered By Astrika."
};
const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault"
};
const language = "English";
export { siteConfig, language, themeConfig };
