import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
const getCurrentPageName = () => {
  // debugger;
  let pageName = window.location.pathname;

  return pageName;
  // return pageName.toUpperCase();
};
const PublicRoutes = ({ history, isLoggedIn }) => {
  // debugger;
  const UrlTobeUsed = getCurrentPageName().lastIndexOf("userSurvey") + 11;
  const dynamicUrl = getCurrentPageName().slice(
    UrlTobeUsed,
    getCurrentPageName().length
  );
  const URL2 = getCurrentPageName().split("/");
  // console.log(
  //   "%c 🍨 URL2: ",
  //   "font-size:20px;background-color: #6EC1C2;color:#fff;",
  //   URL2[2]
  // );
  // const data = `/shortUrl/${URL2[4]}`;
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route
          exact
          path={`/shortUrl/${URL2[2]}`}
          // path={`/customer-acceptance-service/oppoTinyUrl`}
          component={asyncComponent(() =>
            import("./containers/ExtUserSurvey/ExtUserSurvey")
          )}
        />
        {/* <Route
          exact
          path={`/userSurvey/${dynamicUrl}`}
          // path={`/customer-acceptance-service/oppoTinyUrl`}
          component={asyncComponent(() =>
            import("./containers/ExtUserSurvey/ExtUserSurvey")
          )}
        /> */}
        <Route
          exact
          path={"/"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/signin"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/signup"}
          component={asyncComponent(() => import("./containers/Page/signup"))}
        />
        <Route
          exact
          path={"/forgotpass"}
          component={asyncComponent(() =>
            import("./containers/Page/forgotpass")
          )}
        />

        <Route
          exact
          path={"/resetpass"}
          component={asyncComponent(() =>
            import("./containers/Page/resetpass")
          )}
        />

        <Route
          exact
          path={"/menu"}
          component={asyncComponent(() => import("./containers/Main/menu"))}
        />
        {/* <Route
          exact
          path={'/assetmaster'}
          component={asyncComponent(() => import('./containers/AssetMaster/AssetMaster'))}
        /> */}
        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
        />
        {(window.location.pathname === "/dashboard" || window.location.pathname === "/dashboard/") && <Redirect to="/menu" />}
      </div>
    </ConnectedRouter>
  );
};

export default connect((state) => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
