import {
  SaveDataContactActions,
  GetDataContactDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataContactActions
} from "./actions";

const initState = {
  DataContactSuccessMessage: null,
  DataContactErrorMessage: null,
  DataContactStatus: null,
  DataContactMaster: [],
  DataContactData: null,
  ActiveDataContactList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataContactActions.SAVE_DATA_CONTACT:
      return {
        ...state,
        isLoading: true,
        DataContactErrorMessage: null,
        modalActive: false
      };
    case SaveDataContactActions.SAVE_DATA_CONTACT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DataContactStatus: payload.status,
        DataContactErrorMessage: null,
        DataContactSuccessMessage: "Ropa Data Contact added successfully!"
      };

    case SaveDataContactActions.SAVE_DATA_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        DataContactStatus: null,
        DataContactErrorMessage: payload
      };
    case SaveDataContactActions.SAVE_DATA_CONTACT_STATUS:
      return {
        ...state,
        DataContactStatus: 0,
        DataContactErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataContactDetailsActions.FETCH_DATA_CONTACT_DETAILS:
      return {
        ...state,
        isLoading: true,
        DataContactErrorMessage: null,
        modalActive: false,
        DataContactData: null
      };
    case GetDataContactDetailsActions.FETCH_DATA_CONTACT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DataContactData: payload.data,
        DataContactDataStatus: payload.status,
        DataContactErrorMessage: null
      };
    case GetDataContactDetailsActions.FETCH_DATA_CONTACT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        DataContactErrorMessage: payload,
        DataContactData: null
      };
    case GetDataContactDetailsActions.FETCH_DATA_CONTACT_DETAILS_STATUS:
      return {
        ...state,
        DataContactData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataContactActions.FETCH_ACTIVE_DATA_CONTACT:
      return {
        ...state,
        isLoading: true,
        DataContactErrorMessage: null,
        modalActive: false,
        ActiveDataContactList: null
      };
    case GetActiveDataContactActions.FETCH_ACTIVE_DATA_CONTACT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataContactList: payload.data,
        DataContactErrorMessage: null
      };
    case GetActiveDataContactActions.FETCH_ACTIVE_DATA_CONTACT_REJECTED:
      return {
        ...state,
        isLoading: false,
        DataContactErrorMessage: payload,
        ActiveDataContactList: null
      };
    case GetActiveDataContactActions.FETCH_ACTIVE_DATA_CONTACT_STATUS:
      return {
        ...state,
        ActiveDataContactList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetDataContact: null,
        DataContactStatus: null,
        DataContactSuccessMessage: null,
        GetDataContactStatus: null,
        DataContactData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
