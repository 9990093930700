import {
  ClearLogoutActions,
  ModalLogoutActions,
  SetLogoutActions,
  ModalCloseLogoutActions,
} from "./actions";

let initState = {
  logoutFlag: false,
  modalOpenFlag: false,
};
export default function Logout(state = initState, { type, payload }) {
  switch (type) {
    case ClearLogoutActions.CLEAR_LOGOUT_DETAILS:
      return {
        ...state,
        logoutFlag: true,
      };

    case SetLogoutActions.SET_LOGOUT_DETAILS:
      return {
        ...state,
        logoutFlag: false,
        modalOpenFlag: false,
      };
    case ModalLogoutActions.MODAL_LOGOUT_DETAILS:
      return {
        ...state,
        modalOpenFlag: true,
      };
    case ModalCloseLogoutActions.MODAL_CLOSE_LOGOUT_DETAILS:
      return {
        ...state,
        modalOpenFlag: false,
      };

    default:
      return state;
  }
}
