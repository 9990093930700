import React, { Component, lazy, Suspense } from "react";
import { Route } from "react-router-dom";
// import lazy from "../../helpers/AsyncFunc";
import "../../common/loader.css";
const routes = [
  {
    path: "",
    component: lazy(() => import("../dashboard")),
  },

  {
    path: "/menu",
    component: lazy(() => import("../Main/menu")),
  },
  {
    path: "templateDnDUI",
    component: lazy(() => import("../TemplateFieldDnDUI")),
  },
  {
    path: "AssetMasterExcelUpload",
    component: lazy(() => import("../AssetMaster/AssetMasterExcelUpload")),
  },
  {
    path: "RequestMasterExcelUpload",
    component: lazy(() =>
      import("../RequestMasterListing/RequestMasterExcelUpload")
    ),
  },
  {
    path: "AssetTypeExcelUpload",
    component: lazy(() => import("../AssetTypeMaster/AssetTypeExcelUpload")),
  },

  {
    path: "ropaSubCatExcelUpload",
    component: lazy(() =>
      import("../AddRopaDataProcCat/RopaSubCatExcelUpload")
    ),
  },
  {
    path: "dataTransferExcelUpload",
    component: lazy(() =>
      import("../AddDataTransferMethod/DataTransferExcelUpload")
    ),
  },
  {
    path: "lawfulBasisExcelUpload",
    component: lazy(() => import("../AddLegalBasis/LawfulBasisExcelUpload")),
  },

  {
    path: "purposeProcExcelUpload",
    component: lazy(() => import("../AddPurpose/PurposeProcExcelUpload")),
  },
  {
    path: "regulationExcelUpload",
    component: lazy(() => import("../AddRegulation/RegulationExcelUpload")),
  },
  {
    path: "ropaDataCustoExcelUpload",
    component: lazy(() =>
      import("../AddRopaDataContact/RopaDataCustoExcelUpload")
    ),
  },
  {
    path: "UserExcelUpload",
    component: lazy(() => import("../UserMaster/UserExcelUpload")),
  },

  // {
  //   path: "assetmaster",
  //   component: lazy(() => import("../AssetMaster"))
  // },
  // {
  //   path: "assetTypeMaster",
  //   component: lazy(() => import("../AssetTypeMaster"))
  // },
  {
    path: "riskMaster",
    component: lazy(() => import("../RiskMaster")),
  },
  {
    path: "templateFieldMaster",
    component: lazy(() => import("../TemplateFieldMaster")),
  },
  {
    path: "templateFieldFormBuilder",
    component: lazy(() => import("../AddField")),
  },
  {
    path: "BreachSetup",
    component: lazy(() => import("../BreachSetup")),
  },
  {
    path: "purposeListing",
    component: lazy(() => import("../PurposeOfProcessing")),
  },
  {
    path: "consentPurposeListing",
    component: lazy(() => import("../ConsentPurposeListing")),
  },
  {
    path: "consentPointsListing",
    component: lazy(() => import("../ConsentPointsListing")),
  },
  {
    path: "CollectionsMasterListing",
    component: lazy(() => import("../CollectionsMasterListing")),
  },
  {
    path: "ControlFrameworkListing",
    component: lazy(() => import("../ControlFrameworkListing")),
  },
  {
    path: "riskLibraryListing",
    component: lazy(() => import("../RiskLibraryListing")),
  },
  {
    path: "ControlLibraryListing",
    component: lazy(() => import("../ControlLibraryListing")),
  },
  {
    path: "vulnerabilityListing",
    component: lazy(() => import("../VulnerabilityListing")),
  },
  {
    path: "CollectionsView",
    component: lazy(() =>
      import("../CollectionsMasterListing/CollectionsView")
    ),
  },
  {
    path: "consentPointView",
    component: lazy(() =>
      import("../ConsentPointsListing/ConsentCapturePointsView")
    ),
  },
  {
    path: "addConsentPoint",
    component: lazy(() => import("../AddConsentPoint")),
  },
  {
    path: "addOfflineConsentPoint",
    component: lazy(() => import("../AddOfflineConsent")),
  },
  {
    path: "CookieBanner",
    component: lazy(() => import("../CookieBanner")),
  },
  {
    path: "cookieTemplateListing",
    component: lazy(() => import("../CookieBanner/CookieTemplateListing")),
  },
  {
    path: "DomainListing",
    component: lazy(() => import("../DomainListing")),
  },
  {
    path: "cookiesDetails",
    component: lazy(() => import("../DomainListing/CookieDetailedView")),
  },

  {
    path: "viewDomainDetails",
    component: lazy(() => import("../DomainListing/ViewDomainDetails")),
  },

  {
    path: "CookieClassificationListing",
    component: lazy(() => import("../CookieClassificationListing")),
  },
  // {
  //   path: "cookieConsentlist",
  //   component: lazy(() => import("../DomainListing/CookieConsentList")),
  // },
  {
    path: "LocationRulesListing",
    component: lazy(() => import("../LocationRulesListing")),
  },
  {
    path: "templateListing",
    component: lazy(() => import("../TemplateListing")),
  },
  {
    path: "templateForm",
    component: lazy(() => import("../AddTemplate")),
  },
  {
    path: "regulationMasterListing",
    component: lazy(() => import("../RegulationMasterListing")),
  },
  {
    path: "designationListing",
    component: lazy(() => import("../DesignationListing")),
  },
  {
    path: "assetTypeMaster",
    component: lazy(() => import("../AssetTypeListing")),
  },
  {
    path: "accessControl",
    component: lazy(() => import("../Sidebar/NoAccessPage")),
  },
  {
    path: "requestTypeMaster",
    component: lazy(() => import("../RequestTypeListing")),
  },
  {
    path: "requestSourcesMaster",
    component: lazy(() => import("../RequestSourcesListing")),
  },
  {
    path: "requestMaster",
    component: lazy(() => import("../RequestMasterListing")),
  },
  //Data Recipient Master Listing
  {
    path: "dataRecipientListing",
    component: lazy(() => import("../DataRecipientListing")),
  },
  {
    path: "dataContactListing",
    component: lazy(() => import("../RopaDataContactListing")),
  },
  {
    path: "ropaDataProcessExcelUpload",
    component: lazy(() =>
      import("../AddDataProcessor/DataProcessorExcelUpload")
    ),
  },
  {
    path: "ropaDataSubProcessExcelUpload",
    component: lazy(() =>
      import("../AddDataSubProcessor/DataSubProcessorExcelUpload")
    ),
  },
  {
    path: "ropaDataRecepientExcelUpload",
    component: lazy(() =>
      import("../AddDataRecipient/DataRecepientExcelUpload")
    ),
  },

  {
    path: "RopaDataProcCatListing",
    component: lazy(() => import("../RopaDataSubProcListing")),
  },

  {
    path: "templateFieldListing",
    component: lazy(() => import("../TemplateFieldListing")),
  },
  {
    path: "assetMaster",
    component: lazy(() => import("../AssetMasterListing")),
  },
  {
    path: "groupListView",
    component: lazy(() => import("../GroupList")),
  },
  {
    path: "userMaster",
    component: lazy(() => import("../UserMasterListing")),
  },
  {
    path: "roleMaster",
    component: lazy(() => import("../RoleMasterListing")),
  },
  {
    path: "legalBasis",
    component: lazy(() => import("../LegalBasisListing")),
  },
  {
    path: "activityMaster",
    component: lazy(() => import("../ActivityListing")),
  },
  {
    path: "hostingTypeMaster",
    component: lazy(() => import("../HostingTypeListing")),
  },
  {
    path: "roleManagementListing",
    component: lazy(() => import("../RoleManagementListing")),
  },

  {
    path: "addRoleManagement",
    component: lazy(() => import("../AddRoleManagement")),
  },

  {
    path: "dataSubProcessorListing",
    component: lazy(() => import("../DataSubProcessorListing")),
  },
  {
    path: "HostingProviderExcelUpload",
    component: lazy(() =>
      import("../AddHostingProvider/HostingProviderExcelUpload.js")
    ),
  },
  {
    path: "HostingTypeExcelUpload",
    component: lazy(() =>
      import("../AddHostingType/HostingTypeExcelUpload.js")
    ),
  },

  {
    path: "RequestSourcesExcelUpload",
    component: lazy(() =>
      import("../AddRequestSources/RequestSourcesExcelUpload.js")
    ),
  },
  {
    path: "RequestTypeExcelUpload",
    component: lazy(() =>
      import("../AddRequestType/RequestTypeExcelUpload.js")
    ),
  },
  {
    path: "DataSubjectCatExcelUpload",
    component: lazy(() => import("../AddDataSubCat/DataSubCatExcelUpload.js")),
  },
  {
    path: "DataSubjectExcelUpload",
    component: lazy(() => import("../AddDataSub/DataSubExcelUpload.js")),
  },
  {
    path: "DataSubjectEleExcelUpload",
    component: lazy(() =>
      import("../AddDataSubElements/DataSubEleExcelUpload.js")
    ),
  },
  {
    path: "DataSubjVolExcelUpload",
    component: lazy(() => import("../AddDataVol/DataVolExcelUpload.js")),
  },
  {
    path: "SecurityMeasExcelUpload",
    component: lazy(() =>
      import("../AddSecurityMeasure/SecurityMeasureExcelUpload.js")
    ),
  },
  {
    path: "OrgMasterExcelUpload",
    component: lazy(() =>
      import("../OrganizationMaster/OrgMasterExcelUpload.js")
    ),
  },
  {
    path: "SecurityTypeExcelUpload",
    component: lazy(() =>
      import("../AddSecurityType/SecurityTypeExcelUpload.js")
    ),
  },
  {
    path: "DataDispMethExcelUpload",
    component: lazy(() =>
      import("../AddDataDisposal/DataDisposalExcelUpload.js")
    ),
  },
  {
    path: "dataProcessorListing",
    component: lazy(() => import("../DataProcessorListing")),
  },
  {
    path: "hostingProviderMaster",
    component: lazy(() => import("../HostingProviderListing")),
  },
  {
    path: "SurveyListing",
    component: lazy(() => import("../SurveyListing")),
  },
  {
    path: "ResponsesListing",
    component: lazy(() => import("../ResponsesListing")),
  },
  {
    path: "breachIncidentRegister",
    component: lazy(() => import("../BreachManagementListing")),
  },
  {
    path: "BreachTemplateManagerEdit",
    component: lazy(() => import("../BreachTemplateManagerEdit")),
  },
  {
    path: "BreachTemplateManager",
    component: lazy(() => import("../BreachTemplateManager")),
  },
  {
    path: "BreachTypeListing",
    component: lazy(() => import("../BreachTypeListing")),
  },
  {
    path: "ControlRegisterListing",
    component: lazy(() => import("../ControlRegisterListing")),
  },
  {
    path: "BreachSourceListing",
    component: lazy(() => import("../BreachSourceListing")),
  },
  {
    path: "viewBreachSourceSummary",
    component: lazy(() => import("../BreachSourceListing/ViewSummary.js")),
  },
  {
    path: "BreachSourceExcelUpload",
    component: lazy(() =>
      import("../AddBreachSource/BreachSourceExcelUpload.js")
    ),
  },
  {
    path: "RiskLibraryExcelUpload",
    component: lazy(() =>
      import("../AddRiskLibrary/RiskLibraryExcelUpload.js")
    ),
  },
  {
    path: "VulnerabilityExcelUpload",
    component: lazy(() =>
      import("../AddVulnerability/VulnerabilityExcelUpload.js")
    ),
  },
  {
    path: "ThreatListing",
    component: lazy(() => import("../ThreatListing")),
  },
  {
    path: "RiskWorkflowListing",
    component: lazy(() => import("../RiskWorkflowListing")),
  },
  {
    path: "RiskWorkflowExcelUpload",
    component: lazy(() => import("../AddRiskWorkflow/RiskWorkflowExcelUpload")),
  },
  {
    path: "RiskRegisterListing",
    component: lazy(() => import("../RiskRegisterListing")),
  },
  {
    path: "riskAssessment",
    component: lazy(() => import("../RiskAssessmentListing")),
  },
  {
    path: "riskSurveyForm",
    component: lazy(() => import("../RiskAssessmentListing/RiskFormBuilder")),
  },
  {
    path: "RiskSurveyViewResponses",
    component: lazy(() =>
      import("../RiskAssessmentListing/RiskSurveyViewResponses")
    ),
  },
  {
    path: "riskFormBuilder",
    component: lazy(() =>
      import("../RiskAssessmentListing/RiskFormBuilderTab")
    ),
  },
  {
    path: "riskTempAssessment",
    component: lazy(() => import("../RiskAssessmentTemplateListing")),
  },
  {
    path: "riskTempSurveyForm",
    component: lazy(() =>
      import("../RiskAssessmentTemplateListing/RiskTempFormBuilder")
    ),
  },
  {
    path: "riskTempFormBuilder",
    component: lazy(() =>
      import("../RiskAssessmentTemplateListing/RiskTempFormBuilderTab")
    ),
  },
  {
    path: "RiskSurveyTempView",
    component: lazy(() =>
      import("../RiskAssessmentTemplateListing/RiskSurveyTemplateView")
    ),
  },
  {
    path: "RiskSurveyView",
    component: lazy(() =>
      import("../RiskAssessmentListing/RiskSurveyAssessmentReview")
    ),
  },
  {
    path: "riskRegisterManagement",
    component: lazy(() => import("../RiskRegisterListing")),
  },
  {
    path: "breachTemplateManagerListing",
    component: lazy(() => import("../BreachTemplateManagerListing")),
  },
  {
    path: "BreachTemplateFormBuilder",
    component: lazy(() => import("../BreachTemplateFormBuilder")),
  },
  {
    path: "SurveyDraftListing",
    component: lazy(() => import("../SurveyDraftListing")),
  },
  {
    path: "SurveyListingCompleted",
    component: lazy(() => import("../SurveyCompletedAssessment")),
  },
  {
    path: "PreSurveyListing",
    component: lazy(() => import("../PreSurveyListing")),
  },
  {
    path: "AssessmentListing",
    component: lazy(() => import("../UserSurveyListing")),
  },
  {
    path: "AssessmentReviewListing",
    component: lazy(() => import("../UserSurveyReviewListing")),
  },
  {
    path: "RiskCategoriesListing",
    component: lazy(() => import("../RiskCategoryListing")),
  },
  {
    path: "ControlCategoriesListing",
    component: lazy(() => import("../ControlCategoryListing")),
  },

  {
    path: "AssessmentPastReviewListing",
    component: lazy(() => import("../UserSurveyPastReviewListing")),
  },
  {
    path: "templateManagerListing",
    component: lazy(() => import("../SurveyTemplateManagerListing")),
  },
  {
    path: "templateManagerDraftListing",
    component: lazy(() => import("../SurveyTemplateManagerDraftListing")),
  },

  {
    path: "SurveyTemplatesAwaiting",
    component: lazy(() => import("../SurveyTemplatesAwaiting")),
  },

  {
    path: "SurveyTemplateManager",
    component: lazy(() => import("../SurveyTemplateManager")),
  },
  {
    path: "SurveyTemplateDraftManager",
    component: lazy(() => import("../SurveyTemplateDraftManager")),
  },
  {
    path: "SurveyTemplateFormBuilder",
    component: lazy(() => import("../SurveyTemplateFormBuilder")),
  },
  {
    path: "AssessmentForm",
    component: lazy(() => import("../AssessmentForm")),
  },
  {
    path: "ViewResponses",
    component: lazy(() => import("../AssessmentForm/ViewResponses")),
  },
  {
    path: "GiveResponse",
    component: lazy(() => import("../AssessmentForm/GiveResponse")),
  },
  {
    path: "breachTemplateForm",
    component: lazy(() => import("../BreachTemplateForm")),
  },
  {
    path: "AssessmentFormView",
    component: lazy(() => import("../AssessmentFormView")),
  },
  {
    path: "SurveyTemplateReviewForm",
    component: lazy(() => import("../SurveyTemplateReviewForm")),
  },
  {
    path: "SurveyTemplateViewReview",
    component: lazy(() => import("../SurveyTemplateViewReview")),
  },
  {
    path: "SurveyViewAssessment",
    component: lazy(() => import("../SurveyListing/SurveyViewAssessment")),
  },
  {
    path: "SurveyViewAssessmentCompleted",
    component: lazy(() =>
      import("../SurveyListing/surveyViewAssessmentCompleted.js")
    ),
  },
  {
    path: "tierSurveyViewAssessmentCompleted",
    component: lazy(() =>
      import("../SurveyListing/surveyViewAssessmentCompleted.js")
    ),
  },
  {
    path: "SurveyAssessmentReviewForm",
    component: lazy(() => import("../SurveyAssessmentReviewForm")),
  },
  {
    path: "SurveyAssessmentViewReview",
    component: lazy(() => import("../SurveyAssessmentViewReview")),
  },

  {
    path: "SurveyViewResponses",
    component: lazy(() => import("../SurveyViewResponses")),
  },
  {
    path: "SurveyReviewResponses",
    component: lazy(() =>
      import("../SurveyViewResponses/SurveyReviewResponses.js")
    ),
  },
  {
    path: "SurveyChartViewResponses",
    component: lazy(() => import("../SurveyChartViewResponses")),
  },
  {
    path: "SurveyAssessmentAwatingListing",
    component: lazy(() => import("../SurveyAssessmentAwatingListing")),
  },

  {
    path: "dataSubjectCategories",
    component: lazy(() => import("../DataSubjectCatListing")),
  },
  {
    path: "dataSubjectMaster",
    component: lazy(() => import("../DataSubjectListing")),
  },

  {
    path: "dataSubjectElements",
    component: lazy(() => import("../DataSubjectEleListing")),
  },
  {
    path: "dataSubjectVolRange",
    component: lazy(() => import("../DataSubjectVolRange")),
  },
  {
    path: "dataDisposalMaster",
    component: lazy(() => import("../DataDisposalMaster")),
  },

  {
    path: "securityMeasureMaster",
    component: lazy(() => import("../SecurityMeasureListing")),
  },
  {
    path: "securityTypeMaster",
    component: lazy(() => import("../SecurityTypeListing")),
  },
  {
    path: "OrganisationMaster",
    component: lazy(() => import("../OrganizationMaster")),
  },
  // {
  //   path: "ropaMasterListing",
  //   component: lazy(() => import("../RopaMasterListing")),
  // },
  {
    path: "ropaForm",
    component: lazy(() => import("../AddRopa")),
  },
  {
    path: "riskRegisterForm",
    component: lazy(() => import("../AddNewRiskRegister")),
  },
  {
    path: "riskLibraryForm",
    component: lazy(() => import("../AddRiskLibrary")),
  },
  {
    path: "riskWorkflowForm",
    component: lazy(() => import("../AddRiskWorkflow")),
  },
  {
    path: "controlRegisterForm",
    component: lazy(() => import("../AddControlRegister")),
  },
  {
    path: "dataTransferListing",
    component: lazy(() => import("../DataTransferMethodListing")),
  },
  {
    path: "processTeam",
    component: lazy(() => import("../AddProcessTeam")),
  },
  {
    path: "viewPublishedRopa",
    component: lazy(() => import("../AddNewRopa/ViewPublishedRopa")),
  },
  {
    path: "processTemp",
    component: lazy(() => import("../AddNewRopa/Process2")),
  },
  {
    path: "ownerVerify",
    component: lazy(() => import("../AddNewRopa/OwnerVerification")),
  },
  {
    path: "dpoVerify",
    component: lazy(() => import("../AddNewRopa/DPOVerification")),
  },
  {
    path: "published",
    component: lazy(() => import("../AddNewRopa/PublishedRopa")),
  },
  {
    path: "newropaForm",
    component: lazy(() => import("../AddNewRopa")),
  },
  {
    path: "ropaMasterListing",
    component: lazy(() => import("../ProcessRegisterListing")),
  },
  {
    path: "consentHeadListing",
    component: lazy(() => import("../ConsentHeadListing")),
  },
  {
    path: "cookieCategoriesListing",
    component: lazy(() => import("../CookieCategoriesListing")),
  },
  {
    path: "collectionInteractionListing",
    component: lazy(() => import("../CollectionInteractionListing")),
  },
  {
    path: "consentHeadExcelUpload",
    component: lazy(() =>
      import("../ConsentHeadListing/ConsentHeadExcelUpload")
    ),
  },
  {
    path: "consentPurposeExcelUpload",
    component: lazy(() =>
      import("../ConsentPurposeListing/ConsentPurposeExcelUpload")
    ),
  },

  {
    path: "assetForm",
    component: lazy(() => import("../AddAsset")),
  },
  {
    path: "vulnerabilityForm",
    component: lazy(() => import("../AddVulnerability")),
  },
  {
    path: "viewPurposeSummary",
    component: lazy(() => import("../PurposeOfProcessing/ViewSummary.js")),
  },
  {
    path: "viewCookieCatSummary",
    component: lazy(() => import("../CookieCategoriesListing/ViewSummary.js")),
  },
  {
    path: "viewRopaMasterSummary",
    component: lazy(() => import("../ProcessRegisterListing/ViewSummary.js")),
  },
  {
    path: "viewConsentHeadSummary",
    component: lazy(() => import("../ConsentHeadListing/ViewSummary.js")),
  },
  {
    path: "viewConsentPurposeSummary",
    component: lazy(() => import("../ConsentPurposeListing/ViewSummary.js")),
  },
  {
    path: "viewDataSubEleSummary",
    component: lazy(() => import("../DataSubjectEleListing/ViewSummary.js")),
  },
  {
    path: "viewSummaryDataSubProc",
    component: lazy(() => import("../DataSubProcessorListing/ViewSummary.js")),
  },
  {
    path: "viewOwnershipRole",
    component: lazy(() => import("../RoleMasterListing/ViewSummary.js")),
  },
  {
    path: "riskMatrix",
    component: lazy(() => import("../RiskMaster/RiskMatrix")),
  },
  {
    path: "viewUser",
    component: lazy(() => import("../UserMasterListing/ViewSummary.js")),
  },
  {
    path: "viewDesignation",
    component: lazy(() => import("../DesignationListing/ViewSummary.js")),
  },
  {
    path: "viewRopaDataContSummary",
    component: lazy(() => import("../RopaDataContactListing/ViewSummary.js")),
  },

  {
    path: "viewLegalBasisSummary",
    component: lazy(() => import("../LegalBasisListing/ViewSummary.js")),
  },
  {
    path: "viewPurpProcSummary",
    component: lazy(() => import("../PurposeOfProcessing/ViewSummary.js")),
  },

  {
    path: "viewRopaDataReciptSummary",
    component: lazy(() => import("../DataRecipientListing/ViewSummary.js")),
  },

  {
    path: "viewRopaDataProSummary",
    component: lazy(() => import("../DataProcessorListing/ViewSummary.js")),
  },
  {
    path: "viewRopaDataProCatSummary",
    component: lazy(() => import("../RopaDataSubProcListing/ViewSummary.js")),
  },
  {
    path: "viewRequestTypeSummary",
    component: lazy(() => import("../RequestTypeListing/ViewSummary.js")),
  },
  {
    path: "viewRequestMaster",
    component: lazy(() =>
      import("../RequestMasterListing/ViewRequestMaster.js")
    ),
  },
  {
    path: "viewRegulationSummary",
    component: lazy(() => import("../RegulationMasterListing/ViewSummary.js")),
  },
  {
    path: "viewRequestSourceSummary",
    component: lazy(() => import("../RequestSourcesListing/ViewSummary.js")),
  },
  {
    path: "viewAssetTypeSummary",
    component: lazy(() => import("../AssetTypeListing/ViewSummary.js")),
  },
  {
    path: "dataSubjViewSummary",
    component: lazy(() => import("../DataSubjectListing/ViewSummary.js")),
  },
  {
    path: "BreachTypeExcelUpload",
    component: lazy(() => import("../AddBreachType/BreachTypeExcelUpload.js")),
  },
  {
    path: "RoleMasterExcelUpload",
    component: lazy(() => import("../AddRole/RoleMasterExcelUpload.js")),
  },
  {
    path: "DesignationExcelUpload",
    component: lazy(() =>
      import("../AddDesignation/DesignationExcelUpload.js")
    ),
  },
  {
    path: "ThreatExcelUpload",
    component: lazy(() => import("../AddThreat/ThreatExcelUpload.js")),
  },

  {
    path: "SurveyTemplateExcelUpload",
    component: lazy(() =>
      import("../SurveyTemplateDraftManager/SurveyTemplateExcelUpload.js")
    ),
  },
  {
    path: "ControlCategoryExcelUpload",
    component: lazy(() =>
      import("../AddControlCategory/ControlCategoryExcelUpload.js")
    ),
  },
  {
    path: "CookieCategoryExcelUpload",
    component: lazy(() =>
      import("../CookieCategoriesListing/CookieCategoryExcelUpload.js")
    ),
  },
  {
    path: "RiskCategoryExcelUpload",
    component: lazy(() =>
      import("../AddRiskCategory/RiskCategoryExcelUpload.js")
    ),
  },
  {
    path: "ControlLibraryExcelUpload",
    component: lazy(() =>
      import("../AddControlLibrary/ControlLibraryExcelUpload.js")
    ),
  },
  {
    path: "ControlFrameworkExcelUpload",
    component: lazy(() =>
      import("../AddControlFramework/ControlFrameworkExcelUpload.js")
    ),
  },
  {
    path: "BreachIncidentExcelUpload",
    component: lazy(() =>
      import("../AddBreachIncident/BreachIncidentExcelUpload.js")
    ),
  },
  {
    path: "dataTypeViewSummary",
    component: lazy(() => import("../DataSubjectCatListing/ViewSummary.js")),
  },
  {
    path: "dataSubjectVolRangeViewSummary",
    component: lazy(() => import("../DataSubjectVolRange/ViewSummary.js")),
  },
  {
    path: "dataTransferMethodViewSummary",
    component: lazy(() =>
      import("../DataTransferMethodListing/ViewSummary.js")
    ),
  },
  {
    path: "dataDisposalViewSummary",
    component: lazy(() => import("../DataDisposalMaster/ViewSummary.js")),
  },
  {
    path: "SecMeasTypeViewSummary",
    component: lazy(() => import("../SecurityTypeListing/ViewSummary.js")),
  },
  {
    path: "SecMeasureViewSummary",
    component: lazy(() => import("../SecurityMeasureListing/ViewSummary.js")),
  },
  {
    path: "viewBreachTypeSummary",
    component: lazy(() => import("../BreachTypeListing/ViewSummary.js")),
  },
  {
    path: "viewThreatSummary",
    component: lazy(() => import("../ThreatListing/ViewSummary.js")),
  },
  {
    path: "viewControlCategorySummary",
    component: lazy(() => import("../ControlCategoryListing/ViewSummary.js")),
  },
  {
    path: "viewControlFrameworkSummary",
    component: lazy(() => import("../ControlFrameworkListing/ViewSummary.js")),
  },
  {
    path: "viewControlLibrarySummary",
    component: lazy(() => import("../ControlLibraryListing/ViewSummary.js")),
  },
  //{
  // path: "viewRiskRegisterSummary",
  // component: lazy(() =>
  //  import("../RiskRegisterListing/ViewSummary.js")
  //),
  //},
  {
    path: "viewRiskRegisterSummary",
    component: lazy(() => import("../RiskRegisterListing/ViewSummary.js")),
  },
  {
    path: "viewRiskWorkflowSummary",
    component: lazy(() => import("../RiskWorkflowListing/ViewSummary.js")),
  },
  {
    path: "viewCategoryRiskSummary",
    component: lazy(() => import("../RiskCategoryListing/ViewSummary.js")),
  },
  {
    path: "viewAddBreachSummary",
    component: lazy(() => import("../AddBreachIncident/ViewSummary.js")),
  },
  {
    path: "viewAddBreachReviewSummary",
    component: lazy(() => import("../AddBreachIncidentReview/ViewSummary.js")),
  },
  {
    path: "viewAddBreachClosedSummary",
    component: lazy(() =>
      import("../AddBreachIncidentReview/ViewClosedSummary.js")
    ),
  },
  {
    path: "viewHostingTypeSummary",
    component: lazy(() => import("../HostingTypeListing/ViewSummary.js")),
  },
  {
    path: "viewHostingProviderSummary",
    component: lazy(() => import("../HostingProviderListing/ViewSummary.js")),
  },
  {
    path: "viewAssetSummary",
    component: lazy(() => import("../AssetMasterListing/ViewSummary.js")),
  },
  {
    path: "viewVulnerabilitySummary",
    component: lazy(() => import("../VulnerabilityListing/ViewSummary.js")),
  },
  {
    path: "viewRiskLibrarySummary",
    component: lazy(() => import("../RiskLibraryListing/ViewSummary")),
  },
  // {
  //   path: "document",
  //   component: lazy(() => import("../Document")),
  // },
  {
    path: "CustomLogout",
    component: lazy(() => import("../CustomLogout")),
  },
  {
    path: "assesment",
    component: lazy(() => import("../FormBuilder")),
  },
  {
    path: "survey",
    component: lazy(() => import("../SurveyFormBuilder")),
  },
  {
    path: "EditAssessmentFormBuilder",
    component: lazy(() => import("../EditAssessmentFormBuilder")),
  },
  {
    path: "EditPreAssessmentFormBuilder",
    component: lazy(() => import("../EditPreAssessmentFormBuilder")),
  },
  {
    path: "presurvey",
    component: lazy(() => import("../PreSurveyFormBuilder")),
  },
  {
    path: "addNewBreachIncident",
    component: lazy(() => import("../AddBreachIncident")),
  },
  {
    path: "AddBreachIncidentReview",
    component: lazy(() => import("../AddBreachIncidentReview")),
  },
  {
    path: "AddBreachIncidentCopy",
    component: lazy(() => import("../AddBreachIncidentCopy")),
  },

  {
    path: "surveyFormBuilder",
    component: lazy(() => import("../FormBuilderTab")),
  },
  {
    path: "preSurveyFormBuilder",
    component: lazy(() => import("../PreFormBuilderTab")),
  },

  {
    path: "SurveyAssessmentReview",
    component: lazy(() => import("../SurveyAssessmentReview")),
  },
  {
    path: "ropamaster",
    component: lazy(() => import("../RopaMaster")),
  },
  {
    path: "Profile",
    component: lazy(() => import("../profile")),
  },
  {
    path: "AssessmentResponse",
    component: lazy(() => import("../MyAssessment")),
  },
  {
    path: "DataFlowDaigram",
    component: lazy(() => import("../DataFlowDaigram")),
  },
  {
    path: "VendorDraftListing",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/VendorDraftListing"
      )
    ),
  },
  {
    path: "VendorAllTierListing",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/VendorAllTierListing"
      )
    ),
  },
  {
    path: "TieringAssessmentAwatingListing",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/TieringAssessmentAwatingListing"
      )
    ),
  },
  {
    path: "TieringAssessmentListingCompleted",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/TieringAssessmentListingCompleted"
      )
    ),
  },
  {
    path: "TieringTemplateManagerDraftListing",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringTemplate/TieringTemplateManagerDraftListing"
      )
    ),
  },
  {
    path: "TieringTemplatesAwaiting",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringTemplate/TieringTemplatesAwaiting"
      )
    ),
  },
  {
    path: "TieringTemplateManagerListing",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringTemplate/TieringTemplateManagerListing"
      )
    ),
  },
  {
    path: "TieringTemplateDraftManager",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringTemplate/SurveyTemplateDraftManager"
      )
    ),
  },
  {
    path: "TieringTemplateFormBuilder",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringTemplate/SurveyTemplateFormBuilder"
      )
    ),
  },
  {
    path: "TieringTemplateViewReview",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringTemplate/SurveyTemplateViewReview"
      )
    ),
  },
  {
    path: "TieringTemplateExcelUpload",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringTemplate/SurveyTemplateDraftManager/SurveyTemplateExcelUpload.js"
      )
    ),
  },
  {
    path: "VendorRegisterListing",
    component: lazy(() => import("../VendorManagement/VendorRegister")),
  },
  {
    path: "VendorRegisterForm",
    component: lazy(() =>
      import("../VendorManagement/VendorRegister/AddVendorRegister")
    ),
  },
  {
    path: "ViewVendorRegisterSummary",
    component: lazy(() =>
      import("../VendorManagement/VendorRegister/ViewSummary.js")
    ),
  },
  {
    path: "VendorRegisterExcelUpload",
    component: lazy(() =>
      import("../VendorManagement/VendorRegister/VendorRegisterExcelUpload.js")
    ),
  },
  {
    path: "VendorIndustryListing",
    component: lazy(() => import("../VendorManagement/VendorIndustry")),
  },
  {
    path: "ViewIndustryTypeSummary",
    component: lazy(() =>
      import("../VendorManagement/VendorIndustry/ViewSummary.js")
    ),
  },
  {
    path: "IndustryExcelUpload",
    component: lazy(() =>
      import("../VendorManagement/VendorIndustry/IndustryExcelUpload.js")
    ),
  },
  {
    path: "VendorTypeListing",
    component: lazy(() => import("../VendorManagement/VendorType")),
  },
  {
    path: "VendorTypeViewSummary",
    component: lazy(() =>
      import("../VendorManagement/VendorType/ViewSummary.js")
    ),
  },
  {
    path: "VendorTypeExcelUpload",
    component: lazy(() =>
      import("../VendorManagement/VendorType/VendorTypeExcelUpload.js")
    ),
  },
  {
    path: "VendorServiceListing",
    component: lazy(() => import("../VendorManagement/VendorCategory")),
  },
  {
    path: "VendorCategoryViewSummary",
    component: lazy(() =>
      import("../VendorManagement/VendorCategory/ViewSummary.js")
    ),
  },
  {
    path: "VendorCategoryExcelUpload",
    component: lazy(() =>
      import("../VendorManagement/VendorCategory/VendorCategoryExcelUpload.js")
    ),
  },
  {
    path: "VendorRiskRatingListing",
    component: lazy(() => import("../VendorManagement/VendorRiskRating")),
  },
  {
    path: "VendorRiskRatingExcelUpload",
    component: lazy(() =>
      import(
        "../VendorManagement/VendorRiskRating/VendorRiskRatingExcelUpload.js"
      )
    ),
  },
  {
    path: "VendorRiskRatingViewSummary",
    component: lazy(() =>
      import("../VendorManagement/VendorRiskRating/ViewSummary.js")
    ),
  },
  {
    path: "VendorDocumentsViewSummary",
    component: lazy(() =>
      import(
        "../VendorManagement/VendorRegister/TermsAndCondition/ViewSummary.js"
      )
    ),
  },
  {
    path: "VendorTierListing",
    component: lazy(() => import("../VendorManagement/VendorTier")),
  },
  {
    path: "VendorTierExcelUpload",
    component: lazy(() =>
      import("../VendorManagement/VendorTier/VendorTierExcelUpload.js")
    ),
  },
  {
    path: "VendorTierViewSummary",
    component: lazy(() =>
      import("../VendorManagement/VendorTier/ViewSummary.js")
    ),
  },
  {
    path: "DocumentTypeListing",
    component: lazy(() => import("../VendorManagement/DocumentType")),
  },
  {
    path: "DocumentTypeExcelUpload",
    component: lazy(() =>
      import("../VendorManagement/DocumentType/DocumentTypeExcelUpload.js")
    ),
  },
  {
    path: "DocumentTypeViewSummary",
    component: lazy(() =>
      import("../VendorManagement/DocumentType/ViewSummary.js")
    ),
  },
  {
    path: "TierResponsesListing",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringResponse/ResponsesListing"
      )
    ),
  },

  {
    path: "TierAssessmentForm",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringResponse/AssessmentForm/index.js"
      )
    ),
  },
  {
    path: "TierViewResponses",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringResponse/AssessmentForm/ViewResponses.js"
      )
    ),
  },
  {
    path: "TierGiveResponse",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/TieringResponse/AssessmentForm/GiveResponse.js"
      )
    ),
  },
  {
    path: "tierSurvey",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/SurveyFormBuilder"
      )
    ),
  },
  {
    path: "tierSurveyAssessmentReview",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/SurveyAssessmentReview"
      )
    ),
  },
  {
    path: "tiersurveyFormBuilder",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/FormBuilderTab"
      )
    ),
  },
  {
    path: "tierSurveyAssessmentViewReview",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/SurveyAssessmentViewReview"
      )
    ),
  },
  {
    path: "tierSurveyViewAssessment",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/SurveyListing/SurveyViewAssessment"
      )
    ),
  },
  {
    path: "tierSurveyViewResponses",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/SurveyViewResponses"
      )
    ),
  },
  {
    path: "tierSurveyReviewResponses",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/SurveyViewResponses/SurveyReviewResponses.js"
      )
    ),
  },
  {
    path: "VendorDashboard",
    component: lazy(() => import("../VendorManagement/VendorDashboard")),
  },
  {
    path: "VendorAssessmentDraftListing",
    component: lazy(() =>
      import(
        "../VendorManagement/VendorAssessment/VendorAssessmentDraftListing"
      )
    ),
  },
  {
    path: "VendorAllPublishedListing",
    component: lazy(() =>
      import(
        "../VendorManagement/VendorAssessment/VendorAssessmentPublishedAllListing"
      )
    ),
  },
  {
    path: "VendorAssessmentAwatingListing",
    component: lazy(() =>
      import(
        "../VendorManagement/VendorAssessment/VendorAssessmentAwatingListing"
      )
    ),
  },
  {
    path: "VendorAssessmentListingCompleted",
    component: lazy(() =>
      import(
        "../VendorManagement/VendorAssessment/VendorAssessmentListingCompleted"
      )
    ),
  },
  {
    path: "tierSurveyAssessmentReviewForm",
    component: lazy(() =>
      import(
        "../VendorManagement/TieringAssessment/AllTieringAssement/SurveyAssessmentReviewForm"
      )
    ),
  },
  {
    path: "TierScaleListing",
    component: lazy(() => import("../VendorManagement/TierScale")),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Suspense
          fallback={
            <div className="se-pre-con">
              {" "}
              <img src={require("../../image/loader.svg")} alt="hsgd" />
            </div>
          }
        >
          {routes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Suspense>
      </div>
    );
  }
}

export default AppRouter;
