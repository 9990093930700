import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";
var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDataDisposalActions = {
  SAVE_DATA_DISPOSAL: "SAVE_DATA_DISPOSAL",
  SAVE_DATA_DISPOSAL_STATUS: "SAVE_DATA_DISPOSAL_STATUS",
  SAVE_DATA_DISPOSAL_SUCCESS: "SAVE_DATA_DISPOSAL_SUCCESS",
  SAVE_DATA_DISPOSAL_FAILURE: "SAVE_DATA_DISPOSAL_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveDataDisposalActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}
export function SaveDataDisposalRequest() {
  return {
    type: SaveDataDisposalActions.SAVE_DATA_DISPOSAL,
    isPosting: true,
  };
}

export function SaveDataDisposalSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataDisposalActions.SAVE_DATA_DISPOSAL_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataDisposalFailure(error) {
  return {
    type: SaveDataDisposalActions.SAVE_DATA_DISPOSAL_FAILURE,
    payload: error,
  };
}

export function SaveDataDisposalError(error) {
  return {
    type: SaveDataDisposalActions.SAVE_DATA_DISPOSAL_FAILURE,
    payload: error,
  };
}

export function saveDataDisposal(props) {
  var value = {
    dataDisposalId: props.dataDisposalId || 0,
    dataDisposalName: props.dataDisposalName,
    dataDisposalDesc: props.dataDisposalDesc,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_18/saveDataDisposalMaster`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDataDisposalRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataDisposalSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveDataDisposalError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveDataDisposalError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveDataDisposalError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveDataDisposalError(err));
              break;
            case 500:
              dispatch(SaveDataDisposalError("Server Error"));
              break;
            default:
              dispatch(SaveDataDisposalError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDataDisposalDetailsActions = {
  FETCH_DATA_DISPOSAL_DETAILS: "FETCH_DATA_DISPOSAL_DETAILS",
  FETCH_DATA_DISPOSAL_DETAILS_STATUS: "FETCH_DATA_DISPOSAL_DETAILS_STATUS",
  FETCH_DATA_DISPOSAL_DETAILS_FULFILLED:
    "FETCH_DATA_DISPOSAL_DETAILS_FULFILLED",
  FETCH_DATA_DISPOSAL_DETAILS_REJECTED: "FETCH_DATA_DISPOSAL_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataDisposalDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_18/findDataDisposalMasterById?dataDisposalId=` +
        params.dataDisposalId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataDisposalDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataDisposalDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDataDisposalDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataDisposalDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataDisposalDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataDisposalDetailsError(err));
              break;
            case 500:
              dispatch(getDataDisposalDetailsError("Server Error"));
              break;
            default:
              dispatch(getDataDisposalDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataDisposalDetailsRequest() {
  return {
    type: GetDataDisposalDetailsActions.FETCH_DATA_DISPOSAL_DETAILS,
    isPosting: true,
  };
}
export function getDataDisposalDetailsError(error) {
  return {
    type: GetDataDisposalDetailsActions.FETCH_DATA_DISPOSAL_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataDisposalDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetDataDisposalDetailsActions.FETCH_DATA_DISPOSAL_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataDisposalDetailsStatus() {
  return (dispatch) => dispatch(getDataDisposalDetailsStatusRequest());
}
export function getDataDisposalDetailsStatusRequest() {
  return {
    type: GetDataDisposalDetailsActions.FETCH_DATA_DISPOSAL_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataDisposalDetailsErrorRequest());
}
export function getDataDisposalDetailsErrorRequest() {
  return {
    type: GetDataDisposalDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
export const GetActiveDataDisposalActions = {
  FETCH_ACTIVE_DATA_DISPOSAL: "FETCH_ACTIVE_DATA_DISPOSAL",
  FETCH_ACTIVE_DATA_DISPOSAL_STATUS: "FETCH_ACTIVE_DATA_DISPOSAL_STATUS",
  FETCH_ACTIVE_DATA_DISPOSAL_FULFILLED: "FETCH_ACTIVE_DATA_DISPOSAL_FULFILLED",
  FETCH_ACTIVE_DATA_DISPOSAL_REJECTED: "FETCH_ACTIVE_DATA_DISPOSAL_REJECTED",
};
export function getActiveDataDisposalList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_18/getAllDataDisposalMasterPagination?length=10&sortOrder=desc&sortField=dataDisposalId&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataDisposalListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataDisposalListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDataDisposalListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataDisposalListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataDisposalListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataDisposalListError(err));
              break;
            case 500:
              dispatch(getActiveDataDisposalListError("Server Error"));
              break;
            default:
              dispatch(getActiveDataDisposalListError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataDisposalListRequest() {
  return {
    type: GetActiveDataDisposalActions.FETCH_ACTIVE_DATA_DISPOSAL,
    isPosting: true,
  };
}
export function getActiveDataDisposalListError(error) {
  return {
    type: GetActiveDataDisposalActions.FETCH_ACTIVE_DATA_DISPOSAL_REJECTED,
    payload: error,
  };
}

export function getActiveDataDisposalListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataDisposalActions.FETCH_ACTIVE_DATA_DISPOSAL_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataDisposalListStatus() {
  return (dispatch) => dispatch(getActiveDataDisposalListStatusRequest());
}
export function getActiveDataDisposalListStatusRequest() {
  return {
    type: GetActiveDataDisposalActions.FETCH_ACTIVE_DATA_DISPOSAL_STATUS,
  };
}
