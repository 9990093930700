import {
  GetAllDashboardLayoutActions,
  SaveDashboardLayoutActions,
  GetAllDashboardDataActions,
  AddPanelDashboardLayoutActions,
  CustomizeDashboardLayoutActions,
} from "./actions";

const initState = {
  errorMessage: null,
  SaveDashboardStatus: null,
  SaveDashboardErrorMessage: null,
  SaveDashboardSuccessMessage: null,
  SaveDashboardData: null,
  DashboardLayoutData: null,
  DashboardData: null,
  DashboardDataErrorMessage: null,
  DashboardDataSuccessMessage: null,
  DashboardDataStatus: null,
  DashboardLayoutErrorMessage: null,
  DashboardLayoutSucessMessage: null,
  DashboardLayoutStatus: null,
  AddPanelDashboardErrorMessage: null,
  AddPanelDashboardStatus: null,
  AddPanelDashboardData: null,
  AddPanelDashboardSuccessMessage: null,
  CustomizeDashboardErrorMessage: null,
  CustomizeDashboardStatus: null,
  CustomizeDashboardData: null,
  CustomizeDashboardSuccessMessage: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    //Save Dashboard Data
    case SaveDashboardLayoutActions.SAVE_DASHBOARD_LAYOUT:
      return {
        ...state,
        isLoading: true,
        SaveDashboardErrorMessage: null,
        modalActive: false,
      };
    case SaveDashboardLayoutActions.SAVE_DASHBOARD_LAYOUT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SaveDashboardStatus: payload.status,
        SaveDashboardData: payload.data,
        SaveDashboardErrorMessage: null,
        SaveDashboardSuccessMessage: "Dashboard Layout Saved Successfully!",
      };

    case SaveDashboardLayoutActions.SAVE_DASHBOARD_LAYOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        SaveDashboardStatus: null,
        SaveDashboardErrorMessage: payload,
      };
    case SaveDashboardLayoutActions.SAVE_DASHBOARD_LAYOUT_STATUS:
      return {
        ...state,
        SaveDashboardStatus: 0,
        SaveDashboardErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    //GET ALL DASHBOARD LAYOUR
    case GetAllDashboardLayoutActions.FETCH_ALL_DASHBOARD_LAYOUT_DETAILS:
      return {
        ...state,
        isLoading: true,
        DashboardLayoutErrorMessage: null,
        modalActive: false,
        DashboardLayoutData: null,
      };
    case GetAllDashboardLayoutActions.FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DashboardLayoutData: payload.data,
        DashboardLayoutStatus: payload.status,
        DashboardLayoutErrorMessage: null,
        DashboardLayoutSucessMessage: "",
      };
    case GetAllDashboardLayoutActions.FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        DashboardLayoutErrorMessage: payload,
        DashboardLayoutData: null,
      };
    case GetAllDashboardLayoutActions.FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_STATUS:
      return {
        ...state,
        DashboardLayout: null,
      };

    ///////////////get Dashboard Data/////////////////////

    case GetAllDashboardDataActions.FETCH_ALL_DASHBOARD_DATA_DETAILS:
      return {
        ...state,
        isLoading: true,
        DashboardDataErrorMessage: null,
        modalActive: false,
        DashboardData: null,
      };
    case GetAllDashboardDataActions.FETCH_ALL_DASHBOARD_DATA_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DashboardData: payload.data,
        DashboardDataStatus: payload.status,
        DashboardDataErrorMessage: null,
        DashboardDataSuccessMessage: "",
      };
    case GetAllDashboardDataActions.FETCH_ALL_DASHBOARD_DATA_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        DashboardDataErrorMessage: payload,
        DashboardData: null,
      };
    case GetAllDashboardDataActions.FETCH_ALL_DASHBOARD_DATA_DETAILS_STATUS:
      return {
        ...state,
        DashboardData: null,
      };

    //////////ADD PANEL//////////////
    case AddPanelDashboardLayoutActions.ADD_PANEL_DASHBOARD_LAYOUT:
      return {
        ...state,
        isLoading: true,
        AddPanelDashboardErrorMessage: null,
        modalActive: false,
      };
    case AddPanelDashboardLayoutActions.ADD_PANEL_DASHBOARD_LAYOUT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        AddPanelDashboardStatus: payload.status,
        AddPanelDashboardData: payload.data,
        AddPanelDashboardErrorMessage: null,
        AddPanelDashboardSuccessMessage: "Panel Added Successfully!",
      };

    case AddPanelDashboardLayoutActions.ADD_PANEL_DASHBOARD_LAYOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        AddPanelDashboardStatus: null,
        AddPanelDashboardErrorMessage: payload,
      };
    case AddPanelDashboardLayoutActions.ADD_PANEL_DASHBOARD_LAYOUT_STATUS:
      return {
        ...state,
        AddPanelDashboardStatus: 0,
        AddPanelDashboardErrorMessage: null,
      };

    //CUSTOMIZE//
    case CustomizeDashboardLayoutActions.CUSTOMIZE_DASHBOARD_LAYOUT:
      return {
        ...state,
        isLoading: true,
        CustomizeDashboardErrorMessage: null,
        modalActive: false,
      };
    case CustomizeDashboardLayoutActions.CUSTOMIZE_DASHBOARD_LAYOUT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        CustomizeDashboardStatus: payload.status,
        CustomizeDashboardData: payload.data,
        CustomizeDashboardErrorMessage: null,
        // CustomizeDashboardSuccessMessage: "Panel Added Successfully!",
      };

    case CustomizeDashboardLayoutActions.CUSTOMIZE_DASHBOARD_LAYOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        CustomizeDashboardStatus: null,
        CustomizeDashboardErrorMessage: payload,
      };
    case CustomizeDashboardLayoutActions.CUSTOMIZE_DASHBOARD_LAYOUT_STATUS:
      return {
        ...state,
        CustomizeDashboardStatus: 0,
        CustomizeDashboardErrorMessage: null,
      };

    default:
      return state;
  }
}
