import axios from "axios";
import { SuperAdmin_Service_URL, CookieService_Url } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save SaveCookieTemplate

export const saveCookieTemplateActions = {
  SAVE_COOKIE_TEMPLATE: "SAVE_COOKIE_TEMPLATE",
  SAVE_COOKIE_TEMPLATE_STATUS: "SAVE_COOKIE_TEMPLATE_STATUS",
  SAVE_COOKIE_TEMPLATE_SUCCESS: "SAVE_COOKIE_TEMPLATE_SUCCESS",
  SAVE_COOKIE_TEMPLATE_FAILURE: "SAVE_COOKIE_TEMPLATE_FAILURE",
};

export function saveCookieTemplateRequest() {
  return {
    type: saveCookieTemplateActions.SAVE_COOKIE_TEMPLATE,
    isPosting: true,
  };
}

export function saveCookieTemplateSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveCookieTemplateActions.SAVE_COOKIE_TEMPLATE_SUCCESS,
      payload: props,
    });
  };
}

export function saveCookieTemplateFailure(error) {
  return {
    type: saveCookieTemplateActions.SAVE_COOKIE_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function saveCookieTemplateError(error) {
  return {
    type: saveCookieTemplateActions.SAVE_COOKIE_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function saveCookieTemplate(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CookieService_Url}/Apc_Persist/Amc_93/saveCookieTemplate`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        // dispatch(saveCookieTemplateRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveCookieTemplateSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(saveCookieTemplateError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          // dispatch(saveSaveCookieTemplateError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveSaveCookieTemplateError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveSaveCookieTemplateError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveSaveCookieTemplateError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveSaveCookieTemplateError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete SaveCookieTemplate

export const deleteSaveCookieTemplateActions = {
  DELETE_ASSET_TYPE: "DELETE_ASSET_TYPE",
  DELETE_ASSET_TYPE_STATUS: "DELETE_ASSET_TYPE_STATUS",
  DELETE_ASSET_TYPE_SUCCESS: "DELETE_ASSET_TYPE_SUCCESS",
  DELETE_ASSET_TYPE_FAILURE: "DELETE_ASSET_TYPE_FAILURE",
};

export function deleteSaveCookieTemplateRequest() {
  return {
    type: deleteSaveCookieTemplateActions.DELETE_ASSET_TYPE,
    isPosting: true,
  };
}

export function deleteSaveCookieTemplateSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteSaveCookieTemplateActions.DELETE_ASSET_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function deleteSaveCookieTemplateFailure(error) {
  return {
    type: deleteSaveCookieTemplateActions.DELETE_ASSET_TYPE_FAILURE,
    payload: error,
  };
}

export function deleteSaveCookieTemplateError(error) {
  return {
    type: deleteSaveCookieTemplateActions.DELETE_ASSET_TYPE_FAILURE,
    payload: error,
  };
}

export function deleteSaveCookieTemplate(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${CookieService_Url}/Apc_State/Amc_9/deleteSaveCookieTemplate?assetTypeId=` +
        params.assetTypeID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteSaveCookieTemplateRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteSaveCookieTemplateSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteSaveCookieTemplateError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteSaveCookieTemplateError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteSaveCookieTemplateError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteSaveCookieTemplateError(err));
              break;
            case 500:
              dispatch(deleteSaveCookieTemplateError("Server Error"));
              break;
            default:
              dispatch(deleteSaveCookieTemplateError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetCookieTemplateDetailsActions = {
  FETCH_COOKIE_TEMPLATE_DETAILS: "FETCH_COOKIE_TEMPLATE_DETAILS",
  FETCH_COOKIE_TEMPLATE_DETAILS_STATUS: "FETCH_COOKIE_TEMPLATE_DETAILS_STATUS",
  FETCH_COOKIE_TEMPLATE_DETAILS_FULFILLED:
    "FETCH_COOKIE_TEMPLATE_DETAILS_FULFILLED",
  RESET_COOKIE_TEMPLATE_DETAILS: "RESET_COOKIE_TEMPLATE_DETAILS",
  FETCH_COOKIE_TEMPLATE_DETAILS_REJECTED:
    "FETCH_COOKIE_TEMPLATE_DETAILS_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getCookieTemplateDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${CookieService_Url}/Apc_View/Amc_93/getCookieTemplate?id=` +
        params.templateId,
      headers: {
        // transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getCookieTemplateDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getCookieTemplateDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getCookieTemplateDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(getCookieTemplateDetailsError(err));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getSaveCookieTemplateDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getSaveCookieTemplateDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllCookieDomainDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",

      url: `${CookieService_Url}/Apc_View/Amc_93/getAllCookieDomains`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          const error = new Error(response.statusText);
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getCookieTemplateDetailsRequest() {
  return {
    type: GetCookieTemplateDetailsActions.FETCH_COOKIE_TEMPLATE_DETAILS,
    isPosting: true,
  };
}
export function getCookieTemplateDetailsError(error) {
  return {
    type: GetCookieTemplateDetailsActions.FETCH_COOKIE_TEMPLATE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getCookieTemplateDetailsSuccess(props) {
  var data = { ...props };
  return (dispatch) => {
    dispatch({
      type: GetCookieTemplateDetailsActions.FETCH_COOKIE_TEMPLATE_DETAILS_FULFILLED,
      payload: data,
    });
  };
}

export function getCookieTemplateDetailsReset(props) {
  return (dispatch) => {
    dispatch({
      type: GetCookieTemplateDetailsActions.RESET_COOKIE_TEMPLATE_DETAILS,
    });
  };
}

export function getCookieTemplateDetailsStatus() {
  return (dispatch) => dispatch(getCookieTemplateDetailsStatusRequest());
}
export function getCookieTemplateDetailsStatusRequest() {
  return {
    type: GetCookieTemplateDetailsActions.FETCH_COOKIE_TEMPLATE_DETAILS_STATUS,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
///////SET COOKIE DOMAIN BY ID

export const SetCookieDomainTemplateActions = {
  FETCH_COOKIE_DOMAIN_TEMPLATE: "FETCH_COOKIE_DOMAIN_TEMPLATE",
  FETCH_COOKIE_DOMAIN_TEMPLATE_STATUS: "FETCH_COOKIE_DOMAIN_TEMPLATE_STATUS",
  FETCH_COOKIE_DOMAIN_TEMPLATE_FULFILLED:
    "FETCH_COOKIE_DOMAIN_TEMPLATE_FULFILLED",
  FETCH_COOKIE_DOMAIN_TEMPLATE_REJECTED:
    "FETCH_COOKIE_DOMAIN_TEMPLATE_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getCookieDomainTemplate(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),

      url:
        `${CookieService_Url}/Apc_Persist/Amc_93/setDomainTemplate?domainId=` +
        params.domainId +
        `&templateId=` +
        params.templateId,
      headers: {
        // transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getCookieDomainTemplateRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getCookieDomainTemplateSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getCookieDomainTemplateError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(getCookieDomainTemplateError(err));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieDomainTemplateError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieDomainTemplateError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieDomainTemplateError(err));
          //     break;
          //   case 500:
          //     dispatch(getSaveCookieDomainTemplateError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getSaveCookieDomainTemplateError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getCookieDomainTemplateRequest() {
  return {
    type: SetCookieDomainTemplateActions.FETCH_COOKIE_DOMAIN_TEMPLATE,
    isPosting: true,
  };
}
export function getCookieDomainTemplateError(error) {
  return {
    type: SetCookieDomainTemplateActions.FETCH_COOKIE_DOMAIN_TEMPLATE_REJECTED,
    payload: error,
  };
}

export function getCookieDomainTemplateSuccess(props) {
  var data = { ...props };
  return (dispatch) => {
    dispatch({
      type: SetCookieDomainTemplateActions.FETCH_COOKIE_DOMAIN_TEMPLATE_FULFILLED,
      payload: data,
    });
  };
}

export function getCookieDomainTemplateStatus() {
  return (dispatch) => dispatch(getCookieDomainTemplateStatusRequest());
}
export function getCookieDomainTemplateStatusRequest() {
  return {
    type: SetCookieDomainTemplateActions.FETCH_COOKIE_DOMAIN_TEMPLATE_STATUS,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////

export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("assetTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url: `${CookieService_Url}/Apc_Persist/Amc_62/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const saveCookieBannerContentActions = {
  SAVE_COOKIE_BANNER_CONTENT: "SAVE_COOKIE_BANNER_CONTENT",
  SAVE_COOKIE_BANNER_CONTENT_STATUS: "SAVE_COOKIE_BANNER_CONTENT_STATUS",
  SAVE_COOKIE_BANNER_CONTENT_SUCCESS: "SAVE_COOKIE_BANNER_CONTENT_SUCCESS",
  SAVE_COOKIE_BANNER_CONTENT_FAILURE: "SAVE_COOKIE_BANNER_CONTENT_FAILURE",
};

export function saveCookieBannerContentRequest() {
  return {
    type: saveCookieBannerContentActions.SAVE_COOKIE_BANNER_CONTENT,
    isPosting: true,
  };
}

export function saveCookieBannerContentSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveCookieBannerContentActions.SAVE_COOKIE_BANNER_CONTENT_SUCCESS,
      payload: props,
    });
  };
}

export function saveCookieBannerContentFailure(error) {
  return {
    type: saveCookieBannerContentActions.SAVE_COOKIE_BANNER_CONTENT_FAILURE,
    payload: error,
  };
}

export function saveCookieBannerContentError(error) {
  return {
    type: saveCookieBannerContentActions.SAVE_COOKIE_BANNER_CONTENT_FAILURE,
    payload: error,
  };
}

export function saveCookieBannerContent(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CookieService_Url}/Apc_Persist/Amc_93/saveCookieTemplateDetails`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveCookieBannerContentRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveCookieBannerContentSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(saveCookieBannerContentError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveSaveCookieBannerContentError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveSaveCookieBannerContentError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveSaveCookieBannerContentError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveSaveCookieBannerContentError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveSaveCookieBannerContentError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export const saveCookieDetailsActions = {
  SAVE_COOKIE_DETAILS: "SAVE_COOKIE_DETAILS",
  SAVE_COOKIE_DETAILS_STATUS: "SAVE_COOKIE_DETAILS_STATUS",
  SAVE_COOKIE_DETAILS_SUCCESS: "SAVE_COOKIE_DETAILS_SUCCESS",
  SAVE_COOKIE_DETAILS_FAILURE: "SAVE_COOKIE_DETAILS_FAILURE",
};

export function saveCookieDetailsRequest() {
  return {
    type: saveCookieDetailsActions.SAVE_COOKIE_DETAILS,
    isPosting: true,
  };
}

export function saveCookieDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveCookieDetailsActions.SAVE_COOKIE_DETAILS_SUCCESS,
      payload: props,
    });
  };
}

export function saveCookieDetailsFailure(error) {
  return {
    type: saveCookieDetailsActions.SAVE_COOKIE_DETAILS_FAILURE,
    payload: error,
  };
}

export function saveCookieDetailsError(error) {
  return {
    type: saveCookieDetailsActions.SAVE_COOKIE_DETAILS_FAILURE,
    payload: error,
  };
}

export function saveCookieDetails(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CookieService_Url}/Apc_Persist/Amc_93/saveCookieDetails`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveCookieDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveCookieDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(saveCookieDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveSaveCookieTemplateError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveSaveCookieTemplateError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveSaveCookieTemplateError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveSaveCookieTemplateError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveSaveCookieTemplateError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export const ViewDomainCookiesActions = {
  FETCH_DOMAIN_COOKIES: "FETCH_DOMAIN_COOKIES",
  FETCH_DOMAIN_COOKIES_STATUS: "FETCH_DOMAIN_COOKIES_STATUS",
  FETCH_DOMAIN_COOKIES_FULFILLED: "FETCH_DOMAIN_COOKIES_FULFILLED",
  FETCH_DOMAIN_COOKIES_REJECTED: "FETCH_DOMAIN_COOKIES_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function viewSannedDomainCookies(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${CookieService_Url}/Apc_View/Amc_93/getCookieScanDetails?id=` +
        params.scanId,
      headers: {
        // transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(viewDomainCookiesRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(viewDomainCookiesSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(viewDomainCookiesError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(viewDomainCookiesError(err));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getSaveCookieTemplateDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getSaveCookieTemplateDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function viewDomainCookiesRequest() {
  return {
    type: ViewDomainCookiesActions.FETCH_DOMAIN_COOKIES,
    isPosting: true,
  };
}
export function viewDomainCookiesError(error) {
  return {
    type: ViewDomainCookiesActions.FETCH_DOMAIN_COOKIES_REJECTED,
    payload: error,
  };
}

export function viewDomainCookiesSuccess(props) {
  var data = { ...props };
  return (dispatch) => {
    dispatch({
      type: ViewDomainCookiesActions.FETCH_DOMAIN_COOKIES_FULFILLED,
      payload: data,
    });
  };
}

///////Cookie Template Listing

export const getCookieTemplateListActions = {
  FETCH_COOKIE_TEMP_LIST: "FETCH_COOKIE_TEMP_LIST",
  FETCH_COOKIE_TEMP_LIST_STATUS: "FETCH_COOKIE_TEMP_LIST_STATUS",
  FETCH_COOKIE_TEMP_LIST_FULFILLED: "FETCH_COOKIE_TEMP_LIST_FULFILLED",
  FETCH_COOKIE_TEMP_LIST_REJECTED: "FETCH_COOKIE_TEMP_LIST_REJECTED",
};

export function getCookieTempList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      data: {
        defaultSort: {
          sortField: "id",
          sortOrder: "desc",
        },
        length: 0,
        sort: [
          {
            sortField: "id",
            sortOrder: "desc",
          },
        ],
        start: 0,
        status: true,
      },
      url: `${CookieService_Url}/Apc_View/Amc_93/listing/CookieTemplate`,
      headers: {
        // transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getCookieTemplateListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getCookieTemplateListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getCookieTemplateListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieTemplateListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieTemplateListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieTemplateListError(err));
              break;
            case 500:
              dispatch(getCookieTemplateListError("Server Error"));
              break;
            default:
              dispatch(getCookieTemplateListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getCookieTempListByDomainOnly(params) {
  return (dispatch) =>
    axios({
      method: "post",

      url: `${CookieService_Url}/Apc_View/Amc_93/getAllCookieTemplateByDomainName?domainName=${params.domainName}`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getCookieTemplateListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getCookieTemplateListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getCookieTemplateListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieTemplateListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieTemplateListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieTemplateListError(err));
              break;
            case 500:
              dispatch(getCookieTemplateListError("Server Error"));
              break;
            default:
              dispatch(getCookieTemplateListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getCookieTemplateListRequest() {
  return {
    type: getCookieTemplateListActions.FETCH_COOKIE_TEMP_LIST,
    isPosting: true,
  };
}
export function getCookieTemplateListError(error) {
  return {
    type: getCookieTemplateListActions.FETCH_COOKIE_TEMP_LIST_REJECTED,
    payload: error,
  };
}

export function getCookieTemplateListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getCookieTemplateListActions.FETCH_COOKIE_TEMP_LIST_FULFILLED,
      payload: props,
    });
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
///Cookie Selection save///////////

export const saveCookieSelectionActions = {
  SAVE_COOKIE_SELECTION: "SAVE_COOKIE_SELECTION",
  SAVE_COOKIE_SELECTION_STATUS: "SAVE_COOKIE_SELECTION_STATUS",
  SAVE_COOKIE_SELECTION_SUCCESS: "SAVE_COOKIE_SELECTION_SUCCESS",
  SAVE_COOKIE_SELECTION_FAILURE: "SAVE_COOKIE_SELECTION_FAILURE",
};

export function saveCookieSelectionRequest() {
  return {
    type: saveCookieSelectionActions.SAVE_COOKIE_SELECTION,
    isPosting: true,
  };
}

export function saveCookieSelectionSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveCookieSelectionActions.SAVE_COOKIE_SELECTION_SUCCESS,
      payload: props,
    });
  };
}

export function saveCookieSelectionFailure(error) {
  return {
    type: saveCookieSelectionActions.SAVE_COOKIE_SELECTION_FAILURE,
    payload: error,
  };
}

export function saveCookieSelectionError(error) {
  return {
    type: saveCookieSelectionActions.SAVE_COOKIE_SELECTION_FAILURE,
    payload: error,
  };
}

export function saveCookieSelection(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CookieService_Url}/Apc_Persist/Amc_93/saveCookieSelection`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveCookieSelectionRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveCookieSelectionSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(saveCookieSelectionError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveSaveCookieSelectionError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveSaveCookieSelectionError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveSaveCookieSelectionError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveSaveCookieSelectionError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveSaveCookieSelectionError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function viewCookieConsentTrackingData(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CookieService_Url}/Apc_View/Amc_91/getCookieConsentDetails`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
      params: {
        domainName: params.domainName,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          const error = new Error(response.statusText);
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(viewDomainCookiesError(err));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getSaveCookieTemplateDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getSaveCookieTemplateDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getSaveCookieTemplateDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function viewCookieConsentTrackingList(access_token, params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${CookieService_Url}/Apc_View/Amc_91/listingCookieConsentDetails`,
      headers: {
        Authorization: "Bearer " + access_token,
      },
      data: params,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          const error = new Error(response.statusText);
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getCookieCatoriesData(access_token, params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${CookieService_Url}/Apc_View/Amc_62/listing/CookieCategory`,
      headers: {
        Authorization: "Bearer " + access_token,
      },
      data: params,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          const error = new Error(response.statusText);
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function deleteCookieLanguageData(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CookieService_Url}/Apc_State/Amc_93/activateDeactivateLanguage`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          const error = new Error(response.statusText);
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}
