import { getSVMAction } from "./actions";

const initState = {
  SVM: [],
  SVMStatus: null,
  errorMessage: null
};

export default function SVM(state = initState, { type, payload }) {
  switch (type) {
    case getSVMAction.FETCH_SVM:
      return {
        ...state,
        errorMessage: null
      };
    case getSVMAction.FETCH_SVM_FULFILLED:
      return {
        ...state,
        SVMStatus: payload.status,
        SVM: payload.data,
        errorMessage: null
      };
    case getSVMAction.FETCH_SVM_REJECTED:
      return {
        ...state,
        errorMessage: payload,
        SVM: []
      };
    case getSVMAction.SET_SVM_ERROR:
      return {
        errorMessage: payload,
        SVM: []
      };
    default:
      return state;
  }
}
