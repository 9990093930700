import antdHI from "antd/lib/locale-provider/hi_IN";
import appLocaleData from "react-intl/locale-data/hi";
import hiMessages from "../locales/hi_IN.json";

const hiLang = {
  messages: {
    ...hiMessages
  },
  antd: antdHI,
  locale: "hi",
  data: appLocaleData
};
export default hiLang;
