import React, { Component } from "react";

import Form from "../../components/uielements/form";
import Input from "../../components/uielements/input";
import PageHeader from "../../components/utility/pageHeader";
import Box from "../../components/utility/box";
import LayoutWrapper from "../../components/utility/layoutWrapper.js";
import IntlMessages from "../../components/utility/intlMessages";
import CustomLogout from "./CustomLogout";
import Button from "../../components/uielements/button";

const FormItem = Form.Item;

export default class extends Component {
  render() {
    return (
      <div className="asset-detail ">
        {/* <LayoutWrapper> */}
        <CustomLogout />
        {/* </LayoutWrapper> */}
      </div>
    );
  }
}
