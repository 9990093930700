import axios from "axios";
import { RiskManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveRiskWorkflowActions = {
  SAVE_RISKWORKFLOW: "SAVE_RISKWORKFLOW",
  SAVE_RISKWORKFLOW_STATUS: "SAVE_RISKWORKFLOW_STATUS",
  SAVE_RISKWORKFLOW_SUCCESS: "SAVE_RISKWORKFLOW_SUCCESS",
  SAVE_RISKWORKFLOW_FAILURE: "SAVE_RISKWORKFLOW_FAILURE",
};

export function SaveRiskWorkflowRequest() {
  return {
    type: SaveRiskWorkflowActions.SAVE_RISKWORKFLOW,
    isPosting: true,
  };
}

export function SaveRiskWorkflowSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveRiskWorkflowActions.SAVE_RISKWORKFLOW_SUCCESS,
      payload: props,
    });
  };
}

export function SaveRiskWorkflowFailure(error) {
  return {
    type: SaveRiskWorkflowActions.SAVE_RISKWORKFLOW_FAILURE,
    payload: error,
  };
}

export function SaveRiskWorkflowError(error) {
  return {
    type: SaveRiskWorkflowActions.SAVE_RISKWORKFLOW_FAILURE,
    payload: error,
  };
}

export function saveRiskWorkflow(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/Apc_Persist/Amc_9/saveRiskWorkFlowMaster`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveRiskWorkflowRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveRiskWorkflowSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveRiskWorkflowError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(SaveRiskWorkflowError(err));
          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveRiskWorkflowError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveRiskWorkflowError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveRiskWorkflowError(err));
          //   break;
          // case 500:
          //   dispatch(SaveRiskWorkflowError("Server Error"));
          //   break;
          // default:
          //   dispatch(SaveRiskWorkflowError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetRiskWorkflowDetailsActions = {
  FETCH_RISKWORKFLOW: "FETCH_RISKWORKFLOW_DETAILS",
  FETCH_RISKWORKFLOW_STATUS: "FETCH_RISKWORKFLOW_DETAILS_STATUS",
  FETCH_RISKWORKFLOW_FULFILLED: "FETCH_RISKWORKFLOW_DETAILS_FULFILLED",
  FETCH_RISKWORKFLOW_REJECTED: "FETCH_RISKWORKFLOW_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskWorkflowDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/findRiskWorkFlowMasterById?riskWorkFlowMasterId=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRiskWorkflowDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          
          dispatch(getRiskWorkflowDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRiskWorkflowDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskWorkflowDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskWorkflowDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskWorkflowDetailsError(err));
              break;
            case 500:
              dispatch(getRiskWorkflowDetailsError("Server Error"));
              break;
            default:
              dispatch(getRiskWorkflowDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskWorkflowDetailsRequest() {
  return {
    type: GetRiskWorkflowDetailsActions.FETCH_RISKWORKFLOW,
    isPosting: true,
  };
}
export function getRiskWorkflowDetailsError(error) {
  return {
    type: GetRiskWorkflowDetailsActions.FETCH_RISKWORKFLOW_REJECTED,
    payload: error,
  };
}

export function getRiskWorkflowDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRiskWorkflowDetailsActions.FETCH_RISKWORKFLOW_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskWorkflowDetailsStatus() {
  return (dispatch) => dispatch(getRiskWorkflowDetailsStatusRequest());
}
export function getRiskWorkflowDetailsStatusRequest() {
  return {
    type: GetRiskWorkflowDetailsActions.FETCH_RISKWORKFLOW_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getRiskWorkflowDetailsErrorRequest());
}
export function getRiskWorkflowDetailsErrorRequest() {
  return {
    type: GetRiskWorkflowDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("riskWorkflow", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RiskManagementService_URL}/Apc_Persist/Amc_9/bulkUploadExcel/RiskWorkFlowMaster`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveRiskWorkflowActions = {
  FETCH_ACTIVE_RISKWORKFLOW: "FETCH_ACTIVE_RISKWORKFLOW",
  FETCH_ACTIVE_RISKWORKFLOW_STATUS: "FETCH_ACTIVE_RISKWORKFLOW_STATUS",
  FETCH_ACTIVE_RISKWORKFLOW_FULFILLED: "FETCH_ACTIVE_RISKWORKFLOW_FULFILLED",
  FETCH_ACTIVE_RISKWORKFLOW_REJECTED: "FETCH_ACTIVE_RISKWORKFLOW_REJECTED",
};
export function getActiveRiskWorkflowList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RiskManagementService_URL}/Apc_View/Amc_9/listing/RiskWorkFlowMaster`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveRiskWorkflowListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRiskWorkflowListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveRiskWorkflowListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskWorkflowListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskWorkflowListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskWorkflowListError(err));
              break;
            case 500:
              dispatch(getActiveRiskWorkflowListError("Server Error"));
              break;
            default:
              dispatch(getActiveRiskWorkflowListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRiskWorkflowListRequest() {
  return {
    type: GetActiveRiskWorkflowActions.FETCH_ACTIVE_RISKWORKFLOW,
    isPosting: true,
  };
}
export function getActiveRiskWorkflowListError(error) {
  return {
    type: GetActiveRiskWorkflowActions.FETCH_ACTIVE_RISKWORKFLOW_REJECTED,
    payload: error,
  };
}

export function getActiveRiskWorkflowListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveRiskWorkflowActions.FETCH_ACTIVE_RISKWORKFLOW_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRiskWorkflowListStatus() {
  return (dispatch) => dispatch(getActiveRiskWorkflowListStatusRequest());
}
export function getActiveRiskWorkflowListStatusRequest() {
  return {
    type: GetActiveRiskWorkflowActions.FETCH_ACTIVE_RISKWORKFLOW_STATUS,
  };
}

// Get all RiskWorkflow Pagination

export const GetRiskWorkflowActions = {
  FETCH_RISKWORKFLOW: "FETCH_RISKWORKFLOW",
  FETCH_RISKWORKFLOW_STATUS: "FETCH_RISKWORKFLOW_STATUS",
  FETCH_RISKWORKFLOW_FULFILLED:
    "FETCH_RISKWORKFLOW_FULFILLED",
  FETCH_RISKWORKFLOW_REJECTED:
    "FETCH_RISKWORKFLOW_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskWorkflow(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/getAllRiskWorkFlowMasterPagination?length=0&sortOrder=desc&sortField=riskWorkFlowId&status=true`, 
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskWorkflowSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRiskWorkflowError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskWorkflowError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskWorkflowError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskWorkflowError(err));
              break;
            case 500:
              dispatch(getRiskWorkflowError("Server Error"));
              break;
            default:
              dispatch(getRiskWorkflowError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskWorkflowRequest() {
  return {
    type: GetRiskWorkflowActions.FETCH_RISKWORKFLOW,
    isPosting: true,
  };
}
export function getRiskWorkflowError(error) {
  return {
    type: GetRiskWorkflowActions.FETCH_RISKWORKFLOW_REJECTED,
    payload: error,
  };
}

export function getRiskWorkflowSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetRiskWorkflowActions.FETCH_RISKWORKFLOW_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskWorkflowStatus() {
  return (dispatch) => dispatch(getRiskWorkflowStatusRequest());
}
export function getRiskWorkflowStatusRequest() {
  return {
    type: GetRiskWorkflowActions.FETCH_RISKWORKFLOW_STATUS,
  };
}