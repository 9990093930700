import axios from "axios";
import { SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Purpose

export const savePurposeActions = {
  SAVE_PURPOSE: "SAVE_PURPOSE",
  SAVE_PURPOSE_STATUS: "SAVE_PURPOSE_STATUS",
  SAVE_PURPOSE_SUCCESS: "SAVE_PURPOSE_SUCCESS",
  SAVE_PURPOSE_FAILURE: "SAVE_PURPOSE_FAILURE",
};

export function savePurposeRequest() {
  return {
    type: savePurposeActions.SAVE_PURPOSE,
    isPosting: true,
  };
}

export function savePurposeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: savePurposeActions.SAVE_PURPOSE_SUCCESS,
      payload: props,
    });
  };
}

export function savePurposeFailure(error) {
  return {
    type: savePurposeActions.SAVE_PURPOSE_FAILURE,
    payload: error,
  };
}

export function savePurposeError(error) {
  return {
    type: savePurposeActions.SAVE_PURPOSE_FAILURE,
    payload: error,
  };
}

export function savePurpose(props, access_token) {
  // var value = {
  //   assetTypeId: props.assetTypeId || 0,
  //   assetTypeName: props.assetTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SystemService_URL}/Apc_Persist/Amc_28/savePurposeMaster`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(savePurposeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(savePurposeSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(savePurposeError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}
export function logout() {
  return {
    type: savePurposeActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}
// Delete Purpose

export const deletePurposeActions = {
  DELETE_PURPOSE: "DELETE_PURPOSE",
  DELETE_PURPOSE_STATUS: "DELETE_PURPOSE_STATUS",
  DELETE_PURPOSE_SUCCESS: "DELETE_PURPOSE_SUCCESS",
  DELETE_PURPOSE_FAILURE: "DELETE_PURPOSE_FAILURE",
};

export function deletePurposeRequest() {
  return {
    type: deletePurposeActions.DELETE_PURPOSE,
    isPosting: true,
  };
}

export function deletePurposeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deletePurposeActions.DELETE_PURPOSE_SUCCESS,
      payload: props,
    });
  };
}

export function deletePurposeFailure(error) {
  return {
    type: deletePurposeActions.DELETE_PURPOSE_FAILURE,
    payload: error,
  };
}

export function deletePurposeError(error) {
  return {
    type: deletePurposeActions.DELETE_PURPOSE_FAILURE,
    payload: error,
  };
}

export function deletePurpose(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${SystemService_URL}/deletePurpose?assetTypeId=` + params.assetTypeID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deletePurposeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deletePurposeSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deletePurposeError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetPurposeDetailsActions = {
  FETCH_PURPOSE_DETAILS: "FETCH_PURPOSE_DETAILS",
  FETCH_PURPOSE_DETAILS_STATUS: "FETCH_PURPOSE_DETAILS_STATUS",
  FETCH_PURPOSE_DETAILS_FULFILLED: "FETCH_PURPOSE_DETAILS_FULFILLED",
  FETCH_PURPOSE_DETAILS_REJECTED: "FETCH_PURPOSE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getPurposeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_28/findPurposeMasterById?purposeId=` +
        params.purposeId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getPurposeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getPurposeDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getPurposeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getPurposeDetailsRequest() {
  return {
    type: GetPurposeDetailsActions.FETCH_PURPOSE_DETAILS,
    isPosting: true,
  };
}
export function getPurposeDetailsError(error) {
  return {
    type: GetPurposeDetailsActions.FETCH_PURPOSE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getPurposeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetPurposeDetailsActions.FETCH_PURPOSE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getPurposeDetailsStatus() {
  return (dispatch) => dispatch(getPurposeDetailsStatusRequest());
}
export function getPurposeDetailsStatusRequest() {
  return {
    type: GetPurposeDetailsActions.FETCH_PURPOSE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getPurposeDetailsErrorRequest());
}
export function getPurposeDetailsErrorRequest() {
  return {
    type: GetPurposeDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("assetTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${SystemService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LISTActivePurpose

export const GetActivePurposeActions = {
  FETCH_ACTIVE_PURPOSE: "FETCH_ACTIVE_PURPOSE",
  FETCH_ACTIVE_PURPOSE_STATUS: "FETCH_ACTIVE_PURPOSE_STATUS",
  FETCH_ACTIVE_PURPOSE_FULFILLED: "FETCH_ACTIVE_PURPOSE_FULFILLED",
  FETCH_ACTIVE_PURPOSE_REJECTED: "FETCH_ACTIVE_PURPOSE_REJECTED",
};

export function getActivePurposeList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_28/getAllPurposeMasterPagination
?start=0&sortOrder=asc&booleanfield=true&sortField=purposeId&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActivePurposeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActivePurposeListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActivePurposeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActivePurposeListRequest() {
  return {
    type: GetActivePurposeActions.FETCH_ACTIVE_PURPOSE,
    isPosting: true,
  };
}
export function getActivePurposeListError(error) {
  return {
    type: GetActivePurposeActions.FETCH_ACTIVE_PURPOSE_REJECTED,
    payload: error,
  };
}

export function getActivePurposeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActivePurposeActions.FETCH_ACTIVE_PURPOSE_FULFILLED,
      payload: props,
    });
  };
}

export function getActivePurposeListStatus() {
  return (dispatch) => dispatch(getActivePurposeListStatusRequest());
}
export function getActivePurposeListStatusRequest() {
  return {
    type: GetActivePurposeActions.FETCH_ACTIVE_PURPOSE_STATUS,
  };
}
