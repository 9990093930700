import {
  saveConsentPurposeActions,
  getConsentPurposeDetailsActions,
  GetActiveConsentPurposeActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  
} from "./actions";

const initState = {
  assetSuccessMessage: null,
  consentPurposeErrorMessage: null,
  consentPurposeStatus: null,
  consentPurposeMaster: [],
  consentPurposeData: null,
  ActiveConsentPurposeList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveConsentPurposeActions.SAVE_ASSET_TYPE:
      return {
        ...state,
        isLoading: true,
        consentPurposeErrorMessage: null,
        modalActive: false
      };
    case saveConsentPurposeActions.SAVE_CONSENT_PURPOSE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentPurposeStatus: payload.status,
        consentPurposeErrorMessage: null,
        assetSuccessMessage: "Consent Purpose added successfully!"
      };

    case saveConsentPurposeActions.SAVE_CONSENT_PURPOSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        consentPurposeStatus: null,
        consentPurposeErrorMessage: payload
      };
    case saveConsentPurposeActions.SAVE_CONSENT_PURPOSE_STATUS:
      return {
        ...state,
        consentPurposeStatus: 0,
        consentPurposeErrorMessage: null
      };
    ///////////////////////////////////////////////////////
    // GET  BY ID
    case getConsentPurposeDetailsActions.FETCH_CONSENT_PURPOSE_DETAILS:
      return {
        ...state,
        isLoading: true,
        consentPurposeErrorMessage: null,
        modalActive: false,
        ConsentPurposeData: null
      };
    case getConsentPurposeDetailsActions.FETCH_CONSENT_PURPOSE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ConsentPurposeData: payload.data,
        ConsentPurposeDataStatus: payload.status,
        ConsentPurposeErrorMessage: null
      };
    case getConsentPurposeDetailsActions.FETCH_CONSENT_PURPOSE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        consentPurposeErrorMessage: payload,
        ConsentPurposeData: null
      };
    case getConsentPurposeDetailsActions.FETCH_CONSENT_PURPOSE_DETAILS_STATUS:
      return {
        ...state,
        ConsentPurposeData: null
      };
      // GET All Active
    case GetActiveConsentPurposeActions.FETCH_ACTIVE_CONSENT_PURPOSE_STATUS:
      return {
        ...state,
        isLoading: true,
        consentPurposeErrorMessage: null,
        modalActive: false,
        ConsentPurposeData: null
      };
    case GetActiveConsentPurposeActions.FETCH_ACTIVE_CONSENT_PURPOSE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveConsentPurposeList: payload.data,
        ConsentPurposeDataStatus: payload.status,
        ConsentPurposeErrorMessage: null
      };
    case GetActiveConsentPurposeActions.FETCH_ACTIVE_CONSENT_PURPOSE_REJECTED:
      return {
        ...state,
        isLoading: false,
        consentPurposeErrorMessage: payload,
        ActiveConsentPurposeList: null
      };
    case GetActiveConsentPurposeActions.FETCH_ACTIVE_CONSENT_PURPOSE_STATUS:
      return {
        ...state,
        ActiveConsentPurposeList: null
      };
    // clear ConsentPurpose details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetConsentPurpose: null,
        ConsentPurposeStatus: null,
        assetSuccessMessage: null,
        GetConsentPurposeStatus: null,
        ConsentPurposeData: null,
        ActiveConsentPurposeList:null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
