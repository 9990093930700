import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDataSubCatActions = {
  SAVE_DATA_SUB_CAT: "SAVE_DATA_SUB_CAT",
  SAVE_DATA_SUB_CAT_STATUS: "SAVE_DATA_SUB_CAT_STATUS",
  SAVE_DATA_SUB_CAT_SUCCESS: "SAVE_DATA_SUB_CAT_SUCCESS",
  SAVE_DATA_SUB_CAT_FAILURE: "SAVE_DATA_SUB_CAT_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveDataSubCatActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveDataSubCatRequest() {
  return {
    type: SaveDataSubCatActions.SAVE_DATA_SUB_CAT,
    isPosting: true,
  };
}

export function SaveDataSubCatSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataSubCatActions.SAVE_DATA_SUB_CAT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataSubCatFailure(error) {
  return {
    type: SaveDataSubCatActions.SAVE_DATA_SUB_CAT_FAILURE,
    payload: error,
  };
}

export function SaveDataSubCatError(error) {
  return {
    type: SaveDataSubCatActions.SAVE_DATA_SUB_CAT_FAILURE,
    payload: error,
  };
}

export function saveDataSubCat(props) {
  var value = {
    id: props.dataSubCatId || null,
    name: props.dataSubCatName,
    description: props.dataSubCatDesc,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_15/saveDataCategory`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        
        dispatch(SaveDataSubCatRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataSubCatSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveDataSubCatError(error));
          // throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveRoleError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveRoleError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDataSubCatDetailsActions = {
  FETCH_DATA_SUB_CAT_DETAILS: "FETCH_DATA_SUB_CAT_DETAILS",
  FETCH_DATA_SUB_CAT_DETAILS_STATUS: "FETCH_DATA_SUB_CAT_DETAILS_STATUS",
  FETCH_DATA_SUB_CAT_DETAILS_FULFILLED: "FETCH_DATA_SUB_CAT_DETAILS_FULFILLED",
  FETCH_DATA_SUB_CAT_DETAILS_REJECTED: "FETCH_DATA_SUB_CAT_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataSubCatDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_15/findDataCategoryById?id=` +
        params.dataSubjectCategoryId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataSubCatDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataSubCatDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDataSubCatDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubCatDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubCatDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSubCatDetailsError(err));
              break;
            case 500:
              dispatch(getDataSubCatDetailsError("Server Error"));
              break;
            default:
              dispatch(getDataSubCatDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataSubCatDetailsRequest() {
  return {
    type: GetDataSubCatDetailsActions.FETCH_DATA_SUB_CAT_DETAILS,
    isPosting: true,
  };
}
export function getDataSubCatDetailsError(error) {
  return {
    type: GetDataSubCatDetailsActions.FETCH_DATA_SUB_CAT_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataSubCatDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetDataSubCatDetailsActions.FETCH_DATA_SUB_CAT_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataSubCatDetailsStatus() {
  return (dispatch) => dispatch(getDataSubCatDetailsStatusRequest());
}
export function getDataSubCatDetailsStatusRequest() {
  return {
    type: GetDataSubCatDetailsActions.FETCH_DATA_SUB_CAT_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataSubCatDetailsErrorRequest());
}
export function getDataSubCatDetailsErrorRequest() {
  return {
    type: GetDataSubCatDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDataSubjectCatActions = {
  FETCH_ACTIVE_DATA_SUBJECT: "FETCH_ACTIVE_DATA_SUBJECT",
  FETCH_ACTIVE_DATA_SUBJECT_STATUS: "FETCH_ACTIVE_DATA_SUBJECT_STATUS",
  FETCH_ACTIVE_DATA_SUBJECT_FULFILLED: "FETCH_ACTIVE_DATA_SUBJECT_FULFILLED",
  FETCH_ACTIVE_DATA_SUBJECT_REJECTED: "FETCH_ACTIVE_DATA_SUBJECT_REJECTED",
};
export function getActiveDataSubjectList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_15/getAllDataCategoryPagination?length=0&sortOrder=desc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataSubjectListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataSubjectListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDataSubjectListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubjectListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubjectListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubjectListError(err));
              break;
            case 500:
              dispatch(getActiveDataSubjectListError("Server Error"));
              break;
            default:
              dispatch(getActiveDataSubjectListError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataSubjectListRequest() {
  return {
    type: GetActiveDataSubjectCatActions.FETCH_ACTIVE_DATA_SUBJECT,
    isPosting: true,
  };
}
export function getActiveDataSubjectListError(error) {
  return {
    type: GetActiveDataSubjectCatActions.FETCH_ACTIVE_DATA_SUBJECT_REJECTED,
    payload: error,
  };
}

export function getActiveDataSubjectListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataSubjectCatActions.FETCH_ACTIVE_DATA_SUBJECT_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataSubjectListStatus() {
  return (dispatch) => dispatch(getActiveDataSubjectListStatusRequest());
}
export function getActiveDataSubjectListStatusRequest() {
  return {
    type: GetActiveDataSubjectCatActions.FETCH_ACTIVE_DATA_SUBJECT_STATUS,
  };
}
