import axios from "axios";
import { RopaManagementService_URL, AssetManagementService_URL } from "../Url";
import { clearLogoutData } from "../Logout/actions";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Asset

export const saveActivityActions = {
  SAVE_ACTIVITY: "SAVE_ACTIVITY",
  SAVE_ACTIVITY_STATUS: "SAVE_ACTIVITY_STATUS",
  SAVE_ACTIVITY_SUCCESS: "SAVE_ACTIVITY_SUCCESS",
  SAVE_ACTIVITY_FAILURE: "SAVE_ACTIVITY_FAILURE",
};

export function saveActivityRequest() {
  return {
    type: saveActivityActions.SAVE_ACTIVITY,
    isPosting: true,
  };
}

export function saveActivitySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveActivityActions.SAVE_ACTIVITY_SUCCESS,
      payload: props,
    });
  };
}

export function saveActivityFailure(error) {
  return {
    type: saveActivityActions.SAVE_ACTIVITY_FAILURE,
    payload: error,
  };
}

export function saveActivityError(error) {
  return {
    type: saveActivityActions.SAVE_ACTIVITY_FAILURE,
    payload: error,
  };
}

export function saveActivity(props, access_token) {
  // var value = {
  //   assetTypeId: props.assetTypeId || null,
  //   assetTypeName: props.assetTypeName
  // };

  return (dispatch) =>
    axios({
      method: "POST",
      data: props,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_19/saveActivityDetails`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveActivityRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveActivitySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveActivityError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveActivityError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveActivityError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveActivityError(err));
              break;
            case 500:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveActivityError(err));
              break;
            default:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveActivityError(err));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// if (PageName === "ACTIVITYMASTER") {
//   methodType = "POST";
//   const sortField = props.sortField || "id";
//   handler =
//     `${RopaManagementService_URL}/getAllRopaMasterPagination?start=` +
//     page +
//     `&length=` +
//     result +
//     `&sortOrder=` +
//     sortOrder +
//     `&sortField=` +
//     sortField +
//     `&booleanfield=` +
//     active +
//     `&search=` +
//     search +
//     `&searchCol=` +
//     searchCol +
//     `&access_token=` +
//     access_token;
// }

// Delete Asset

export const deleteActivityActions = {
  DELETE_ASSET: "DELETE_ACTIVITY",
  DELETE_ASSET_STATUS: "DELETE_ACTIVITY_STATUS",
  DELETE_ASSET_SUCCESS: "DELETE_ACTIVITY_SUCCESS",
  DELETE_ASSET_FAILURE: "DELETE_ACTIVITY_FAILURE",
};

export function deleteActivityRequest() {
  return {
    type: deleteActivityActions.DELETE_ACTIVITY,
    isPosting: true,
  };
}

export function deleteActivitySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteActivityActions.DELETE_ACTIVITY_SUCCESS,
      payload: props,
    });
  };
}

export function deleteActivityFailure(error) {
  return {
    type: deleteActivityActions.DELETE_ACTIVITY_FAILURE,
    payload: error,
  };
}

export function deleteActivityError(error) {
  return {
    type: deleteActivityActions.DELETE_ACTIVITY_FAILURE,
    payload: error,
  };
}

export function deleteActivity(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${RopaManagementService_URL}/deleteActivity?activityId=` +
        params.activityId,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteActivityRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteActivitySuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteActivityError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteActivityError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteActivityError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteActivityError(err));
              break;
            case 500:
              dispatch(deleteActivityError("Server Error"));
              break;
            default:
              dispatch(deleteActivityError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

//Get complete activity with pagination

export const GetAllActivityActions = {
  FETCH_ALL_ACTIVITY_DETAILS: "FETCH_ALL_ACTIVITY_DETAILS",
  FETCH_ALL_ACTIVITY_DETAILS_STATUS: "FETCH_ALL_ACTIVITY_DETAILS_STATUS",
  FETCH_ALL_ACTIVITY_DETAILS_FULFILLED: "FETCH_ALL_ACTIVITY_DETAILS_FULFILLED",
  FETCH_ALL_ACTIVITY_DETAILS_REJECTED: "FETCH_ALL_ACTIVITY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getAllActivityDetails(props) {
  const search = props.search || "";
  const statusID = props.statusID || "0";
  const active = props.active;
  const result = props.result || "10";
  const page = props.page || "0";
  const pageNameInState = props.currentPage;
  const sortOrder = props.sortOrder === false ? "asc" : "desc" || "desc";
  const searchCol = (props.search === null ? "" : props.searchCol) || "";
  const access_token = props.access_token;
  const sortField = props.sortField || "id";
  return (dispatch) =>
    axios({
      method: "POST",
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_19/getAllRopaMasterPagination?start=` +
        page +
        `&length=` +
        result +
        `&sortOrder=` +
        sortOrder +
        `&sortField=` +
        sortField +
        `&booleanfield=` +
        active +
        `&search=` +
        search +
        `&searchCol=` +
        searchCol +
        `&access_token=` +
        access_token,
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllActivityDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getAllActivityDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActivityDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllActivityDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllActivityDetailsError(err));
              break;
            case 500:
              dispatch(getAllActivityDetailsError("Server Error"));
              break;
            default:
              dispatch(getAllActivityDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllActivityDetailsRequest() {
  return {
    type: GetAllActivityActions.FETCH_ALL_ACTIVITY_DETAILS,
    isPosting: true,
  };
}
export function getAllActivityDetailsError(error) {
  return {
    type: GetAllActivityActions.FETCH_ALL_ACTIVITY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getAllActivityDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAllActivityActions.FETCH_ALL_ACTIVITY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetActivityActions = {
  FETCH_ACTIVITY_DETAILS: "FETCH_ACTIVITY_DETAILS",
  FETCH_ACTIVITY_DETAILS_STATUS: "FETCH_ACTIVITY_DETAILS_STATUS",
  FETCH_ACTIVITY_DETAILS_FULFILLED: "FETCH_ACTIVITY_DETAILS_FULFILLED",
  FETCH_ACTIVITY_DETAILS_REJECTED: "FETCH_ACTIVITY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getActivityDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_19/findActivityDetailsById
?id =` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActivityDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActivityDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActivityDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActivityDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActivityDetailsError(err));
              break;
            case 500:
              dispatch(getActivityDetailsError("Server Error"));
              break;
            default:
              dispatch(getActivityDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActivityDetailsRequest() {
  return {
    type: GetActivityActions.FETCH_ACTIVITY_DETAILS,
    isPosting: true,
  };
}
export function getActivityDetailsError(error) {
  return {
    type: GetActivityActions.FETCH_ACTIVITY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getActivityDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActivityActions.FETCH_ACTIVITY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

// export function getActivityDetailsStatus() {
//   return dispatch => dispatch(getActivityDetailsStatusRequest());
// }
// export function getActivityDetailsStatusRequest() {
//   return {
//     type: GetActivityActions.FETCH_ACTIVITY_DETAILS_STATUS
//   };
// }

export function setDetailsError() {
  return (dispatch) => dispatch(getActivityDetailsErrorRequest());
}
export function getActivityDetailsErrorRequest() {
  return {
    type: GetActivityActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("assetTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveActivityActions = {
  FETCH_ACTIVE_ACTIVITY: "FETCH_ACTIVE_ACTIVITY",
  FETCH_ACTIVE_ACTIVITY_STATUS: "FETCH_ACTIVE_ACTIVITY_STATUS",
  FETCH_ACTIVE_ACTIVITY_FULFILLED: "FETCH_ACTIVE_ACTIVITY_FULFILLED",
  FETCH_ACTIVE_ACTIVITY_REJECTED: "FETCH_ACTIVE_ACTIVITY_REJECTED",
};

export function getActiveActivityList(params) {
  
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_19/getAllActivity/` +
        params.ropaId,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveActivityListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveActivityListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveActivityListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveActivityListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveActivityListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveActivityListError(err));
              break;
            case 500:
              dispatch(getActiveActivityListError("Server Error"));
              break;
            default:
              dispatch(getActiveActivityListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveActivityListRequest() {
  return {
    type: GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY,
    isPosting: true,
  };
}
export function getActiveActivityListError(error) {
  return {
    type: GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_REJECTED,
    payload: error,
  };
}

export function getActiveActivityListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveActivityListStatus() {
  return (dispatch) => dispatch(getActiveActivityListStatusRequest());
}
export function getActiveActivityListStatusRequest() {
  return {
    type: GetActiveActivityActions.FETCH_ACTIVE_ACTIVITY_STATUS,
  };
}
