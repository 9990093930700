import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveSecurityMeasureTypeActions = {
  SAVE_SECURITY_MEASURE_TYPE: "SAVE_SECURITY_MEASURE_TYPE",
  SAVE_SECURITY_MEASURE_TYPE_STATUS: "SAVE_SECURITY_MEASURE_TYPE_STATUS",
  SAVE_SECURITY_MEASURE_TYPE_SUCCESS: "SAVE_SECURITY_MEASURE_TYPE_SUCCESS",
  SAVE_SECURITY_MEASURE_TYPE_FAILURE: "SAVE_SECURITY_MEASURE_TYPE_FAILURE",
};

export function SaveSecurityMeasureTypeRequest() {
  return {
    type: SaveSecurityMeasureTypeActions.SAVE_SECURITY_MEASURE_TYPE,
    isPosting: true,
  };
}

export function SaveSecurityMeasureTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveSecurityMeasureTypeActions.SAVE_SECURITY_MEASURE_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveSecurityMeasureTypeFailure(error) {
  return {
    type: SaveSecurityMeasureTypeActions.SAVE_SECURITY_MEASURE_TYPE_FAILURE,
    payload: error,
  };
}

export function SaveSecurityMeasureTypeError(error) {
  return {
    type: SaveSecurityMeasureTypeActions.SAVE_SECURITY_MEASURE_TYPE_FAILURE,
    payload: error,
  };
}

export function saveSecurityMeasureType(props) {
  var value = {
    securityMeasureTypeId: props.securityMeasureTypeId || null,
    securityMeasureTypeName: props.securityMeasureTypeName || null,
    securityMeasureTypeDesc: props.securityMeasureTypeDesc || null,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_12/saveSecurityMeasureTypeMaster`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveSecurityMeasureTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveSecurityMeasureTypeSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveSecurityMeasureTypeError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveSecurityMeasureTypeError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveSecurityMeasureTypeError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveSecurityMeasureTypeError(err));
              break;
            case 500:
              dispatch(SaveSecurityMeasureTypeError("Server Error"));
              break;
            default:
              dispatch(SaveSecurityMeasureTypeError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetSecurityMeasureTypeDetailsActions = {
  FETCH_SECURITY_MEASURE_TYPE_DETAILS: "FETCH_SECURITY_MEASURE_TYPE_DETAILS",
  FETCH_SECURITY_MEASURE_TYPE_DETAILS_STATUS:
    "FETCH_SECURITY_MEASURE_TYPE_DETAILS_STATUS",
  FETCH_SECURITY_MEASURE_TYPE_DETAILS_FULFILLED:
    "FETCH_SECURITY_MEASURE_TYPE_DETAILS_FULFILLED",
  FETCH_SECURITY_MEASURE_TYPE_DETAILS_REJECTED:
    "FETCH_SECURITY_MEASURE_TYPE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSecurityMeasureTypeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_12/findSecurityMeasureTypeMasterById?securityMeasureTypeId=` +
        params.securityMeasureTypeId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSecurityMeasureTypeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSecurityMeasureTypeDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getSecurityMeasureTypeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSecurityMeasureTypeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSecurityMeasureTypeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSecurityMeasureTypeDetailsError(err));
              break;
            case 500:
              dispatch(getSecurityMeasureTypeDetailsError("Server Error"));
              break;
            default:
              dispatch(getSecurityMeasureTypeDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getSecurityMeasureTypeDetailsRequest() {
  return {
    type:
      GetSecurityMeasureTypeDetailsActions.FETCH_SECURITY_MEASURE_TYPE_DETAILS,
    isPosting: true,
  };
}
export function getSecurityMeasureTypeDetailsError(error) {
  return {
    type:
      GetSecurityMeasureTypeDetailsActions.FETCH_SECURITY_MEASURE_TYPE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSecurityMeasureTypeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetSecurityMeasureTypeDetailsActions.FETCH_SECURITY_MEASURE_TYPE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSecurityMeasureTypeDetailsStatus() {
  return (dispatch) => dispatch(getSecurityMeasureTypeDetailsStatusRequest());
}
export function getSecurityMeasureTypeDetailsStatusRequest() {
  return {
    type:
      GetSecurityMeasureTypeDetailsActions.FETCH_SECURITY_MEASURE_TYPE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getSecurityMeasureTypeDetailsErrorRequest());
}
export function getSecurityMeasureTypeDetailsErrorRequest() {
  return {
    type: GetSecurityMeasureTypeDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveSecTypeActions = {
  FETCH_ACTIVE_SECURITY_TYPE: "FETCH_ACTIVE_SECURITY_TYPE",
  FETCH_ACTIVE_SECURITY_TYPE_STATUS: "FETCH_ACTIVE_SECURITY_TYPE_STATUS",
  FETCH_ACTIVE_SECURITY_TYPE_FULFILLED: "FETCH_ACTIVE_SECURITY_TYPE_FULFILLED",
  FETCH_ACTIVE_SECURITY_TYPE_REJECTED: "FETCH_ACTIVE_SECURITY_TYPE_REJECTED",
};
export function getActiveSecTypeList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_12/getAllSecurityMeasureTypeMasterPagination?length=0&sortOrder=desc&sortField=securityMeasureTypeId&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveSecTypeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveSecTypeListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveSecTypeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveSecTypeListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveSecTypeListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveSecTypeListError(err));
              break;
            case 500:
              dispatch(getActiveSecTypeListError("Server Error"));
              break;
            default:
              dispatch(getActiveSecTypeListError("Unknown Error"));
              break;
          }
        } else {
          // dispatch(logout());
        }
      });
}

export function getActiveSecTypeListRequest() {
  return {
    type: GetActiveSecTypeActions.FETCH_ACTIVE_SECURITY_TYPE,
    isPosting: true,
  };
}
export function getActiveSecTypeListError(error) {
  return {
    type: GetActiveSecTypeActions.FETCH_ACTIVE_SECURITY_TYPE_REJECTED,
    payload: error,
  };
}

export function getActiveSecTypeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveSecTypeActions.FETCH_ACTIVE_SECURITY_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveSecTypeListStatus() {
  return (dispatch) => dispatch(getActiveSecTypeListStatusRequest());
}
export function getActiveSecTypeListStatusRequest() {
  return {
    type: GetActiveSecTypeActions.FETCH_ACTIVE_SECURITY_TYPE_STATUS,
  };
}
