import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveSecurityMeasureActions = {
  SAVE_SECURITY_MEASURE: "SAVE_SECURITY_MEASURE",
  SAVE_SECURITY_MEASURE_STATUS: "SAVE_SECURITY_MEASURE_STATUS",
  SAVE_SECURITY_MEASURE_SUCCESS: "SAVE_SECURITY_MEASURE_SUCCESS",
  SAVE_SECURITY_MEASURE_FAILURE: "SAVE_SECURITY_MEASURE_FAILURE",
};

export function SaveSecurityMeasureRequest() {
  return {
    type: SaveSecurityMeasureActions.SAVE_SECURITY_MEASURE,
    isPosting: true,
  };
}

export function SaveSecurityMeasureSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveSecurityMeasureActions.SAVE_SECURITY_MEASURE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveSecurityMeasureFailure(error) {
  return {
    type: SaveSecurityMeasureActions.SAVE_SECURITY_MEASURE_FAILURE,
    payload: error,
  };
}

export function SaveSecurityMeasureError(error) {
  return {
    type: SaveSecurityMeasureActions.SAVE_SECURITY_MEASURE_FAILURE,
    payload: error,
  };
}

export function saveSecurityMeasure(props) {
  var value = {
    securityMeasureId: props.securityMeasureId || null,
    securityMeasureName: props.securityMeasureName || null,
    securityMeasureDesc: props.securityMeasureDesc || null,
    securityMeasureType: props.securityMeasureType || null,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_13/saveSecurityMeasureMaster`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveSecurityMeasureRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveSecurityMeasureSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveSecurityMeasureError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveSecurityMeasureError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveSecurityMeasureError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveSecurityMeasureError(err));
              break;
            case 500:
              dispatch(SaveSecurityMeasureError("Server Error"));
              break;
            default:
              dispatch(SaveSecurityMeasureError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetSecurityMeasureDetailsActions = {
  FETCH_SECURITY_MEASURE_DETAILS: "FETCH_SECURITY_MEASURE_DETAILS",
  FETCH_SECURITY_MEASURE_DETAILS_STATUS:
    "FETCH_SECURITY_MEASURE_DETAILS_STATUS",
  FETCH_SECURITY_MEASURE_DETAILS_FULFILLED:
    "FETCH_SECURITY_MEASURE_DETAILS_FULFILLED",
  FETCH_SECURITY_MEASURE_DETAILS_REJECTED:
    "FETCH_SECURITY_MEASURE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSecurityMeasureDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_13/findSecurityMeasureMasterById?securityMeasureId=` +
        params.securityMeasureId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSecurityMeasureDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSecurityMeasureDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSecurityMeasureDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSecurityMeasureDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSecurityMeasureDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSecurityMeasureDetailsError(err));
              break;
            case 500:
              dispatch(getSecurityMeasureDetailsError("Server Error"));
              break;
            default:
              dispatch(getSecurityMeasureDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getSecurityMeasureDetailsRequest() {
  return {
    type: GetSecurityMeasureDetailsActions.FETCH_SECURITY_MEASURE_DETAILS,
    isPosting: true,
  };
}
export function getSecurityMeasureDetailsError(error) {
  return {
    type: GetSecurityMeasureDetailsActions.FETCH_SECURITY_MEASURE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSecurityMeasureDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSecurityMeasureDetailsActions.FETCH_SECURITY_MEASURE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSecurityMeasureDetailsStatus() {
  return (dispatch) => dispatch(getSecurityMeasureDetailsStatusRequest());
}
export function getSecurityMeasureDetailsStatusRequest() {
  return {
    type: GetSecurityMeasureDetailsActions.FETCH_SECURITY_MEASURE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getSecurityMeasureDetailsErrorRequest());
}
export function getSecurityMeasureDetailsErrorRequest() {
  return {
    type: GetSecurityMeasureDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveSecurityMeasureActions = {
  FETCH_ACTIVE_SECURITY_MEASURE: "FETCH_ACTIVE_SECURITY_MEASURE",
  FETCH_ACTIVE_SECURITY_MEASURE_STATUS: "FETCH_ACTIVE_SECURITY_MEASURE_STATUS",
  FETCH_ACTIVE_SECURITY_MEASURE_FULFILLED:
    "FETCH_ACTIVE_SECURITY_MEASURE_FULFILLED",
  FETCH_ACTIVE_SECURITY_MEASURE_REJECTED:
    "FETCH_ACTIVE_SECURITY_MEASURE_REJECTED",
};
export function getActiveSecurityMeasureList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_13/getAllSecurityMeasureMasterPagination?length=0&sortOrder=desc&sortField=securityMeasureId&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveSecurityMeasureListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveSecurityMeasureListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveSecurityMeasureListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveSecurityMeasureListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveSecurityMeasureListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveSecurityMeasureListError(err));
              break;
            case 500:
              dispatch(getActiveSecurityMeasureListError("Server Error"));
              break;
            default:
              dispatch(getActiveSecurityMeasureListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveSecurityMeasureListRequest() {
  return {
    type: GetActiveSecurityMeasureActions.FETCH_ACTIVE_SECURITY_MEASURE,
    isPosting: true,
  };
}
export function getActiveSecurityMeasureListError(error) {
  return {
    type: GetActiveSecurityMeasureActions.FETCH_ACTIVE_SECURITY_MEASURE_REJECTED,
    payload: error,
  };
}

export function getActiveSecurityMeasureListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveSecurityMeasureActions.FETCH_ACTIVE_SECURITY_MEASURE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveSecurityMeasureListStatus() {
  return (dispatch) => dispatch(getActiveSecurityMeasureListStatusRequest());
}
export function getActiveSecurityMeasureListStatusRequest() {
  return {
    type: GetActiveSecurityMeasureActions.FETCH_ACTIVE_SECURITY_MEASURE_STATUS,
  };
}
