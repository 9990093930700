import {
  saveRequestMasterActions,
  deleteRequestMasterActions,
  GetRequestMasterDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRequestMasterActions,
  getSubDataIdentifierrDetailsActions
} from "./actions";

const initState = {
  requestSuccessMessage: null,
  requestMasterErrorMessage: null,
  RequestMasterStatus: null,
  RequestMasterMaster: [],
  RequestMasterData: null,
  subjectIdentifierData:[],
  ActiveRequestMasterList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveRequestMasterActions.SAVE_REQUEST_MASTER:
      return {
        ...state,
        isLoading: true,
        requestMasterErrorMessage: null,
        modalActive: false,
      };
    case saveRequestMasterActions.SAVE_REQUEST_MASTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestMasterStatus: payload.status,
        requestMasterErrorMessage: null,
        requestSuccessMessage: "Request Type added successfully!",
      };

    case saveRequestMasterActions.SAVE_REQUEST_MASTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        RequestMasterStatus: null,
        requestMasterErrorMessage: payload,
      };
    case saveRequestMasterActions.SAVE_REQUEST_MASTER_STATUS:
      return {
        ...state,
        RequestMasterStatus: 0,
        requestMasterErrorMessage: null,
      };

    // Delete Request Type

    case deleteRequestMasterActions.DELETE_REQUEST_MASTER:
      return {
        ...state,
        isLoading: true,
        requestMasterErrorMessage: null,
        modalActive: false,
      };
    case deleteRequestMasterActions.DELETE_REQUEST_MASTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestMasterStatus: payload.status,
        requestMasterErrorMessage: null,
        requestSuccessMessage: "Request Type Deleted successfully!",
      };

    case deleteRequestMasterActions.DELETE_REQUEST_MASTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        RequestMasterStatus: null,
        requestMasterErrorMessage: payload,
      };
    case deleteRequestMasterActions.DELETE_REQUEST_MASTER_STATUS:
      return {
        ...state,
        RequestMasterStatus: 0,
        requestMasterErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS:
      return {
        ...state,
        isLoading: true,
        requestMasterErrorMessage: null,
        modalActive: false,
        RequestMasterData: null,
      };
    case GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RequestMasterData: payload.data,
        RequestMasterDataStatus: payload.status,
        requestMasterErrorMessage: null,
      };
    case GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        requestMasterErrorMessage: payload,
        RequestMasterData: null,
      };
    case GetRequestMasterDetailsActions.FETCH_REQUEST_MASTER_DETAILS_STATUS:
      return {
        ...state,
        RequestMasterData: null,
      };
    ///Active requests
    case GetActiveRequestMasterActions.FETCH_ACTIVE_REQUEST_MASTER:
      return {
        ...state,
        isLoading: true,
        requestMasterErrorMessage: null,
        modalActive: false,
        ActiveRequestMasterList: null,
      };
    case GetActiveRequestMasterActions.FETCH_ACTIVE_REQUEST_MASTER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveRequestMasterList: payload.data,
        requestMasterErrorMessage: null,
      };
    case GetActiveRequestMasterActions.FETCH_ACTIVE_REQUEST_MASTER_REJECTED:
      return {
        ...state,
        isLoading: false,
        requestMasterErrorMessage: payload,
        ActiveRequestMasterList: null,
      };
    case GetActiveRequestMasterActions.FETCH_ACTIVE_REQUEST_MASTER_STATUS:
      return {
        ...state,
        ActiveRequestMasterList: null,
      };
    // clear requestMaster details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRequestMaster: null,
        RequestMasterStatus: null,
        requestSuccessMessage: null,
        GetRequestMasterStatus: null,
        RequestMasterData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
     /////////////////////// get subject data identifier/////
    case getSubDataIdentifierrDetailsActions.FETCH_SUBDATA_IDENTIFIER_DETAILS:
      return {
        ...state,
        isLoading: true,
        requestMasterErrorMessage: null,
        modalActive: false,
        subjectIdentifierData: null,
      };
    case getSubDataIdentifierrDetailsActions.FETCH_SUBDATA_IDENTIFIER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        subjectIdentifierData: payload.data,
        requestMasterErrorMessage: null,
      };
    case getSubDataIdentifierrDetailsActions.FETCH_SUBDATA_IDENTIFIER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        requestMasterErrorMessage: payload,
        subjectIdentifierData: null,
      };
    case getSubDataIdentifierrDetailsActions.FETCH_SUBDATA_IDENTIFIER_DETAILS_STATUS:
      return {
        ...state,
        subjectIdentifierData: null,
      };

    default:
      return state;
  }
}
