import {
  SaveRoleActions,
  GetRoleDetailsActions,
  GetContactsDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActivecontactsActions,
  GetAllUsersByCompanyIdActions
} from "./actions";

const initState = {
  errorMessage: null,
  contactsSuccessMessage: null,
  contactsErrorMessage: null,
  contactsStatus: null,
  contactsMaster: [],
  contactsData: null,
  roleData:null,
  roleSuccessMessage: null,
  roleErrorMessage: null,
  roleStatus: null,
  roleMaster: [],
  ActivecontactsList: null,
  usersByCompanyList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveRoleActions.SAVE_ROLE:
      return {
        ...state,
        isLoading: true,
        roleErrorMessage: null,
        modalActive: false
      };
    case SaveRoleActions.SAVE_ROLE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        roleStatus: payload.status,
        roleErrorMessage: null,
        roleSuccessMessage: "Role added successfully!"
      };

    case SaveRoleActions.SAVE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        roleStatus: null,
        roleErrorMessage: payload
      };
    case SaveRoleActions.SAVE_ROLE_STATUS:
      return {
        ...state,
        roleStatus: 0,
        roleErrorMessage: null
      };

      ///////////////////////////////////////////////////////
    // GET Role BY ID
    case GetRoleDetailsActions.FETCH_ROLE_DETAILS:
    return {
      ...state,
      isLoading: true,
      contactsErrorMessage: null,
      modalActive: false,
      roleData: null
    };
  case GetRoleDetailsActions.FETCH_ROLE_DETAILS_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      roleData: payload.data,
      roleDataStatus: payload.status,
      roleErrorMessage: null
    };
  case GetRoleDetailsActions.FETCH_ROLE_DETAILS_REJECTED:
    return {
      ...state,
      isLoading: false,
      roleErrorMessage: payload,
      roleData: null
    };
  case GetRoleDetailsActions.FETCH_ROLE_DETAILS_STATUS:
    return {
      ...state,
      roleData: null
    };
    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetContactsDetailsActions.FETCH_CONTACTS_DETAILS:
      return {
        ...state,
        isLoading: true,
        contactsErrorMessage: null,
        modalActive: false,
        contactsData: null
      };
    case GetContactsDetailsActions.FETCH_CONTACTS_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        contactsData: payload.data,
        contactsDataStatus: payload.status,
        contactsErrorMessage: null
      };
    case GetContactsDetailsActions.FETCH_CONTACTS_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        contactsErrorMessage: payload,
        contactsData: null
      };
    case GetContactsDetailsActions.FETCH_CONTACTS_DETAILS_STATUS:
      return {
        ...state,
        contactsData: null
      };
    ///Active DATA DISPOSAL
    case GetActivecontactsActions.FETCH_ACTIVE_CONTACTS:
      return {
        ...state,
        isLoading: true,
        contactsErrorMessage: null,
        modalActive: false,
        ActivecontactsList: null
      };
    case GetActivecontactsActions.FETCH_ACTIVE_CONTACTS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActivecontactsList: payload.data,
        contactsErrorMessage: null
      };
    case GetActivecontactsActions.FETCH_ACTIVE_CONTACTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        contactsErrorMessage: payload,
        ActivecontactsList: null
      };
    case GetActivecontactsActions.FETCH_ACTIVE_CONTACTS_STATUS:
      return {
        ...state,
        ActivecontactsList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        Getcontacts: null,
        contactsStatus: null,
        contactsSuccessMessage: null,
        GetcontactsStatus: null,
        contactsData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */
    case GetAllUsersByCompanyIdActions.FETCH_USERS_BY_COMPANY:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
        usersByCompanyList: null
      };
    case GetAllUsersByCompanyIdActions.FETCH_USERS_BY_COMPANY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        usersByCompanyList: payload.data,
        errorMessage: payload.status
      };
    case GetAllUsersByCompanyIdActions.FETCH_USERS_BY_COMPANY_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        usersByCompanyList: null
      };
    case GetAllUsersByCompanyIdActions.FETCH_USERS_BY_COMPANY_STATUS:
      return {
        ...state,
        usersByCompanyList: null
      };
    default:
      return state;
  }
}
