import {
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRiskMasterActions,
  GetActiveRiskMatrixActions,
  SaveRiskMatrixActions,
} from "./actions";

const initState = {
  hostingSuccessMessage: null,
  hostingErrorMessage: null,
  HostingTypeStatus: null,
  HostingTypeMaster: [],
  HostingTypeData: null,
  ActiveHostingTypeList: null,
  RiskLevels: null,
  RiskMatrix: null,
  riskErrorMessage: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    ///////////////////////////////////////////////////////
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetHostingType: null,
        HostingTypeStatus: null,
        hostingSuccessMessage: null,
        GetHostingTypeStatus: null,
        HostingTypeData: null,
        RiskLevels: null,
        riskErrorMessage: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    // case SaveRiskMatrixActions.SAVE_RISK_MATRIX:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     riskErrorMessage: null,
    //     modalActive: false,
    //     RiskMatrix: null,
    //   };
    // case SaveRiskMatrixActions.SAVE_RISK_MATRIX_SUCCESS:
    //   return {
    //     ...state,
    //     loading: true,
    //     isLoading: false,
    //     RiskMatrix: payload.data,
    //     riskErrorMessage: null,
    //   };
    // case SaveRiskMatrixActions.SAVE_RISK_MATRIX_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     riskErrorMessage: payload,
    //     RiskMatrix: null,
    //   };

    //Risk Levels
    case GetActiveRiskMasterActions.FETCH_ACTIVE_RISK_MASTER:
      return {
        ...state,
        isLoading: true,
        riskErrorMessage: null,
        modalActive: false,
        RiskLevels: null,
      };
    case GetActiveRiskMasterActions.FETCH_ACTIVE_RISK_MASTER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskLevels: payload.data,
        riskErrorMessage: null,
      };
    case GetActiveRiskMasterActions.FETCH_ACTIVE_RISK_MASTER_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskErrorMessage: payload,
        RiskLevels: null,
      };

    //Risk Levels
    case GetActiveRiskMatrixActions.FETCH_ACTIVE_RISK_MATRIX:
      return {
        ...state,
        isLoading: true,
        riskErrorMessage: null,
        modalActive: false,
        RiskMatrix: null,
      };
    case GetActiveRiskMatrixActions.FETCH_ACTIVE_RISK_MATRIX_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskMatrix: payload.data,
        riskErrorMessage: null,
      };
    case GetActiveRiskMatrixActions.FETCH_ACTIVE_RISK_MATRIX_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskErrorMessage: payload,
        RiskMatrix: null,
      };

    default:
      return state;
  }
}
