import axios from "axios";
import {
  SuperAdmin_Service_URL,
  AssetManagementService_URL,
  IncidentManagementService_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";
var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save BreachSource

export const saveBreachSourceActions = {
  SAVE_BREACH_SOURCE: "SAVE_BREACH_SOURCE",
  SAVE_BREACH_SOURCE_STATUS: "SAVE_BREACH_SOURCE_STATUS",
  SAVE_BREACH_SOURCE_SUCCESS: "SAVE_BREACH_SOURCE_SUCCESS",
  SAVE_BREACH_SOURCE_FAILURE: "SAVE_BREACH_SOURCE_FAILURE",
};

export function saveBreachSourceRequest() {
  return {
    type: saveBreachSourceActions.SAVE_BREACH_SOURCE,
    isPosting: true,
  };
}

export function saveBreachSourceSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveBreachSourceActions.SAVE_BREACH_SOURCE_SUCCESS,
      payload: props,
    });
  };
}

export function saveBreachSourceFailure(error) {
  return {
    type: saveBreachSourceActions.SAVE_BREACH_SOURCE_FAILURE,
    payload: error,
  };
}

export function saveBreachSourceError(error) {
  return {
    type: saveBreachSourceActions.SAVE_BREACH_SOURCE_FAILURE,
    payload: error,
  };
}

export function saveBreachSource(props) {
  var value = {
    id: props.id || 0,
    name: props.name,
    status: props.status,
    description: props.description,
    code: props.code,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${IncidentManagementService_URL}/Apc_Persist/Amc_84/saveBreachSource`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveBreachSourceRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveBreachSourceSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveBreachSourceError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveBreachSourceError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveBreachSourceError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveBreachSourceError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveBreachSourceError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveBreachSourceError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete BreachSource

export const deleteBreachSourceActions = {
  DELETE_BREACH_SOURCE: "DELETE_BREACH_SOURCE",
  DELETE_BREACH_SOURCE_STATUS: "DELETE_BREACH_SOURCE_STATUS",
  DELETE_BREACH_SOURCE_SUCCESS: "DELETE_BREACH_SOURCE_SUCCESS",
  DELETE_BREACH_SOURCE_FAILURE: "DELETE_BREACH_SOURCE_FAILURE",
};

export function deleteBreachSourceRequest() {
  return {
    type: deleteBreachSourceActions.DELETE_BREACH_SOURCE,
    isPosting: true,
  };
}

export function deleteBreachSourceSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteBreachSourceActions.DELETE_BREACH_SOURCE_SUCCESS,
      payload: props,
    });
  };
}

export function deleteBreachSourceFailure(error) {
  return {
    type: deleteBreachSourceActions.DELETE_BREACH_SOURCE_FAILURE,
    payload: error,
  };
}

export function deleteBreachSourceError(error) {
  return {
    type: deleteBreachSourceActions.DELETE_BREACH_SOURCE_FAILURE,
    payload: error,
  };
}

export function deleteBreachSource(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${AssetManagementService_URL}/deleteBreachSource?BreachSourceId=` +
        params.BreachSourceID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteBreachSourceRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteBreachSourceSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteBreachSourceError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteBreachSourceError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteBreachSourceError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteBreachSourceError(err));
              break;
            case 500:
              dispatch(deleteBreachSourceError("Server Error"));
              break;
            default:
              dispatch(deleteBreachSourceError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetBreachSourceDetailsActions = {
  FETCH_BREACH_SOURCE_DETAILS: "FETCH_BREACH_SOURCE_DETAILS",
  FETCH_BREACH_SOURCE_DETAILS_STATUS: "FETCH_BREACH_SOURCE_DETAILS_STATUS",
  FETCH_BREACH_SOURCE_DETAILS_FULFILLED: "FETCH_BREACH_SOURCE_DETAILS_FULFILLED",
  FETCH_BREACH_SOURCE_DETAILS_REJECTED: "FETCH_BREACH_SOURCE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getBreachSourceDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${IncidentManagementService_URL}/Apc_View/Amc_84/getBreachSourceById?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getBreachSourceDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getBreachSourceDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getBreachSourceDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getBreachSourceDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getBreachSourceDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getBreachSourceDetailsError(err));
              break;
            case 500:
              dispatch(getBreachSourceDetailsError("Server Error"));
              break;
            default:
              dispatch(getBreachSourceDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getBreachSourceDetailsRequest() {
  return {
    type: GetBreachSourceDetailsActions.FETCH_BREACH_SOURCE_DETAILS,
    isPosting: true,
  };
}
export function getBreachSourceDetailsError(error) {
  return {
    type: GetBreachSourceDetailsActions.FETCH_BREACH_SOURCE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getBreachSourceDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetBreachSourceDetailsActions.FETCH_BREACH_SOURCE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getBreachSourceDetailsStatus() {
  return (dispatch) => dispatch(getBreachSourceDetailsStatusRequest());
}
export function getBreachSourceDetailsStatusRequest() {
  return {
    type: GetBreachSourceDetailsActions.FETCH_BREACH_SOURCE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getBreachSourceDetailsErrorRequest());
}
export function getBreachSourceDetailsErrorRequest() {
  return {
    type: GetBreachSourceDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("BreachSources", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveBreachSourceTypeActions = {
  FETCH_ACTIVE_BREACH_SOURCE: "FETCH_ACTIVE_BREACH_SOURCE",
  FETCH_ACTIVE_BREACH_SOURCE_STATUS: "FETCH_ACTIVE_BREACH_SOURCE_STATUS",
  FETCH_ACTIVE_BREACH_SOURCE_FULFILLED: "FETCH_ACTIVE_BREACH_SOURCE_FULFILLED",
  FETCH_ACTIVE_BREACH_SOURCE_REJECTED: "FETCH_ACTIVE_BREACH_SOURCE_REJECTED",
};

export function getActiveBreachSourceList(params) {
  
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${IncidentManagementService_URL}/getAllBreachSourcePagination?start=0&sortOrder=desc&booleanfield=true&status=true&sortField=id&length=0&access_token=` +
        params.access_token,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveBreachSourceListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveBreachSourceListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveBreachSourceListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveBreachSourceListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveBreachSourceListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveBreachSourceListError(err));
              break;
            case 500:
              dispatch(getActiveBreachSourceListError("Server Error"));
              break;
            default:
              dispatch(getActiveBreachSourceListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveBreachSourceListRequest() {
  return {
    type: GetActiveBreachSourceTypeActions.FETCH_ACTIVE_BREACH_SOURCE,
    isPosting: true,
  };
}
export function getActiveBreachSourceListError(error) {
  return {
    type: GetActiveBreachSourceTypeActions.FETCH_ACTIVE_BREACH_SOURCE_REJECTED,
    payload: error,
  };
}

export function getActiveBreachSourceListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveBreachSourceTypeActions.FETCH_ACTIVE_BREACH_SOURCE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveBreachSourceListStatus() {
  return (dispatch) => dispatch(getActiveBreachSourceListStatusRequest());
}
export function getActiveBreachSourceListStatusRequest() {
  return {
    type: GetActiveBreachSourceTypeActions.FETCH_ACTIVE_BREACH_SOURCE_STATUS,
  };
}
