import axios from "axios";
import { GoogleDrive_URL, SurveyService_URL, VendorService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save IndustryType
export const saveIndustryTypeActions = {
  SAVE_INDUSTRY_TYPE: "SAVE_INDUSTRY_TYPE",
  SAVE_INDUSTRY_TYPE_STATUS: "SAVE_INDUSTRY_TYPE_STATUS",
  SAVE_INDUSTRY_TYPE_SUCCESS: "SAVE_INDUSTRY_TYPE_SUCCESS",
  SAVE_INDUSTRY_TYPE_FAILURE: "SAVE_INDUSTRY_TYPE_FAILURE",
};

export function saveIndustryTypeRequest() {
  return {
    type: saveIndustryTypeActions.SAVE_INDUSTRY_TYPE,
    isPosting: true,
  };
}

export function saveIndustryTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveIndustryTypeActions.SAVE_INDUSTRY_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function saveIndustryTypeFailure(error) {
  return {
    type: saveIndustryTypeActions.SAVE_INDUSTRY_TYPE_FAILURE,
    payload: error,
  };
}

export function saveIndustryTypeError(error) {
  return {
    type: saveIndustryTypeActions.SAVE_INDUSTRY_TYPE_FAILURE,
    payload: error,
  };
}

export function saveIndustryType(props) {
  var value = {
    industryTypeId: props.industryTypeId || 0,
    industryTypeName: props.industryTypeName,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${VendorService_URL}/Apc_Persist/Amc_85/saveIndustryType`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveIndustryTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveIndustryTypeSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveIndustryTypeError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get industry details by id
export const GetIndustryTypeDetailsActions = {
  FETCH_INDUSTRY_TYPE_DETAILS: "FETCH_INDUSTRY_TYPE_DETAILS",
  FETCH_INDUSTRY_TYPE_DETAILS_STATUS: "FETCH_INDUSTRY_TYPE_DETAILS_STATUS",
  FETCH_INDUSTRY_TYPE_DETAILS_FULFILLED:
    "FETCH_INDUSTRY_TYPE_DETAILS_FULFILLED",
  FETCH_INDUSTRY_TYPE_DETAILS_REJECTED: "FETCH_INDUSTRY_TYPE_DETAILS_REJECTED",
};

export function getIndustryTypeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${VendorService_URL}/Apc_View/Amc_85/findIndustryTypeById?industryTypeId=` +
        params.industryTypeID,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getIndustryTypeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getIndustryTypeDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getIndustryTypeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getIndustryTypeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getIndustryTypeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getIndustryTypeDetailsError(err));
              break;
            case 500:
              dispatch(getIndustryTypeDetailsError("Server Error"));
              break;
            default:
              dispatch(getIndustryTypeDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getIndustryTypeDetailsRequest() {
  return {
    type: GetIndustryTypeDetailsActions.FETCH_INDUSTRY_TYPE_DETAILS,
    isPosting: true,
  };
}
export function getIndustryTypeDetailsError(error) {
  return {
    type: GetIndustryTypeDetailsActions.FETCH_INDUSTRY_TYPE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getIndustryTypeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetIndustryTypeDetailsActions.FETCH_INDUSTRY_TYPE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getIndustryTypeDetailsStatus() {
  return (dispatch) => dispatch(getIndustryTypeDetailsStatusRequest());
}
export function getIndustryTypeDetailsStatusRequest() {
  return {
    type: GetIndustryTypeDetailsActions.FETCH_INDUSTRY_TYPE_DETAILS_STATUS,
  };
}

//GET Active Vendor Industry List
export const GetActiveIndustryTypeActions = {
  FETCH_ACTIVE_INDUSTRY_TYPE: "FETCH_ACTIVE_INDUSTRY_TYPE",
  FETCH_ACTIVE_INDUSTRY_TYPE_STATUS: "FETCH_ACTIVE_INDUSTRY_TYPE_STATUS",
  FETCH_ACTIVE_INDUSTRY_TYPE_FULFILLED: "FETCH_ACTIVE_INDUSTRY_TYPE_FULFILLED",
  FETCH_ACTIVE_INDUSTRY_TYPE_REJECTED: "FETCH_ACTIVE_INDUSTRY_TYPE_REJECTED",
};

export function getActiveIndustryTypeList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_89/getAllIndustryTypeByCompanyId?companyId=${params.companyId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveIndustryTypeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveIndustryTypeListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveIndustryTypeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveIndustryTypeListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveIndustryTypeListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveIndustryTypeListError(err));
              break;
            case 500:
              dispatch(getActiveIndustryTypeListError("Server Error"));
              break;
            default:
              dispatch(getActiveIndustryTypeListError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveIndustryTypeListRequest() {
  return {
    type: GetActiveIndustryTypeActions.FETCH_ACTIVE_INDUSTRY_TYPE,
    isPosting: true,
  };
}
export function getActiveIndustryTypeListError(error) {
  return {
    type: GetActiveIndustryTypeActions.FETCH_ACTIVE_INDUSTRY_TYPE_REJECTED,
    payload: error,
  };
}

export function getActiveIndustryTypeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveIndustryTypeActions.FETCH_ACTIVE_INDUSTRY_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveIndustryTypeListStatus() {
  return (dispatch) => dispatch(getActiveIndustryTypeListStatusRequest());
}
export function getActiveIndustryTypeListStatusRequest() {
  return {
    type: GetActiveIndustryTypeActions.FETCH_ACTIVE_INDUSTRY_TYPE_STATUS,
  };
}

//SAVE VENDOR TYPE
export const SaveVendorTypeActions = {
  SAVE_VENDOR_TYPE: "SAVE_VENDOR_TYPE",
  SAVE_VENDOR_TYPE_STATUS: "SAVE_VENDOR_TYPE_STATUS",
  SAVE_VENDOR_TYPE_SUCCESS: "SAVE_VENDOR_TYPE_SUCCESS",
  SAVE_VENDOR_TYPE_FAILURE: "SAVE_VENDOR_TYPE_FAILURE",
};

export function SaveVendorTypeRequest() {
  return {
    type: SaveVendorTypeActions.SAVE_VENDOR_TYPE,
    isPosting: true,
  };
}

export function SaveVendorTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorTypeActions.SAVE_VENDOR_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorTypeFailure(error) {
  return {
    type: SaveVendorTypeActions.SAVE_VENDOR_TYPE_FAILURE,
    payload: error,
  };
}

export function SaveVendorTypeError(error) {
  return {
    type: SaveVendorTypeActions.SAVE_VENDOR_TYPE_FAILURE,
    payload: error,
  };
}

export function saveVendorType(props) {
  var value = {
    id: props.vendorTypeId || null,
    name: props.vendorTypeName,
    description: props.vendorTypeDesc,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${VendorService_URL}/Apc_View/Amc_86/saveVendorType`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorTypeSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorTypeError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET VENDOR TYPE BY ID
export const GetVendorTypeDetailsActions = {
  FETCH_VENDOR_TYPE_DETAILS: "FETCH_VENDOR_TYPE_DETAILS",
  FETCH_VENDOR_TYPE_DETAILS_STATUS: "FETCH_VENDOR_TYPE_DETAILS_STATUS",
  FETCH_VENDOR_TYPE_DETAILS_FULFILLED: "FETCH_VENDOR_TYPE_DETAILS_FULFILLED",
  FETCH_VENDOR_TYPE_DETAILS_REJECTED: "FETCH_VENDOR_TYPE_DETAILS_REJECTED",
};

export function getVendorTypeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${VendorService_URL}/Apc_View/Amc_86/findVendorTypeById?id=` +
        params.vendorTypeId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVendorTypeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVendorTypeDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVendorTypeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTypeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTypeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTypeDetailsError(err));
              break;
            case 500:
              dispatch(getVendorTypeDetailsError("Server Error"));
              break;
            default:
              dispatch(getVendorTypeDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVendorTypeDetailsRequest() {
  return {
    type: GetVendorTypeDetailsActions.FETCH_VENDOR_TYPE_DETAILS,
    isPosting: true,
  };
}
export function getVendorTypeDetailsError(error) {
  return {
    type: GetVendorTypeDetailsActions.FETCH_VENDOR_TYPE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getVendorTypeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVendorTypeDetailsActions.FETCH_VENDOR_TYPE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getVendorTypeDetailsStatus() {
  return (dispatch) => dispatch(getVendorTypeDetailsStatusRequest());
}

export function getVendorTypeDetailsStatusRequest() {
  return {
    type: GetVendorTypeDetailsActions.FETCH_VENDOR_TYPE_DETAILS_STATUS,
  };
}

//GET Active Vendor Type List
export const GetActiveVendorTypeActions = {
  FETCH_ACTIVE_VENDOR_TYPE: "FETCH_ACTIVE_VENDOR_TYPE",
  FETCH_ACTIVE_VENDOR_TYPE_STATUS: "FETCH_ACTIVE_VENDOR_TYPE_STATUS",
  FETCH_ACTIVE_VENDOR_TYPE_FULFILLED: "FETCH_ACTIVE_VENDOR_TYPE_FULFILLED",
  FETCH_ACTIVE_VENDOR_TYPE_REJECTED: "FETCH_ACTIVE_VENDOR_TYPE_REJECTED",
};

export function getActiveVendorTypeList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_89/getAllVendorTypeByCompanyId?companyId=${params.companyId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveVendorTypeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveVendorTypeListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveVendorTypeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTypeListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTypeListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTypeListError(err));
              break;
            case 500:
              dispatch(getActiveVendorTypeListError("Server Error"));
              break;
            default:
              dispatch(getActiveVendorTypeListError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveVendorTypeListRequest() {
  return {
    type: GetActiveVendorTypeActions.FETCH_ACTIVE_VENDOR_TYPE,
    isPosting: true,
  };
}
export function getActiveVendorTypeListError(error) {
  return {
    type: GetActiveVendorTypeActions.FETCH_ACTIVE_VENDOR_TYPE_REJECTED,
    payload: error,
  };
}

export function getActiveVendorTypeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveVendorTypeActions.FETCH_ACTIVE_VENDOR_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveVendorTypeListStatus() {
  return (dispatch) => dispatch(getActiveVendorTypeListStatusRequest());
}
export function getActiveVendorTypeListStatusRequest() {
  return {
    type: GetActiveVendorTypeActions.FETCH_ACTIVE_VENDOR_TYPE_STATUS,
  };
}

//--------------------------------------------------------------//
//SAVE VENDOR CATEGORY
export const SaveVendorCategoryActions = {
  SAVE_VENDOR_CATEGORY: "SAVE_VENDOR_CATEGORY",
  SAVE_VENDOR_CATEGORY_STATUS: "SAVE_VENDOR_CATEGORY_STATUS",
  SAVE_VENDOR_CATEGORY_SUCCESS: "SAVE_VENDOR_CATEGORY_SUCCESS",
  SAVE_VENDOR_CATEGORY_FAILURE: "SAVE_VENDOR_CATEGORY_FAILURE",
};

export function SaveVendorCategoryRequest() {
  return {
    type: SaveVendorCategoryActions.SAVE_VENDOR_CATEGORY,
    isPosting: true,
  };
}

export function SaveVendorCategorySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorCategoryActions.SAVE_VENDOR_CATEGORY_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorCategoryFailure(error) {
  return {
    type: SaveVendorCategoryActions.SAVE_VENDOR_CATEGORY_FAILURE,
    payload: error,
  };
}

export function SaveVendorCategoryError(error) {
  return {
    type: SaveVendorCategoryActions.SAVE_VENDOR_CATEGORY_FAILURE,
    payload: error,
  };
}

export function saveVendorCategory(props) {
  var value = {
    vendorCategoryId: props.vendorCategoryId || null,
    vendorCategoryName: props.vendorCategoryName,
    vendorCategoryDesc: props.vendorCategoryDesc,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${VendorService_URL}/Apc_View/Amc_88/saveVendorCategory`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorCategoryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorCategorySuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorCategoryError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET VENDOR CATEGORY BY ID
export const GetVendorCategoryDetailsActions = {
  FETCH_VENDOR_CATEGORY_DETAILS: "FETCH_VENDOR_CATEGORY_DETAILS",
  FETCH_VENDOR_CATEGORY_DETAILS_STATUS: "FETCH_VENDOR_CATEGORY_DETAILS_STATUS",
  FETCH_VENDOR_CATEGORY_DETAILS_FULFILLED:
    "FETCH_VENDOR_CATEGORY_DETAILS_FULFILLED",
  FETCH_VENDOR_CATEGORY_DETAILS_REJECTED:
    "FETCH_VENDOR_CATEGORY_DETAILS_REJECTED",
};

export function getVendorCategoryDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${VendorService_URL}/Apc_View/Amc_88/findVendorCategoryById?vendorCategoryId=` +
        params.vendorCategoryId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVendorCategoryDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVendorCategoryDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVendorCategoryDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorCategoryDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorCategoryDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorCategoryDetailsError(err));
              break;
            case 500:
              dispatch(getVendorCategoryDetailsError("Server Error"));
              break;
            default:
              dispatch(getVendorCategoryDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVendorCategoryDetailsRequest() {
  return {
    type: GetVendorCategoryDetailsActions.FETCH_VENDOR_CATEGORY_DETAILS,
    isPosting: true,
  };
}
export function getVendorCategoryDetailsError(error) {
  return {
    type: GetVendorCategoryDetailsActions.FETCH_VENDOR_CATEGORY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getVendorCategoryDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVendorCategoryDetailsActions.FETCH_VENDOR_CATEGORY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getVendorCategoryDetailsStatus() {
  return (dispatch) => dispatch(getVendorCategoryDetailsStatusRequest());
}

export function getVendorCategoryDetailsStatusRequest() {
  return {
    type: GetVendorCategoryDetailsActions.FETCH_VENDOR_CATEGORY_DETAILS_STATUS,
  };
}

//GET Active Vendor Category List
export const GetActiveVendorCategoryActions = {
  FETCH_ACTIVE_VENDOR_CATEGORY: "FETCH_ACTIVE_VENDOR_CATEGORY",
  FETCH_ACTIVE_VENDOR_CATEGORY_STATUS: "FETCH_ACTIVE_VENDOR_CATEGORY_STATUS",
  FETCH_ACTIVE_VENDOR_CATEGORY_FULFILLED:
    "FETCH_ACTIVE_VENDOR_CATEGORY_FULFILLED",
  FETCH_ACTIVE_VENDOR_CATEGORY_REJECTED:
    "FETCH_ACTIVE_VENDOR_CATEGORY_REJECTED",
};

export function getActiveVendorCategoryList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_89/getAllVendorCategoryByCompanyId?companyId=${params.companyId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveVendorCategoryListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveVendorCategoryListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveVendorCategoryListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorCategoryListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorCategoryListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorCategoryListError(err));
              break;
            case 500:
              dispatch(getActiveVendorCategoryListError("Server Error"));
              break;
            default:
              dispatch(getActiveVendorCategoryListError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveVendorCategoryListRequest() {
  return {
    type: GetActiveVendorCategoryActions.FETCH_ACTIVE_VENDOR_CATEGORY,
    isPosting: true,
  };
}
export function getActiveVendorCategoryListError(error) {
  return {
    type: GetActiveVendorCategoryActions.FETCH_ACTIVE_VENDOR_CATEGORY_REJECTED,
    payload: error,
  };
}

export function getActiveVendorCategoryListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveVendorCategoryActions.FETCH_ACTIVE_VENDOR_CATEGORY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveVendorCategoryListStatus() {
  return (dispatch) => dispatch(getActiveVendorCategoryListStatusRequest());
}
export function getActiveVendorCategoryListStatusRequest() {
  return {
    type: GetActiveVendorCategoryActions.FETCH_ACTIVE_VENDOR_CATEGORY_STATUS,
  };
}

//--------------------------------------------------------------//
//SAVE VENDOR REGISTER
export const SaveVendorRegisterActions = {
  SAVE_VENDOR_REGISTER: "SAVE_VENDOR_REGISTER",
  SAVE_VENDOR_REGISTER_STATUS: "SAVE_VENDOR_REGISTER_STATUS",
  SAVE_VENDOR_REGISTER_SUCCESS: "SAVE_VENDOR_REGISTER_SUCCESS",
  SAVE_VENDOR_REGISTER_FAILURE: "SAVE_VENDOR_REGISTER_FAILURE",
};

export function SaveVendorRegisterRequest() {
  return {
    type: SaveVendorRegisterActions.SAVE_VENDOR_REGISTER,
    isPosting: true,
  };
}

export function SaveVendorRegisterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorRegisterActions.SAVE_VENDOR_REGISTER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorRegisterFailure(error) {
  return {
    type: SaveVendorRegisterActions.SAVE_VENDOR_REGISTER_FAILURE,
    payload: error,
  };
}

export function SaveVendorRegisterError(error) {
  return {
    type: SaveVendorRegisterActions.SAVE_VENDOR_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveVendorRegister(data, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data,
      url: `${VendorService_URL}/Apc_Persist/Amc_89/saveVendorMaster`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorRegisterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorRegisterSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorRegisterError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET VENDOR REGISTER BY ID
export const GetVendorRegisterDetailsActions = {
  FETCH_VENDOR_REGISTER_DETAILS: "FETCH_VENDOR_REGISTER_DETAILS",
  FETCH_VENDOR_REGISTER_DETAILS_STATUS: "FETCH_VENDOR_REGISTER_DETAILS_STATUS",
  FETCH_VENDOR_REGISTER_DETAILS_FULFILLED:
    "FETCH_VENDOR_REGISTER_DETAILS_FULFILLED",
  FETCH_VENDOR_REGISTER_DETAILS_REJECTED:
    "FETCH_VENDOR_REGISTER_DETAILS_REJECTED",
};

export function getVendorRegisterDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${VendorService_URL}/Apc_View/Amc_89/getByIdvendorMaster?vendorId=` +
        params.vendorId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVendorRegisterDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVendorRegisterDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVendorRegisterDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorRegisterDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorRegisterDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorRegisterDetailsError(err));
              break;
            case 500:
              dispatch(getVendorRegisterDetailsError("Server Error"));
              break;
            default:
              dispatch(getVendorRegisterDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVendorRegisterDetailsRequest() {
  return {
    type: GetVendorRegisterDetailsActions.FETCH_VENDOR_REGISTER_DETAILS,
    isPosting: true,
  };
}
export function getVendorRegisterDetailsError(error) {
  return {
    type: GetVendorRegisterDetailsActions.FETCH_VENDOR_REGISTER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getVendorRegisterDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVendorRegisterDetailsActions.FETCH_VENDOR_REGISTER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getVendorRegisterDetailsStatus() {
  return (dispatch) => dispatch(getVendorRegisterDetailsStatusRequest());
}

export function getVendorRegisterDetailsStatusRequest() {
  return {
    type: GetVendorRegisterDetailsActions.FETCH_VENDOR_REGISTER_DETAILS_STATUS,
  };
}

//DELETE VENDOR ADDRESS
export const DeleteVendorAddressActions = {
  DELETE_VENDOR_ADDRESS: "DELETE_VENDOR_ADDRESS",
  DELETE_VENDOR_ADDRESS_STATUS: "DELETE_VENDOR_ADDRESS_STATUS",
  DELETE_VENDOR_ADDRESS_FULFILLED: "DELETE_VENDOR_ADDRESS_FULFILLED",
  DELETE_VENDOR_ADDRESS_REJECTED: "DELETE_VENDOR_ADDRESS_REJECTED",
};
export function deleteVendorAddress(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${VendorService_URL}/Apc_State/Amc_89/deleteVendorAddress?vendorId=${params.vendorId}&addressId=${params.addressId}&status=${params.isActive}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteVendorAddressRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteVendorAddressSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteVendorAddressError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorAddressError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorAddressError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorAddressError(err));
              break;
            case 500:
              dispatch(deleteVendorAddressError("Server Error"));
              break;
            default:
              dispatch(deleteVendorAddressError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function deleteVendorAddressRequest() {
  return {
    type: DeleteVendorAddressActions.DELETE_VENDOR_ADDRESS,
    isPosting: true,
  };
}
export function deleteVendorAddressError(error) {
  return {
    type: DeleteVendorAddressActions.DELETE_VENDOR_ADDRESS_REJECTED,
    payload: error,
  };
}

export function deleteVendorAddressSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: DeleteVendorAddressActions.DELETE_VENDOR_ADDRESS_FULFILLED,
      payload: props,
    });
  };
}

//DELETE VENDOR CONTACT
export const DeleteVendorContactActions = {
  DELETE_VENDOR_CONTACT: "DELETE_VENDOR_CONTACT",
  DELETE_VENDOR_CONTACT_STATUS: "DELETE_VENDOR_CONTACT_STATUS",
  DELETE_VENDOR_CONTACT_FULFILLED: "DELETE_VENDOR_CONTACT_FULFILLED",
  DELETE_VENDOR_CONTACT_REJECTED: "DELETE_VENDOR_CONTACT_REJECTED",
};
export function deleteVendorContact(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${VendorService_URL}/Apc_State/Amc_89/deleteVendorContact?vendorId=${params.vendorId}&contactId=${params.vendorContactId}&status=${params.isActive}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteVendorContactRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteVendorContactSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteVendorContactError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorContactError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorContactError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorContactError(err));
              break;
            case 500:
              dispatch(deleteVendorContactError("Server Error"));
              break;
            default:
              dispatch(deleteVendorContactError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function deleteVendorContactRequest() {
  return {
    type: DeleteVendorContactActions.DELETE_VENDOR_CONTACT,
    isPosting: true,
  };
}
export function deleteVendorContactError(error) {
  return {
    type: DeleteVendorContactActions.DELETE_VENDOR_CONTACT_REJECTED,
    payload: error,
  };
}

export function deleteVendorContactSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: DeleteVendorContactActions.DELETE_VENDOR_CONTACT_FULFILLED,
      payload: props,
    });
  };
}

//GET VENDOR DOCUMENTS BY VENDOR ID
export const GetVendorTnCDocumentsActions = {
  FETCH_VENDOR_TNC_DOCUMENTS: "FETCH_VENDOR_TNC_DOCUMENTS",
  FETCH_VENDOR_TNC_DOCUMENTS_STATUS: "FETCH_VENDOR_TNC_DOCUMENTS_STATUS",
  FETCH_VENDOR_TNC_DOCUMENTS_FULFILLED: "FETCH_VENDOR_TNC_DOCUMENTS_FULFILLED",
  FETCH_VENDOR_TNC_DOCUMENTS_REJECTED: "FETCH_VENDOR_TNC_DOCUMENTS_REJECTED",
};

export function getVendorTnCDocuments(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${VendorService_URL}/Apc_View/Amc_89/getVendorDocumentsByVendorId?vendorId=${params.vendorId}&status=${params.isActive}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVendorTnCDocumentsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVendorTnCDocumentsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVendorTnCDocumentsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTnCDocumentsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTnCDocumentsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTnCDocumentsError(err));
              break;
            case 500:
              dispatch(getVendorTnCDocumentsError("Server Error"));
              break;
            default:
              dispatch(getVendorTnCDocumentsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVendorTnCDocumentsRequest() {
  return {
    type: GetVendorTnCDocumentsActions.FETCH_VENDOR_TNC_DOCUMENTS,
    isPosting: true,
  };
}
export function getVendorTnCDocumentsError(error) {
  return {
    type: GetVendorTnCDocumentsActions.FETCH_VENDOR_TNC_DOCUMENTS_REJECTED,
    payload: error,
  };
}

export function getVendorTnCDocumentsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVendorTnCDocumentsActions.FETCH_VENDOR_TNC_DOCUMENTS_FULFILLED,
      payload: props,
    });
  };
}

export function getVendorTnCDocumentsStatus() {
  return (dispatch) => dispatch(getVendorTnCDocumentsStatusRequest());
}

export function getVendorTnCDocumentsStatusRequest() {
  return {
    type: GetVendorTnCDocumentsActions.FETCH_VENDOR_TNC_DOCUMENTS_STATUS,
  };
}

//SAVE VENDOR TERMS AND CONDITION DOCUMENTS
export const SaveVendorTnCDocumentsActions = {
  SAVE_VENDOR_TNC_DOCUMENTS: "SAVE_VENDOR_TNC_DOCUMENTS",
  SAVE_VENDOR_TNC_DOCUMENTS_STATUS: "SAVE_VENDOR_TNC_DOCUMENTS_STATUS",
  SAVE_VENDOR_TNC_DOCUMENTS_SUCCESS: "SAVE_VENDOR_TNC_DOCUMENTS_SUCCESS",
  SAVE_VENDOR_TNC_DOCUMENTS_FAILURE: "SAVE_VENDOR_TNC_DOCUMENTS_FAILURE",
};

export function SaveVendorTnCDocumentsRequest() {
  return {
    type: SaveVendorTnCDocumentsActions.SAVE_VENDOR_TNC_DOCUMENTS,
    isPosting: true,
  };
}

export function SaveVendorTnCDocumentsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorTnCDocumentsActions.SAVE_VENDOR_TNC_DOCUMENTS_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorTnCDocumentsFailure(error) {
  return {
    type: SaveVendorTnCDocumentsActions.SAVE_VENDOR_TNC_DOCUMENTS_FAILURE,
    payload: error,
  };
}

export function SaveVendorTnCDocumentsError(error) {
  return {
    type: SaveVendorTnCDocumentsActions.SAVE_VENDOR_TNC_DOCUMENTS_FAILURE,
    payload: error,
  };
}

export function saveVendorTnCDocuments(data, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data,
      url: `${VendorService_URL}/Apc_Persist/Amc_89/saveVendorDocuments`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorTnCDocumentsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorTnCDocumentsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorTnCDocumentsError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//DELETE VENDOR Terms And Conditions Documents
export const DeleteVendorTnCDocumentsActions = {
  DELETE_VENDOR_TNC_DOCUMENTS: "DELETE_VENDOR_TNC_DOCUMENTS",
  DELETE_VENDOR_TNC_DOCUMENTS_STATUS: "DELETE_VENDOR_TNC_DOCUMENTS_STATUS",
  DELETE_VENDOR_TNC_DOCUMENTS_FULFILLED:
    "DELETE_VENDOR_TNC_DOCUMENTS_FULFILLED",
  DELETE_VENDOR_TNC_DOCUMENTS_REJECTED: "DELETE_VENDOR_TNC_DOCUMENTS_REJECTED",
};
export function deleteVendorTnCDocuments(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${VendorService_URL}/Apc_State/Amc_89/deleteVendorDocument?vendorId=${params.vendorId}&documentId=${params.documentId}&status=${params.isActive}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteVendorTnCDocumentsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteVendorTnCDocumentsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteVendorTnCDocumentsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorTnCDocumentsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorTnCDocumentsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(deleteVendorTnCDocumentsError(err));
              break;
            case 500:
              dispatch(deleteVendorTnCDocumentsError("Server Error"));
              break;
            default:
              dispatch(deleteVendorTnCDocumentsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function deleteVendorTnCDocumentsRequest() {
  return {
    type: DeleteVendorTnCDocumentsActions.DELETE_VENDOR_TNC_DOCUMENTS,
    isPosting: true,
  };
}
export function deleteVendorTnCDocumentsError(error) {
  return {
    type: DeleteVendorTnCDocumentsActions.DELETE_VENDOR_TNC_DOCUMENTS_REJECTED,
    payload: error,
  };
}

export function deleteVendorTnCDocumentsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: DeleteVendorTnCDocumentsActions.DELETE_VENDOR_TNC_DOCUMENTS_FULFILLED,
      payload: props,
    });
  };
}

//SAVE VENDOR TERMS AND CONDITION DOCUMENTS REVIEW
export const SaveVendorTnCDocumentsReviewActions = {
  SAVE_VENDOR_TNC_DOCUMENTS_REVIEW: "SAVE_VENDOR_TNC_DOCUMENTS_REVIEW",
  SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_STATUS:
    "SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_STATUS",
  SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_SUCCESS:
    "SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_SUCCESS",
  SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_FAILURE:
    "SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_FAILURE",
};

export function SaveVendorTnCDocumentsReviewRequest() {
  return {
    type: SaveVendorTnCDocumentsReviewActions.SAVE_VENDOR_TNC_DOCUMENTS_REVIEW,
    isPosting: true,
  };
}

export function SaveVendorTnCDocumentsReviewSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorTnCDocumentsReviewActions.SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorTnCDocumentsReviewFailure(error) {
  return {
    type: SaveVendorTnCDocumentsReviewActions.SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_FAILURE,
    payload: error,
  };
}

export function SaveVendorTnCDocumentsReviewError(error) {
  return {
    type: SaveVendorTnCDocumentsReviewActions.SAVE_VENDOR_TNC_DOCUMENTS_REVIEW_FAILURE,
    payload: error,
  };
}

export function saveVendorTnCDocumentsReview({ data, access_token }) {
  return (dispatch) =>
    axios({
      method: "post",
      data,
      url: `${VendorService_URL}/Apc_View/Amc_89/saveVendorDocumentStatus`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorTnCDocumentsReviewRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorTnCDocumentsReviewSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorTnCDocumentsReviewError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET VIEW DOCUMENT
export const GetViewDocumentsActions = {
  FETCH_VIEW_DOCUMENTS: "FETCH_VIEW_DOCUMENTS",
  FETCH_VIEW_DOCUMENTS_STATUS: "FETCH_VIEW_DOCUMENTS_STATUS",
  FETCH_VIEW_DOCUMENTS_FULFILLED: "FETCH_VIEW_DOCUMENTS_FULFILLED",
  FETCH_VIEW_DOCUMENTS_REJECTED: "FETCH_VIEW_DOCUMENTS_REJECTED",
};

export function getViewDocumentDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${VendorService_URL}/Apc_View/Amc_89/getVendorDocumentByVendorIdAndDocumentIdForExternalPortal?vendorId=${params.vendorId}&documentId=${params.documentId}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getViewDocumentsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getViewDocumentSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getViewDocumentsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getViewDocumentsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getViewDocumentsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getViewDocumentsError(err));
              break;
            case 500:
              dispatch(getViewDocumentsError("Server Error"));
              break;
            default:
              dispatch(getViewDocumentsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getViewDocumentsRequest() {
  return {
    type: GetViewDocumentsActions.FETCH_VIEW_DOCUMENTS,
    isPosting: true,
  };
}
export function getViewDocumentsError(error) {
  return {
    type: GetViewDocumentsActions.FETCH_VIEW_DOCUMENTS_REJECTED,
    payload: error,
  };
}

export function getViewDocumentSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetViewDocumentsActions.FETCH_VIEW_DOCUMENTS_FULFILLED,
      payload: props,
    });
  };
}

export function getViewDocumentStatus() {
  return (dispatch) => dispatch(getViewDocumentStatusRequest());
}

export function getViewDocumentStatusRequest() {
  return {
    type: GetViewDocumentsActions.FETCH_VIEW_DOCUMENTS_STATUS,
  };
}

// ACTIVE VENDOR LIST
export const GetActiveVendorActions = {
  FETCH_ACTIVE_VENDORS: "FETCH_ACTIVE_VENDORS",
  FETCH_ACTIVE_VENDOR_STATUS: "FETCH_ACTIVE_VENDOR_STATUS",
  FETCH_ACTIVE_VENDOR_FULFILLED: "FETCH_ACTIVE_VENDOR_FULFILLED",
  FETCH_ACTIVE_VENDOR_REJECTED: "FETCH_ACTIVE_VENDOR_REJECTED",
};

export function getActiveVendorList(params) {
  var dataToPost = {
    defaultSort: {
      sortField: "vendorId",
      sortOrder: "desc",
    },
    sort: [],
    search: [],
    length: 1000,
    start: 0,
    status: true,
  };
  return (dispatch) =>
    axios({
      method: "POST",
      data: dataToPost,
      url: `${VendorService_URL}/Apc_View/Amc_89/getAllVendorMasterPagination`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveVendorListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveVendorListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveVendorListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorListError(err));
              break;
            case 500:
              dispatch(getActiveVendorListError("Server Error"));
              break;
            default:
              dispatch(getActiveVendorListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveVendorListRequest() {
  return {
    type: GetActiveVendorActions.FETCH_ACTIVE_VENDORS,
    isPosting: true,
  };
}
export function getActiveVendorListError(error) {
  return {
    type: GetActiveVendorActions.FETCH_ACTIVE_VENDOR_REJECTED,
    payload: error,
  };
}

export function getActiveVendorListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveVendorActions.FETCH_ACTIVE_VENDOR_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveVendorListStatus() {
  return (dispatch) => dispatch(getActiveVendorListStatusRequest());
}
export function getActiveVendorListStatusRequest() {
  return {
    type: GetActiveVendorActions.FETCH_ACTIVE_VENDOR_STATUS,
  };
}

//---------------------------VENDOR RISK RATING--------------------------//
//SAVE VENDOR RISK RATING
export const SaveVendorRiskRatingActions = {
  SAVE_VENDOR_RISK_RATING: "SAVE_VENDOR_RISK_RATING",
  SAVE_VENDOR_RISK_RATING_STATUS: "SAVE_VENDOR_RISK_RATING_STATUS",
  SAVE_VENDOR_RISK_RATING_SUCCESS: "SAVE_VENDOR_RISK_RATING_SUCCESS",
  SAVE_VENDOR_RISK_RATING_FAILURE: "SAVE_VENDOR_RISK_RATING_FAILURE",
};

export function SaveVendorRiskRatingRequest() {
  return {
    type: SaveVendorRiskRatingActions.SAVE_VENDOR_RISK_RATING,
    isPosting: true,
  };
}

export function SaveVendorRiskRatingSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorRiskRatingActions.SAVE_VENDOR_RISK_RATING_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorRiskRatingFailure(error) {
  return {
    type: SaveVendorRiskRatingActions.SAVE_VENDOR_RISK_RATING_FAILURE,
    payload: error,
  };
}

export function SaveVendorRiskRatingError(error) {
  return {
    type: SaveVendorRiskRatingActions.SAVE_VENDOR_RISK_RATING_FAILURE,
    payload: error,
  };
}

export function saveVendorRiskRating(props) {
  var value = {
    id: props.id || null,
    riskName: props.riskName,
    description: props.description,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${VendorService_URL}/Apc_Persist/Amc_97/saveVendorRisk`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorRiskRatingRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorRiskRatingSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorRiskRatingError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET VENDOR RISK RATING BY ID
export const GetVendorRiskRatingDetailsActions = {
  FETCH_VENDOR_RISK_RATING_DETAILS: "FETCH_VENDOR_RISK_RATING_DETAILS",
  FETCH_VENDOR_RISK_RATING_DETAILS_STATUS:
    "FETCH_VENDOR_RISK_RATING_DETAILS_STATUS",
  FETCH_VENDOR_RISK_RATING_DETAILS_FULFILLED:
    "FETCH_VENDOR_RISK_RATING_DETAILS_FULFILLED",
  FETCH_VENDOR_RISK_RATING_DETAILS_REJECTED:
    "FETCH_VENDOR_RISK_RATING_DETAILS_REJECTED",
};

export function getVendorRiskRatingDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${VendorService_URL}/Apc_Persist/Amc_97/getVendorRiskById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVendorRiskRatingDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVendorRiskRatingDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVendorRiskRatingDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorRiskRatingDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorRiskRatingDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorRiskRatingDetailsError(err));
              break;
            case 500:
              dispatch(getVendorRiskRatingDetailsError("Server Error"));
              break;
            default:
              dispatch(getVendorRiskRatingDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVendorRiskRatingDetailsRequest() {
  return {
    type: GetVendorRiskRatingDetailsActions.FETCH_VENDOR_RISK_RATING_DETAILS,
    isPosting: true,
  };
}
export function getVendorRiskRatingDetailsError(error) {
  return {
    type: GetVendorRiskRatingDetailsActions.FETCH_VENDOR_RISK_RATING_DETAILS_REJECTED,
    payload: error,
  };
}

export function getVendorRiskRatingDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVendorRiskRatingDetailsActions.FETCH_VENDOR_RISK_RATING_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getVendorRiskRatingDetailsStatus() {
  return (dispatch) => dispatch(getVendorRiskRatingDetailsStatusRequest());
}

export function getVendorRiskRatingDetailsStatusRequest() {
  return {
    type: GetVendorRiskRatingDetailsActions.FETCH_VENDOR_RISK_RATING_DETAILS_STATUS,
  };
}

//GET Active Vendor Risk Rating List
export const GetActiveVendorRiskRatingActions = {
  FETCH_ACTIVE_VENDOR_RISK_RATING: "FETCH_ACTIVE_VENDOR_RISK_RATING",
  FETCH_ACTIVE_VENDOR_RISK_RATING_STATUS:
    "FETCH_ACTIVE_VENDOR_RISK_RATING_STATUS",
  FETCH_ACTIVE_VENDOR_RISK_RATING_FULFILLED:
    "FETCH_ACTIVE_VENDOR_RISK_RATING_FULFILLED",
  FETCH_ACTIVE_VENDOR_RISK_RATING_REJECTED:
    "FETCH_ACTIVE_VENDOR_RISK_RATING_REJECTED",
};

export function getActiveVendorRiskRatingList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_89/getAllVendorRiskByCompanyId?companyId=${params.companyId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveVendorRiskRatingListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveVendorRiskRatingListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveVendorRiskRatingListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorRiskRatingListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorRiskRatingListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorRiskRatingListError(err));
              break;
            case 500:
              dispatch(getActiveVendorRiskRatingListError("Server Error"));
              break;
            default:
              dispatch(getActiveVendorRiskRatingListError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveVendorRiskRatingListRequest() {
  return {
    type: GetActiveVendorRiskRatingActions.FETCH_ACTIVE_VENDOR_RISK_RATING,
    isPosting: true,
  };
}
export function getActiveVendorRiskRatingListError(error) {
  return {
    type: GetActiveVendorRiskRatingActions.FETCH_ACTIVE_VENDOR_RISK_RATING_REJECTED,
    payload: error,
  };
}

export function getActiveVendorRiskRatingListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveVendorRiskRatingActions.FETCH_ACTIVE_VENDOR_RISK_RATING_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveVendorRiskRatingListStatus() {
  return (dispatch) => dispatch(getActiveVendorRiskRatingListStatusRequest());
}
export function getActiveVendorRiskRatingListStatusRequest() {
  return {
    type: GetActiveVendorRiskRatingActions.FETCH_ACTIVE_VENDOR_RISK_RATING_STATUS,
  };
}

//SAVE VENDOR RISK RATING SEQUENCE
export const SaveVendorRiskRatingSequenceActions = {
  SAVE_VENDOR_RISK_RATING_SEQUENCE: "SAVE_VENDOR_RISK_RATING_SEQUENCE",
  SAVE_VENDOR_RISK_RATING_SEQUENCE_STATUS:
    "SAVE_VENDOR_RISK_RATING_SEQUENCE_STATUS",
  SAVE_VENDOR_RISK_RATING_SEQUENCE_SUCCESS:
    "SAVE_VENDOR_RISK_RATING_SEQUENCE_SUCCESS",
  SAVE_VENDOR_RISK_RATING_SEQUENCE_FAILURE:
    "SAVE_VENDOR_RISK_RATING_SEQUENCE_FAILURE",
};

export function SaveVendorRiskRatingSequenceRequest() {
  return {
    type: SaveVendorRiskRatingSequenceActions.SAVE_VENDOR_RISK_RATING_SEQUENCE,
    isPosting: true,
  };
}

export function SaveVendorRiskRatingSequenceSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorRiskRatingSequenceActions.SAVE_VENDOR_RISK_RATING_SEQUENCE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorRiskRatingSequenceFailure(error) {
  return {
    type: SaveVendorRiskRatingSequenceActions.SAVE_VENDOR_RISK_RATING_SEQUENCE_FAILURE,
    payload: error,
  };
}

export function SaveVendorRiskRatingSequenceError(error) {
  return {
    type: SaveVendorRiskRatingSequenceActions.SAVE_VENDOR_RISK_RATING_SEQUENCE_FAILURE,
    payload: error,
  };
}

export function saveVendorRiskRatingSequence(props) {
  const value = {
    fieldDTOs: props.data,
    companyId: props.companyId,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${VendorService_URL}/Apc_Persist/Amc_97/chanageSequenceVendorRisk?status=${props.isActive}`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorRiskRatingSequenceRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorRiskRatingSequenceSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorRiskRatingSequenceError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET Active Vendor Risk Rating Sequence List
export const GetActiveVendorRiskRatingSequenceActions = {
  FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE:
    "FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE",
  FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_STATUS:
    "FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_STATUS",
  FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_FULFILLED:
    "FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_FULFILLED",
  FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_REJECTED:
    "FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_REJECTED",
};

export function getActiveVendorRiskRatingSequenceList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_97/getAllRiskByCompanyId?companyId=${params.companyId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveVendorRiskRatingSequenceListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveVendorRiskRatingSequenceListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveVendorRiskRatingSequenceListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorRiskRatingSequenceListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorRiskRatingSequenceListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorRiskRatingSequenceListError(err));
              break;
            case 500:
              dispatch(
                getActiveVendorRiskRatingSequenceListError("Server Error")
              );
              break;
            default:
              dispatch(
                getActiveVendorRiskRatingSequenceListError("Unknown Error")
              );
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveVendorRiskRatingSequenceListRequest() {
  return {
    type: GetActiveVendorRiskRatingSequenceActions.FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE,
    isPosting: true,
  };
}
export function getActiveVendorRiskRatingSequenceListError(error) {
  return {
    type: GetActiveVendorRiskRatingSequenceActions.FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_REJECTED,
    payload: error,
  };
}

export function getActiveVendorRiskRatingSequenceListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveVendorRiskRatingSequenceActions.FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveVendorRiskRatingSequenceListStatus() {
  return (dispatch) =>
    dispatch(getActiveVendorRiskRatingSequenceListStatusRequest());
}
export function getActiveVendorRiskRatingSequenceListStatusRequest() {
  return {
    type: GetActiveVendorRiskRatingSequenceActions.FETCH_ACTIVE_VENDOR_RISK_RATING_SEQUENCE_STATUS,
  };
}

//---------------------------VENDOR TIER--------------------------//
//SAVE VENDOR TIER
export const SaveVendorTierActions = {
  SAVE_VENDOR_TIER: "SAVE_VENDOR_TIER",
  SAVE_VENDOR_TIER_STATUS: "SAVE_VENDOR_TIER_STATUS",
  SAVE_VENDOR_TIER_SUCCESS: "SAVE_VENDOR_TIER_SUCCESS",
  SAVE_VENDOR_TIER_FAILURE: "SAVE_VENDOR_TIER_FAILURE",
};

export function SaveVendorTierRequest() {
  return {
    type: SaveVendorTierActions.SAVE_VENDOR_TIER,
    isPosting: true,
  };
}

export function SaveVendorTierSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorTierActions.SAVE_VENDOR_TIER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorTierFailure(error) {
  return {
    type: SaveVendorTierActions.SAVE_VENDOR_TIER_FAILURE,
    payload: error,
  };
}

export function SaveVendorTierError(error) {
  return {
    type: SaveVendorTierActions.SAVE_VENDOR_TIER_FAILURE,
    payload: error,
  };
}

export function saveVendorTier(props) {
  var value = {
    id: props.id || null,
    tierName: props.tierName,
    description: props.description,
    fromScore: props.fromScore,
    toScore: props.toScore,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${VendorService_URL}/Apc_Persist/Amc_98/saveVendorTier`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorTierRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorTierSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorTierError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET VENDOR TIER BY ID
export const GetVendorTierDetailsActions = {
  FETCH_VENDOR_TIER_DETAILS: "FETCH_VENDOR_TIER_DETAILS",
  FETCH_VENDOR_TIER_DETAILS_STATUS: "FETCH_VENDOR_TIER_DETAILS_STATUS",
  FETCH_VENDOR_TIER_DETAILS_FULFILLED: "FETCH_VENDOR_TIER_DETAILS_FULFILLED",
  FETCH_VENDOR_TIER_DETAILS_REJECTED: "FETCH_VENDOR_TIER_DETAILS_REJECTED",
};

export function getVendorTierDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${VendorService_URL}/Apc_Persist/Amc_98/getVendorTierById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVendorTierDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVendorTierDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVendorTierDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTierDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTierDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorTierDetailsError(err));
              break;
            case 500:
              dispatch(getVendorTierDetailsError("Server Error"));
              break;
            default:
              dispatch(getVendorTierDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVendorTierDetailsRequest() {
  return {
    type: GetVendorTierDetailsActions.FETCH_VENDOR_TIER_DETAILS,
    isPosting: true,
  };
}
export function getVendorTierDetailsError(error) {
  return {
    type: GetVendorTierDetailsActions.FETCH_VENDOR_TIER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getVendorTierDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVendorTierDetailsActions.FETCH_VENDOR_TIER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getVendorTierDetailsStatus() {
  return (dispatch) => dispatch(getVendorTierDetailsStatusRequest());
}

export function getVendorTierDetailsStatusRequest() {
  return {
    type: GetVendorTierDetailsActions.FETCH_VENDOR_TIER_DETAILS_STATUS,
  };
}

//GET Active Vendor Tier List
export const GetActiveVendorTierActions = {
  FETCH_ACTIVE_VENDOR_TIER: "FETCH_ACTIVE_VENDOR_TIER",
  FETCH_ACTIVE_VENDOR_TIER_STATUS: "FETCH_ACTIVE_VENDOR_TIER_STATUS",
  FETCH_ACTIVE_VENDOR_TIER_FULFILLED: "FETCH_ACTIVE_VENDOR_TIER_FULFILLED",
  FETCH_ACTIVE_VENDOR_TIER_REJECTED: "FETCH_ACTIVE_VENDOR_TIER_REJECTED",
};

export function getActiveVendorTierList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_89/getAllVendorTierByCompanyId?companyId=${params.companyId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveVendorTierListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveVendorTierListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveVendorTierListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTierListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTierListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTierListError(err));
              break;
            case 500:
              dispatch(getActiveVendorTierListError("Server Error"));
              break;
            default:
              dispatch(getActiveVendorTierListError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveVendorTierListRequest() {
  return {
    type: GetActiveVendorTierActions.FETCH_ACTIVE_VENDOR_TIER,
    isPosting: true,
  };
}
export function getActiveVendorTierListError(error) {
  return {
    type: GetActiveVendorTierActions.FETCH_ACTIVE_VENDOR_TIER_REJECTED,
    payload: error,
  };
}

export function getActiveVendorTierListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveVendorTierActions.FETCH_ACTIVE_VENDOR_TIER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveVendorTierListStatus() {
  return (dispatch) => dispatch(getActiveVendorTierListStatusRequest());
}
export function getActiveVendorTierListStatusRequest() {
  return {
    type: GetActiveVendorTierActions.FETCH_ACTIVE_VENDOR_TIER_STATUS,
  };
}

//SAVE VENDOR TIER SEQUENCE
export const SaveVendorTierSequenceActions = {
  SAVE_VENDOR_TIER_SEQUENCE: "SAVE_VENDOR_TIER_SEQUENCE",
  SAVE_VENDOR_TIER_SEQUENCE_STATUS: "SAVE_VENDOR_TIER_SEQUENCE_STATUS",
  SAVE_VENDOR_TIER_SEQUENCE_SUCCESS: "SAVE_VENDOR_TIER_SEQUENCE_SUCCESS",
  SAVE_VENDOR_TIER_SEQUENCE_FAILURE: "SAVE_VENDOR_TIER_SEQUENCE_FAILURE",
};

export function SaveVendorTierSequenceRequest() {
  return {
    type: SaveVendorTierSequenceActions.SAVE_VENDOR_TIER_SEQUENCE,
    isPosting: true,
  };
}

export function SaveVendorTierSequenceSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVendorTierSequenceActions.SAVE_VENDOR_TIER_SEQUENCE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVendorTierSequenceFailure(error) {
  return {
    type: SaveVendorTierSequenceActions.SAVE_VENDOR_TIER_SEQUENCE_FAILURE,
    payload: error,
  };
}

export function SaveVendorTierSequenceError(error) {
  return {
    type: SaveVendorTierSequenceActions.SAVE_VENDOR_TIER_SEQUENCE_FAILURE,
    payload: error,
  };
}

export function saveVendorTierSequence(props) {
  const value = {
    fieldDTOs: props.data,
    companyId: props.companyId,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${VendorService_URL}/Apc_Persist/Amc_98/chanageSequenceVendorTier?status=${props.isActive}`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVendorTierSequenceRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVendorTierSequenceSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVendorTierSequenceError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET Active Vendor Tier Sequence List
export const GetActiveVendorTierSequenceActions = {
  FETCH_ACTIVE_VENDOR_TIER_SEQUENCE: "FETCH_ACTIVE_VENDOR_TIER_SEQUENCE",
  FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_STATUS:
    "FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_STATUS",
  FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_FULFILLED:
    "FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_FULFILLED",
  FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_REJECTED:
    "FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_REJECTED",
};

export function getActiveVendorTierSequenceList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_98/getAllTierByCompanyId?companyId=${params.companyId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveVendorTierSequenceListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveVendorTierSequenceListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveVendorTierSequenceListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTierSequenceListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTierSequenceListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVendorTierSequenceListError(err));
              break;
            case 500:
              dispatch(getActiveVendorTierSequenceListError("Server Error"));
              break;
            default:
              dispatch(getActiveVendorTierSequenceListError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveVendorTierSequenceListRequest() {
  return {
    type: GetActiveVendorTierSequenceActions.FETCH_ACTIVE_VENDOR_TIER_SEQUENCE,
    isPosting: true,
  };
}
export function getActiveVendorTierSequenceListError(error) {
  return {
    type: GetActiveVendorTierSequenceActions.FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_REJECTED,
    payload: error,
  };
}

export function getActiveVendorTierSequenceListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveVendorTierSequenceActions.FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveVendorTierSequenceListStatus() {
  return (dispatch) => dispatch(getActiveVendorTierSequenceListStatusRequest());
}
export function getActiveVendorTierSequenceListStatusRequest() {
  return {
    type: GetActiveVendorTierSequenceActions.FETCH_ACTIVE_VENDOR_TIER_SEQUENCE_STATUS,
  };
}

//
//GET getVendor ExternalUsers
export const VenderIdListActions = {
  FETCH_VENDER_LIST_DETAILS: "FETCH_VENDER_LIST_DETAILS",
  FETCH_VENDER_LIST_DETAILS_STATUS: "FETCH_VENDER_LIST_DETAILS_STATUS",
  FETCH_VENDER_LIST_DETAILS_FULFILLED: "FETCH_VENDER_LIST_DETAILS_FULFILLED",
  FETCH_VENDER_LIST_DETAILS_REJECTED: "FETCH_VENDER_LIST_DETAILS_REJECTED",
};

export function getVenderIdListDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${VendorService_URL}/Apc_View/Amc_89/getVendorExternalUsers?vendorIdList=${params.venderIdList}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVenderIdListDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVenderIdListDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVenderIdListDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVenderIdListDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVenderIdListDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVenderIdListDetailsError(err));
              break;
            case 500:
              dispatch(getVenderIdListDetailsError("Server Error"));
              break;
            default:
              dispatch(getVenderIdListDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVenderIdListDetailsRequest() {
  return {
    type: VenderIdListActions.FETCH_VENDER_LIST_DETAILS,
    isPosting: true,
  };
}
export function getVenderIdListDetailsError(error) {
  return {
    type: VenderIdListActions.FETCH_VENDER_LIST_DETAILS_REJECTED,
    payload: error,
  };
}

export function getVenderIdListDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: VenderIdListActions.FETCH_VENDER_LIST_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getVenderIdListDetailsStatus() {
  return (dispatch) => dispatch(getVenderIdListDetailsRequest());
}

export function getVenderIdListDetailsStatusRequest() {
  return {
    type: VenderIdListActions.FETCH_VENDER_LIST_DETAILS_STATUS,
  };
}

//SAVE VENDOR DOCUMENT TYPE
export const SaveDocumentTypeActions = {
  SAVE_DOCUMENT_TYPE: "SAVE_DOCUMENT_TYPE",
  SAVE_DOCUMENT_TYPE_STATUS: "SAVE_DOCUMENT_TYPE_STATUS",
  SAVE_DOCUMENT_TYPE_SUCCESS: "SAVE_DOCUMENT_TYPE_SUCCESS",
  SAVE_DOCUMENT_TYPE_FAILURE: "SAVE_DOCUMENT_TYPE_FAILURE",
};

export function SaveDocumentTypeRequest() {
  return {
    type: SaveDocumentTypeActions.SAVE_DOCUMENT_TYPE,
    isPosting: true,
  };
}

export function SaveDocumentTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDocumentTypeActions.SAVE_DOCUMENT_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDocumentTypeFailure(error) {
  return {
    type: SaveDocumentTypeActions.SAVE_DOCUMENT_TYPE_FAILURE,
    payload: error,
  };
}

export function SaveDocumentTypeError(error) {
  return {
    type: SaveDocumentTypeActions.SAVE_DOCUMENT_TYPE_FAILURE,
    payload: error,
  };
}

export function saveDocumentType(props) {
  var value = {
    id: props.id || null,
    name: props.name,
    description: props.description,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${VendorService_URL}/Apc_View/Amc_99/saveVendorDocumentType`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDocumentTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDocumentTypeSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveDocumentTypeError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET VENDOR DOCUMENT TYPE BY ID
export const GetDocumentTypeDetailsActions = {
  FETCH_DOCUMENT_TYPE_DETAILS: "FETCH_DOCUMENT_TYPE_DETAILS",
  FETCH_DOCUMENT_TYPE_DETAILS_STATUS: "FETCH_DOCUMENT_TYPE_DETAILS_STATUS",
  FETCH_DOCUMENT_TYPE_DETAILS_FULFILLED:
    "FETCH_DOCUMENT_TYPE_DETAILS_FULFILLED",
  FETCH_DOCUMENT_TYPE_DETAILS_REJECTED: "FETCH_DOCUMENT_TYPE_DETAILS_REJECTED",
};

export function getDocumentTypeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${VendorService_URL}/Apc_View/Amc_99/findVendorDocumentTypeById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDocumentTypeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDocumentTypeDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getDocumentTypeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getDocumentTypeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getDocumentTypeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getDocumentTypeDetailsError(err));
              break;
            case 500:
              dispatch(getDocumentTypeDetailsError("Server Error"));
              break;
            default:
              dispatch(getDocumentTypeDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getDocumentTypeDetailsRequest() {
  return {
    type: GetDocumentTypeDetailsActions.FETCH_DOCUMENT_TYPE_DETAILS,
    isPosting: true,
  };
}
export function getDocumentTypeDetailsError(error) {
  return {
    type: GetDocumentTypeDetailsActions.FETCH_DOCUMENT_TYPE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDocumentTypeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetDocumentTypeDetailsActions.FETCH_DOCUMENT_TYPE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDocumentTypeDetailsStatus() {
  return (dispatch) => dispatch(getDocumentTypeDetailsStatusRequest());
}

export function getDocumentTypeDetailsStatusRequest() {
  return {
    type: GetDocumentTypeDetailsActions.FETCH_DOCUMENT_TYPE_DETAILS_STATUS,
  };
}

//GET Active Vendor Document Type List
export const GetActiveDocumentTypeActions = {
  FETCH_ACTIVE_DOCUMENT_TYPE: "FETCH_ACTIVE_DOCUMENT_TYPE",
  FETCH_ACTIVE_DOCUMENT_TYPE_STATUS: "FETCH_ACTIVE_DOCUMENT_TYPE_STATUS",
  FETCH_ACTIVE_DOCUMENT_TYPE_FULFILLED: "FETCH_ACTIVE_DOCUMENT_TYPE_FULFILLED",
  FETCH_ACTIVE_DOCUMENT_TYPE_REJECTED: "FETCH_ACTIVE_DOCUMENT_TYPE_REJECTED",
};

export function getActiveDocumentTypeList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_99/getAllVendorDocumentTypes`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDocumentTypeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDocumentTypeListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveDocumentTypeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDocumentTypeListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDocumentTypeListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDocumentTypeListError(err));
              break;
            case 500:
              dispatch(getActiveDocumentTypeListError("Server Error"));
              break;
            default:
              dispatch(getActiveDocumentTypeListError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDocumentTypeListRequest() {
  return {
    type: GetActiveDocumentTypeActions.FETCH_ACTIVE_DOCUMENT_TYPE,
    isPosting: true,
  };
}
export function getActiveDocumentTypeListError(error) {
  return {
    type: GetActiveDocumentTypeActions.FETCH_ACTIVE_DOCUMENT_TYPE_REJECTED,
    payload: error,
  };
}

export function getActiveDocumentTypeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDocumentTypeActions.FETCH_ACTIVE_DOCUMENT_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDocumentTypeListStatus() {
  return (dispatch) => dispatch(getActiveDocumentTypeListStatusRequest());
}
export function getActiveDocumentTypeListStatusRequest() {
  return {
    type: GetActiveDocumentTypeActions.FETCH_ACTIVE_DOCUMENT_TYPE_STATUS,
  };
}

//VENDOR DASHBOARD
// GET VENDOR DASHBOARD
export const GetVendorDashboardActions = {
  FETCH_VENDOR_DASHBOARD: "FETCH_VENDOR_DASHBOARD",
  FETCH_VENDOR_DASHBOARD_STATUS: "FETCH_VENDOR_DASHBOARD_STATUS",
  FETCH_VENDOR_DASHBOARD_FULFILLED: "FETCH_VENDOR_DASHBOARD_FULFILLED",
  FETCH_VENDOR_DASHBOARD_REJECTED: "FETCH_VENDOR_DASHBOARD_REJECTED",
};

export function getVendorDashboardData(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${VendorService_URL}/Apc_View/Amc_89/getVendorDashboard`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVendorDashboardDataRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVendorDashboardDataSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVendorDashboardDataError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVendorDashboardDataError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVendorDashboardDataError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVendorDashboardDataError(err));
              break;
            case 500:
              dispatch(getVendorDashboardDataError("Server Error"));
              break;
            default:
              dispatch(getVendorDashboardDataError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVendorDashboardDataRequest() {
  return {
    type: GetVendorDashboardActions.FETCH_VENDOR_DASHBOARD,
    isPosting: true,
  };
}
export function getVendorDashboardDataError(error) {
  return {
    type: GetVendorDashboardActions.FETCH_VENDOR_DASHBOARD_REJECTED,
    payload: error,
  };
}

export function getVendorDashboardDataSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVendorDashboardActions.FETCH_VENDOR_DASHBOARD_FULFILLED,
      payload: props,
    });
  };
}

export function getVendorDashboardDataStatus() {
  return (dispatch) => dispatch(getVendorDashboardDataStatusRequest());
}
export function getVendorDashboardDataStatusRequest() {
  return {
    type: GetVendorDashboardActions.FETCH_VENDOR_DASHBOARD_STATUS,
  };
}

//SAVE TIER SCALE
export const SaveTierScaleActions = {
  SAVE_TIER_SCALE: "SAVE_TIER_SCALE",
  SAVE_TIER_SCALE_STATUS: "SAVE_TIER_SCALE_STATUS",
  SAVE_TIER_SCALE_SUCCESS: "SAVE_TIER_SCALE_SUCCESS",
  SAVE_TIER_SCALE_FAILURE: "SAVE_TIER_SCALE_FAILURE",
};

export function SaveTierScaleRequest() {
  return {
    type: SaveTierScaleActions.SAVE_TIER_SCALE,
    isPosting: true,
  };
}

export function SaveTierScaleSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveTierScaleActions.SAVE_TIER_SCALE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveTierScaleFailure(error) {
  return {
    type: SaveTierScaleActions.SAVE_TIER_SCALE_FAILURE,
    payload: error,
  };
}

export function SaveTierScaleError(error) {
  return {
    type: SaveTierScaleActions.SAVE_TIER_SCALE_FAILURE,
    payload: error,
  };
}

export function saveTierScale(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props.data,
      url: `${VendorService_URL}/Apc_Persist/Amc_98/savetTierScales`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveTierScaleRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveTierScaleSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveTierScaleError(error));
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET TIER SCALES
export const GetTierScaleActions = {
  FETCH_TIER_SCALE_DETAILS: "FETCH_TIER_SCALE_DETAILS",
  FETCH_TIER_SCALE_DETAILS_STATUS: "FETCH_TIER_SCALE_DETAILS_STATUS",
  FETCH_TIER_SCALE_DETAILS_FULFILLED: "FETCH_TIER_SCALE_DETAILS_FULFILLED",
  FETCH_TIER_SCALE_DETAILS_REJECTED: "FETCH_TIER_SCALE_DETAILS_REJECTED",
};

export function getTierScale(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${VendorService_URL}/Apc_Persist/Amc_98/findCompanyTierScales`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getTierScaleRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getTierScaleSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getTierScaleError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getTierScaleError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getTierScaleError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getTierScaleError(err));
              break;
            case 500:
              dispatch(getTierScaleError("Server Error"));
              break;
            default:
              dispatch(getTierScaleError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getTierScaleRequest() {
  return {
    type: GetTierScaleActions.FETCH_TIER_SCALE_DETAILS,
    isPosting: true,
  };
}
export function getTierScaleError(error) {
  return {
    type: GetTierScaleActions.FETCH_TIER_SCALE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getTierScaleSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetTierScaleActions.FETCH_TIER_SCALE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getTierScaleStatus() {
  return (dispatch) => dispatch(getTierScaleStatusRequest());
}

export function getTierScaleStatusRequest() {
  return {
    type: GetTierScaleActions.FETCH_TIER_SCALE_DETAILS_STATUS,
  };
}

//GET VEDNOR SUMMARY BY ID
export const GetVendorSummaryActions = {
  FETCH_VENDOR_SUMMARY_DETAILS: "FETCH_VENDOR_SUMMARY_DETAILS",
  FETCH_VENDOR_SUMMARY_DETAILS_STATUS: "FETCH_VENDOR_SUMMARY_DETAILS_STATUS",
  FETCH_VENDOR_SUMMARY_DETAILS_FULFILLED:
    "FETCH_VENDOR_SUMMARY_DETAILS_FULFILLED",
  FETCH_VENDOR_SUMMARY_DETAILS_REJECTED:
    "FETCH_VENDOR_SUMMARY_DETAILS_REJECTED",
};

export function getVendorSummary(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${VendorService_URL}/Apc_View/Amc_89/getVendorSummary?vendorId=${params.vendorId}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVendorSummaryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVendorSummarySuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getVendorSummaryError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorSummaryError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorSummaryError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
              map.forEach((ele) => (err = ele));
              dispatch(getVendorSummaryError(err));
              break;
            case 500:
              dispatch(getVendorSummaryError("Server Error"));
              break;
            default:
              dispatch(getVendorSummaryError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getVendorSummaryRequest() {
  return {
    type: GetVendorSummaryActions.FETCH_VENDOR_SUMMARY_DETAILS,
    isPosting: true,
  };
}
export function getVendorSummaryError(error) {
  return {
    type: GetVendorSummaryActions.FETCH_VENDOR_SUMMARY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getVendorSummarySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVendorSummaryActions.FETCH_VENDOR_SUMMARY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getVendorSummaryStatus() {
  return (dispatch) => dispatch(getVendorSummaryStatusRequest());
}

export function getVendorSummaryStatusRequest() {
  return {
    type: GetVendorSummaryActions.FETCH_VENDOR_SUMMARY_DETAILS_STATUS,
  };
}
// get doc from google drive
export const GetGoogleDriveOpenDocActions = {
  FETCH_OPEN_DOC: "FETCH_OPEN_DOC",
  FETCH_OPEN_DOC_STATUS: "FETCH_OPEN_DOC_STATUS",
  FETCH_OPEN_DOC_FULFILLED: "FETCH_OPEN_DOC_FULFILLED",
  FETCH_OPEN_DOC_REJECTED: "FETCH_OPEN_DOC_REJECTED",
};

export function getGoogleDriveOpenDocList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${GoogleDrive_URL}/openDocument?fileId=${params.fileId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getGoogleDriveOpenDocRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getGoogleDriveOpenDocSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getGoogleDriveOpenDocError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getGoogleDriveOpenDocError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getGoogleDriveOpenDocError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getGoogleDriveOpenDocError(err));
              break;
            case 500:
              dispatch(getGoogleDriveOpenDocError("Server Error"));
              break;
            default:
              dispatch(getGoogleDriveOpenDocError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getGoogleDriveOpenDocRequest() {
  return {
    type: GetGoogleDriveOpenDocActions.FETCH_OPEN_DOC,
    isPosting: true,
  };
}
export function getGoogleDriveOpenDocError(error) {
  return {
    type: GetGoogleDriveOpenDocActions.FETCH_OPEN_DOC_REJECTED,
    payload: error,
  };
}

export function getGoogleDriveOpenDocSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetGoogleDriveOpenDocActions.FETCH_OPEN_DOC_FULFILLED,
      payload: props,
    });
  };
}

export function getGoogleDriveOpenDocStatus() {
  return (dispatch) => dispatch(getActiveVendorCategoryListStatusRequest());
}
export function getGoogleDriveOpenDocStatusRequest() {
  return {
    type: GetGoogleDriveOpenDocActions.FETCH_OPEN_DOC_STATUS,
  };
}

//save share document details
export const saveShareDocumentActions = {
  SAVE_SHARE_DOCUMENT_TYPE: "SAVE_SHARE_DOCUMENT_TYPE",
  SAVE_SHARE_DOCUMEN_TYPE_STATUS: "SAVE_SHARE_DOCUMEN_TYPE_STATUS",
  SAVE_SHARE_DOCUMEN_TYPE_SUCCESS: "SAVE_SHARE_DOCUMEN_TYPE_SUCCESS",
  SAVE_SHARE_DOCUMEN_TYPE_FAILURE: "SAVE_SHARE_DOCUMEN_TYPE_FAILURE",
};

export function saveShareDocumentTypeRequest() {
  return {
    type: saveShareDocumentActions.SAVE_SHARE_DOCUMENT_TYPE,
    isPosting: true,
  };
}

export function saveShareDocumentTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveShareDocumentActions.SAVE_SHARE_DOCUMEN_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function saveShareDocumentTypeFailure(error) {
  return {
    type: saveShareDocumentActions.SAVE_SHARE_DOCUMEN_TYPE_FAILURE,
    payload: error,
  };
}

export function saveShareDocumentTypeError(error) {
  return {
    type: saveShareDocumentActions.SAVE_SHARE_DOCUMEN_TYPE_FAILURE,
    payload: error,
  };
}

export function saveShareDocumentDetails(props) {
  var value = {
    fileId: props.data.fileId,
    emailRoles: props.data.emailRoles,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${GoogleDrive_URL}/shareDocument`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveShareDocumentTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveShareDocumentTypeSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveShareDocumentTypeError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));
          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET VENDOR TIER ASSESSMENT 
export const GetAllTierAssessmentActions = {
  FETCH_ALL_TIER_ASSESSMENT: "FETCH_ALL_TIER_ASSESSMENT",
  FETCH_ALL_TIER_ASSESSMENT_STATUS: "FETCH_ALL_TIER_ASSESSMENT_STATUS",
  FETCH_ALL_TIER_ASSESSMENT_FULFILLED: "FETCH_ALL_TIER_ASSESSMENT_FULFILLED",
  FETCH_ALL_TIER_ASSESSMENT_REJECTED: "FETCH_ALL_TIER_ASSESSMENT_REJECTED",
};
export function getAllTierAssessmentByStatus(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=0&length=10&sortOrder=desc&sortField=id&status=true&search=&searchCol=&type=1,2&surveyStatus=${params.surveyStatus}&isVendorTiering=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllTierAssessmentByStatusSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAllTierAssessmentByStatusError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getAllTierAssessmentByStatusRequest() {
  return {
    type: GetAllTierAssessmentActions.FETCH_ALL_TIER_ASSESSMENT,
    isPosting: true,
  };
}
export function getAllTierAssessmentByStatusError(error) {
  return {
    type: GetAllTierAssessmentActions.FETCH_ALL_TIER_ASSESSMENT_REJECTED,
    payload: error,
  };
}

export function getAllTierAssessmentByStatusSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAllTierAssessmentActions.FETCH_ALL_TIER_ASSESSMENT_FULFILLED,
      payload: props,
    });
  };
}
